import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { DateFormat, DateFormatUnit } from "../_enums/general/date";
import { FormatDate } from "../_helpers/formatHelper/formatDate"


@Pipe({
    name: 'generalRewrite'
})
export class GeneralRewritePipe implements PipeTransform {

    constructor(
        private _translateService: TranslateService,
        private formatDate: FormatDate,
    ) { }

    transform(): string {
        return '';
    }

    //used for rewrite an enum number value into the string (mostly used for translation
    enumRewrite(enumType: any, value: number): string {
        return enumType[value];
    }

    //used for enum translations
    enumTranslation(enumType: any, value: number, except): string {
        let currentEnumRewrite = this.enumRewrite(enumType, value);

        if (currentEnumRewrite == undefined) {
            return this.translate('BASE.UNKNOWN_TYPE', { type: value });
        }

        let currentEnumStringChain = this.enumNameForLocalization(except + currentEnumRewrite, except).toUpperCase();
        let translatedEnum: string = this._translateService.instant(currentEnumStringChain);

        if (translatedEnum.substring(0, 6) == 'ENUMS.') {
            return this.translate('BASE.UNKNOWN_TRANSLATION', { text: currentEnumRewrite });
        }

        return translatedEnum;
    }

    translate(value: string, arg?: Object): string {

        if (arg) {
            return this._translateService.instant(value, arg);
        } else {
            return this._translateService.instant(value, {});
        }
    }


    //used for truncate strings
    truncate(value: string, limit = 25, completeWords = false, ellipsis = '...') {

        if (value.length > 0) {
            if (completeWords) {
                limit = value.substr(0, 13).lastIndexOf(' ');
            }
            return `${value.substr(0, limit)}${ellipsis}`;
        }
        else {
            return '';
        }
    }

    enumNameForLocalization(value: string, except: string): string {

        let newWord: string = '';
        let checkWord = value.split(except);
        for (var i = 0, len = checkWord[1].length, ch; i < len; ++i) {
            ch = checkWord[1].charAt(i);
            if (ch >= 'A' && ch <= 'Z') {
                if (i !== 0) {
                    newWord += '_';
                }
                newWord += ch;
            } else {
                newWord += ch;
            }
        }

        return except + newWord;
    }

    getDate(kind: DateFormat, amount?: number, unit?: DateFormatUnit) {
        switch (kind) {
            case DateFormat.Now:
                return this.formatDate.manipulateDateNow();
                break;
            case DateFormat.Add:
                return this.formatDate.manipulateDateAdd(amount, unit);
                break;
            case DateFormat.Subtract:
                return this.formatDate.manipulateDateSubtract(amount, unit);
                break;
            case DateFormat.Between:
                return this.formatDate.manipulateDateBetween(amount, unit);
                break;
            default:
                return '';
        }
    }

    getTime() {

    }
}
