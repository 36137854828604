import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    OnChanges,
    Output,
    EventEmitter,
    SimpleChanges
} from '@angular/core';
import { GlobalCacheVariable } from "../../../../../../auth/_globals/globals";
import { GroupServerService } from "../../../../../../auth/_services/groupserver.service";
import { TerminalTypeNamingPipe } from "../../../../../../auth/_pipes/terminalTypeNaming.pipe";
import { TerminalType } from "../../../../../../auth/_enums/terminalType";
import { VersionType } from "../../../../../../auth/_enums/versionType";
import { AddressExt } from "../../../../../../auth/_models/addressExt";
import { EffectiveAddressPipe } from "../../../../../../auth/_pipes/effectiveAddress.pipe";
import { TerminalState } from "../../../../../../auth/_enums/terminalState";
import { TerminalConnectionType } from '../../../../../../auth/_enums/terminalConnectionType';
import { TerminalStateClass } from '../../../../../../auth/_helpers/componentState';
import { GeneralRewritePipe } from '../../../../../../auth/_pipes/generalRewrite.pipe';
import {
    IArtDetailsIPropertyView,
    IProperty
} from '../../../../../../auth/_models/group/property';
import { Dialog } from 'primeng/dialog';
import {
    IArtDetailsTerminalState,
    IArtDetailsTerminalView
} from '../../../../../../auth/_models/group/artDetailsTerminalView';
import { TerminalStatesPipe } from '../../../../../../auth/_pipes/terminalStates.pipe';
import { IArtInstallationDetails } from '../../../../../../auth/_models/group/artInstallationDetails';
import { TerminalStatusPipe } from '../../../../../../auth/_pipes/terminalStatus.pipe';
import { ITableColumn } from '../../../../../../auth/_models/tableColumn';
import {
    IContactInfosLabel,
    IContactPersonLabel,
    ILocationLabel
} from '../../../../../../auth/_models/group/locationL';
import { IPropertyDetailsLabels } from '../../../../../../auth/_models/group/artIntallationDetailsLabels';


@Component({
    selector: "art-installation-property-details",
    templateUrl: "./property-details.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [GeneralRewritePipe, TerminalTypeNamingPipe, EffectiveAddressPipe]
})
export class ARTInstallationPropertyDetailsComponent implements OnInit, OnChanges {

    @Input('currentPropertyDetails')
    currentPropertyDetails: IArtDetailsIPropertyView;

    @Input('currentPropertyDetailsId')
    currentPropertyId: number;

    @Input('artInstallationDetails')
    artInstallationDetails: IArtInstallationDetails;

    // Location
    @Input('locationLabel')
    locationLabel: ILocationLabel;

    // Contact infos
    @Input('contactInfosLabel')
    contactInfosLabel: IContactInfosLabel;

    // Contact person
    @Input('contactPersonLabel')
    contactPersonLabel: IContactPersonLabel;

    @Output()
    closePropertyDetails: EventEmitter<object> = new EventEmitter<object>();

    columns: ITableColumn[];
    viewReady: boolean = false;

    // TerminalDetails
    columnsPropertyDetails: ITableColumn[];

    // TerminalDetails Dialog
    displayArtDetailsProperty: boolean = false;
    artDetailsPropertyView: boolean = false;
    terminalDetailTableView: boolean = false;
    propertyCount: number = 0;
    currentAddress: AddressExt;

    // TerminalDetails Dialog
    displayArtDetailsTerminal: boolean = false;
    artDetailsTerminalView: boolean = false;
    terminalList: IArtDetailsTerminalView[];
    currentTerminalDetails: IArtDetailsTerminalView;
    currentTerminalDetailsID: number;
    terminalCount: number = 0;
    terminalListTableView: boolean = false;
    terminalCountMax: number = 0;

    // Property
    propertyDetailsView: IProperty;

    // Labels
    goToWebSiteLabel: string;
    labels: IPropertyDetailsLabels;
    findButtonLabel: string;
    localePropertyInfos: string = 'STATISTICS.ART_OVERVIEW.MODALS.PROPERTY_INFORMATION';
    localeTerminalVersions: string = 'STATISTICS.ART_OVERVIEW.MODALS.TERMINALS.VERSIONS';

    constructor(
        private groupServerService: GroupServerService,
        public globalCache: GlobalCacheVariable,
        private terminalTypeNamingPipe: TerminalTypeNamingPipe,
        private effectiveAddressPipe: EffectiveAddressPipe,
        private terminalStates: TerminalStateClass,
        private generalRewritePipe: GeneralRewritePipe,
        private terminalStatesPipe: TerminalStatesPipe,
        private terminalStatusPipe: TerminalStatusPipe,
    ) {

        // Labels
        this.labels = {
            title: generalRewritePipe.translate(this.localePropertyInfos + '.TITLE'),
            propertyNr: generalRewritePipe.translate(this.localePropertyInfos + '.NO'),
            propertyName: generalRewritePipe.translate(this.localePropertyInfos + '.NAME'),
            default: generalRewritePipe.translate(this.localePropertyInfos + '.DEFAULT'),
            propertyDescription: generalRewritePipe.translate(this.localePropertyInfos + '.DESCRIPTION'),
            terminals: generalRewritePipe.translate('BASE.TERMINALS'),
        }

        this.findButtonLabel = generalRewritePipe.translate("BASE.SEARCH.FIND");

        this.currentAddress = new AddressExt();

        this.columnsPropertyDetails = [
            {
                field: 'Name',
                header: this.generalRewritePipe.translate(this.localeTerminalVersions + '.TABLE.NAME'),
                class: 'tb-left'
            },
            {
                field: 'Type',
                header: this.generalRewritePipe.translate(this.localeTerminalVersions + '.TABLE.VERSION_TYPE'),
                class: 'tb-left'
            },
            {
                field: 'Version',
                header: this.generalRewritePipe.translate(this.localeTerminalVersions + '.TABLE.VERSION'),
                class: 'tb-right'
            },
        ];

        this.columns = [
            {
                field: 'TerminalState.text',
                header: this.generalRewritePipe.translate('State'),
                class: 'tb-center'
            },
            {
                field: 'TerminalId',
                header: this.generalRewritePipe.translate('BASE.ID'),
                class: 'tb-right'
            },
            {
                field: 'TerminalName',
                header: this.generalRewritePipe.translate('BASE.TERMINAL'),
                class: 'tb-left'
            },
            {
                field: 'TerminalSCVersion',
                header: this.generalRewritePipe.translate('STATISTICS.ART_OVERVIEW.MODALS.TERMINALS.TABLE.SCVERSION'),
                class: 'tb-left'
            },
            {
                field: 'TerminalType',
                header: this.generalRewritePipe.translate('TERMINAL.BASE.FILTER.TERMINAL_TYPE.MAIN'),
                class: 'tb-left'
            },
            {
                field: 'TerminalVersions.length',
                header: this.generalRewritePipe.translate(this.localeTerminalVersions + '.TITLE'),
                class: 'tb-right'
            },
        ];

    }

    ngOnInit(): void {
        this.goToWebSiteLabel = this.generalRewritePipe.translate(
            'STATISTICS.ART_OVERVIEW.MODALS.GO_TO_WEBSITE',
            { Website: this.artInstallationDetails.ArtWebClientUri }
        );
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.currentPropertyId.currentValue !== changes.currentPropertyId.previousValue) {
            this.openPropertyDetail(this.currentPropertyDetails);
        }

    }

    openPropertyDetail(property: IArtDetailsIPropertyView) {
        this.propertyDetailsView = { ...property.FullPropertyInformation };
        this.currentAddress = new AddressExt();

        if (property.FullPropertyInformation.AddressFlags !== 0) {

            //merge address
            this.currentAddress = this.effectiveAddressPipe.transform(
                this.artInstallationDetails.AddressExt,
                property.FullPropertyInformation.AddressExt,
                property.FullPropertyInformation.AddressFlags
            );

        }
        else {
            this.currentAddress = this.artInstallationDetails.AddressExt;
        }

        this.prepareTerminalList();
        this.displayArtDetailsProperty = true;
        this.artDetailsPropertyView = true;
        this.viewReady = true;
    }

    prepareTerminalList() {
        this.terminalList = [];

        for (const terminal of this.artInstallationDetails.Terminals) {

            if (terminal.PropertyNo == this.currentPropertyId) {
                let currentTerminalState = 0;
                let softwareVersion = '';
                let cLibVersion = '';
                let SCVersion = 'N/A / N/A';

                for (let terminalVersion of terminal.TerminalVersions) {

                    if (terminalVersion.Type === VersionType.Software) {
                        softwareVersion = terminalVersion.Version;
                    }

                    if (terminalVersion.Type === VersionType.SoftwareLib) {
                        cLibVersion = terminalVersion.Version;
                    }

                }

                if (softwareVersion !== '' && cLibVersion !== '') {
                    SCVersion = softwareVersion + ' / ' + cLibVersion;
                }
                else if (softwareVersion !== '' && cLibVersion === '') {
                    SCVersion = softwareVersion + ' / N/A';
                }
                else if (softwareVersion === '' && cLibVersion !== '') {
                    SCVersion = 'N/A / ' + cLibVersion;
                }

                if (terminal.Connected) {

                    if (terminal?.TerminalState == TerminalState.Ready || terminal?.TerminalState == TerminalState.InTransaction) {
                        currentTerminalState = 1;
                    }
                    else {
                        currentTerminalState = 2;
                    }

                }

                let connected: TerminalConnectionType
                    = terminal.Connected ? TerminalConnectionType.Connected : TerminalConnectionType.NotConnected;

                let terminalConnected: IArtDetailsTerminalState = {
                    class: this.terminalStates.connected.get(connected),
                    text: this.generalRewritePipe.translate(this.terminalStatusPipe.transform(connected)),
                };

                let terminalState: IArtDetailsTerminalState = {
                    class: this.terminalStates.states.get(currentTerminalState),
                    text: this.generalRewritePipe.translate(this.terminalStatesPipe.transform(currentTerminalState)),
                };

                this.terminalList.push({
                    TerminalState: terminalState,
                    ManufacturedBlocked: terminal.ManufacturerBlocked,
                    TerminalId: terminal.TerminalId,
                    TerminalName: terminal.TerminalName,
                    TerminalSCVersion: SCVersion,
                    TerminalType: this.terminalTypeNamingPipe.transform(TerminalType[terminal.TerminalType]),
                    TerminalVersions: terminal.TerminalVersions,
                    TerminalFull: terminal,
                    Connected: terminalConnected,
                    Components: terminal.Components,
                });

            }

        }

        let count = this.terminalList.length;
        this.terminalCount = count;
        this.terminalCountMax = count;
        this.terminalListTableView = true;
    }

    onFilter(event) {
        this.propertyCount = event.filteredValue.length;
    }

    onFilterTerminal(event) {
        this.terminalCount = event.filteredValue.length;
    }

    closeTerminalDetails() {
        this.artDetailsTerminalView = false;
    }

    openTerminalDetail(terminal: IArtDetailsTerminalView) {
        this.currentTerminalDetails = terminal;
        this.currentTerminalDetailsID = terminal.TerminalId;
        this.displayArtDetailsTerminal = true;
        this.artDetailsTerminalView = true;
        this.terminalDetailTableView = true;
    }

    closePropertyDetailsModal() {
        this.closePropertyDetails.emit();
        this.viewReady = false;
    }

    showDialogMaximized(dialog: Dialog) {
        dialog.maximize();
    }

}
