import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../_services/authentication.service";
import { Helpers } from "../../helpers";
import { GlobalCacheVariable } from "../_globals/globals";
import { TranslatePipe } from '@ngx-translate/core';
import { ThemeComponent } from "../../theme/theme.component";
import { Message, MessageSocketService } from "../_services/message-socket.service";
import { WebsocketService } from "../_services/websocket.service";
import { TerminalNamePipe } from "../_pipes/terminalName.pipe";
import { Subject } from "rxjs";
import { MessageService } from "primeng/api";

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [TranslatePipe, MessageSocketService, WebsocketService, TerminalNamePipe]
})

export class LogoutComponent implements OnInit {
    constructor(
        private _router: Router,
        private _authService: AuthenticationService,
        public globalCacheVariable: GlobalCacheVariable,
        private translate: TranslatePipe,
        //private themeComponent: ThemeComponent,
        public messageSocketService: MessageSocketService,
        public messageService: MessageService,
    ) {

    }

    ngOnInit(): void {
        Helpers.setLoading(true, this.translate.transform('BASE.LOADING.TITLE'));
        // reset login status
        let self = this;
        this._authService.logout().subscribe(data => {
            this._router.navigate(['/login']);
        });

        setTimeout(function() {
            if (self.globalCacheVariable.groupWebClient()) {
                localStorage.removeItem('currentGroupUser');
            }
            else {
                localStorage.removeItem('currentUser');


                //self.webSockedService.unsubscribe(this.ART_WEBSOCKET_URL);
                //self.ThemeComponent.

            }
            self._router.navigate(['/login']);
        }, 500);
    }
}
