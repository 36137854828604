import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { GlobalCacheVariable } from '../_globals/globals';

@Pipe({
    name: 'artCurrency',
})
export class ArtCurrencyPipe implements PipeTransform {

    constructor(public globalCacheVariable: GlobalCacheVariable) { }

    transform(
        value: number,
        currencyCode: string = this.globalCacheVariable.artConfigurationEntries.get("Basics/SystemCurrencyCode").Value,
        display: | 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
        digitsInfo: string = '3.2-2',
        locale: string = 'en-US',
    ): string | null {
        if (value == null) {
            return 'N/A';
        } else {
            if (this.globalCacheVariable.globalFormatSettings.symbolInsteadCurrency === true) {
                if (currencyCode !== '') {
                    return formatNumber(value / 100, 'en', '0.2-2') + ' ' + currencyCode;
                } else {
                    return formatNumber(value / 100, 'en', '0.2-2') + ' ' + this.globalCacheVariable.artConfigurationEntries.get("Basics/SystemCurrencyCode").Value;
                }
            } else {
                let currencyInfo: any;
                if (currencyCode !== '') {
                    currencyInfo = this.symbolMap(currencyCode);
                    if (currencyInfo !== undefined) {
                        return formatNumber(value / 100, 'en', '0.2-2') + ' ' + currencyInfo.CurrencySymbol;
                    } else {
                        return formatNumber(value / 100, 'en', '0.2-2') + ' ' + currencyCode;
                    }
                } else {
                    currencyInfo = this.symbolMap(this.globalCacheVariable.artConfigurationEntries.get("Basics/SystemCurrencyCode").Value);
                    if (currencyInfo !== undefined) {
                        return formatNumber(value / 100, 'en', '0.2-2') + ' ' + currencyInfo.CurrencySymbol;
                    } else {
                        return formatNumber(value / 100, 'en', '0.2-2') + ' ' + currencyCode;
                    }
                }
            }
        }
        /*
        return formatCurrency(
            value,
            locale,
            getCurrencySymbol(currencyCode, 'wide'),
            currencyCode,
            digitsInfo,
        );*/
    }

    symbolMap(currency) {

        switch (currency) {
            case 'EUR':
                return {
                    Currency: 'EURO',
                    CurrencyCode: 'EUR',
                    CurrencySymbol: '€',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: '',
                    Decimal: '.'
                }

            case 'USD':
                return {
                    Currency: 'US Dollar',
                    CurrencyCode: 'USD',
                    CurrencySymbol: '$',
                    Separator: '.',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: ','
                }

            case 'ZAR':
                return {
                    Currency: 'South African Rand',
                    CurrencyCode: 'ZAR',
                    CurrencySymbol: 'R',
                    Separator: '.',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: ','
                }

            case 'GBP':
                return {
                    Currency: 'Great British Pound',
                    CurrencyCode: 'GBP',
                    CurrencySymbol: '£',
                    Separator: '.',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: ','
                }

            case 'CAD':
                return {
                    Currency: 'Canadian Dollar',
                    CurrencyCode: 'CAD',
                    CurrencySymbol: '$',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'AUD':
                return {
                    Currency: 'Australia Dollar',
                    CurrencyCode: 'AUD',
                    CurrencySymbol: '$',
                    Separator: '.',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: ','
                }

            case 'CNY':
                return {
                    Currency: 'China Yuan Renminbi',
                    CurrencyCode: 'CNY',
                    CurrencySymbol: '¥',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'HRK':
                return {
                    Currency: 'Croatia Kuna',
                    CurrencyCode: 'HRK',
                    CurrencySymbol: 'kn',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'CZK':
                return {
                    Currency: 'Czech Republic Koruna',
                    CurrencyCode: 'CZK',
                    CurrencySymbol: 'Kč',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'DKK':
                return {
                    Currency: 'Denmark Krone',
                    CurrencyCode: 'DKK',
                    CurrencySymbol: 'kr',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'HKD':
                return {
                    Currency: 'Hong Kong Dollar',
                    CurrencyCode: 'HKD',
                    CurrencySymbol: '$',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'HUF':
                return {
                    Currency: 'Hungary Forint',
                    CurrencyCode: 'HUF',
                    CurrencySymbol: 'Ft',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'JPY':
                return {
                    Currency: 'Japan Yen',
                    CurrencyCode: 'JPY',
                    CurrencySymbol: '¥',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'KRW':
                return {
                    Currency: 'Korean (South) Won',
                    CurrencyCode: 'KRW',
                    CurrencySymbol: '₩',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'MYR':
                return {
                    Currency: 'Malaysia Ringgit',
                    CurrencyCode: 'MYR',
                    CurrencySymbol: 'RM',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'MXN':
                return {
                    Currency: 'Mexico Peso',
                    CurrencyCode: 'MXN',
                    CurrencySymbol: '$',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'NZD':
                return {
                    Currency: 'New Zealand Dollar',
                    CurrencyCode: 'NZD',
                    CurrencySymbol: '$',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'PHP':
                return {
                    Currency: 'Philippines Peso',
                    CurrencyCode: 'PHP',
                    CurrencySymbol: '₱',
                    Separator: '.',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: ','
                }

            case 'PLN':
                return {
                    Currency: 'Poland Zloty',
                    CurrencyCode: 'PLN',
                    CurrencySymbol: 'zł',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'RON':
                return {
                    Currency: 'Romania Leu',
                    CurrencyCode: 'RON',
                    CurrencySymbol: 'lei',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'RUB':
                return {
                    Currency: 'Russian Ruble',
                    CurrencyCode: 'RUB',
                    CurrencySymbol: '₽',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'SGD':
                return {
                    Currency: 'Singapore Dollar',
                    CurrencyCode: 'SGD',
                    CurrencySymbol: '$',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'SEK':
                return {
                    Currency: 'Sweden Krona',
                    CurrencyCode: 'SEK',
                    CurrencySymbol: 'kr',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'CHF':
                return {
                    Currency: 'Switzerland Fran',
                    CurrencyCode: 'CHF',
                    CurrencySymbol: 'CHF',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'TWD':
                return {
                    Currency: 'Taiwan New Dollar',
                    CurrencyCode: 'TWD',
                    CurrencySymbol: 'NT$',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'THB':
                return {
                    Currency: 'Thailand Baht',
                    CurrencyCode: 'THB',
                    CurrencySymbol: '฿',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'TRY':
                return {
                    Currency: 'Turkey Lira',
                    CurrencyCode: 'TRY',
                    CurrencySymbol: 'TRY',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'VND':
                return {
                    Currency: 'Viet Nam Dong',
                    CurrencyCode: 'VND',
                    CurrencySymbol: '₫',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }

            case 'UYU':
                return {
                    Currency: 'Uruguay Peso',
                    CurrencyCode: 'UYU',
                    CurrencySymbol: '$U',
                    Separator: ',',
                    NumberFormat: '0.2-2',
                    Locale: 'en-US',
                    Decimal: '.'
                }
        }

    }
}
