export enum ArtRight {
    Administration = 1 << 0,
    Configuration = 1 << 1, // not used right now
    Accounting = 1 << 2,
    ManageAdvertisements = 1 << 3,
    Logs = 1 << 4, // not used right now
    Login = 1 << 5,
    Security = 1 << 6,
    TerminalOutOfOrder = 1 << 7, // TerminalOperationState = 1 << 7,
    OperatorManagement = 1 << 8,
    SuspendNotifications = 1 << 9,
    ViewTransactionPictures = 1 << 10,
    RequestOneTimeMaintenanceAccess = 1 << 11,
    ShowPinAlert = 1 << 12,
    RemoteControl = 1 << 13,
    ApproveTransactions = 1 << 14,
    TerminalRestart = 1 << 15,
    AdjustTransactions = 1 << 16,
    RemoteAccess = 1 << 17,
    ManageDebitAccounts = 1 << 18,
    DonationManagement = 1 << 19,
    ViewOperatorAccesses = 1 << 20,
    PlayerManagement = 1 << 21,
    ViewIDScans = 1 << 22,
    ExchangeRateManagement = 1 << 23,
    ManageOnlineSystemMedia = 1 << 24,
    ManageCustomers = 1 << 25,
    ViewCommissionReport = 1 << 26,
    ViewDistributorReport = 1 << 27,
    ViewStoreReport = 1 << 28,
    ViewInstallationDashboard = 1 << 29,
}
