export enum FeatureType {
    None = 0,
    BillBreak = 1,
    TicketCreation = 2,
    TicketRedemption = 3,
    PlayerCardCreation = 4,
    PlayerCardRedemption = 5,
    PlayerCardCharging = 6,
    //[Obsolete("Replaced by constant with integer value 31.")]
    //BonusCardRedemption  =  7,
    EmptyCardDispense = 8,
    Atm = 9,
    Pos = 10,
    Cec = 11,
    Jackpot = 12,
    CashChange = 13,
    VoucherRedemption = 14,
    MarkerRedemption = 15,
    CurrencyExchange = 16,
    DebitAccountPayout = 17,
    DebitAccountReturn = 18,
    CashierCashPayout = 19,
    CashierCashReturn = 20,
    Advertising = 21,
    TransactionApproval = 22,
    RewardSystem = 23,
    PosToTicket = 24,
    PosToCard = 25,
    EWalletWithdrawal = 26,
    EWalletDeposit = 27,
    CryptoKeys = 28,
    CashToTicket = 29,
    BonusCardCreation = 30,
    BonusCardRedemption = 31,
    BonusCardCharging = 32,
    SignIn = 33,
    SignUp = 34,
    BillPay = 35,
    CheckCashing = 36,
    GiftCardSale = 37,
    MobileTopUp = 38,
    WireTransfer = 39,
    GiftCardBuy = 40,
    CryptoCurrencyBuy = 41,
    PrepaidCard = 42,
    CryptoCurrencySale = 43,
    AdvertisingStream = 44
}
