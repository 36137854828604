import { TransactionType } from "../_enums/transactionType";
import { TransactionCategory } from "../_enums/transactionCategory";
import { Injectable } from "@angular/core";


let LogOperatorTransactions = [
    TransactionType.JackpotPayout,
    TransactionType.FillBagFront,
    TransactionType.EmptyHopper,
    TransactionType.JackpotRear,
    TransactionType.FillBackRear,
    TransactionType.CassetteRemoved,
    TransactionType.CassetteInserted,
    TransactionType.BillValidatorEmptied,
    TransactionType.CoinsInserted,
    TransactionType.RejectBoxEmptied,
    TransactionType.OpenCreditCleared,
    TransactionType.TestHopper,
    TransactionType.MediaOutEmptied,
    TransactionType.MediaInEmptied,
    TransactionType.CashDifference,
    TransactionType.CashboxFilled,
    TransactionType.CashboxWithdrawn,
    TransactionType.CashboxReload,
    TransactionType.MediaRefill,
    TransactionType.DepositWithheld,
    TransactionType.DepositPaid,
    TransactionType.RoundingDifferenceCleared,
    TransactionType.SamToSuperSam,
    TransactionType.JackpotCancelCredit,
    TransactionType.JackpotShortPay,
    TransactionType.GMHopperRefill,
    TransactionType.ProgressiveJackpot,
    TransactionType.CaptureBinEmptied,
    TransactionType.StackerStockAdded,
    TransactionType.PrintedTicketsCleared,
    TransactionType.LostMoney,
    TransactionType.LostMoneyCleared,
    TransactionType.JackpotAdditional,
    TransactionType.CoinAcceptorEmptied,
    TransactionType.PosCleared,
    TransactionType.CheckCashingCleared,
    TransactionType.AtmCleared,
    TransactionType.LoansCleared,
    TransactionType.DonationsCleared,
    TransactionType.TaxWithheldCleared,
    TransactionType.DebitPayout,
    TransactionType.DebitReturn,
    TransactionType.CashierCashPayout,
    TransactionType.CashierCashReturn,
    TransactionType.StackerEmptied,
    TransactionType.EWalletDepositCleared,
    TransactionType.EWalletWithdrawalCleared,
];
let LogCustomerTransactions = [
    TransactionType.CoinRedemption,
    TransactionType.BillBreaker,
    TransactionType.TicketRedemption,
    TransactionType.MagneticCardRedemption,
    TransactionType.SmartCardRedemption,
    TransactionType.TicketPrinted,
    TransactionType.MagneticCardCharged,
    TransactionType.SmartCardCharged,
    TransactionType.CoinsDispensed,
    TransactionType.AtmWithdrawal,
    TransactionType.AtmCashAdvance,
    TransactionType.AtmBalanceInquiry,
    TransactionType.PosWithdrawal,
    TransactionType.ChipRedemption,
    TransactionType.ChipsPaidOut,
    TransactionType.CouponRedemption,
    TransactionType.CheckCashing,
    TransactionType.LoanRaised,
    TransactionType.LoanRepaidCash,
    TransactionType.LoanRepaidCard,
    TransactionType.MarkerRedemption,
    TransactionType.EWalletDeposit,
    TransactionType.EWalletWithdrawal,
    TransactionType.RejectedBill,
    TransactionType.RejectedCard,
    TransactionType.RejectedTicket,
];
let LogAtmTransactions = [
    TransactionType.AtmWithdrawal,
    TransactionType.AtmCashAdvance,
    TransactionType.AtmBalanceInquiry
    //TransactionType.AtmCleared
];
let LogJackpotTransactions = [
    TransactionType.JackpotPayout,
    TransactionType.JackpotCancelCredit,
    TransactionType.JackpotShortPay,
    TransactionType.GMHopperRefill,
    TransactionType.ProgressiveJackpot,
    TransactionType.JackpotAdditional
];
let LogMagneticCardTransactions = [
    TransactionType.MagneticCardCharged,
    TransactionType.MagneticCardRedemption
];
let LogSmartCardTransactions = [
    TransactionType.SmartCardCharged,
    TransactionType.SmartCardRedemption
];
let LogTicketTransactions = [
    TransactionType.TicketPrinted,
    TransactionType.TicketRedemption
];
let LogLoanTransactions = [
    TransactionType.LoanRaised,
    TransactionType.LoanRepaidCash,
    TransactionType.LoanRepaidCard
    //TransactionType.LoansCleared
];
let LogEWalletTransactions = [
    TransactionType.EWalletDeposit,
    TransactionType.EWalletWithdrawal,
];

let LogPaymentTransactions = [
    TransactionType.BillPay,
    TransactionType.CheckCashingPayment,
    TransactionType.MobileTopUp,
    TransactionType.GiftCardSale,
    TransactionType.BillPayCleared,
    TransactionType.WireTransfer,
    TransactionType.GiftCardBuy,
    TransactionType.CryptoCurrencyBuy
];

@Injectable()
export class TransactionTypes {

    isTransactionTypePayment(transactionType) {

        switch (transactionType) {
            case TransactionType.BillPay:
            case TransactionType.CheckCashingPayment:
            case TransactionType.MobileTopUp:
            case TransactionType.GiftCardSale:
            case TransactionType.BillPayCleared:
            case TransactionType.WireTransfer:
                return true;
            default:
                return false;
        }
    }
    getTransactionLogTypeCategory(transactionType) {

        let transactionTypeArray = [];

        switch (transactionType) {
            case 'all':
                transactionTypeArray = [];
                break;
            case 'OperatorTransactions':
                transactionTypeArray = LogOperatorTransactions;
                break;
            case 'CustomerTransactions':
                transactionTypeArray = LogCustomerTransactions;
                break;
            case 'AtmTransactions':
                transactionTypeArray = LogAtmTransactions;
                break;
            case 'JackpotTransactions':
                transactionTypeArray = LogJackpotTransactions;
                break;
            case 'MagneticCardTransactions':
                transactionTypeArray = LogMagneticCardTransactions;
                break;
            case 'SmartCardTransactions':
                transactionTypeArray = LogSmartCardTransactions;
                break;
            case 'TicketTransactions':
                transactionTypeArray = LogTicketTransactions;
                break;
            case 'LoanTransactions':
                transactionTypeArray = LogLoanTransactions;
                break;
            case 'EWalletTransactions':
                transactionTypeArray = LogEWalletTransactions;
                break;
            case 'PaymentTransactions':
                transactionTypeArray = LogPaymentTransactions;
                break;
            default:
                transactionTypeArray.push(Number(transactionType));
                break;
        }
    }

    getTransactionStatisticTypeCategory(transactionType) {

        switch (transactionType) {
            case TransactionType.CoinRedemption:
            case TransactionType.BillBreaker:
            case TransactionType.TicketRedemption:
            case TransactionType.MagneticCardRedemption:
            case TransactionType.SmartCardRedemption:
            case TransactionType.TicketPrinted:
            case TransactionType.MagneticCardCharged:
            case TransactionType.SmartCardCharged:
            case TransactionType.CoinsDispensed:
            case TransactionType.AtmWithdrawal:
            case TransactionType.AtmCashAdvance:
            case TransactionType.AtmBalanceInquiry:
            //case TransactionType.OpenCreditCleared: // see below -> 'Operator Transaction'
            //case TransactionType.SamToSuperSam: // see below -> 'Internal'
            case TransactionType.PosWithdrawal:
            case TransactionType.ChipRedemption:
            case TransactionType.ChipsPaidOut:
            case TransactionType.CouponRedemption:
            case TransactionType.CheckCashing:
            case TransactionType.LoanRaised:
            case TransactionType.LoanRepaidCash:
            case TransactionType.LoanRepaidCard:
            case TransactionType.MarkerRedemption:
            case TransactionType.EWalletWithdrawal:
            case TransactionType.EWalletDeposit:
            case TransactionType.RejectedBill:
            case TransactionType.RejectedTicket:
            case TransactionType.RejectedCard:
            //New 15.03.2021
            case TransactionType.TicketRecreated:
            case TransactionType.BonusCardRedemption:
            case TransactionType.BonusCardCharged:
            case TransactionType.BillPay:
            case TransactionType.CheckCashingPayment:
            case TransactionType.MobileTopUp:
            case TransactionType.GiftCardSale:
            case TransactionType.WireTransfer:
            case TransactionType.DebitCardSale:
            case TransactionType.GiftCardBuy:
            case TransactionType.CryptoCurrencyBuy:
            case TransactionType.PrepaidCardCharged:
            case TransactionType.IDScannerCleared:
            case TransactionType.CryptoCurrencySale:
            case TransactionType.PrepaidCardIssued:
            case TransactionType.IouReceiptRedemption:
                return TransactionCategory.CustomerTransaction;

            case TransactionType.JackpotPayout:
            case TransactionType.JackpotRear:
            case TransactionType.JackpotCancelCredit:
            case TransactionType.JackpotShortPay:
            case TransactionType.GMHopperRefill:
            case TransactionType.ProgressiveJackpot:
            case TransactionType.JackpotAdditional:
                return TransactionCategory.Jackpot;

            case TransactionType.FillBagFront:
            case TransactionType.EmptyHopper:
            case TransactionType.FillBackRear:
            case TransactionType.CassetteRemoved:
            case TransactionType.CassetteInserted:
            case TransactionType.BillValidatorEmptied:
            case TransactionType.CoinsInserted:
            case TransactionType.RejectBoxEmptied:

            //case TransactionType.TestHopper: // see below -> 'Operator Transaction'
            case TransactionType.MediaOutEmptied: // ?
            case TransactionType.MediaInEmptied: // ?
            case TransactionType.CashDifference: // ?
            case TransactionType.CashboxFilled:
            case TransactionType.CashboxWithdrawn:
            case TransactionType.CashboxReload:
            case TransactionType.MediaRefill:
            case TransactionType.DepositWithheld: // ?
            case TransactionType.DepositPaid: // ?
            case TransactionType.RoundingDifferenceCleared: // ?

            case TransactionType.CaptureBinEmptied:
            case TransactionType.StackerStockAdded:
            case TransactionType.PrintedTicketsCleared:
            //case TransactionType.LostMoney: // see below -> 'Operator Transaction'
            case TransactionType.LostMoneyCleared:
            //case TransactionType.JackpotAdditional: // see above -> 'Jackpot'
            case TransactionType.CoinAcceptorEmptied:
            case TransactionType.PosCleared:
            case TransactionType.CheckCashingCleared:
            case TransactionType.AtmCleared:
            case TransactionType.TicketHandPayCleared:
            case TransactionType.DropBoxEmptied:
            case TransactionType.LoansCleared:
            case TransactionType.DonationsCleared:
            case TransactionType.TaxWithheldCleared:
            case TransactionType.StackerEmptied:
            case TransactionType.EWalletWithdrawalCleared:
            case TransactionType.EWalletDepositCleared:
            //New 15.03.2021
            case TransactionType.BillPayCleared:
            case TransactionType.CheckCashingPaymentCleared:
            case TransactionType.MobileTopUpCleared:
            case TransactionType.GiftCardSaleCleared:
            case TransactionType.WireTransferCleared:
            case TransactionType.DebitCardSaleCleared:
            case TransactionType.PrepaidCardChargedCleared:
            case TransactionType.CryptoCurrencyBuyCleared:
            case TransactionType.CryptoCurrencySaleCleared:
            case TransactionType.FeeWithheldCleared:
            case TransactionType.PrintedIouReceiptsCleared:
                return TransactionCategory.MoneyService;

            case TransactionType.OpenCreditCleared:
            case TransactionType.TestHopper:
            case TransactionType.LostMoney:
            case TransactionType.DebitPayout:
            case TransactionType.DebitReturn:
            case TransactionType.CashierCashPayout:
            case TransactionType.CashierCashReturn:
                return TransactionCategory.OperatorTransaction;

            case TransactionType.SamToSuperSam:
                return TransactionCategory.Internal;

            default:
                return TransactionCategory.None;
        }
    }
}
