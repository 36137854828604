import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services/terminal.service';

import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';

import { LoggingService } from '../../../../../../auth/_services/log.service';
import { EventLogListItem } from '../../../../../../auth/_models/eventloglistitem';

import * as moment from "moment";


@Component({
    selector: "terminal-single-details-event-log",
    templateUrl: "./terminal-single-details-event-log.component.html",
    encapsulation: ViewEncapsulation.None,
})

export class TerminalSingleDetailsEventLogComponent implements OnInit, AfterViewInit, OnChanges {

    @Input('terminalID') terminalID: number;

    eventLog: any;
    paginatorPage: number = 1;
    paginatorRowsPerPage: number = 20;
    eventLogView: boolean = false;
    constructor(
        private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        private loggingService: LoggingService,
        public globalCacheVariable: GlobalCacheVariable,
        private zone: NgZone,
    ) {
        this.eventLog = new Array<EventLogListItem>();
    }

    getEventLogData() {
        let startDate = moment().subtract(1000, "days");
        let endDate = moment();
        this.loggingService.getRecentEventLogs(this.terminalID, 10)
            .subscribe(data => {
                this.zone.run(() => {
                    this.eventLog = data;
                    this.eventLogView = true;
                    setTimeout(function() {
                        //$(".terminal-single-details-container .event-log-table .ui-table-scrollable-body").mCustomScrollbar("destroy");
                        /*$(".terminal-single-details-container .event-log-table2 .ui-table-scrollable-body").mCustomScrollbar({
                            "scrollInertia": 0,
                            scrollbarPosition: "outside",
                            axis: "yx",
                            callbacks: {    // callback for thead,tbody scroll
                                whileScrolling: function () {
                                    $(".ui-table-scrollable-header").scrollLeft(this.mcs.left * -1);
                                },
                            },
                        });*/

                    }, 0);
                });
            });
    }
    ngOnInit(): void {
        //this.getEventLogData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getEventLogData();
        }
    }

    ngAfterViewInit(): void {
        // this.getTerminalIdentifierList();
    }
}
