import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from '../helpers';
import { ScriptLoaderService } from '../_services/script-loader.service';
import * as jquery from 'jquery';
import { UpdateTermViewEventType } from "../auth/_enums/updateTermViewEventType";
import { Terminal } from "../auth/_models";
import { MessageSocketService } from "../auth/_services/message-socket.service";
import { MessageService } from "primeng/api";
import { GlobalCacheVariable } from "../auth/_globals/globals";
import { TerminalService } from "../auth/_services";
import { OperatorService } from "../auth/_services/operator.service";
import { RewriteService } from "../auth/_services/rewrite.service";
import { WebsocketService } from "../auth/_services/websocket.service";
import { DashboardService } from "../auth/_services/dashboard.service";
import * as moment from 'moment';
import { HostListener } from '@angular/core';
import { ArtRight } from "../auth/_enums/artRight";
import { TerminalNamePipe } from "../auth/_pipes/terminalName.pipe";
import { TerminalEvent } from "../auth/_enums/terminalEvent";
import { RemoteAccessResponseCode } from "../auth/_enums/remoteAccessResponseCode";
import { NONE_TYPE } from "@angular/compiler";
import { ToastService } from "../auth/_services/toast.service";
import { TranslatePipe } from '@ngx-translate/core';

declare let mApp: any;
declare let mUtil: any;
declare let mLayout: any;
declare let niceScroll: any;
declare let mCustomScrollbar: any;
declare let DataTable: any;

@Component({
    selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
    templateUrl: "./theme.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [MessageSocketService, WebsocketService, TerminalNamePipe, TranslatePipe]
})
export class ThemeComponent implements OnInit {

    @HostListener('window:beforeunload', ['$event'])
    public beforeUnloadHandler($event) {
        if (this.globalCacheVariable.singleWebClient()) {
            $event.returnValue = "Are you sure?";
            let self = this;
            //setTimeout(function(){
            self._router.navigateByUrl('/logout');
        }

    }

    @HostListener('window:unload', ['$event'])
    public unloadHandler($event) {
        if (this.globalCacheVariable.singleWebClient()) {
            let self = this;
            //setTimeout(function(){
            self._router.navigateByUrl('/logout');
            $event.returnValue = "Are you sure?";
        }
    }

    artConfigEntries: any = new Map();
    supervisingOperatorIDSArray: any;
    dashboardConfigurationEntries: any = new Map();

    //heartBeat
    heartBeatInterval;
    heartBeatIntervalTime: number = 300000;
    //heartBeatIntervalTime: number = 15000;

    //Websocket
    displayWebsocketError: boolean = true;

    //Terminal Events
    eventBlocklist: any;

    constructor(
        private _script: ScriptLoaderService,
        private _router: Router,
        public messageSocketService: MessageSocketService,
        public messageService: MessageService,
        public globalCacheVariable: GlobalCacheVariable,
        private terminalService: TerminalService,
        private dashboardService: DashboardService,
        private operatorService: OperatorService,
        private rewriteService: RewriteService,
        public terminalName: TerminalNamePipe,
        private toastService: ToastService,
        private translate: TranslatePipe,
    ) {
        this.eventBlocklist = [];
        this.eventBlocklist.push(TerminalEvent.TransactionApprovalRequired);
        this.eventBlocklist.push(TerminalEvent.TransactionApproved);
        this.eventBlocklist.push(TerminalEvent.RemoteAccessConfirmation);
        this.eventBlocklist.push(TerminalEvent.PortForwardingStatus);
        if (this.globalCacheVariable.singleWebClient()) {
            let self = this;
            setTimeout(function() {
                self.startWebsocket();
            }, 500);
        }
    }

    mapEventType(eventType) {
        switch (eventType) {
            case UpdateTermViewEventType.Basic:
                return "Basic";
            case UpdateTermViewEventType.Condition:
                return "Condition";
            case UpdateTermViewEventType.Transaction:
                return "Transaction";
            case UpdateTermViewEventType.OperatorLogin:
                return "Operator Login";
            case UpdateTermViewEventType.Versions:
                return "Versions";
            case UpdateTermViewEventType.CoinIn:
                return "Coin In";
            case UpdateTermViewEventType.CoinOut:
                return "Coin Out";
            case UpdateTermViewEventType.BillIn:
                return "Bill In";
            case UpdateTermViewEventType.BillOut:
                return "Bill Out";
            case UpdateTermViewEventType.Media:
                return "Media";
            case UpdateTermViewEventType.Setting:
                return "Settings";
            case UpdateTermViewEventType.BalanceLog:
                return "Balance Log";
            case UpdateTermViewEventType.Functions:
                return "Functions";
            case UpdateTermViewEventType.AtmClient:
                return "ATM Client";
            case UpdateTermViewEventType.ComponentsChanged:
                return "Components Changed";
            case UpdateTermViewEventType.FeaturesChanged:
                return "Features Changed";
            case UpdateTermViewEventType.StockChanged:
                return "Stock Changed";
            default:
                return 'Unknown (' + eventType + ')';
        }
    }

    startWebsocket() {
        console.log("Trying to establish Websocket connection!");
        this.messageSocketService.messages.complete();
        this.messageSocketService.messages.subscribe(msg => {
            //console.log("websocket msg", msg);
            if (this.displayWebsocketError == true) {
                this.toastService.success("Websocket successfully connected", "Websocket connected", "toast", 3000, true);
                this.displayWebsocketError = false;
            }

            //let currentUrl = this._router.url;
            let parts = msg.message.split(",");

            if (parts.length > 0) {
                for (let i in parts) {
                    let part = parts[i];

                    if (part.startsWith("TA")) {
                        if (this.globalCacheVariable.checkARTRight(ArtRight.ApproveTransactions) === true) {
                            this.showTransactionApproval(part);
                            return;
                        }

                    }

                    if (part.startsWith("TD")) {
                        this.hideTransactionApproval(part);
                        return;
                    }

                    if (part.startsWith("TE")) {
                        //console.log("part theme ", part);
                        this.addTerminalEventEntry(part);
                        return;
                    }

                    if (part.startsWith("ST")) {
                        this.sessionTerminated(part);
                        return;
                    }

                    if (part.startsWith("SS")) {
                        this.artShutdown(part);
                        return;
                    }


                    if (part.startsWith("LFR")) {
                        //console.log("part theme ", part);
                        if (this.globalCacheVariable.requestedLogfilesArray.length > 0) {
                            this.addRequestedLogfilesEntry(part);
                            return;
                        }

                    }

                    /*
                    if (part.startsWith("PFS")) {
                        //console.log("part theme ", part);
                        if (this.globalCacheVariable.requestedRemoteSessions.length > 0) {
                            this.changeRequestedRemoteInformation(part);
                            return;
                        }

                    }
    */

                    let fields = part.split("_");
                    let eventType = Number(fields[0]);

                    if (!isNaN(eventType))

                        if (eventType == UpdateTermViewEventType.Basic ||
                            eventType == UpdateTermViewEventType.Condition ||
                            eventType == UpdateTermViewEventType.Transaction ||
                            eventType == UpdateTermViewEventType.OperatorLogin) {

                            let terminalID = fields[1];

                            this.updateTerminalInformation(terminalID, eventType);
                        }
                }
            }

            if (msg.message.split(",")[0] == "Alive") {
                this.displayWebsocketError = false;
                //console.log("Sending Alive response...");
                //if (ws) ws.send("AliveResponse");
                //if (wss) wss.send("AliveResponse");
                //console.log("return of child component", this.child.terminalsInfo());

                //TODO: implement later
                // this.getAllTerminalsInfo();
            }


        });

        //TODO here comes the dialog for
    }


    sessionTerminated(part) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let parts = part.split("_");
        if (parts[1] === currentUser.SessionKey) {
            localStorage.removeItem('currentUser');
            let url = '/login';
            this._router.navigate([url]);
        }
    }

    artShutdown(part) {
        let parts = part.split("_");
        localStorage.removeItem('currentUser');
        let url = '/login';
        this._router.navigate([url]);
    }

    hideTransactionApproval(part) {
        // this.messageService.clear();
        let parts = part.split("_");
        //TA_9888_4_2_100_1
        let message: any = {
            terminal: parts[1],
            sequenceNumber: parts[2],
            approvedOperatorID: parts[3],
        };

        let transApprovalID = 'transApprove-' + message.terminal + '-' + message.sequenceNumber;
        let textState = "";

        this.messageService.clear('transApprove');
        let currentOperator = this.globalCacheVariable.getCurrentOperator();
        if (Number(parts[4]) === 0) {
            textState = "declined";
        } else {
            textState = "approved";
        }
        if (Number(parts[3]) === Number(currentOperator.OperatorID)) {
            this.messageService.add({
                key: 'transApproveState',
                severity: 'success',
                summary: 'Transaction sequence# ' + message.sequenceNumber + ' ' + textState + '.',
                life: 3000,
                detail: 'Operator: ' + message.approvedOperatorID,
            });
        }

    }

    addRequestedLogfilesEntry(part) {
        let parts = part.split("_");
        if (this.globalCacheVariable.requestedLogfilesArray.includes(parts[2])) {

            let currentTime = moment().toISOString(true);
            let message: any = {
                terminalID: Number(parts[1]),
                terminalName: this.terminalName.transform(Number(parts[1])),
                taskID: parts[2],
                fileName: parts[3].replace(/<underscore>/g, "_"),
                from: parts[4],
                to: parts[5],
                timestamp: currentTime,
                timestampShown: moment(currentTime).fromNow(),
            };
            this.globalCacheVariable.notifications.Logs.push(message);

        }
    }

    /*
    changeRequestedRemoteInformation(part) {
        let parts = part.split("_");
        if (this.globalCacheVariable.requestedRemoteSessions.length > 0) {
            for (let rRS = 0; rRS < this.globalCacheVariable.requestedRemoteSessions.length; rRS++) {
                if (Number(this.globalCacheVariable.requestedRemoteSessions[rRS].TerminalID) == Number(parts[1])) {
                    const responseObject = JSON.parse(JSON.stringify(parts[3]));
                    if (this.globalCacheVariable.requestedRemoteSessions[rRS].SourcePort === responseObject.SourcePort) {
                        this.globalCacheVariable.requestedRemoteSessions[rRS].PortForwardingStatus = responseObject.Established;
                        this.globalCacheVariable.currentRemoteSession.PortForwardingStatus = parts[2].Established;
                        if (responseObject.Established === false) {
                            console.log("VNC not possible with state: ", responseObject.StatusText);
                        }
                        break;
                    } else {
                        console.log("Remote Access SourcePort for Terminal not matching: ", responseObject.SourcePort);
                    }
                }
            }
        }
    }

     */

    addTerminalEventEntry(part) {
        let parts = part.split("_");

        //console.log("parts ", parts);
        //TE_19062_20_6_1080, 1920\r
        //let ;
        if (this.eventBlocklist.includes(Number(parts[2]))) {
            if (Number(parts[2]) == Number(TerminalEvent.RemoteAccessConfirmation)) {
                if (this.globalCacheVariable.requestedRemoteSessions.length > 0) {
                    for (let rRS = 0; rRS < this.globalCacheVariable.requestedRemoteSessions.length; rRS++) {
                        if (Number(this.globalCacheVariable.requestedRemoteSessions[rRS].TerminalID) == Number(parts[1])) {
                            switch (Number(parts[3])) {
                                case RemoteAccessResponseCode.Unknown:
                                    this.globalCacheVariable.requestedRemoteSessions[rRS].RemoteAccessConfirmation = 0;
                                    this.globalCacheVariable.currentRemoteSession.RemoteAccessConfirmation = 0;
                                    break;
                                case RemoteAccessResponseCode.None:
                                    this.globalCacheVariable.requestedRemoteSessions[rRS].RemoteAccessConfirmation = 0;
                                    this.globalCacheVariable.currentRemoteSession.RemoteAccessConfirmation = 0;
                                    break;
                                case RemoteAccessResponseCode.RemoteAccessPossible:
                                    this.globalCacheVariable.requestedRemoteSessions[rRS].RemoteAccessConfirmation = 1;
                                    this.globalCacheVariable.currentRemoteSession.RemoteAccessConfirmation = 1;
                                    this.messageService.add({
                                        key: 'remoteMessage',
                                        severity: 'success',
                                        summary: 'Remote access possible',
                                        life: 5000,
                                        detail: 'The requested remote access at Terminal is possible'
                                    });
                                    break;
                                case RemoteAccessResponseCode.InTransaction:
                                    this.globalCacheVariable.requestedRemoteSessions[rRS].RemoteAccessConfirmation = 0;
                                    this.globalCacheVariable.currentRemoteSession.RemoteAccessConfirmation = 0;
                                    this.messageService.add({
                                        key: 'remoteMessage',
                                        severity: 'error',
                                        summary: 'Terminal in transaction',
                                        life: 5000,
                                        detail: 'The requested Terminal is currently in transaction'
                                    });
                                    break;
                                case RemoteAccessResponseCode.OperatorAlreadyLoggedIn:
                                    this.globalCacheVariable.requestedRemoteSessions[rRS].RemoteAccessConfirmation = 0;
                                    this.globalCacheVariable.currentRemoteSession.RemoteAccessConfirmation = 0;
                                    this.messageService.add({
                                        key: 'remoteMessage',
                                        severity: 'error',
                                        summary: 'Operator already logged in',
                                        life: 5000,
                                        detail: 'Another Operator is currently logged in at the Terminal'
                                    });
                                    break;
                                case RemoteAccessResponseCode.OperatorUnknown:
                                    this.globalCacheVariable.requestedRemoteSessions[rRS].RemoteAccessConfirmation = 0;
                                    this.globalCacheVariable.currentRemoteSession.RemoteAccessConfirmation = 0;
                                    this.messageService.add({
                                        key: 'remoteMessage',
                                        severity: 'error',
                                        summary: 'Operator unknown',
                                        life: 5000,
                                        detail: 'This Operator is unknown on requested Terminal'
                                    });
                                    break;
                                case RemoteAccessResponseCode.ServiceFailure:
                                    this.globalCacheVariable.requestedRemoteSessions[rRS].RemoteAccessConfirmation = 0;
                                    this.globalCacheVariable.currentRemoteSession.RemoteAccessConfirmation = 0;
                                    this.messageService.add({
                                        key: 'remoteMessage',
                                        severity: 'error',
                                        summary: 'Service Failure',
                                        life: 5000,
                                        detail: 'The requested Service (remote access) is failure'
                                    });
                                    break;
                                case RemoteAccessResponseCode.InvalidRequest:
                                    this.globalCacheVariable.requestedRemoteSessions[rRS].RemoteAccessConfirmation = 0;
                                    this.globalCacheVariable.currentRemoteSession.RemoteAccessConfirmation = 0;
                                    this.messageService.add({
                                        key: 'remoteMessage',
                                        severity: 'error',
                                        summary: 'Invalid request',
                                        life: 5000,
                                        detail: 'Invalid request happened'
                                    });
                                    break;

                            }
                            break;
                        }
                    }
                }
            } else if (Number(parts[2]) == Number(TerminalEvent.PortForwardingStatus)) {
                if (this.globalCacheVariable.requestedRemoteSessions.length > 0) {
                    for (let rRS = 0; rRS < this.globalCacheVariable.requestedRemoteSessions.length; rRS++) {
                        if (Number(this.globalCacheVariable.requestedRemoteSessions[rRS].TerminalID) == Number(parts[1])) {
                            //console.log("this.globalCacheVariable.requestedRemoteSessions[rRS] ", this.globalCacheVariable.requestedRemoteSessions[rRS]);
                            if (this.globalCacheVariable.requestedRemoteSessions[rRS].RemotePort == Number(parts[3])) {
                                this.globalCacheVariable.requestedRemoteSessions[rRS].PortForwardingStatus = Number(parts[4]);
                                this.globalCacheVariable.currentRemoteSession.PortForwardingStatus = Number(parts[4]);
                                if (Number(parts[4]) == 0) {
                                    console.log("VNC not possible with state: ", parts[5]);
                                }
                                break;
                            } else {
                                console.log("Remote Access SourcePort for Terminal not matching: ", parts[3]);
                            }
                        }
                    }
                }
            }


        } else {
            let currentTime = moment().toISOString(true);
            let message: any = {
                terminalID: Number(parts[1]),
                terminalName: this.terminalName.transform(Number(parts[1])),
                terminalEvent: Number(parts[2]),
                //terminalEvent: 20,
                details: parts[3],
                //details: 10,
                timestamp: currentTime,
                timestampShown: moment(currentTime).fromNow(),
            };
            let terminalIDAlreadyIn = 0;
            for (let te = 0; te < this.globalCacheVariable.notifications.Events.length; te++) {
                if (Number(message.terminalID) == Number(this.globalCacheVariable.notifications.Events[te].terminalID)) {
                    this.globalCacheVariable.notifications.Events.splice(te, 1);
                    this.globalCacheVariable.notifications.Events.push(message);
                    terminalIDAlreadyIn = 1;
                    break;
                }
            }

            if (terminalIDAlreadyIn == 0) {
                this.globalCacheVariable.notifications.Events.push(message);
            }
        }
    }

    showTransactionApproval(part) {
        this.supervisingOperatorIDSArray = [];
        // this.messageService.clear();
        //console.log("PART -> ", part);
        let parts = part.split("_");
        //TA_9888_4_2_100_1
        //console.log("PARTS -> ", parts);

        let supervisingOperatorIDSString = parts[6].split("#");
        for (let soids = 0; soids < supervisingOperatorIDSString.length; soids++) {
            this.supervisingOperatorIDSArray.push(Number(supervisingOperatorIDSString[soids]));
        }

        let currentOperator = this.globalCacheVariable.getCurrentOperator();
        let supervisingOperatorID = this.supervisingOperatorIDSArray.indexOf(Number(currentOperator.OperatorID));
        if (supervisingOperatorID !== -1) {
            let message: any = {
                terminal: parts[1],
                sequenceNumber: parts[2],
                transactionType: parts[3],
                requestedAmount: parts[4],
                requestedOperatorID: parts[5],
                supervisingOperatorIDS: parts[6],
                currencyCode: parts[7],
            };

            let transApprovalID = 'transApprove-' + message.terminal + '-' + message.sequenceNumber;

            this.messageService.add({
                id: transApprovalID,
                key: 'transApprove',
                sticky: true,
                severity: 'warn',
                summary: 'Transaction Approval Required',
                detail: message
            });
        }
    }

    onTransactionApprovalConfirm(message) {
        return this.terminalService.approveTransaction(message, true).subscribe(
            (result: any) => {
                this.messageService.clear('transApprove');
                /*
                if(result === true){
                    this.messageService.add({
                        key: 'transApproveState',
                        severity:'success',
                        summary: 'Transaction approved successfully.',
                        life: 2000,
                        detail: 'Transaction Sequence: '+ message.detail.sequenceNumber
                    });
                }
                 */
            },

            (error: any) => {
            },
            () => {

            }
        );
    }

    onTransactionApprovalReject(message) {
        return this.terminalService.approveTransaction(message, false).subscribe(
            (result: any) => {
                this.messageService.clear('transApprove');
                /*
                if(result === true){
                    this.messageService.add({
                        key: 'transApproveState',
                        severity:'success',
                        summary: 'Transaction declined successfully.',
                        life: 2000,
                        detail: 'Transaction Sequence: '+ message.detail.sequenceNumber
                    });
                }*/
            },

            (error: any) => {
            },
            () => {
                // this.child.getAllTerminalsCache()
            }
        );

    }

    updateTerminalInformation(terminalID, eventType) {
        if (this.mapEventType(eventType) === "Transaction" || this.mapEventType(eventType) === "Operator Login" || this.mapEventType(eventType) === "Basic" || this.mapEventType(eventType) === "Condition") {
            let actualyTerminalChangedData = this.getOneTerminal(terminalID);
        }
    }

    getOneTerminal(terminalID) {
        return this.terminalService.getSingleTerminal(terminalID).subscribe(
            (result: Terminal) => {

                this.rewriteService.rewriteTerminalIconInformations(result, 'single').then(
                    data => {
                        let existTerminal = false;

                        for (let i = 0; i < this.globalCacheVariable.Terminals.length; i++) {

                            if (this.globalCacheVariable.Terminals[i].TerminalID === Number(data.TerminalID)) {
                                let currentTerminal = this.globalCacheVariable.Terminals[i];
                                if (data.TerminalType > 0) {
                                    data.Visible = this.terminalService.filterTerminalVisibility(data, "single");
                                    if (currentTerminal.FillLevelData != null && currentTerminal.FillLevelData != undefined) {
                                        data.FillLevelData = currentTerminal.FillLevelData;
                                    }
                                    this.globalCacheVariable.Terminals[i] = data;
                                    existTerminal = true;
                                }
                            }
                        }


                        if (existTerminal === false) {
                            if (data.TerminalType > 0) {
                                data.Visible = this.terminalService.filterTerminalVisibility(data, "single");
                                this.globalCacheVariable.Terminals.push(data);
                            }
                        }
                        //this.globalCacheVariable.Terminals[i].Visible = true;

                    }
                );
            },

            (error: any) => {
            },
            () => {
                // this.child.getAllTerminalsCache()
            }
        );
    }

    getArtConfigurationEntries() {
        return this.operatorService.getArtConfigurations().subscribe(
            (result: any) => {
                this.globalCacheVariable.globalFormatSettings.symbolInsteadCurrency = false;
                for (let i = 0; i < result.length; i++) {
                    this.artConfigEntries.set(result[i].Key, result[i]);
                    this.globalCacheVariable.artConfigurationEntries = this.artConfigEntries;
                    if (result[i].Key === "Basics/DisplayIsoCurrencyCodes") {
                        this.globalCacheVariable.globalFormatSettings.symbolInsteadCurrency = result[i].Value === "True";
                    }
                }

                if (this.globalCacheVariable.artConfigurationEntries.get('Security/UseExtendedArtRights') != undefined && this.globalCacheVariable.artConfigurationEntries.get('Security/UseExtendedArtRights') != null) {
                    this.globalCacheVariable.ArtRightState = this.globalCacheVariable.artConfigurationEntries.get('Security/UseExtendedArtRights');
                }
            },

            (error: any) => {
            },
            () => {
            }
        );

    }

    getDashBoardConfigurationEntries() {
        return this.dashboardService.getDashboardConfigurations().subscribe(
            (result: any) => {
                this.globalCacheVariable.DashboardConfiguration = result;

            },

            (error: any) => {
            },
            () => {
            }
        );

    }

    getUserSettings() {
        return this.operatorService.getUserSettings().subscribe(
            (result: any) => {
                // this.globalCacheVariable.operatorCustomSettings = result;
                console.log("get operatorSettings -> ", JSON.parse(result));
            },

            (error: any) => {
            },
            () => {
            }
        );

    }

    startHeartBeat() {
        var self = this;
        if (this.globalCacheVariable.singleWebClient()) {
            self.heartBeatInterval = setInterval(function() {
                let currentTime = moment().toISOString(true);
                if (self.globalCacheVariable.heartBeat) {
                    return self.dashboardService.heartBeat(currentTime).subscribe(
                        (result: any) => {
                            if (result != null && result.SessionKeyValid == true) {

                            } else {
                                self.artShutdown("1_1");
                                self.globalCacheVariable.heartBeat = false;
                            }
                        },

                        (error: any) => {
                        },
                        () => {
                        }
                    );
                } else {
                    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                    if (currentUser.SessionKey !== undefined) {
                        self.globalCacheVariable.heartBeat = true;
                    }
                }


            }, this.heartBeatIntervalTime);
        }
    }

    ngOnInit() {
        this._script.load('body', 'assets/vendors/base/vendors.bundle.js', 'assets/demo/default/base/scripts.bundle.js', 'assets/app/js/jquery.mCustomScrollbar.js')
            .then(result => {
                Helpers.setLoading(false);
                this._script.load('head', 'assets/vendors/custom/fullcalendar/fullcalendar.bundle.js')
            });
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                //(<any>mLayout).closeMobileAsideMenuOffcanvas();
                //(<any>mLayout).closeMobileHorMenuOffcanvas();
                (<any>mApp).scrollTop();
                Helpers.setLoading(true, this.translate.transform('BASE.LOADING.TITLE'));
                // hide visible popover
                (<any>$('[data-toggle="m-popover"]')).popover('hide');
            }
            if (route instanceof NavigationEnd) {
                // init required js
                (<any>mApp).init();
                (<any>mUtil).init();
                Helpers.setLoading(false);
                // content m-wrapper animation
                let animation = 'm-animate-fade-in-up';
                $('.m-wrapper').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(e) {
                    $('.m-wrapper').removeClass(animation);
                }).removeClass(animation).addClass(animation);
            }
        });

        clearInterval(this.heartBeatInterval);
        this.startHeartBeat();
    }

    ngAfterViewInit() {
        //console.log("NG AFTER VIEW OF THEME");
        if (this.globalCacheVariable.singleWebClient()) {
            this.getArtConfigurationEntries();
            this.getDashBoardConfigurationEntries();
        }
        //const container = document.querySelector('.m-body .m-wrapper');
        //const ps = new PerfectScrollbar(container);
    }

    ngOnDestroy() {
        clearInterval(this.heartBeatInterval);
    }
}
