import { LifecycleRecordType } from "../../_enums/lifecycle/lifeCycleRecordType";
import { MachineStatus } from "../../_enums/machineStatus";

export interface ITerminalRegistration {
    ArtInstallationId: string;
    LifecycleCreated: string;
    LifecycleUpdated: string;
    SerialNumber: string;
}

export interface ITerminalRegistrationView {
    artInstallationId: string;
    lifecycleCreated: string;
    lifecycleUpdated: string;
    serialNumber: string;
}

export class TerminalLifeCycle {
    Id: number;
    ArtInstallationId: string;
    LifecycleCreated: string;
    LifecycleUpdated: string;
    SerialNumber: string;
    LifecycleValue: LifecycleValue;
    LifecycleType: LifecycleRecordType;
    LifecycleTypeView: string;
    Timestamp: string;

}

export class LifecycleValue {
    TerminalID: number;
    SerialNumber: string;
    MacAddress: string;
    ArtID: string;
    UserName: string;
    Reason: string;
    MachineStatus: MachineStatus;
    MachineStatusView: string;
}
