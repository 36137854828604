import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { map, takeUntil, tap, catchError } from 'rxjs/operators';
import { ProductType } from "../_enums/productType";
import { HttpClient } from "@angular/common/http";

//const ART_WEBSOCKET_URL = 'ws://'+document.location.hostname+':2011/ArtClientWebSocket';

export interface Message {
    message: any
}

@Injectable()
export class MessageSocketService {
    public messages: Subject<Message>;
    ART_WEBSOCKET_URL: string;
    currentProject: any;
    constructor(
        wsService: WebsocketService,
        private http: HttpClient) {
        let objectData = this.getProjectConfig().subscribe(data => {
            this.currentProject = data;
            //console.log("currentProject", this.currentProject);
            if (this.currentProject.product_type == ProductType.WebClient) {
                if (document.location.protocol === 'http:') {
                    this.ART_WEBSOCKET_URL = 'ws://' + document.location.hostname + ':2011/ArtClientWebSocket';
                }
                else {
                    this.ART_WEBSOCKET_URL = 'wss://' + document.location.hostname + ':2012/ArtClientWebSocket';
                }

                try {
                    this.messages = <Subject<Message>>wsService
                        .connect(this.ART_WEBSOCKET_URL)
                        //.connect('ws://'+document.location.hostname+':2011/ArtClientWebSockets')
                        .pipe(
                            map((response: MessageEvent): Message => {
                                console.log("Socket message received: ", response.data);
                                //let data = JSON.parse(response.data);
                                let data = response.data;
                                return {
                                    message: data
                                }
                            })
                        )

                } catch (error) {
                    console.log(error);
                    //return "websocketError";
                }
            }
        });



    }

    getProjectConfig(): any {
        try {
            let data = {};
            let body = JSON.stringify(data);
            return this.http.get("./project_config.json")
                .pipe(map(this.extractProjectConfig));
        } catch (error) {
            console.log(error);
        }
    }

    extractProjectConfig(res: Response) {
        const responseData = JSON.parse(JSON.stringify(res));
        return responseData || {};
    }
}

