import { Pipe, PipeTransform } from '@angular/core';
import { InfoType } from '../_enums/infoType';
@Pipe({
    name: 'infoType'
})
export class InfoTypePipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];

        return InfoType[value];
    }
}
