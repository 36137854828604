<p-table [value]="maintenanceLog" [rows]="paginatorRowsPerPage" [scrollable]="true" [style]="{width:'auto'}" class="maintenance-log-table content-table" scrollHeight="flex" *ngIf="maintenanceLogView">
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-colums>
        <tr>
            <th class="tb-left" *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>


    <ng-template pTemplate="body" let-log>
        <tr>
            <td class="tb-left">{{log.TerminalID}}</td>
            <td class="tb-left">{{log.MaintenanceDate}}</td>
            <td class="tb-left">{{log.OperatorID | operatorName}}</td>
            <td class="tb-left">{{log.MaintenanceType}}</td>
            <td class="tb-left">{{log.ComponentType}}</td>
            <td class="tb-left">{{log.ComponentRank}}</td>
            <td class="tb-left">{{log.DeviceType}}</td>
            <td class="tb-left"
                pTooltip="{{log.MaintenanceDetails}}">{{log.MaintenanceDetailsShortener}}</td>
        </tr>
    </ng-template>

</p-table>
