import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalCacheVariable } from '../_globals/globals';
import { catchError, map } from 'rxjs/operators';
import { CustomerSearchParameter } from '../_models/CustomerSearchParameter';
import { ApiDataConverter } from "../_helpers/api-data-converter";
import { ApiCallType } from "../_enums/apiCallType";
import { throwError as observableThrowError } from "rxjs/internal/observable/throwError";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class PaymentKioskService {

    currentCustomer;
    customerInformationTableView: boolean;
    currentCustomerDetail: boolean;
    customerInformationDetailView: boolean;
    constructor(
        private http: HttpClient,
        public cache: GlobalCacheVariable
    ) { }

    private static extractBridge(res, apiType) {
        let response = ApiDataConverter.extractData(res, apiType);
        return response || {};
    }

    public handleError(errorMessage: any, apiType) {
        ApiDataConverter.errorHandling(errorMessage, apiType);
        return observableThrowError(errorMessage);
    }

    getPaymentReport(terminal, reportType, fileType, from, to, summaryOnly, mode) {
        let terminalChooser = [-1];
        if (mode === 1) {
            if (terminal === 'all' || terminal === -1 || terminal === 0) {
                terminalChooser = [-1];
            }
            else {
                terminalChooser = [terminal];
            }
        }
        else {
            terminalChooser = terminal
        }

        try {
            let dataAdd = {
                sessionKey: this.cache.getSessionKey(),
                terminalIDs: terminalChooser,
                start: from,
                end: to,
                reportType: reportType,
                fileType: fileType,
                summaryOnly: summaryOnly
            };

            let body = dataAdd;
            return this.http.post(this.cache.ARTApi + 'GetPaymentReport', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPaymentReport);
                    }),
                    map(data => {
                        return PaymentKioskService.extractBridge(data, ApiCallType.GetPaymentReport);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getCustomerList(from, to) {
        try {
            let dataAdd = {
                sessionKey: this.cache.getSessionKey(),
                from: from,
                to: to,
            };

            let body = dataAdd;
            return this.http.post(this.cache.ARTApi + 'GetCustomers', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetCustomers);
                    }),
                    map(data => {
                        return PaymentKioskService.extractBridge(data, ApiCallType.GetCustomers);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getCustomerDetails(phoneNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                phoneNumber: phoneNumber,
            };
            return this.http.post(this.cache.ARTApi + 'GetCustomerDetails', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetCustomerDetails);
                    }),
                    map(data => {
                        return PaymentKioskService.extractBridge(data, ApiCallType.GetCustomerDetails);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    deleteCustomer(phoneNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                phoneNumber: phoneNumber,
            };
            return this.http.post(this.cache.ARTApi + 'DeleteCustomer', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.DeleteCustomer);
                    }),
                    map(data => {
                        return PaymentKioskService.extractBridge(data, ApiCallType.DeleteCustomer);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    blockCustomer(phoneNumber, blockState) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                phoneNumber: phoneNumber,
                blocked: blockState
            };
            return this.http.post(this.cache.ARTApi + 'BlockCustomer', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.BlockCustomer);
                    }),
                    map(data => {
                        return PaymentKioskService.extractBridge(data, ApiCallType.BlockCustomer);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    searchForCustomers(searchParam: CustomerSearchParameter) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                searchParams: searchParam,
            };
            return this.http.post(this.cache.ARTApi + 'SearchForCustomers', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.SearchForCustomers);
                    }),
                    map(data => {
                        return PaymentKioskService.extractBridge(data, ApiCallType.SearchForCustomers);
                    }));
        } catch (error) {
            console.log(error);
        }
    }
}
