<p-table [value]="errorLog" [rows]="paginatorRowsPerPage" [scrollable]="true" [style]="{width:'auto'}" class="error-log-table content-table" scrollHeight="flex" *ngIf="errorLogView">
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th class="tb-left">{{'BASE.DATE' | translate}}</th>
            <th class="tb-left">{{'BASE.TYPE' | translate}}</th>
            <th class="tb-left">{{'BASE.DETAILS' | translate}}</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-log>
        <tr>
            <td class="tb-left">{{log.ErrorDate | date: globalCacheVariable.globalFormatSettings.dateFormatLong}}</td>
            <td class="tb-left">
                {{'ENUMS.ERROR_TYPE.'+(log.ErrorType | terminalErrorType) | rewriteEnumEntriesForTranslation : 'ENUMS.ERROR_TYPE.'| uppercase | translate }}</td>
            <!--{{log.ErrorType | terminalErrorType}}-->
            <td class="tb-left">{{log.ErrorMessage}}</td>
        </tr>
    </ng-template>

</p-table>
