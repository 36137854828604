import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ScriptLoaderService } from "../_services/script-loader.service";
import { AuthenticationService } from './_services';
import { UserService } from './_services';
import { LoginCustom } from "./_helpers/login-custom";
import { Helpers } from "../helpers";
import { SelectItem } from 'primeng/api';
import { TranslationService } from "./_services/translation.service";
import { MessageService } from 'primeng/api';
import { GlobalCacheVariable } from './_globals/globals';
import { LoginResult } from './_enums/loginStates';
import { fromEvent, Subscription } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { ResponseType } from "./_enums/group/responseType";
import { TranslatePipe } from "@ngx-translate/core";
import { OperatorService } from './_services/operator.service';
import { GroupServerService } from './_services/groupserver.service';
import { ToastSeverity } from "./_enums/messages/toastSeverity";
import { MessageType } from "./_enums/messages/messageType";
import { GeneralRewritePipe } from "./_pipes/generalRewrite.pipe";
import { IGroupUserCustomSettings } from './_models/operatorCustomSettings';

@Component({
    selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
    templateUrl: './templates/login-main.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [MessageService, TranslatePipe, GeneralRewritePipe]
})

export class AuthComponent implements OnInit {
    model: any = {};
    loading: boolean = false;
    returnUrl: string;
    returnGroupUrl: string;
    languages: SelectItem[];
    urlParams: string;
    NewArtRightsArray: any;
    private subscription: Subscription;

    constructor(
        private _router: Router,
        private _script: ScriptLoaderService,
        private _userService: UserService,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _translationService: TranslationService,
        public cache: GlobalCacheVariable,
        private operatorService: OperatorService,
        private groupService: GroupServerService,
        private generalRewrite: GeneralRewritePipe,
        private messageService: MessageService

    ) {
    }

    ngAfterViewInit() {
        this._route.queryParams
            .subscribe(params => {

                if (params.s != null) {
                    this.urlParams = params.product;
                    this.cache.currentUrlLoginParameter = params;

                    if (params.o != null && params.o != '') {

                        if (params.p != null && params.p != '') {
                            this.signIn("script", params);
                        }

                    }

                }

            });
    }

    ngOnInit() {
        const keyDowns = fromEvent(document, 'keydown').pipe(
            filter((e: KeyboardEvent) => e.keyCode === 13),
            distinctUntilChanged()
        );
        this.subscription = keyDowns.subscribe(pressEnter => {

            if (pressEnter.type === 'keydown') {
                this.signIn();
            }

        });
        this.model.remember = true;
        this._script.load('body', 'assets/vendors/base/vendors.bundle.js', 'assets/demo/default/base/scripts.bundle.js')
            .then(() => {
                Helpers.setLoading(false);
                LoginCustom.init();
            });
    }

    signIn(state = null, params = null) {
        this.loading = true;
        let loginName = '';
        let loginPwd = '';
        let stateLogin = 'name';

        if (state == "script") {
            loginName = params.o;
            loginPwd = params.p;
            stateLogin = 'id';
        }
        else {
            loginName = this.model.email;
            loginPwd = this.model.password;
            stateLogin = 'name';
        }

        this._authService.login(loginName, loginPwd, stateLogin)
            .subscribe(data => {
                const self = this;
                //let user =  JSON.parse(JSON.stringify(response));

                if (self.cache.singleWebClient()) {
                    let user = JSON.parse(localStorage.getItem('currentUser'));

                    if (user !== null && user.SessionKey !== "" && user.SessionKey !== null && user.SessionKey !== undefined) {
                        this.messageService.add({
                            key: 'LoginMessage',
                            severity: 'success',
                            summary: 'Login correct',
                            life: 2000,
                            detail: 'Login was correct.'
                        });
                        this.subscription.unsubscribe();

                        this.operatorService.getUserSettings()
                            .subscribe(userSettings => {

                                if (userSettings.hasOwnProperty('userProfileSettings')) {
                                    this.cache.operatorCustomSettings.userProfileSettings.language =
                                        userSettings.userProfileSettings.language;
                                    this.cache.operatorCustomSettings.userProfileSettings.entryPage.webClient =
                                        userSettings.userProfileSettings.entryPage.webClient;
                                }
                                else {
                                    this.cache.getDefaultOperatorSetting(this.cache.artViewMode);
                                }

                                this._translationService.setLanguage(
                                    this.cache.operatorCustomSettings.userProfileSettings.language
                                );
                            })

                        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || this.cache.ARTEntryPoint;

                        setTimeout(function() {
                            self._router.navigate(['/index']).then();
                        }, 500);

                        //ART RIGHT X part
                        if (user.ArtRightsX !== "" && user.ArtRightsX !== null && user.ArtRightsX !== undefined) {
                            this.NewArtRightsArray = new Map();

                            for (let i = 0; i < user.ArtRightsX.length; i++) {
                                this.getTree(user.ArtRightsX[i]);
                            }

                            this.cache.OwnArtRightsX.ArtRightsXArray = this.NewArtRightsArray;
                        }

                    }
                    else {
                        switch (this.cache.loginResult) {
                            case LoginResult.None:
                                this.messageService.add({
                                    key: 'LoginMessage',
                                    severity: 'error',
                                    summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.LOGIN_NOT_POSSIBLE.SUMMARY"),
                                    life: 5000,
                                    detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.LOGIN_NOT_POSSIBLE.DETAIL"),

                                });
                                break;
                            case LoginResult.InvalidPassword:
                                this.messageService.add({
                                    key: 'LoginMessage',
                                    severity: 'error',
                                    summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.INVALID_PASSWORD.SUMMARY"),
                                    life: 5000,
                                    detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.INVALID_PASSWORD.DETAIL"),
                                });
                                break;
                            case LoginResult.InvalidUser:
                                this.messageService.add({
                                    key: 'LoginMessage',
                                    severity: 'error',
                                    summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.INVALID_USERNAME.SUMMARY"),
                                    life: 5000,
                                    detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.INVALID_USERNAME.DETAIL"),
                                });
                                break;
                            case LoginResult.LoginDisabled:
                                this.messageService.add({
                                    key: 'LoginMessage',
                                    severity: 'error',
                                    summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.LOGIN_DISABLED.SUMMARY"),
                                    life: 5000,
                                    detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.LOGIN_DISABLED.DETAIL"),
                                });
                                break;
                            case LoginResult.NotLoggedIn:
                                this.messageService.add({
                                    key: 'LoginMessage',
                                    severity: 'error',
                                    summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.NOT_LOGGED_IN.SUMMARY"),
                                    life: 5000,
                                    detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.NOT_LOGGED_IN.DETAIL"),
                                });
                                break;
                            case LoginResult.SessionCountExceeded:
                                this.messageService.add({
                                    key: 'LoginMessage',
                                    severity: 'error',
                                    summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.SESSION_COUNT_EXCEEDED.SUMMARY"),
                                    life: 5000,
                                    detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.SESSION_COUNT_EXCEEDED.DETAIL"),
                                });
                                break;
                            case LoginResult.AccountTypeNotSupported:
                                this.messageService.add({
                                    key: 'LoginMessage',
                                    severity: 'error',
                                    summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.ACCOUNT_TYPE_NOT_SUPPORTED.SUMMARY"),
                                    life: 5000,
                                    detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.ACCOUNT_TYPE_NOT_SUPPORTED.DETAIL"),
                                });
                                break;
                            case LoginResult.LoginDenied:
                                this.messageService.add({
                                    key: 'LoginMessage',
                                    severity: 'error',
                                    summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.LOGIN_DENIED.SUMMARY"),
                                    life: 5000,
                                    detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.WEB_CLIENT.LOGIN_DENIED.DETAIL"),
                                });
                                break;

                        }
                        self.loading = false;
                    }

                }
                else {
                    let groupUser = JSON.parse(localStorage.getItem('currentGroupUser'));

                    if (
                        groupUser !== null
                        && groupUser.ResponseType == ResponseType.SuccessfulLogIn
                        && groupUser.Token !== ""
                        && groupUser.Token !== null
                        && groupUser.Token !== undefined
                    ) {
                        this.messageService.add({
                            key: 'LoginMessage',
                            severity: 'success',
                            summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.LOGIN_CORRECT.SUMMARY"),
                            life: 2000,
                            detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.LOGIN_CORRECT.DETAIL"),
                        });
                        this.subscription.unsubscribe();

                        this.groupService.getUserSettings()
                            .subscribe((groupUserSettings: IGroupUserCustomSettings) => {

                                if (groupUserSettings.hasOwnProperty('userProfileSettings')) {
                                    this.cache.groupOperatorCustomSettings.userProfileSettings.language =
                                        groupUserSettings.userProfileSettings.language;
                                    this.cache.groupOperatorCustomSettings.userProfileSettings.entryPage.groupWebClient =
                                        groupUserSettings.userProfileSettings.entryPage.groupWebClient;
                                    this.cache.groupOperatorCustomSettings.mapViewSettings = groupUserSettings.mapViewSettings;
                                    this.cache.groupOperatorCustomSettings.dashboardSettings = groupUserSettings.dashboardSettings;
                                }
                                else {
                                    this.cache.getDefaultOperatorSetting(this.cache.artViewMode);
                                }

                                this._translationService.setLanguage(
                                    this.cache.groupOperatorCustomSettings.userProfileSettings.language
                                );
                            })

                        this.returnGroupUrl = this._route.snapshot.queryParams['returnUrl'] || this.cache.ARTGroupEntryPoint;
                        setTimeout(function() {
                            self._router.navigate(['/index']).then();
                        }, 500);
                    }
                    else {
                        if (this.cache.loginResult == ResponseType.LoginDenied) {
                            this.messageService.add({
                                key: 'LoginMessage',
                                severity: 'error',
                                summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.LOGIN_INCORRECT.SUMMARY"),
                                life: 5000,
                                detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.LOGIN_INCORRECT.DETAIL"),
                            });
                        }
                        else if (this.cache.loginResult == ResponseType.InvalidUsername) {
                            this.messageService.add({
                                key: 'LoginMessage',
                                severity: 'error',
                                summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.INVALID_USERNAME.SUMMARY"),
                                life: 5000,
                                detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.INVALID_USERNAME.DETAIL"),
                            });
                        }
                        else if (this.cache.loginResult == ResponseType.InvalidPassword) {
                            this.messageService.add({
                                key: 'LoginMessage',
                                severity: 'error',
                                summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.INVALID_PASSWORD.SUMMARY"),
                                life: 5000,
                                detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.INVALID_PASSWORD.DETAIL"),
                            });
                        }
                        else if (this.cache.loginResult == ResponseType.LoginDisabled) {
                            this.messageService.add({
                                key: 'LoginMessage',
                                severity: 'error',
                                summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.LOGIN_DISABLED.SUMMARY"),
                                life: 5000,
                                detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.LOGIN_DISABLED.DETAIL"),
                            });
                        }
                        else {
                            this.messageService.add({
                                key: 'LoginMessage',
                                severity: 'error',
                                summary: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.LOGIN_INCORRECT.SUMMARY"),
                                life: 5000,
                                detail: this.generalRewrite.translate("AUTH.LOGIN.RESPONSE.GROUP_WEB_CLIENT.LOGIN_INCORRECT.DETAIL"),
                            });
                        }

                        self.loading = false;
                    }



                }

            },
                error => {
                    this.showAlert();
                    this.loading = false;
                });
    }

    getTree(data) {
        //console.log("data", data);
        this.NewArtRightsArray.set(data.Right, true);
        //this.NewArtRightsArray.push(data.Right);
        if (data.SubRights.length > 0) {
            this.getChild(data.SubRights);
        }
    }

    getChild(data) {
        for (let c = 0; c < data.length; c++) {
            this.NewArtRightsArray.set(data[c].Right, true);
            if (data[c].SubRights.length > 0) {
                this.getChild(data[c].SubRights);
            }
        }
    }

    /*
      signup() {
        this.loading = true;
        this._userService.create(this.model)
          .subscribe(
          data => {
            this.showAlert('alertSignin');
            this._alertService.success('Thank you. To complete your registration please check your email.', true);
            this.loading = false;
            LoginCustom.displaySignInForm();
            this.model = {};
          },
          error => {
            this.showAlert('alertSignup');
            this._alertService.error(error);
            this.loading = false;
          });
      }

      forgotPass() {
        this.loading = true;
        this._userService.forgotPassword(this.model.email)
          .subscribe(
          data => {
            this.showAlert('alertSignin');
            this._alertService.success('Cool! Password recovery instruction has been sent to your email.', true);
            this.loading = false;
            LoginCustom.displaySignInForm();
            this.model = {};
          },
          error => {
            this.showAlert('alertForgotPass');
            this._alertService.error(error);
            this.loading = false;
          });
      }
      */

    showAlert() {
        GlobalCacheVariable.toast.next({
            severity: ToastSeverity.Error,
            text: this.generalRewrite.translate('BASE.TOASTS.CONNECTION.NO.TEXT'),
            title: this.generalRewrite.translate('BASE.TOASTS.CONNECTION.NO.TITLE'),
            life: 5000,
            type: MessageType.Toast,
        });
        /*
        this[target].clear();
        let factory = this.cfr.resolveComponentFactory(AlertComponent);
        let ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
        */
    }
}
