import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class SortService {

    constructor() {
    }

    sortTerminalsByName(a, b) {
        if (a.terminalName < b.terminalName) {
            return -1;
        }
        if (a.terminalName > b.terminalName) {
            return 1;
        }
        return 0;
    }

    sortTerminalsByID() {

    }

    sortIDs() {

    }

    sortStrings() {

    }




    public naturalSort(a, b) {
        try {

            return (a.localeCompare(b, undefined, { numeric: true }));

        } catch (error) {

            console.warn("Extended localeCompare() not supported in this browser.");
            return (a.localeCompare(b));

        }
    }

    public handleLocaleCompareSort(direction: "asc" | "desc", caseInsensitive: boolean, arrayForSort) {

        if (direction === "asc") {

            var operator = caseInsensitive
                ? this.compareNoCase
                : this.compare
                ;

        } else {

            var operator = caseInsensitive
                ? this.invertedCompareNoCase
                : this.invertedCompare
                ;

        }
        return arrayForSort.sort(operator);
    }

    public compare(a: string, b: string): number {

        // Not all browsers support the extended options for localeCompare(). As such, let's
        // wrap the extended version in a try/catch and just fall-back to using the simple
        // version. In this case, we're going to use the "numeric" option, which gets the
        // browser to treat embedded numbers AS NUMBERS, which allows for a more "natural
        // sort" behavior.
        try {

            return (a.localeCompare(b, undefined, { numeric: true }));

        } catch (error) {

            console.warn("Extended localeCompare() not supported in this browser.");
            return (a.localeCompare(b));

        }

    }

    public compareNoCase(a: string, b: string): number {

        return (this.compare(a.toUpperCase(), b.toUpperCase()));

    }

    public invertedCompare(a: string, b: string): number {

        return (-this.compare(a, b));

    }

    public invertedCompareNoCase(a: string, b: string): number {

        return (-this.compareNoCase(a, b));

    }

}
