import { Pipe, PipeTransform } from '@angular/core';
import { MediaDirection } from '../_enums/mediaDirection';
@Pipe({
    name: 'mediaDirection'
})
export class MediaDirectionPipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];

        return MediaDirection[value];
    }
}
