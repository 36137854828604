export enum LoginResult {
    None = 0,
    NotLoggedIn = 1,
    InvalidUser = 2,
    InvalidPassword = 3,
    LoginDisabled = 4,
    LoggedIn = 5,
    SessionCountExceeded = 6,
    AccountTypeNotSupported = 7,
    LoginDenied = 8,
}
