export class TerminalTypeDefinition {
    GeneralDataSync: boolean;
    OperatorSync: boolean;
    Stock: boolean;
    ConfigurationProvider: boolean;
    ElectronicLock: boolean;
    SessionManager: boolean;
    AccountSync: boolean;
    Replication: boolean;
    ServiceCards: boolean;
    MediaPolicy: boolean;
    TransactionApproval: boolean;
    Branding: boolean;

    HasShutdown: boolean;
    HasAdvertising: boolean;
    HasNotifications: boolean;
    HasBillDispenser: boolean;
    HasMoneyConfiguration: boolean;
    HasOperatorCardLogin: boolean;
    HasDeviceAvailability: boolean;
    HasLifetimeMeters: boolean;
    HasModuleConfiguration: boolean;
    HasMoneyConfigurationLoad: boolean;
    HasComponentOverview: boolean;
    HasFeatureOverview: boolean;
    HasRemoteControl: boolean;
    HasRemoteAccess: boolean;
    HasRemoteSession: boolean;
    HasScreenshots: boolean;
    HasElectronicLock: boolean;
    HasFillLevels: boolean;
    HasAccountSync: boolean;
    HasPermanentNotice: boolean;
    HasTransactionApproval: boolean;
    HasMaintenanceLog: boolean;
}
