export enum RemoteAccessResponseCode {
    Unknown = -1,
    None = 0,
    RemoteAccessPossible = 1,
    InTransaction = 2,
    OperatorAlreadyLoggedIn = 3,
    OperatorUnknown = 4,
    ServiceFailure = 5,
    InvalidRequest = 6,
}
