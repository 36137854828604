<div class="p-grid feature-overview">
    <div class="p-col-12 p-md-12">
        <div class="p-col-12">
            <div class="p-grid">
                <ng-container *ngIf="displayTerminalFeature">
                    <ng-container *ngIf="currentChangeState">
                        <ng-container *ngFor="let featureEntry of showedFeatures">
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-2 p-col-align-center">
                                        <div [class]="featureEntry.class"></div>
                                    </div>
                                    <div class="p-col-10">
                                        <h2>{{featureEntry.type}}</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-9">
                                <div class="p-grid">
                                    <ng-container *ngFor="let dependency of featureEntry.dependencies">
                                        <div class="p-col-1" (click)="showComponentMonitor(dependency)">
                                            <div class="p-col-12 p-col-align-center">
                                                <img [title]="dependency.title" [src]="dependency.src" [alt]="dependency.alt"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!currentChangeState">
                        <div>{{'Feature not available (only for currently connected Terminals)'}}</div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
