import { Injectable } from "@angular/core";
import { GlobalCacheVariable } from '../_globals/globals';
import { TranslationService } from './translation.service';
import { ArtRightX } from "../_enums/artRightX";
import { ArtRight } from "../_enums/artRight";
import { GeneralService } from './general.service';
import { UserRight } from '../_enums/group/userRight';
import { CurrentGroupUser } from '../_models/group/currentGroupUser';

@Injectable()
export class CheckRightsService {
    worldRightsOnly: any;
    constructor(
        private _translationService: TranslationService,
        public cache: GlobalCacheVariable,
        private generalService: GeneralService
    ) {
        this.worldRightsOnly = [
            UserRight.KioskRegistration,
            UserRight.ManufacturingOrderOverview,
            UserRight.TerminalLifecycleView,
            UserRight.ComputerRegistrationView,
            UserRight.LicenseKeyUsageOverview,
            UserRight.ManufacturingOrders
        ]
    }

    checkArtRightsX(givenRight) {

        if (this.cache.ArtRightState) {
            return this.cache.OwnArtRightsX.ArtRightsXArray.get(givenRight);
        }
        else {

            if (givenRight == ArtRightX.OperatorManagement) {
                return this.cache.checkARTRight(ArtRight.OperatorManagement);
            }
            else if (givenRight == ArtRightX.PlayerManagement) {
                return this.cache.checkARTRight(ArtRight.PlayerManagement);
            }
            else if (givenRight == ArtRightX.CustomerManagement) {
                return this.cache.checkARTRight(ArtRight.ManageCustomers);
            }
            else {
                return true;
            }

        }

    }

    checkUserRight(right: UserRight): boolean {
        let currentUser: CurrentGroupUser = this.cache.getCurrentGroupUser();
        let currentUserRights: number = currentUser.Rights;

        if (this.cache.worldMode == true) {
            return this.generalService.hasRight(currentUserRights, right);
        }
        else {

            if (!this.worldRightsOnly.includes(right)) {
                return this.generalService.hasRight(currentUserRights, right);
            }
            else {
                return false;
            }

        }

    }



}
