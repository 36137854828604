export enum DeviceType {
    None = 0,
    Debug = 1,

    // bill acceptors
    BillAcceptorWba = 101,
    BillAcceptorSanwa = 102, // is a ticket acceptor/printer only (not bill) !!
    BillAcceptorWbaIrq = 103,
    BillAcceptorCashflow = 104,
    BillAcceptorNv200 = 105,
    BillAcceptorCashcode = 106,
    BillAcceptorJcmIPro = 107,
    BillAcceptorJcmIVizion = 108,
    BillAcceptorJcmTbv = 109,

    // bill dispensers
    BillDispenserItautec = 201,
    BillDispenserDelarueNmd100 = 202,
    BillDispenserDelarueNmd50 = 203,
    BillDispenserDelarueMultimech = 204,
    BillDispenserFujitsuF610 = 205,
    BillDispenserFujitsuF53 = 206,
    BillDispenserGrgCdm8240 = 207,
    BillDispenserFujitsuF510 = 208,
    BillDispenserGrgCdm6240 = 209,

    // coin acceptors
    CoinAcceptorCctalk = 301,
    CoinAcceptorProCoin = 302,

    // coin dispensers
    CoinDispenserItautec = 401,
    CoinDispenserPerconta = 402,
    CoinDispenserCctalk = 403,
    CoinDispenserSmart = 404,

    // cassettes
    CassetteItautec = 501,
    CassetteDelarueNmd100 = 502,
    CassetteDelarueNmd50 = 503,
    CassetteDelarueMultimech = 504,
    CassetteFujitsuF610 = 505,
    CassetteFujitsuF53 = 506,
    CassetteGrgCdm8240 = 507,
    CassetteFujitsuF510 = 508,
    CassetteGrgCdm6240 = 509,

    // reject boxes
    RejectBoxItautec = 601,
    RejectBoxDelarueNmd100 = 602,
    RejectBoxDelarueNmd50 = 603,
    RejectBoxDelarueMultimech = 604,
    RejectBoxFujitsuF610 = 605,
    RejectBoxFujitsuF53 = 606,
    RejectBoxGrgCdm8240 = 607,

    // shutters
    ShutterGrg = 701,

    // hoppers
    HopperItautec = 801,
    HopperPerconta = 802,
    HopperCctalk = 803,
    HopperSmart = 804,

    // ticket printers
    TicketPrinterFuturelogicGen2 = 901,
    TicketPrinterTransactEpic950 = 902,

    // card readers
    CardReaderIcm300 = 1001,
    CardReaderCim1000 = 1002,
    CardReaderCrt288 = 1003,
    CardReaderKyt6Xxx = 1004,
    CardReaderHs862 = 1005,
    CardReaderIcm330 = 1006,
    CardReaderHs910 = 1007,
    CardReaderMagtekSwipe = 1008,
    CardReaderHs855 = 1009,
    CardReaderKytronicsChr1000 = 1010,
    CardReaderSankyoIcm370 = 1011,
    CardReaderEfloorCr7 = 1012,
    CardReaderIct3V5 = 1013,
    CardReaderAcr39 = 1014,

    // controllers
    ControllerItautec = 1101,
    ControllerSensorOnly = 1102,

    // uninterruptible power supplies
    UpsPulsar = 1201,
    UpsApc = 1202,
    UpsEaton = 1203,
    UpsEatonHid = 1204,

    // keyboards
    KeyboardStandard = 1301,
    KeyboardItautec = 1302,
    KeyboardSagem4220 = 1303,
    KeyboardSagem4242 = 1304,
    KeyboardCryptera1215 = 1305,
    KeyboardCryptera1215Pci5 = 1306,

    // touchscreens
    Touchscreen3M = 1401,
    TouchscreenElo = 1402,
    TouchscreenPerconta = 1403,

    // receipt printers
    ReceiptPrinterTg1280 = 1501,
    ReceiptPrinterTg2480 = 1502,
    ReceiptPrinterFuturelogicGen2 = 1503,
    ReceiptPrinterTransactEpic950 = 1504,
    ReceiptPrinterFiscalBox = 1505,

    // fingerprint readers
    FingerprintReaderTouchChip = 1601,

    // palm scanners
    PalmScannerPalmSecure = 1602,

    // video texts
    VideoTextStandard = 1701,
    VideoTextGtd = 1702,

    // rear controls
    RearControlStandard = 1801,
    RearControlExtended = 1802,

    // barcode scanners
    BarcodeScannerStandard = 1901,
    BarcodeScannerHid = 1902,
    BarcodeScannerDatalogicGryphon = 1903,

    // point of sales
    PosIngenico = 2001,
    PosCcv = 2002,

    // badges
    BadgeGd32Custom = 2101,
    BadgeSsd1322 = 2102,

    // fiscal connections
    FiscalConnectionPayOnline = 2201,

    // ID scanners
    IDScannerArhSmartKiosk = 2301,
    IDScannerPertech6000 = 2302,

    // handhelds
    HandheldPt7003 = 2401,
    HandheldGtS7500 = 2402,
    HandheldSmJ320Fn = 2403,

    // ticket acceptors
    TicketAcceptorAmTote = 2501,

    // power supplies
    GenericCashioPowerSupply = 2600,
    CashioPowerSupply12V = 2601,
    CashioPowerSupply24V = 2602,

    // monitors
    GenericCashioMonitor = 2700,
    GeneralTouchMonitor = 2701,
    GreenTouchMonitor = 2702,

    // main controllers
    GenericCashioMainController = 2800,
}
