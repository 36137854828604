import { Pipe, PipeTransform } from '@angular/core';
import { AccessPoint } from '../_enums/accessPoint';
import { GlobalCacheVariable } from "../_globals/globals";
import { GeneralRewritePipe } from './generalRewrite.pipe';

@Pipe({
    name: 'accessPoint'
})
export class AccessPointPipe implements PipeTransform {

    constructor(
        public globalCache: GlobalCacheVariable,
        private generalPipe: GeneralRewritePipe
    ) { }

    transform(accessPoint: AccessPoint, terminalID: number, ClientEndpoint: string): string {

        switch (accessPoint) {
            case AccessPoint.ArtClient:
                return "ART Client " + ClientEndpoint;

            case AccessPoint.Terminal:
                return this.globalCache.Terminals.find(terminal => terminal.TerminalID == terminalID)?.TerminalName ||
                    this.generalPipe.translate('LOGS.OPERATOR_ACCESS.TABLE.UNKNOWN_TERMINAL_NAME', { id: terminalID });

            case AccessPoint.ArtWebClient:
                return "ART Web Client"

            default:
                return this.generalPipe.translate('BASE.UNKNOWN_TYPE', { type: accessPoint });
        }

    }

}

