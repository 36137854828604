import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MoneyPipe } from "./auth/_pipes/money.pipe";
import { ArtCurrencyPipe } from "./auth/_pipes/artCurrency.pipe";
import { TerminalNamePipe } from "./auth/_pipes/terminalName.pipe";
import { PropertyNamePipe } from "./auth/_pipes/propertyName.pipe";
import { TerminalErrorTypePipe } from "./auth/_pipes/terminalErrorType.pipe";
import { TransactionTypePipe } from './auth/_pipes/transactionType.pipe';
import { MediumTypePipe } from './auth/_pipes/mediumType.pipe';
import { StockMediumTypePipe } from './auth/_pipes/stockMediumType.pipe';
import { InfoTypePipe } from './auth/_pipes/infoType.pipe';
import { TransactionFlagPipe } from './auth/_pipes/transactionFlag.pipe';
import { MediaDirectionPipe } from './auth/_pipes/mediaDirection.pipe';
import { MediumActionPipe } from './auth/_pipes/mediumAction.pipe';
import { EventSourcePipe } from './auth/_pipes/eventSource.pipe';
import { OperatorRolePipe } from './auth/_pipes/operatorRole.pipe';
import { AccessPointPipe } from './auth/_pipes/accessPoint.pipe';
import { VersionTypePipe } from './auth/_pipes/versionType.pipe';
import { ComponentTypePipe } from './auth/_pipes/componentType.pipe';
import { TerminalStatusPipe } from './auth/_pipes/terminalStatus.pipe';
import { FillLevelDeviceTypePipe } from './auth/_pipes/fillLevelDeviceType.pipe';
import { OperatorNamePipe } from "./auth/_pipes/operatorName.pipe";
import { RewriteEnumEntriesForTranslationPipe } from "./auth/_pipes/rewriteEnumEntriesForTranslation.pipe";
import { TerminalStatePipe } from "./auth/_pipes/terminalState.pipe";
import { TerminalTypeNamingPipe } from "./auth/_pipes/terminalTypeNaming.pipe";
import { TerminalIconRewritePipe } from "./auth/_pipes/terminalIconRewrite.pipe";
import { FlagNamePipe } from "./auth/_pipes/flagName.pipe";
import { PredefinedReportTypePipe } from "./auth/_pipes/predefinedReportType.pipe";
import { BalancingTypePipe } from "./auth/_pipes/balancingType.pipe";
import { TimeSpanPipe } from "./auth/_pipes/timeSpan.pipe";
import { TerminalTypePipe } from "./auth/_pipes/terminalType.pipe";
import { DashboardWidgetValidatePipe } from "./auth/_pipes/dashboardWidget.pipe";
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { UserMessagesListComponent } from "./theme/common/user-messages-list/user-messages-list.component";
import { TransactionLogDetailComponent } from "./theme/common/transaction-log-detail/transaction-log-detail.component";
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from "@angular/common";
import { ChartModule } from "primeng/chart";
import { NgChartsModule } from 'ng2-charts';
import 'chart.piecelabel.js';
import { SelectButtonModule } from "primeng/selectbutton";
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SliderModule } from 'primeng/slider';
import { TreeTableModule } from 'primeng/treetable';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ListboxModule } from "primeng/listbox";
import { ProgressBarModule } from 'primeng/progressbar';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';

import { TerminalComponentListComponent } from "./theme/pages/default/monitoring/component-overview/terminal-component-list/terminal-component-list.component";
import { TerminalComponentFilterComponent } from "./theme/pages/default/monitoring/component-overview/terminal-component-filter/terminal-component-filter.component";
import { TerminalIdentifierListComponent } from "./theme/pages/default/monitoring/terminal-identifiers/terminal-identifier-list/terminal-identifier-list.component";
import { TerminalIdentifierFilterComponent } from "./theme/pages/default/monitoring/terminal-identifiers/terminal-identifier-filter/terminal-identifier-filter.component";
import { TerminalIconComponent } from "./theme/pages/default/monitoring/terminal/terminal-icon/terminal-icon.component";
import { TerminalSingleDetailsComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details.component";
import { TerminalSingleDetailsErrorLogComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details-error-log/terminal-single-details-error-log.component";
import { TerminalSingleDetailsMediaLogComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details-media-log/terminal-single-details-media-log.component";
import { TerminalSingleDetailsEventLogComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details-event-log/terminal-single-details-event-log.component";
import { TerminalSingleDetailsMaintenanceLogComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details-maintenance-log/terminal-single-details-maintenance-log.component";
import { TerminalSingleDetailsConfigurationChanceLogComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details-configuration-log/terminal-single-details-configuration-log.component";
import { TerminalSingleDetailsTransactionLogComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details-transaction-log/terminal-single-details-transaction-log.component";
import { TerminalSingleDetailsTerminalEditComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details-terminal-edit/terminal-single-details-terminal-edit.component";
import { TerminalSingleDetailsIdentifierComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details-identifier/terminal-single-details-identifier.component";
import { TerminalSingleDetailsOperatorAccessLogComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details-operator-access-log/terminal-single-details-operator-access-log.component";
import { TerminalDetailsComponent } from "./theme/pages/default/monitoring/terminal/terminal-details.component/terminal-details.component";
import { OperatorCurrentSessionListComponent } from "./theme/common/operator-current-session-list/operator-current-session-list.component";
import { UserLanguageSwitcherComponent } from "./theme/common/user-language-switcher/user-language-switcher.component";
import { ArtAboutComponent } from "./theme/common/art-about/art-about.component";
import { MaintenanceListComponent } from "./theme/common/maintenance-list/maintenance-list.component";
import { TerminalChooserComponent } from "./theme/common/terminal-chooser/terminal-chooser.component";
import { DashboardMachineOverviewComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-machine-overview/dashboard-machine-overview.component";
import { DashboardTransactionCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-transaction-count/dashboard-transaction-count.component";
import { DashboardTransactionTotalsComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-transaction-totals/dashboard-transaction-totals.component";
import { DashboardTransactionAveragesComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-transaction-averages/dashboard-transaction-averages.component";
import { DashboardTransactionLastComponent } from "./theme/pages/default/monitoring/dashboard/dashboad-last-transactions/dashboard-transaction-last.component";
import { DashboardOperatorsCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-operators-count/dashboard-operators-count.component";
import { DashboardOperatorsLastComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-operators-last/dashboard-operators-last.component";
import { DashboardMachineListComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-machine-list/dashboard-machine-list.component";
import { DashboardCustomerCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-customer-count/dashboard-customer-count.component";
import { DashboardTerminalUptimeCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-terminal-uptime-count/dashboard-terminal-uptime-count.component";
import { DashboardDonatedCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-donated-count/dashboard-donated-count.component";
import { DashboardIssuedTerminalCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-issued-terminal-count/dashboard-issued-terminal-count.component";
import { DashboardReplacedPartsCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-replaced-parts-count/dashboard-replaced-parts-count.component";
import { DashboardMoneyServiceCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-money-service-count/dashboard-money-service-count.component";
import { DashboardMaintenanceCallsCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-maintenance-calls-count/dashboard-maintenance-calls-count.component";
import { DashboardSoftwareUpdateCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-software-updates-count/dashboard-software-update-count.component";
import { DashboardSwitchComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-switch/dashboard-switch.component";
import { DashboardTerminalCountComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-terminal-count/dashboard-terminal-count.component";
import { TerminalComponentDetailOverviewComponent } from "./theme/pages/default/monitoring/component-overview/terminal-component-detail-overview/terminal-component-detail-overview.component";
import { TerminalComponentDetailComponent } from "./theme/pages/default/monitoring/component-overview/terminal-component-detail/terminal-component-detail.component";
import { DashboardTerminalUptimeExtendedComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-terminal-uptime-extended/dashboard-terminal-uptime-extended.component";
import { ToastModule } from "primeng/toast";
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { FieldsetModule } from 'primeng/fieldset';
import { GalleriaModule } from 'primeng/galleria';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { StepsModule } from 'primeng/steps';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { InputNumberModule } from 'primeng/inputnumber';
import { ContextMenuModule } from "primeng/contextmenu";
import { DashboardSidebarsGeneralConfigurationComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-sidebars/general-configuration/general-configuration.component";
import { FeatureOverviewComponent } from "./theme/common/feature-overview/feature-overview.component";
import { ArtInstallationDetailsComponent } from "./theme/pages/default/group-server/common/art-installation-details/art-installation-details.component";
import { DependencyPipe } from "./auth/_pipes/dependency.pipe";
import { RouterPagePipe } from "./auth/_pipes/routerPage.pipe";

import { TerminalUtilizationPipe } from "./auth/_pipes/terminalUtilization.pipe";
import { ARTInstallationTerminalDetailsComponent } from "./theme/pages/default/group-server/common/terminal-details/terminal-details.component";
import { LifeCycleStepPipe } from "./auth/_pipes/lifeCycleStep.pipe";
import { ARTInstallationPropertyDetailsComponent } from "./theme/pages/default/group-server/common/property-details/property-details.component";
import { EffectiveAddressPipe } from "./auth/_pipes/effectiveAddress.pipe";
import { ArtInstallationModePipe } from "./auth/_pipes/artInstallationMode.pipe";
import { SiteAssignmentComponent } from "./theme/common/site_assignment/site-assignment.component";
import { TestRunTypePipe } from "./auth/_pipes/testRunType.pipe";
import { SeverityPipe } from "./auth/_pipes/severity.pipe";
import { DeviceInformationTypePipe } from "./auth/_pipes/deviceInformationType.pipe";
import { TestResultPipe } from "./auth/_pipes/testResult.pipe";
import { LifeCycleRecordTypePipe } from "./auth/_pipes/lifeCycleRecordType.pipe";
import { DashboardRegisteredCustomersComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-registered-customers/dashboard-registered-customers.component";
import { DashboardRegisteredPlayersComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-registered-players/dashboard-registered-players.component";
import { DashboardMoneyServiceActionRequiredComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-money-service-action-required/dashboard-money-service-action-required.component";
import { DashboardTerminalOutOfOperationComponent } from "./theme/pages/default/monitoring/dashboard/dashboard-terminal-out-of-operation/dashboard-terminal-out-of-operation.component";
import { SplitAmountTypePipe } from "./auth/_pipes/splitAmountType.pipe";
import { DateStringRewritePipe } from "./auth/_pipes/dateStringRewrite.pipe";
import { TerminalTypeByIDPipe } from "./auth/_pipes/terminalTypeByID.pipe";
import { TerminalEventPipe } from "./auth/_pipes/terminalEvent.pipe";
import { ARTInstallationOperatorDetailsComponent } from "./theme/pages/default/group-server/common/operator-details/operator-details.component";
import { CameraComponent } from "./theme/common/camera/camera.component";
import { WebcamModule } from "ngx-webcam";
import { ArtFilterComponent } from "./theme/layouts/art-filter/art-filter.component";
import { TerminalSingleDetailsTerminalAddressComponent } from "./theme/pages/default/monitoring/terminal-single-details/terminal-single-details-terminal-address/terminal-single-details-terminal-address.component";
import { PaymentCustomerSearchComponent } from './theme/pages/default/management/payment-customer/payment-customer-search/payment-customer-search.component';
import { PaymentCustomerDetails } from './theme/pages/default/management/payment-customer/payment-customer-details/payment-customer-details.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessageModule } from 'primeng/message';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { PasswordModule } from 'primeng/password';
import { VpnStatePipe } from './auth/_pipes/vpnStatePipe';
import { CpuArchPipe } from './auth/_pipes/cpuArchPipe';
import { EndOfShiftReportDetailsComponent } from './theme/pages/default/statistics/end-of-shift/end-of-shift-report-details/end-of-shift-report-details.component';
import { DashboardTransactionTypeComponent } from './theme/pages/default/monitoring/dashboard/dashboard-transaction-type/dashboard-transaction-type.component';
import { DashboardSoftwareDistributionOSComponent } from './theme/pages/default/monitoring/dashboard/dashboard-software-distribution-os/dashboard-software-distribution-os.component';
import { DashboardSoftwareDistributionComponent } from './theme/pages/default/monitoring/dashboard/dashboard-software-distribution/dashboard-software-distribution.component';
import { TerminalLifecycleDetailsComponent } from './theme/pages/default/group-server/common/terminal-lifecycle-details/terminal-lifecycle-details.component';
import { PagerBarComponent } from "./theme/common/pager-bar/pager-bar.component";
import { TerminalFilterbarComponent } from "./theme/pages/default/monitoring/terminal/terminal-filterbar/terminal-filterbar.component";
import { TerminalIconSizeComponent } from "./theme/pages/default/monitoring/terminal/terminal-icon-size/terminal-icon-size.component";
import { TerminalDetailsTitaComponent } from "./theme/pages/default/monitoring/terminal/terminal-details-tita.component/terminal-details-tita.component";
import { TerminalStatesPipe } from './auth/_pipes/terminalStates.pipe';
import { GeneralRewritePipe } from "./auth/_pipes/generalRewrite.pipe";
import { RippleModule } from 'primeng/ripple';
import { ManufacturingOrdersDetailsComponent } from './theme/pages/default/group-server/management/manufacturing-orders/manufacturing-orders-details/manufacturing-orders-details.component';
import { PanelModule } from "primeng/panel";
import {
    BlockUnblockChangeComponent
} from "./theme/pages/default/group-server/common/block-unblock-change/block-unblock-change.component";
import { PieChartDetailsComponent } from './theme/pages/default/monitoring/dashboard/pie-chart-details/pie-chart-details.component';

//ART GROUPSERVER

@NgModule({
    declarations: [
        TerminalNamePipe,
        PropertyNamePipe,
        TerminalErrorTypePipe,
        MoneyPipe,
        ArtCurrencyPipe,
        TransactionTypePipe,
        MediumTypePipe,
        InfoTypePipe,
        StockMediumTypePipe,
        TransactionFlagPipe,
        MediaDirectionPipe,
        MediumActionPipe,
        EventSourcePipe,
        OperatorRolePipe,
        AccessPointPipe,
        OperatorNamePipe,
        VersionTypePipe,
        RewriteEnumEntriesForTranslationPipe,
        TerminalStatePipe,
        TerminalStatesPipe,
        TerminalTypeNamingPipe,
        TerminalIconRewritePipe,
        ComponentTypePipe,
        TerminalStatusPipe,
        FillLevelDeviceTypePipe,
        FlagNamePipe,
        PredefinedReportTypePipe,
        BalancingTypePipe,
        TimeSpanPipe,
        TerminalTypePipe,
        TerminalTypeByIDPipe,
        DashboardWidgetValidatePipe,
        DependencyPipe,
        RouterPagePipe,
        TerminalUtilizationPipe,
        LifeCycleStepPipe,
        EffectiveAddressPipe,
        ArtInstallationModePipe,
        TestRunTypePipe,
        TestResultPipe,
        SeverityPipe,
        DeviceInformationTypePipe,
        LifeCycleRecordTypePipe,
        SplitAmountTypePipe,
        DateStringRewritePipe,
        TerminalEventPipe,
        GeneralRewritePipe,
        UserMessagesListComponent,
        TransactionLogDetailComponent,
        TerminalComponentListComponent,
        TerminalComponentFilterComponent,
        TerminalComponentDetailOverviewComponent,
        TerminalComponentDetailComponent,
        TerminalIdentifierListComponent,
        TerminalIdentifierFilterComponent,
        TerminalIconComponent,
        TerminalSingleDetailsComponent,
        TerminalSingleDetailsErrorLogComponent,
        TerminalSingleDetailsMediaLogComponent,
        TerminalSingleDetailsEventLogComponent,
        TerminalSingleDetailsMaintenanceLogComponent,
        TerminalSingleDetailsConfigurationChanceLogComponent,
        TerminalSingleDetailsTransactionLogComponent,
        TerminalSingleDetailsTerminalEditComponent,
        TerminalSingleDetailsTerminalAddressComponent,
        TerminalSingleDetailsIdentifierComponent,
        TerminalSingleDetailsOperatorAccessLogComponent,
        TerminalDetailsComponent,
        TerminalFilterbarComponent,
        TerminalIconSizeComponent,
        TerminalDetailsTitaComponent,
        DashboardMachineOverviewComponent,
        DashboardSoftwareDistributionComponent,
        DashboardTransactionCountComponent,
        DashboardTransactionTotalsComponent,
        DashboardTransactionAveragesComponent,
        DashboardTransactionLastComponent,
        DashboardOperatorsCountComponent,
        DashboardTerminalCountComponent,
        DashboardOperatorsLastComponent,
        DashboardMachineListComponent,
        DashboardCustomerCountComponent,
        DashboardTerminalUptimeCountComponent,
        DashboardTerminalUptimeExtendedComponent,
        DashboardDonatedCountComponent,
        DashboardTransactionTypeComponent,
        DashboardIssuedTerminalCountComponent,
        DashboardReplacedPartsCountComponent,
        DashboardMoneyServiceCountComponent,
        DashboardMaintenanceCallsCountComponent,
        DashboardSoftwareUpdateCountComponent,
        DashboardSwitchComponent,
        DashboardSidebarsGeneralConfigurationComponent,
        DashboardSoftwareDistributionOSComponent,
        DashboardRegisteredCustomersComponent,
        DashboardRegisteredPlayersComponent,
        DashboardMoneyServiceActionRequiredComponent,
        DashboardTerminalOutOfOperationComponent,
        OperatorCurrentSessionListComponent,
        UserLanguageSwitcherComponent,
        ArtAboutComponent,
        PagerBarComponent,
        TerminalChooserComponent,
        MaintenanceListComponent,
        FeatureOverviewComponent,
        ArtInstallationDetailsComponent,
        ARTInstallationTerminalDetailsComponent,
        ARTInstallationPropertyDetailsComponent,
        ARTInstallationOperatorDetailsComponent,
        SiteAssignmentComponent,
        CameraComponent,
        ArtFilterComponent,
        PaymentCustomerSearchComponent,
        PaymentCustomerDetails,
        VpnStatePipe,
        CpuArchPipe,
        EndOfShiftReportDetailsComponent,
        TerminalLifecycleDetailsComponent,
        ManufacturingOrdersDetailsComponent,
        BlockUnblockChangeComponent,
        PieChartDetailsComponent,

    ],
    imports: [
        CommonModule,
        FormsModule,
        DialogModule,
        TableModule,
        CardModule,
        TabViewModule,
        TranslateModule,
        TooltipModule,
        ChartModule,
        NgChartsModule,
        ButtonModule,
        SidebarModule,
        DropdownModule,
        SelectButtonModule,
        RouterModule,
        InputSwitchModule,
        SliderModule,
        TreeTableModule,
        CheckboxModule,
        CalendarModule,
        ScrollPanelModule,
        PanelModule,
        ListboxModule,
        ProgressBarModule,
        PaginatorModule,
        ToastModule,
        ToolbarModule,
        InputNumberModule,
        InputTextModule,
        BreadcrumbModule,
        ContextMenuModule,
        SplitButtonModule,
        StepsModule,
        InputTextareaModule,
        TreeTableModule,
        TreeModule,
        GalleriaModule,
        KeyFilterModule,
        MessageModule,
        GalleriaModule,
        FontAwesomeModule,
        WebcamModule,
        AngularSignaturePadModule,
        PasswordModule,
        RippleModule
    ],
    exports: [
        TerminalNamePipe,
        PropertyNamePipe,
        TerminalErrorTypePipe,
        MoneyPipe,
        ArtCurrencyPipe,
        TransactionTypePipe,
        MediumTypePipe,
        InfoTypePipe,
        StockMediumTypePipe,
        TransactionFlagPipe,
        MediaDirectionPipe,
        MediumActionPipe,
        EventSourcePipe,
        OperatorRolePipe,
        AccessPointPipe,
        OperatorNamePipe,
        VersionTypePipe,
        RewriteEnumEntriesForTranslationPipe,
        TerminalStatePipe,
        TerminalStatesPipe,
        TerminalTypeNamingPipe,
        TerminalIconRewritePipe,
        ComponentTypePipe,
        TerminalStatusPipe,
        FillLevelDeviceTypePipe,
        FlagNamePipe,
        PredefinedReportTypePipe,
        BalancingTypePipe,
        TimeSpanPipe,
        TerminalTypePipe,
        DashboardWidgetValidatePipe,
        DependencyPipe,
        RouterPagePipe,
        TerminalUtilizationPipe,
        LifeCycleStepPipe,
        EffectiveAddressPipe,
        ArtInstallationModePipe,
        TestRunTypePipe,
        TestResultPipe,
        TerminalTypeByIDPipe,
        SeverityPipe,
        DeviceInformationTypePipe,
        LifeCycleRecordTypePipe,
        SplitAmountTypePipe,
        DateStringRewritePipe,
        TerminalEventPipe,
        GeneralRewritePipe,
        FormsModule,
        DialogModule,
        TabViewModule,
        SelectButtonModule,
        InputSwitchModule,
        SliderModule,
        ButtonModule,
        SidebarModule,
        DropdownModule,
        ChartModule,
        NgChartsModule,
        TooltipModule,
        CardModule,
        PaginatorModule,
        CalendarModule,
        ScrollPanelModule,
        PanelModule,
        ListboxModule,
        ProgressBarModule,
        ToastModule,
        CheckboxModule,
        TableModule,
        ToolbarModule,
        InputTextModule,
        InputNumberModule,
        BreadcrumbModule,
        ContextMenuModule,
        SplitButtonModule,
        InputTextareaModule,
        FieldsetModule,
        StepsModule,
        TreeTableModule,
        TreeModule,
        GalleriaModule,
        MenuModule,
        UserMessagesListComponent,
        TransactionLogDetailComponent,
        TranslateModule,
        AngularSignaturePadModule,
        TerminalComponentListComponent,
        TerminalComponentFilterComponent,
        TerminalComponentDetailOverviewComponent,
        TerminalComponentDetailComponent,
        TerminalIdentifierListComponent,
        TerminalIdentifierFilterComponent,
        TerminalIconComponent,
        TerminalSingleDetailsComponent,
        TerminalSingleDetailsErrorLogComponent,
        TerminalSingleDetailsMediaLogComponent,
        TerminalSingleDetailsEventLogComponent,
        TerminalSingleDetailsMaintenanceLogComponent,
        TerminalSingleDetailsConfigurationChanceLogComponent,
        TerminalSingleDetailsTransactionLogComponent,
        TerminalSingleDetailsTerminalEditComponent,
        TerminalSingleDetailsIdentifierComponent,
        TerminalSingleDetailsOperatorAccessLogComponent,
        TerminalDetailsComponent,
        TerminalFilterbarComponent,
        TerminalIconSizeComponent,
        TerminalDetailsTitaComponent,
        DashboardMachineOverviewComponent,
        DashboardSoftwareDistributionComponent,
        DashboardTransactionCountComponent,
        DashboardTransactionAveragesComponent,
        DashboardTransactionTotalsComponent,
        DashboardTransactionLastComponent,
        DashboardOperatorsCountComponent,
        DashboardTerminalCountComponent,
        DashboardOperatorsLastComponent,
        DashboardMachineListComponent,
        DashboardCustomerCountComponent,
        DashboardTerminalUptimeCountComponent,
        DashboardTerminalUptimeExtendedComponent,
        DashboardDonatedCountComponent,
        DashboardTransactionTypeComponent,
        DashboardIssuedTerminalCountComponent,
        DashboardReplacedPartsCountComponent,
        DashboardMoneyServiceCountComponent,
        DashboardMaintenanceCallsCountComponent,
        DashboardSoftwareUpdateCountComponent,
        DashboardSwitchComponent,
        DashboardSidebarsGeneralConfigurationComponent,
        DashboardSoftwareDistributionOSComponent,
        DashboardRegisteredCustomersComponent,
        DashboardRegisteredPlayersComponent,
        DashboardMoneyServiceActionRequiredComponent,
        DashboardTerminalOutOfOperationComponent,
        OperatorCurrentSessionListComponent,
        UserLanguageSwitcherComponent,
        ArtAboutComponent,
        PagerBarComponent,
        TerminalChooserComponent,
        MaintenanceListComponent,
        FeatureOverviewComponent,
        ArtInstallationDetailsComponent,
        ARTInstallationTerminalDetailsComponent,
        ARTInstallationPropertyDetailsComponent,
        ARTInstallationOperatorDetailsComponent,
        SiteAssignmentComponent,
        CameraComponent,
        RouterModule,
        ArtFilterComponent,
        PaymentCustomerSearchComponent,
        PaymentCustomerDetails,
        VpnStatePipe,
        CpuArchPipe,
        EndOfShiftReportDetailsComponent,
        TerminalLifecycleDetailsComponent,
        FontAwesomeModule,
        ManufacturingOrdersDetailsComponent,
        BlockUnblockChangeComponent,
        PieChartDetailsComponent
    ],
})
export class SharingModule {
    static forRoot(): ModuleWithProviders<SharingModule> {
        return {
            ngModule: SharingModule,
            providers: [TerminalIconRewritePipe]
        };
    }
}
