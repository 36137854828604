export enum TransactionFlag {
    None = 0,
    Warning = 1 << 0,
    Error = 1 << 1, // 'Failure' name not fitting
    Rounding = 1 << 2,
    CreditCleared = 1 << 3,
    PinAlert = 1 << 4,
    Adjusted = 1 << 5,
    Aborted = 1 << 6,
    CurrencyExchange = 1 << 7,
    Voided = 1 << 8,
    Declined = 1 << 9,
}
