import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { GlobalCacheVariable } from '../_globals/globals'
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TerminalState } from '../_enums/terminalState';
import { TerminalIconRewritePipe } from "../_pipes/terminalIconRewrite.pipe";
import { TerminalConnectionType } from "../_enums/terminalConnectionType";
import { ApiDataConverter } from "../_helpers/api-data-converter";
import { ApiCallType } from "../_enums/apiCallType";

if (localStorage.getItem('currentUser')) {
} else {
    localStorage.setItem('currentUser', '{}');
}

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class TerminalService {
    public currentUser: any;
    public sessionKey: any;

    constructor(
        private http: HttpClient,
        private cache: GlobalCacheVariable,
        private terminalIconRewrite: TerminalIconRewritePipe = new TerminalIconRewritePipe(),

    ) { }

    private static extractBridge(res, apiType) {
        let response = ApiDataConverter.extractData(res, apiType);
        return response || {};
    }

    public handleError(errorMessage: any, apiType) {
        ApiDataConverter.errorHandling(errorMessage, apiType);
        return observableThrowError(errorMessage);
    }

    filterTerminalVisibility(terminal, kind) {
        let currentFilterCriteria = this.cache.getFilter("terminalFilter");

        let currentFilterCriteriaTerminalStateConnection = [];
        switch (currentFilterCriteria.connection) {
            case TerminalConnectionType.All:
                currentFilterCriteriaTerminalStateConnection = [0, 1];
                break;
            case TerminalConnectionType.NotConnected:
                currentFilterCriteriaTerminalStateConnection = [0];
                break;
            case TerminalConnectionType.Connected:
                currentFilterCriteriaTerminalStateConnection = [1];
                break;
            case TerminalConnectionType.RecentlySeen:
                currentFilterCriteriaTerminalStateConnection = [2];
                break;
        }

        let currentFilterCriteriaTerminalState = [];
        let currentFilterCriteriaTerminalStateTransaction = 0;
        let currentFilterCriteriaTerminalStateOperator = 0;
        let currentFilterCriteriaTerminalStateService = 0;
        switch (currentFilterCriteria.terminalState) {
            case 'all'://all
                currentFilterCriteriaTerminalState = [TerminalState.OutOfService, TerminalState.Ready, TerminalState.InTransaction, TerminalState.Failure, TerminalState.Blocked, TerminalState.ShutOff];
                break;
            case 'Idle':
                currentFilterCriteriaTerminalState = [TerminalState.Ready];
                break;
            case 'InTransaction':
                currentFilterCriteriaTerminalState = [];
                currentFilterCriteriaTerminalStateTransaction = 1;
                break;

            case 'OperatorLogin':
                currentFilterCriteriaTerminalState = [];
                currentFilterCriteriaTerminalStateOperator = 1;
                break;
            case 'ServiceLogin':
                currentFilterCriteriaTerminalState = [];
                currentFilterCriteriaTerminalStateService = 1
                break;
            case 'OutOfOperation':
                currentFilterCriteriaTerminalState = [TerminalState.ShutOff, TerminalState.OutOfService];
                break;


        }

        if (kind === "multiple") {
            if (currentFilterCriteria.terminalType === "all" && currentFilterCriteria.property === "all" && currentFilterCriteria.terminalState === "all" && currentFilterCriteria.connection === -1 && currentFilterCriteria.freetext === "") {
                //console.log("filter ALL true");
                for (let i = 0; i < this.cache.Terminals.length; i++) {
                    this.cache.Terminals[i].Visible = true;
                }
                this.cache.filter.terminalFilter.terminalFilteredCount = this.cache.Terminals.length;
                this.cache.filter.terminalFilter.terminalCount = this.cache.Terminals.length;
            } else {
                if (currentFilterCriteriaTerminalStateOperator === 1 || currentFilterCriteriaTerminalStateTransaction === 1 || currentFilterCriteriaTerminalStateService === 1) {
                    if (currentFilterCriteriaTerminalStateConnection.includes(2)) {//new part (if recently seen)
                        let countFilteredTerminals = 0;
                        let currentTimestamp = (Date.now() - 604800000);
                        for (let i = 0; i < this.cache.Terminals.length; i++) {
                            if (currentTimestamp > this.cache.Terminals[i].LastSeen) {
                                this.cache.Terminals[i].Visible = false;
                            } else {
                                let currentArrayTerminalTypeCriteriaTerminalState = this.cache.Terminals[i].TerminalType;
                                if (
                                    (currentArrayTerminalTypeCriteriaTerminalState === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                                    (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === this.cache.Terminals[i].PropertyNumber) &&
                                    (this.cache.Terminals[i].TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || this.cache.Terminals[i].LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                                ) {
                                    if (currentFilterCriteriaTerminalStateOperator === 1) {
                                        if (this.cache.Terminals[i].OperatorLoginState === 1) {
                                            this.cache.Terminals[i].Visible = true;
                                            countFilteredTerminals++;
                                        } else {
                                            this.cache.Terminals[i].Visible = false;
                                        }
                                    } else if (currentFilterCriteriaTerminalStateService === 1) {
                                        if (this.cache.Terminals[i].OperatorLoginState === 2) {
                                            this.cache.Terminals[i].Visible = true;
                                            countFilteredTerminals++;
                                        } else {
                                            this.cache.Terminals[i].Visible = false;
                                        }
                                    } else {
                                        if (this.cache.Terminals[i].TransactionState === 1) {
                                            this.cache.Terminals[i].Visible = true;
                                            countFilteredTerminals++;
                                        } else {
                                            this.cache.Terminals[i].Visible = false;
                                        }
                                    }
                                } else {
                                    this.cache.Terminals[i].Visible = false;
                                }
                            }
                        }
                        this.cache.filter.terminalFilter.terminalFilteredCount = countFilteredTerminals;
                        this.cache.filter.terminalFilter.terminalCount = this.cache.Terminals.length;
                    } else {
                        let countFilteredTerminals = 0;
                        for (let i = 0; i < this.cache.Terminals.length; i++) {
                            let currentArrayTerminalTypeCriteriaTerminalState = this.cache.Terminals[i].TerminalType;
                            if (
                                (currentArrayTerminalTypeCriteriaTerminalState === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                                (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === this.cache.Terminals[i].PropertyNumber) &&
                                (currentFilterCriteria.connection === -1 || currentFilterCriteriaTerminalStateConnection.includes(this.cache.Terminals[i].Status)) &&
                                (this.cache.Terminals[i].TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || this.cache.Terminals[i].LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                            ) {
                                if (currentFilterCriteriaTerminalStateOperator === 1) {
                                    if (this.cache.Terminals[i].OperatorLoginState === 1) {
                                        this.cache.Terminals[i].Visible = true;
                                        countFilteredTerminals++;
                                    } else {
                                        this.cache.Terminals[i].Visible = false;
                                    }
                                } else if (currentFilterCriteriaTerminalStateService === 1) {
                                    if (this.cache.Terminals[i].OperatorLoginState === 2) {
                                        this.cache.Terminals[i].Visible = true;
                                        countFilteredTerminals++;
                                    } else {
                                        this.cache.Terminals[i].Visible = false;
                                    }
                                } else {
                                    if (this.cache.Terminals[i].TransactionState === 1) {
                                        this.cache.Terminals[i].Visible = true;
                                        countFilteredTerminals++;
                                    } else {
                                        this.cache.Terminals[i].Visible = false;
                                    }
                                }
                            } else {
                                this.cache.Terminals[i].Visible = false;
                            }
                        }
                        this.cache.filter.terminalFilter.terminalFilteredCount = countFilteredTerminals;
                        this.cache.filter.terminalFilter.terminalCount = this.cache.Terminals.length;
                    }
                } else {
                    if (currentFilterCriteriaTerminalStateConnection.includes(2)) {//new part (if recently seen)
                        let countFilteredTerminals = 0;
                        let currentTimestamp = (Date.now() - 604800000);
                        for (let i = 0; i < this.cache.Terminals.length; i++) {
                            if (currentTimestamp > this.cache.Terminals[i].LastSeen) {
                                this.cache.Terminals[i].Visible = false;
                            } else {
                                let currentArrayTerminalTypeCriteriaTerminalState = this.cache.Terminals[i].TerminalType;
                                if (
                                    (currentArrayTerminalTypeCriteriaTerminalState === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                                    (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === this.cache.Terminals[i].PropertyNumber) &&
                                    (currentFilterCriteria.terminalState === "all" || currentFilterCriteriaTerminalState.includes(Number(this.cache.Terminals[i].TerminalState))) &&
                                    (this.cache.Terminals[i].TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || this.cache.Terminals[i].LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                                ) {
                                    this.cache.Terminals[i].Visible = true;
                                    countFilteredTerminals++;
                                } else {
                                    this.cache.Terminals[i].Visible = false;
                                }
                            }
                        }
                        this.cache.filter.terminalFilter.terminalFilteredCount = countFilteredTerminals;
                        this.cache.filter.terminalFilter.terminalCount = this.cache.Terminals.length;
                    } else {
                        let countFilteredTerminals = 0;
                        for (let i = 0; i < this.cache.Terminals.length; i++) {
                            let currentArrayTerminalTypeCriteriaTerminalState = this.cache.Terminals[i].TerminalType;
                            if (
                                (currentArrayTerminalTypeCriteriaTerminalState === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                                (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === this.cache.Terminals[i].PropertyNumber) &&
                                (currentFilterCriteria.terminalState === "all" || currentFilterCriteriaTerminalState.includes(this.cache.Terminals[i].TerminalState)) &&
                                (currentFilterCriteria.connection === -1 || currentFilterCriteriaTerminalStateConnection.includes(this.cache.Terminals[i].Status)) &&
                                (this.cache.Terminals[i].TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || this.cache.Terminals[i].LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                            ) {
                                this.cache.Terminals[i].Visible = true;
                                countFilteredTerminals++;
                            } else {
                                this.cache.Terminals[i].Visible = false;
                            }
                        }
                        this.cache.filter.terminalFilter.terminalFilteredCount = countFilteredTerminals;
                        this.cache.filter.terminalFilter.terminalCount = this.cache.Terminals.length;
                    }
                }
            }
        } else {
            //console.log("filter single");
            if (currentFilterCriteria.terminalType === "all" && currentFilterCriteria.property === "all" && currentFilterCriteria.terminalState === "all" && currentFilterCriteria.freetext === "") {
                //console.log("filter ONE true");
                return true;

            } else {
                //console.log("filter ONE mixed");
                if (currentFilterCriteriaTerminalStateConnection.includes(2)) {//new part (if recently seen)
                    let countFilteredTerminals = 0;
                    let currentTimestamp = (Date.now() - 604800000);
                    for (let i = 0; i < this.cache.Terminals.length; i++) {
                        let currentTerminalInCache = this.cache.Terminals[i];
                        if (currentTerminalInCache.TerminalID == terminal.TerminalID) {
                            if (currentTimestamp > currentTerminalInCache.LastSeen) {
                                currentTerminalInCache.Visible = false;
                            } else {
                                let currentArrayTerminalTypeCriteriaTerminalState = currentTerminalInCache.TerminalType;
                                if (
                                    (terminal.TerminalType === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                                    (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === terminal.PropertyNumber) &&
                                    (currentFilterCriteria.connection === -1 || currentFilterCriteriaTerminalStateConnection.includes(terminal.Status)) &&
                                    (terminal.TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || terminal.LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                                ) {
                                    currentTerminalInCache.Visible = true;
                                    countFilteredTerminals++;
                                } else {
                                    currentTerminalInCache.Visible = false;
                                }
                            }
                        }
                        else {
                            if (currentTerminalInCache.Visible == true) {
                                countFilteredTerminals++;
                            }
                        }
                    }

                    this.cache.filter.terminalFilter.terminalFilteredCount = countFilteredTerminals;
                    this.cache.filter.terminalFilter.terminalCount = this.cache.Terminals.length;
                } else {
                    let countFilteredTerminals = 0;
                    for (let i = 0; i < this.cache.Terminals.length; i++) {
                        let currentTerminalInCache = this.cache.Terminals[i];
                        if (currentTerminalInCache.TerminalID == terminal.TerminalID) {
                            let currentArrayTerminalTypeCriteriaTerminalState = currentTerminalInCache.TerminalType;
                            if (
                                (terminal.TerminalType === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                                (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === terminal.PropertyNumber) &&
                                (currentFilterCriteria.terminalState === "all" || currentFilterCriteriaTerminalStateConnection.includes(currentTerminalInCache.TerminalState)) &&
                                (currentFilterCriteria.connection === -1 || currentFilterCriteriaTerminalStateConnection.includes(terminal.Status)) &&
                                (terminal.TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || terminal.LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                            ) {
                                currentTerminalInCache.Visible = true;
                                countFilteredTerminals++;
                            } else {
                                currentTerminalInCache.Visible = false;
                            }
                        }
                        else {
                            if (currentTerminalInCache.Visible == true) {
                                countFilteredTerminals++;
                            }
                        }
                    }
                    this.cache.filter.terminalFilter.terminalFilteredCount = countFilteredTerminals;
                    this.cache.filter.terminalFilter.terminalCount = this.cache.Terminals.length;
                }

                if (
                    (terminal.TerminalType === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                    (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === terminal.PropertyNumber) &&
                    (currentFilterCriteria.terminalState === "all" || currentFilterCriteriaTerminalStateConnection.includes(terminal.TerminalState)) &&
                    (terminal.TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || terminal.LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    filterTerminalListVisibility(terminal, kind) {
        //console.log("nice try -> ", terminal);
        if (this.cache.TerminalListData.length !== undefined && this.cache.TerminalListData.length > 0 && this.cache.TerminalListData.length !== null) {

            let currentFilterCriteria = this.cache.getFilter("terminalFilter");

            let currentFilterCriteriaTerminalStateConnection = [];
            switch (currentFilterCriteria.connection) {
                case TerminalConnectionType.All:
                    currentFilterCriteriaTerminalStateConnection = [0, 1];
                    break;
                case TerminalConnectionType.NotConnected:
                    currentFilterCriteriaTerminalStateConnection = [0];
                    break;
                case TerminalConnectionType.Connected:
                    currentFilterCriteriaTerminalStateConnection = [1];
                    break;
                case TerminalConnectionType.RecentlySeen:
                    currentFilterCriteriaTerminalStateConnection = [2];
                    break;
            }

            let currentFilterCriteriaTerminalState = [];
            let currentFilterCriteriaTerminalStateTransaction = 0;
            let currentFilterCriteriaTerminalStateOperator = 0;
            let currentFilterCriteriaTerminalStateService = 0;
            switch (currentFilterCriteria.terminalState) {
                case 'all'://all
                    currentFilterCriteriaTerminalState = [TerminalState.OutOfService, TerminalState.Ready, TerminalState.InTransaction, TerminalState.Failure, TerminalState.Blocked, TerminalState.ShutOff];
                    break;
                case 'Idle':
                    currentFilterCriteriaTerminalState = [TerminalState.Ready];
                    break;
                case 'InTransaction':
                    currentFilterCriteriaTerminalState = [];
                    currentFilterCriteriaTerminalStateTransaction = 1;
                    break;

                case 'OperatorLogin':
                    currentFilterCriteriaTerminalState = [];
                    currentFilterCriteriaTerminalStateOperator = 1;
                    break;
                case 'ServiceLogin':
                    currentFilterCriteriaTerminalState = [];
                    currentFilterCriteriaTerminalStateService = 1
                    break;
                case 'OutOfOperation':
                    currentFilterCriteriaTerminalState = [TerminalState.ShutOff, TerminalState.OutOfService];
                    break;


            }


            if (kind === "multiple") {
                if (currentFilterCriteria.terminalType === "all" && currentFilterCriteria.property === "all" && currentFilterCriteria.terminalState === "all" && currentFilterCriteria.connection === -1 && currentFilterCriteria.freetext === "") {
                    //console.log("filter ALL true");
                    for (let i = 0; i < this.cache.TerminalListData.length; i++) {
                        this.cache.TerminalListData[i].Terminal.Visible = true;
                    }
                    this.cache.filter.terminalFilter.terminalFilteredCount = this.cache.Terminals.length;
                    this.cache.filter.terminalFilter.terminalCount = this.cache.Terminals.length;
                } else {
                    if (currentFilterCriteriaTerminalStateOperator === 1 || currentFilterCriteriaTerminalStateTransaction === 1 || currentFilterCriteriaTerminalStateService === 1) {
                        if (currentFilterCriteriaTerminalStateConnection.includes(2)) {//new part (if recently seen)
                            let countFilteredTerminals = 0;
                            let currentTimestamp = (Date.now() - 604800000);
                            for (let i = 0; i < this.cache.TerminalListData.length; i++) {
                                if (currentTimestamp > this.cache.TerminalListData[i].Terminal.LastSeen) {
                                    this.cache.TerminalListData[i].Terminal.Visible = false;
                                } else {
                                    let currentArrayTerminalTypeCriteriaTerminalState = this.cache.TerminalListData[i].Terminal.TerminalType;
                                    if (
                                        (currentArrayTerminalTypeCriteriaTerminalState === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                                        (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === this.cache.TerminalListData[i].Terminal.PropertyNumber) &&
                                        (this.cache.TerminalListData[i].Terminal.TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || this.cache.TerminalListData[i].Terminal.LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                                    ) {
                                        if (currentFilterCriteriaTerminalStateOperator === 1) {
                                            if (this.cache.TerminalListData[i].Terminal.OperatorLoginState === 1) {
                                                this.cache.TerminalListData[i].Terminal.Visible = true;
                                                countFilteredTerminals++;
                                            } else {
                                                this.cache.TerminalListData[i].Terminal.Visible = false;
                                            }
                                        } else if (currentFilterCriteriaTerminalStateService === 1) {
                                            if (this.cache.TerminalListData[i].Terminal.OperatorLoginState === 2) {
                                                this.cache.TerminalListData[i].Terminal.Visible = true;
                                                countFilteredTerminals++;
                                            } else {
                                                this.cache.TerminalListData[i].Terminal.Visible = false;
                                            }
                                        } else {
                                            if (this.cache.TerminalListData[i].Terminal.TransactionState === 1) {
                                                this.cache.TerminalListData[i].Terminal.Visible = true;
                                                countFilteredTerminals++;
                                            } else {
                                                this.cache.TerminalListData[i].Terminal.Visible = false;
                                            }
                                        }
                                    } else {
                                        this.cache.TerminalListData[i].Terminal.Visible = false;
                                    }
                                }
                            }
                            this.cache.filter.terminalFilter.terminalFilteredCount = countFilteredTerminals;
                            this.cache.filter.terminalFilter.terminalCount = this.cache.TerminalListData.length;
                        } else {
                            let countFilteredTerminals = 0;
                            for (let i = 0; i < this.cache.TerminalListData.length; i++) {
                                let currentArrayTerminalTypeCriteriaTerminalState = this.cache.TerminalListData[i].Terminal.TerminalType;
                                if (
                                    (currentArrayTerminalTypeCriteriaTerminalState === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                                    (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === this.cache.TerminalListData[i].Terminal.PropertyNumber) &&
                                    (currentFilterCriteria.connection === -1 || currentFilterCriteriaTerminalStateConnection.includes(this.cache.TerminalListData[i].Terminal.Status)) &&
                                    (this.cache.TerminalListData[i].Terminal.TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || this.cache.TerminalListData[i].Terminal.LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                                ) {
                                    if (currentFilterCriteriaTerminalStateOperator === 1) {
                                        if (this.cache.TerminalListData[i].Terminal.OperatorLoginState === 1) {
                                            this.cache.TerminalListData[i].Terminal.Visible = true;
                                            countFilteredTerminals++;
                                        } else {
                                            this.cache.TerminalListData[i].Terminal.Visible = false;
                                        }
                                    } else if (currentFilterCriteriaTerminalStateService === 1) {
                                        if (this.cache.TerminalListData[i].Terminal.OperatorLoginState === 2) {
                                            this.cache.TerminalListData[i].Terminal.Visible = true;
                                            countFilteredTerminals++;
                                        } else {
                                            this.cache.TerminalListData[i].Terminal.Visible = false;
                                        }
                                    } else {
                                        if (this.cache.TerminalListData[i].Terminal.TransactionState === 1) {
                                            this.cache.TerminalListData[i].Terminal.Visible = true;
                                            countFilteredTerminals++;
                                        } else {
                                            this.cache.TerminalListData[i].Terminal.Visible = false;
                                        }
                                    }
                                } else {
                                    this.cache.TerminalListData[i].Terminal.Visible = false;
                                }
                            }
                            this.cache.filter.terminalFilter.terminalFilteredCount = countFilteredTerminals;
                            this.cache.filter.terminalFilter.terminalCount = this.cache.Terminals.length;
                        }
                    } else {
                        if (currentFilterCriteriaTerminalStateConnection.includes(2)) {//new part (if recently seen)
                            let countFilteredTerminals = 0;
                            let currentTimestamp = (Date.now() - 604800000);
                            for (let i = 0; i < this.cache.TerminalListData.length; i++) {
                                if (currentTimestamp > this.cache.TerminalListData[i].Terminal.LastSeen) {
                                    this.cache.TerminalListData[i].Terminal.Visible = false;
                                } else {
                                    let currentArrayTerminalTypeCriteriaTerminalState = this.cache.Terminals[i].TerminalType;
                                    if (
                                        (currentArrayTerminalTypeCriteriaTerminalState === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                                        (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === this.cache.TerminalListData[i].Terminal.PropertyNumber) &&
                                        (currentFilterCriteria.terminalState === "all" || currentFilterCriteriaTerminalState.includes(Number(this.cache.TerminalListData[i].Terminal.TerminalState))) &&
                                        (this.cache.TerminalListData[i].Terminal.TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || this.cache.TerminalListData[i].Terminal.LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                                    ) {
                                        this.cache.TerminalListData[i].Terminal.Visible = true;
                                        countFilteredTerminals++;
                                    } else {
                                        this.cache.TerminalListData[i].Terminal.Visible = false;
                                    }
                                }
                            }
                            this.cache.filter.terminalFilter.terminalFilteredCount = countFilteredTerminals;
                            this.cache.filter.terminalFilter.terminalCount = this.cache.TerminalListData.length;
                        } else {
                            let countFilteredTerminals = 0;
                            for (let i = 0; i < this.cache.TerminalListData.length; i++) {
                                let currentArrayTerminalTypeCriteriaTerminalState = this.cache.Terminals[i].TerminalType;
                                if (
                                    (currentArrayTerminalTypeCriteriaTerminalState === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                                    (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === this.cache.TerminalListData[i].Terminal.PropertyNumber) &&
                                    (currentFilterCriteria.terminalState === "all" || currentFilterCriteriaTerminalState.includes(this.cache.TerminalListData[i].Terminal.TerminalState)) &&
                                    (currentFilterCriteria.connection === -1 || currentFilterCriteriaTerminalStateConnection.includes(this.cache.TerminalListData[i].Terminal.Status)) &&
                                    (this.cache.TerminalListData[i].Terminal.TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || this.cache.TerminalListData[i].Terminal.LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                                ) {
                                    this.cache.TerminalListData[i].Terminal.Visible = true;
                                    countFilteredTerminals++;
                                } else {
                                    this.cache.TerminalListData[i].Terminal.Visible = false;
                                }
                            }
                            this.cache.filter.terminalFilter.terminalFilteredCount = countFilteredTerminals;
                            this.cache.filter.terminalFilter.terminalCount = this.cache.TerminalListData.length;
                        }
                    }
                }
            }





            else {
                if (currentFilterCriteria.terminalType === "all" && currentFilterCriteria.property === "all" && currentFilterCriteria.terminalState === "all" && currentFilterCriteria.freetext === "") {
                    //console.log("filter ONE true");
                    return true;

                } else {
                    //console.log("filter ONE mixed");
                    if (
                        (terminal.TerminalType === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                        (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === terminal.PropertyNumber) &&
                        (currentFilterCriteria.terminalState === "all" || currentFilterCriteriaTerminalStateConnection.includes(terminal.TerminalState)) &&
                        (terminal.TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || terminal.LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }

            }
        }
    }

    setVisible(mode, connection, search, exclude) {
        //let filter1  = mode       != null ? this.mode     == mode                                       : true;
        //let filter2  = connection != null ? this.lastSync >= moment().subtract(7, 'days').toISOString() : true;
        //let filter3  = search     != null ? this.search(search)                                         : true;
        //let filter4  = exclude    != null && exclude != "" ? this.exclude(exclude)                      : true;
        //return filter1 && filter2 && filter3 && filter4;
    }

    private search(searchElement) {
        searchElement = searchElement.replace(/\s/g, "").toLowerCase();
        // return this.searchInfos.includes(searchElement);
    }

    filterTerminalIdentifierVisibility(terminal, kind) {
        let currentFilterCriteria = this.cache.getFilter("terminalIdentifierFilter");
        /*
        let currentFilterCriteriaTerminalStateConnection = [];
        switch (currentFilterCriteria.connection) {
            case 'all':
                currentFilterCriteriaTerminalStateConnection = [TerminalState.OutOfService, TerminalState.Ready, TerminalState.InTransaction, TerminalState.Failure, TerminalState.Blocked, TerminalState.ShutOff];
                break;
            case 'Connected':
                currentFilterCriteriaTerminalStateConnection = [TerminalState.Ready, TerminalState.InTransaction];
                break;
            case 'Failure':
                currentFilterCriteriaTerminalStateConnection = [TerminalState.Failure, TerminalState.Blocked];
                break;

            case 'notConnected':
                currentFilterCriteriaTerminalStateConnection = [TerminalState.ShutOff, TerminalState.OutOfService];
                break;
        }
        */


        let currentFilterCriteriaTerminalStateConnection = [];
        switch (currentFilterCriteria.terminalState) {
            case TerminalConnectionType.All:
                currentFilterCriteriaTerminalStateConnection = [0, 1];
                break;
            case TerminalConnectionType.NotConnected:
                currentFilterCriteriaTerminalStateConnection = [0];
                break;
            case TerminalConnectionType.Connected:
                currentFilterCriteriaTerminalStateConnection = [1];
                break;
            case TerminalConnectionType.RecentlySeen:
                currentFilterCriteriaTerminalStateConnection = [2];
                break;
        }

        if (kind === "multiple") {
            //console.log("filter multiple");
            //console.log("filter actualyFilterCriteria -> ", actualyFilterCriteria);

            if (currentFilterCriteria.terminalType === "all" && currentFilterCriteria.property === "all" && currentFilterCriteria.terminalState === "all" && currentFilterCriteria.freetext === "") {
                //console.log("filter ALL true");
                for (let i = 0; i < this.cache.TerminalsIdentifier.length; i++) {
                    this.cache.TerminalsIdentifier[i].Visible = true;
                }
                this.cache.filter.terminalIdentifierFilter.terminalFilteredCount = this.cache.TerminalsIdentifier.length;
                this.cache.filter.terminalIdentifierFilter.terminalCount = this.cache.TerminalsIdentifier.length;
            } else {
                let countFilteredTerminals = 0;
                //console.log("filter ALL mixed");
                for (let i = 0; i < this.cache.TerminalsIdentifier.length; i++) {
                    //console.log("test location icon -> ", this.cache.Terminals[i].LocationName);
                    //console.log("this.cache.Terminals[i].TerminalState) "+ this.cache.Terminals[i].TerminalID, this.cache.Terminals[i].TerminalState);
                    //console.log("currentFilterCriteriaTerminalState.includes(this.cache.Terminals[i].TerminalState)", currentFilterCriteriaTerminalState.includes(this.cache.Terminals[i].TerminalState));
                    let currentArrayTerminalTypeCriteriaTerminalState = this.cache.TerminalsIdentifier[i].TerminalType;
                    //console.log("currentArrayTerminalTypeiteriaTerminalState -> ", currentArrayTerminalTypeiteriaTerminalState);
                    if (
                        (currentArrayTerminalTypeCriteriaTerminalState === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                        (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === this.cache.TerminalsIdentifier[i].PropertyNumber) &&
                        (currentFilterCriteria.terminalState === "all" || currentFilterCriteriaTerminalStateConnection.includes(this.cache.TerminalsIdentifier[i].TerminalState)) &&
                        (this.cache.TerminalsIdentifier[i].TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || this.cache.TerminalsIdentifier[i].LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                    ) {
                        //console.log("same TerminalType = Visible True");
                        this.cache.TerminalsIdentifier[i].Visible = true;
                        countFilteredTerminals++;
                    } else {
                        //console.log("same TerminalType = Visible false");
                        this.cache.TerminalsIdentifier[i].Visible = false;
                    }
                }
                this.cache.filter.terminalIdentifierFilter.terminalFilteredCount = countFilteredTerminals;
                this.cache.filter.terminalIdentifierFilter.terminalCount = this.cache.TerminalsIdentifier.length;
            }
        } else {
            //console.log("filter single");
            if (currentFilterCriteria.terminalType === "all" && currentFilterCriteria.property === "all" && currentFilterCriteria.terminalState === "all" && currentFilterCriteria.freetext === "") {
                //console.log("filter ONE true");
                return true;

            } else {
                //console.log("filter ONE mixed");
                if (
                    (terminal.TerminalType === currentFilterCriteria.terminalType || currentFilterCriteria.terminalType === "all") &&
                    (currentFilterCriteria.property === "all" || Number(currentFilterCriteria.property) === terminal.PropertyNumber) &&
                    (currentFilterCriteria.connection === "all" || currentFilterCriteriaTerminalStateConnection.includes(terminal.TerminalState)) &&
                    (terminal.TerminalName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()) || terminal.LocationName.toLowerCase().includes(currentFilterCriteria.freetext.toLowerCase()))
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    getProjectConfig(): any {
        try {
            let data = { strSessionKey: this.sessionKey };
            let body = JSON.stringify(data);
            return this.http.get("./project_config.json")
                .pipe(map(this.extractProjectConfig));
        } catch (error) {
            console.log(error);
        }
    }

    extractProjectConfig(res: Response) {
        const responseData = JSON.parse(JSON.stringify(res));
        return responseData || {};
    }

    getAllTerminals(): any {
        try {
            let data = { strSessionKey: this.cache.getSessionKey() };
            let body = JSON.stringify(data);
            let self = this.terminalIconRewrite;
            return this.http.post(this.cache.ARTApi + 'GetTerminalList', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTerminalList);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetTerminalList);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getSingleTerminal(terminalID): any {
        try {
            let data = { strSessionKey: this.cache.getSessionKey(), terminalID: terminalID };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetTerminal', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTerminal);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetTerminal);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getSingleTerminalIdentifier(terminalID): any {
        try {
            let data = { sessionKey: this.cache.getSessionKey(), terminalID: terminalID };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetTerminalIdentifierDataForTerminal', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTerminalIdentifierDataForTerminal);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetTerminalIdentifierDataForTerminal);
                    }));
        } catch (error) {
            console.log(error);
        }
    }


    getTerminalIdentifierList(): any {
        try {
            let data = { strSessionKey: this.cache.getSessionKey() };
            let body = JSON.stringify(data);
            let self = this.terminalIconRewrite;
            return this.http.post(this.cache.ARTApi + 'GetTerminalIdentifierData', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTerminalIdentifierData);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetTerminalIdentifierData);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    approveTransaction(message, state): any {
        try {
            let data = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: message.detail.terminal,
                sequenceNumber: message.detail.sequenceNumber,
                requestedAmount: message.detail.requestedAmount,
                approved: "" + state + "",
                requestingOperatorID: message.detail.requestedOperatorID,
                approvingOperatorID: "" + this.currentUser.OperatorID + "",
            };
            let body = JSON.stringify(data);

            return this.http.post(this.cache.ARTApi + 'ApproveTransaction', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.ApproveTransaction);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.ApproveTransaction);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllTerminalsList() {
        try {
            let data = { strSessionKey: this.cache.getSessionKey() };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetTerminalListData', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTerminalListData);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetTerminalListData);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getTerminalDetails(terminal) {
        try {
            let data = { strSessionKey: this.cache.getSessionKey(), iTerminalID: terminal };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetStockForTerminal', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetStockForTerminal);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetStockForTerminal);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getTerminalStock(terminalId, stockDate, queryType) {
        try {
            let data = { sessionKey: this.cache.getSessionKey(), terminalID: terminalId, stockDate: stockDate, queryType: queryType };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetTerminalStock', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTTerminalStock);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetTTerminalStock);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getTerminalVersions(terminal) {
        try {
            let data = { sessionKey: this.cache.getSessionKey(), terminalID: terminal };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetVersionEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetVersionEntries);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetVersionEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getFillLevel() {
        try {
            let data = { strSessionKey: this.cache.getSessionKey() };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetFillLevelData', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetFillLevelData);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetFillLevelData);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getFeatureOverviewEntries() {
        try {
            let data = { sessionKey: this.cache.getSessionKey() };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetFeatureOverviewEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetFeatureOverviewEntries);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetFeatureOverviewEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getFeatureMonitorEntries(terminalID) {
        try {
            let data = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID
            };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetFeatureMonitorEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetFeatureMonitorEntries);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetFeatureMonitorEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getComponentMonitorList() {
        try {
            let data = { sessionKey: this.cache.getSessionKey() };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetTerminalComponentEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTerminalComponentEntries);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetTerminalComponentEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getComponentMonitorTerminal(terminalID) {
        try {
            let data = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
            };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetComponentMonitorEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetComponentMonitorEntries);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetComponentMonitorEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getComponentMonitorDetails(terminalComponent, terminalID, startTime, sinceLastStartup, includeGlobalEvents, includeSubComponentEvents) {
        try {
            let data = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                componentID: terminalComponent.ComponentType,
                componentRank: terminalComponent.ComponentRank,
                startTime: startTime,
                sinceLastStartup: sinceLastStartup,
                includeGlobalEvents: includeGlobalEvents,
                includeSubComponentEvents: includeSubComponentEvents,
            };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetComponentMonitorDetails', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetComponentMonitorDetails);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetComponentMonitorDetails);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    setTerminalStatus(terminalID, terminalStatus, forceNow: boolean = false) {
        try {
            let data = { sessionKey: this.cache.getSessionKey(), terminalID: terminalID, terminalStatus: terminalStatus, forceNow: forceNow };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'SetTerminalStatus', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.SetTerminalStatus);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.SetTerminalStatus);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    startRemoteSession(terminalID, remoteAccessSessionType, otkValue) {
        try {
            let data = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                remoteAccessSessionType: remoteAccessSessionType,
                otkValue: otkValue,
            };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'StartRemoteSession', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.StartRemoteSession);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.StartRemoteSession);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    shutDownRemoteSession(terminalID, forwardPort) {
        try {
            let data = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                forwardPort: forwardPort,
            };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'ShutDownRemoteSession', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.ShutDownRemoteSession);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.ShutDownRemoteSession);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getLogfiles(terminalID, from, to) {
        try {
            let data = { sessionKey: this.cache.getSessionKey(), terminalID: terminalID, startDate: from, endDate: to };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetLogFiles', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetLogFiles);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetLogFiles);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    updateTerminal(terminal) {
        try {
            terminal.ExtensionData = null;
            if (terminal.CustomAddress != null)
                terminal.CustomAddress.ExtensionData = null;
            if (terminal.CustomAddress != null && terminal.CustomAddress.PostalAddress != null)
                terminal.CustomAddress.PostalAddress.ExtensionData = null;
            if (terminal.CustomAddress != null && terminal.CustomAddress.ContactPerson != null)
                terminal.CustomAddress.ContactPerson.ExtensionData = null;
            terminal.LastSeen = "";
            terminal.FirstSeen = "";
            let data = { sessionKey: this.cache.getSessionKey(), terminal: terminal };
            let body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'UpdateTerminal', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.UpdateTerminal);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.UpdateTerminal);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getCountryInfos() {
        try {
            const data = { sessionKey: this.cache.getSessionKey() };
            const body = JSON.stringify(data);
            return this.http.post(
                this.cache.ARTApi + 'GetCountryInfos', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetCountryInfos);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetCountryInfos);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getTimeZoneInfos() {
        try {
            const data = { sessionKey: this.cache.getSessionKey() };
            const body = JSON.stringify(data);
            return this.http.post(
                this.cache.ARTApi + 'GetTimeZoneInfos', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTimeZoneInfos);
                    }),
                    map(data => {
                        return TerminalService.extractBridge(data, ApiCallType.GetTimeZoneInfos);
                    }));
        } catch (error) {
            console.log(error);
        }
    }
}

