<p-table [value]="operatorAccessLog" [scrollable]="true" [style]="{width:'auto'}" scrollHeight="70%" class="operator-access-log-table content-table" scrollHeight="flex" *ngIf="operatorAccessLogView" >
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-colums>
        <tr>
            <th class="{{col.class}}" *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-log>
        <tr>
            <td class="tb-left">{{log.OperatorID}}</td>
            <td class="tb-left">{{log.OperatorName}}</td>
            <td class="tb-left">{{log.AccessPoint}}</td>
            <td class="tb-left">{{log.AccessDate}}</td>
            <td class="tb-left">{{log.AccessState}}</td>
            <td class="tb-left">{{log.AccessType}}</td>
            <td class="tb-left">{{log.AccessDetails}}</td>
        </tr>
    </ng-template>

</p-table>
