<p-table [value]="eventLog" [rows]="paginatorRowsPerPage" [scrollable]="true" [style]="{width:'auto'}" class="event-log-table content-table" scrollHeight="flex" *ngIf="eventLogView">
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th class="tb-left">{{'BASE.DATE' | translate}}</th>
            <th class="tb-left">{{'LOGS.EVENT.TABLE.MESSAGE' | translate}}</th>
            <th class="tb-left">{{'LOGS.EVENT.TABLE.SOURCE' | translate}}</th>
            <th class="tb-left">{{'LOGS.EVENT.TABLE.CODE' | translate}}</th>
            <th class="tb-left">{{'LOGS.EVENT.TABLE.LEVEL' | translate}}</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-log>
        <tr>
            <td class="tb-left">{{log.EventDate | date: globalCacheVariable.globalFormatSettings.dateFormatLong}}</td>
            <td class="tb-left">{{log.EventMessage}}</td>
            <td class="tb-left">{{log.EventSource | eventSource}}</td>
            <td class="tb-left">{{log.EventCode}} </td>
            <td class="tb-left">{{log.EventLevel}}</td>
        </tr>
    </ng-template>

</p-table>
