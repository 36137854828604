export enum VersionType {
    None = 0,
    Software = 1,
    Configuration = 2,
    SurveillanceProtocol = 3,
    CosInterface = 4,
    Cos = 5,
    JosInterface = 6,
    Jos = 7,
    MonInterface = 8,
    Mon = 9,
    SoftwareLib = 10,
    OperatingSystem = 11,
    RuntimeEnvironment = 12,
    TableID = 13,
    AtmProxy = 14,
    CosProxy = 15,
    CosModCosInterface = 16,
    ComponentTest = 17,
    RemoteDesktopService = 18,
    ServiceCardsFile = 19
}
