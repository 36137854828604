<p-table [value]="terminalIdentifierListData" [scrollable]="true" [style]="{width:'auto'}"
         class="terminal-identifier-table content-table" *ngIf="terminalIdentifierLogView">
    <ng-template pTemplate="body" let-entry>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.TERMINAL_ID' | translate}}</td>
            <td class="tb-left">{{entry.TerminalID}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.IP_ADDRESS' | translate}}</td>
            <td class="tb-left">{{entry.IPAddress}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.MAC_ADDRESS' | translate}}</td>
            <td class="tb-left">{{entry.MacADress}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.NAME' | translate}}</td>
            <td class="tb-left">{{entry.TerminalName}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.LOCATION' | translate}}</td>
            <td class="tb-left">{{entry.LocationName}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.TIME_ZONE' | translate}}</td>
            <td class="tb-left">{{entry.TimeZone}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.SYSTEM_CURRENCY' | translate}}</td>
            <td class="tb-left">{{entry.SystemCurrency}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.PROPERTY_NUMBER' | translate}}</td>
            <td class="tb-left">{{entry.PropertyNumber}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.BANK_TERMINAL_ID' | translate}}</td>
            <td class="tb-left">{{entry.AtmTerminalID}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.KIOSK_ID' | translate}}</td>
            <td class="tb-left">{{entry.KioskID}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.OPERATING_SYSTEM' | translate}}</td>
            <td class="tb-left">{{entry.OperatingSystem}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.RUNTIME_ENVIRONMENT' | translate}}</td>
            <td class="tb-left">{{entry.RuntimeEnvironment}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.SOFTWARE_VERSION' | translate}}</td>
            <td class="tb-left">{{entry.SoftwareVersion}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.ONLINE_SYSTEM' | translate}}</td>
            <td class="tb-left">{{entry.OnlineSystem}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.DISTRIBUTOR_NUMBER' | translate}}</td>
            <td class="tb-left">{{entry.DistributorID}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.CUSTOMER_NUMBER' | translate}}</td>
            <td class="tb-left">{{entry.CustomerID}}</td>
        </tr>
        <tr>
            <td class="tb-left">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.LOCATION_ID' | translate}}</td>
            <td class="tb-left">{{entry.LocationID}}</td>
        </tr>
    </ng-template>
</p-table>