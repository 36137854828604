import { StockForTerminal } from "../_models/stockForTerminal";
import { Injectable } from "@angular/core";

@Injectable()
export class Stock {

    private stockForTerminal: StockForTerminal;
    public stockDetailInformation: any = {
        "billAcceptorTotalSum": 0,
        "coinAcceptorTotalSum": 0,
        "dispenserTotalSum": 0,
        "coinDispenserTotalSum": 0,
        "mediaInTotalSum": 0,
        "mediaOutTotalSum": 0,
        "dispenserTotalCount": 0,
        "ticketInCount": 0,
        "ticketInAmount": 0,
        "ticketOutCount": 0,
        "ticketOutAmount": 0,
        "roundingTicket": 0,
        "roundingPlayerCards": 0,
        "roundingBonusCards": 0,
        "roundingJackpots": 0,
        "roundingBills": 0,
        "roundingTotal": 0,
        "coinDispenserTotalCount": 0,
        "coinAcceptorTotalCount": 0,
        "billAcceptorTotalCount": 0,
        "sam": 0,
        "depositWithheld": 0,
        "depositPaid": 0,
        "mediaInWithheldAmount": 0,
        "mediaInWithheldCount": 0,
        "mediaInChipAmount": 0,
        "mediaInCouponAmount": 0,
        "mediaInCouponCount": 0,
        "mediaInMarkerAmount": 0,
        "mediaInMarkerCount": 0,
        "mediaOutChipAmount": 0,
        "mediaOutCouponAmount": 0,
        "mediaOutCouponCount": 0,
        "mediaOutMarkerAmount": 0,
        "mediaOutMarkerCount": 0,
        "mediaOutCardsAmount": 0,
        "mediaOutCardsCount": 0,
        "mediaOutPaidAmount": 0,
        "mediaOutPaidCount": 0,
        "cardsStacker": 0,
        "cardsBin": 0,
    };

    public calculateStockForTerminal(data, terminal) {
        this.stockForTerminal = data;
        this.stockForTerminal.Terminal = terminal;
        /* Calculated Part for additional Information in Stock needed -> start*/

        //additional Parts
        // this.stockForTerminal.Calculated
        this.stockDetailInformation.billAcceptorTotalSum = 0;
        this.stockDetailInformation.coinAcceptorTotalSum = 0;
        this.stockDetailInformation.dispenserTotalSum = 0;
        this.stockDetailInformation.coinDispenserTotalSum = 0;
        this.stockDetailInformation.mediaInTotalSum = 0;
        this.stockDetailInformation.mediaOutTotalSum = 0;
        this.stockDetailInformation.dispenserTotalCount = 0;
        this.stockDetailInformation.ticketInCount = 0;
        this.stockDetailInformation.ticketInAmount = 0;
        this.stockDetailInformation.ticketOutCount = 0;
        this.stockDetailInformation.ticketOutAmount = 0;
        this.stockDetailInformation.roundingJackpots = 0;
        this.stockDetailInformation.roundingBonusCards = 0;
        this.stockDetailInformation.roundingPlayerCards = 0;
        this.stockDetailInformation.roundingTicket = 0;
        this.stockDetailInformation.roundingBills = 0;
        this.stockDetailInformation.roundingTotal = 0;
        this.stockDetailInformation.coinDispenserTotalCount = 0;
        this.stockDetailInformation.coinAcceptorTotalCount = 0;
        this.stockDetailInformation.billAcceptorTotalCount = 0;
        this.stockDetailInformation.sam = 0;
        this.stockDetailInformation.depositWithheld = 0;
        this.stockDetailInformation.depositPaid = 0;
        this.stockDetailInformation.mediaInWithheldAmount = 0;
        this.stockDetailInformation.mediaInWithheldCount = 0;
        this.stockDetailInformation.mediaInChipAmount = 0;
        this.stockDetailInformation.mediaInCouponAmount = 0;
        this.stockDetailInformation.mediaInCouponCount = 0;
        this.stockDetailInformation.mediaInMarkerAmount = 0;
        this.stockDetailInformation.mediaInMarkerCount = 0;
        this.stockDetailInformation.mediaInCardsAmount = 0;
        this.stockDetailInformation.mediaInCardsCount = 0;

        this.stockDetailInformation.mediaOutChipAmount = 0;
        this.stockDetailInformation.mediaOutCouponAmount = 0;
        this.stockDetailInformation.mediaOutCouponCount = 0;
        this.stockDetailInformation.mediaOutMarkerAmount = 0;
        this.stockDetailInformation.mediaOutMarkerCount = 0;
        this.stockDetailInformation.mediaOutCardsAmount = 0;
        this.stockDetailInformation.mediaOutCardsCount = 0;

        this.stockDetailInformation.mediaOutPaidAmount = 0;
        this.stockDetailInformation.mediaOutPaidCount = 0;
        this.stockDetailInformation.cardsStacker = 0;
        this.stockDetailInformation.cardsBin = 0;


        if (this.stockForTerminal.BillAcceptor !== null && this.stockForTerminal.BillAcceptor.length !== 0) {
            for (let ba = 0; ba < this.stockForTerminal.BillAcceptor.length; ba++) {
                this.stockForTerminal.BillAcceptor[ba].Sum = (this.stockForTerminal.BillAcceptor[ba].Amount * this.stockForTerminal.BillAcceptor[ba].Pieces);
                this.stockDetailInformation.billAcceptorTotalSum += (this.stockForTerminal.BillAcceptor[ba].Amount * this.stockForTerminal.BillAcceptor[ba].Pieces);
                this.stockDetailInformation.billAcceptorTotalCount += this.stockForTerminal.BillAcceptor[ba].Pieces;
            }
            // console.log(" this.billAcceptorFullSum -> ",  this.billAcceptorTotalSum);
            // console.log(" this.stockForTerminal -> ",  this.stockForTerminal);
            //  this.stockForTerminal.Calculated.billAcceptorFullSum = this.billAcceptorFullSum;
        }
        if (this.stockForTerminal.CoinAcceptor !== null && this.stockForTerminal.CoinAcceptor.length !== 0) {
            for (let ca = 0; ca < this.stockForTerminal.CoinAcceptor.length; ca++) {
                this.stockForTerminal.CoinAcceptor[ca].Sum = (this.stockForTerminal.CoinAcceptor[ca].Amount * this.stockForTerminal.CoinAcceptor[ca].Pieces);
                this.stockDetailInformation.coinAcceptorTotalSum += (this.stockForTerminal.CoinAcceptor[ca].Amount * this.stockForTerminal.CoinAcceptor[ca].Pieces);
                this.stockDetailInformation.coinAcceptorTotalCount += this.stockForTerminal.CoinAcceptor[ca].Pieces;
            }
            //console.log(" this.CoinAcceptorFullSum -> ",  this.coinAcceptorTotalSum);
            //console.log(" this.stockForTerminal -> ",  this.stockForTerminal);
            //   this.stockForTerminal.Calculated.coinAcceptorFullSum = this.coinAcceptorFullSum;
        }
        if (this.stockForTerminal.Dispenser !== null && this.stockForTerminal.Dispenser.length !== 0) {
            for (let dp = 0; dp < this.stockForTerminal.Dispenser.length; dp++) {
                if (this.stockForTerminal.Dispenser[dp].Type !== 62) {
                    this.stockForTerminal.Dispenser[dp].Sum = (this.stockForTerminal.Dispenser[dp].Amount * this.stockForTerminal.Dispenser[dp].Pieces);
                    this.stockDetailInformation.dispenserTotalSum += (this.stockForTerminal.Dispenser[dp].Amount * this.stockForTerminal.Dispenser[dp].Pieces);
                    this.stockDetailInformation.dispenserTotalCount += this.stockForTerminal.Dispenser[dp].Pieces;
                }
                else {
                    this.stockForTerminal.Dispenser[dp].Sum = (this.stockForTerminal.Dispenser[dp].Amount);
                    this.stockDetailInformation.dispenserTotalSum += (this.stockForTerminal.Dispenser[dp].Amount);
                    this.stockDetailInformation.dispenserTotalCount += this.stockForTerminal.Dispenser[dp].Pieces;
                }
            }
            // console.log("  this.dispenserFullSum -> ",   this.dispenserTotalSum);
            //  this.stockForTerminal.Calculated.dispenserFullSum = this.dispenserFullSum;
        }

        if (this.stockForTerminal.MediaIn !== null && this.stockForTerminal.MediaIn.length !== 0) {
            for (let mi = 0; mi < this.stockForTerminal.MediaIn.length; mi++) {
                this.stockForTerminal.MediaIn[mi].Sum = (this.stockForTerminal.MediaIn[mi].Amount * this.stockForTerminal.MediaIn[mi].Pieces);
                this.stockDetailInformation.mediaInTotalSum += (this.stockForTerminal.MediaIn[mi].Amount * this.stockForTerminal.MediaIn[mi].Pieces);
                if (this.stockForTerminal.MediaIn[mi].Type === 3) {
                    this.stockDetailInformation.ticketInAmount += this.stockForTerminal.MediaIn[mi].Amount;
                    this.stockDetailInformation.ticketInCount += this.stockForTerminal.MediaIn[mi].Pieces;
                }
                if (this.stockForTerminal.MediaIn[mi].Type === 4 || this.stockForTerminal.MediaIn[mi].Type === 5) {
                    this.stockDetailInformation.mediaInWithheldAmount += this.stockForTerminal.MediaIn[mi].Amount;
                    this.stockDetailInformation.mediaInWithheldCount += this.stockForTerminal.MediaIn[mi].Pieces;
                }
                if (this.stockForTerminal.MediaIn[mi].Type === 7) {
                    this.stockDetailInformation.mediaInChipAmount += this.stockForTerminal.MediaIn[mi].Amount;

                }
                if (this.stockForTerminal.MediaIn[mi].Type === 8) {
                    this.stockDetailInformation.mediaInCouponAmount += this.stockForTerminal.MediaIn[mi].Amount;
                    this.stockDetailInformation.mediaInCouponCount += this.stockForTerminal.MediaIn[mi].Pieces;
                }
                if (this.stockForTerminal.MediaIn[mi].Type === 9) {
                    this.stockDetailInformation.mediaInMarkerAmount += this.stockForTerminal.MediaIn[mi].Amount;
                    this.stockDetailInformation.mediaInMarkerCount += this.stockForTerminal.MediaIn[mi].Pieces;
                }

            }
            //console.log("  this.mediaInFullSum -> ",   this.mediaInTotalSum);
            //this.stockForTerminal.Calculated.media.withheld = this.mediaInFullSum;
        }

        if (this.stockForTerminal.MediaOut !== null && this.stockForTerminal.MediaOut.length !== 0) {
            for (let mo = 0; mo < this.stockForTerminal.MediaOut.length; mo++) {
                this.stockForTerminal.MediaOut[mo].Sum = (this.stockForTerminal.MediaOut[mo].Amount * this.stockForTerminal.MediaOut[mo].Pieces);
                this.stockDetailInformation.mediaOutTotalSum += (this.stockForTerminal.MediaOut[mo].Amount * this.stockForTerminal.MediaOut[mo].Pieces);
                if (this.stockForTerminal.MediaOut[mo].Type === 3) {
                    this.stockDetailInformation.ticketOutAmount += this.stockForTerminal.MediaOut[mo].Amount;
                    this.stockDetailInformation.ticketOutCount += this.stockForTerminal.MediaOut[mo].Pieces;
                }
                if (this.stockForTerminal.MediaOut[mo].Type === 4 || this.stockForTerminal.MediaOut[mo].Type === 5) {
                    this.stockDetailInformation.mediaOutPaidAmount += this.stockForTerminal.MediaOut[mo].Amount;
                    this.stockDetailInformation.mediaOutPaidCount += this.stockForTerminal.MediaOut[mo].Pieces;
                }

                if (this.stockForTerminal.MediaOut[mo].Type === 7) {
                    this.stockDetailInformation.mediaOutChipAmount += this.stockForTerminal.MediaOut[mo].Amount;

                }
                if (this.stockForTerminal.MediaOut[mo].Type === 8) {
                    this.stockDetailInformation.mediaOutCouponAmount += this.stockForTerminal.MediaOut[mo].Amount;
                    this.stockDetailInformation.mediaOutCouponCount += this.stockForTerminal.MediaOut[mo].Pieces;
                }
                if (this.stockForTerminal.MediaOut[mo].Type === 9) {
                    this.stockDetailInformation.mediaOutMarkerAmount += this.stockForTerminal.MediaOut[mo].Amount;
                    this.stockDetailInformation.mediaOutMarkerCount += this.stockForTerminal.MediaOut[mo].Pieces;
                }
            }
            //console.log("  this.mediaOutFullSum -> ",   this.mediaOutTotalSum);
            //this.stockForTerminal.Calculated.media.withheld = this.mediaInFullSum;
        }

        if (this.stockForTerminal.Hopper !== null && this.stockForTerminal.Hopper.length !== 0) {
            for (let ho = 0; ho < this.stockForTerminal.Hopper.length; ho++) {
                this.stockForTerminal.Hopper[ho].Sum = (this.stockForTerminal.Hopper[ho].Amount * this.stockForTerminal.Hopper[ho].Pieces);
                this.stockDetailInformation.coinDispenserTotalSum += (this.stockForTerminal.Hopper[ho].Amount * this.stockForTerminal.Hopper[ho].Pieces);
                this.stockDetailInformation.coinDispenserTotalCount += this.stockForTerminal.Hopper[ho].Pieces;
            }
            //console.log("  this.coinDispenserFullSum -> ",   this.coinDispenserTotalSum);
            //this.stockForTerminal.Calculated.media.withheld = this.mediaInFullSum;
        }

        if (this.stockForTerminal.Current !== null && this.stockForTerminal.Current.length !== 0) {
            for (let ct = 0; ct < this.stockForTerminal.Current.length; ct++) {
                if (this.stockForTerminal.Current[ct].Type === 71) {
                    this.stockDetailInformation.roundingTicket = this.stockForTerminal.Current[ct].Amount;
                }
                if (this.stockForTerminal.Current[ct].Type === 72) {
                    this.stockDetailInformation.roundingPlayerCards = this.stockForTerminal.Current[ct].Amount;
                }
                if (this.stockForTerminal.Current[ct].Type === 73) {
                    this.stockDetailInformation.roundingBonusCards = this.stockForTerminal.Current[ct].Amount;
                }
                if (this.stockForTerminal.Current[ct].Type === 74) {
                    this.stockDetailInformation.roundingJackpots = this.stockForTerminal.Current[ct].Amount;
                }
                if (this.stockForTerminal.Current[ct].Type === 75) {
                    this.stockDetailInformation.roundingBills = this.stockForTerminal.Current[ct].Amount;
                }

                if (this.stockForTerminal.Current[ct].Type === 101) {
                    this.stockDetailInformation.cardsStacker = this.stockForTerminal.Current[ct].Pieces;
                }
                if (this.stockForTerminal.Current[ct].Type === 100) {
                    this.stockDetailInformation.cardsBin = this.stockForTerminal.Current[ct].Pieces;
                }
                if (this.stockForTerminal.Current[ct].Type === 102) {
                    this.stockDetailInformation.sam = this.stockForTerminal.Current[ct].Amount;
                }
                if (this.stockForTerminal.Current[ct].Type === 109) {
                    this.stockDetailInformation.depositWithheld = this.stockForTerminal.Current[ct].Amount;
                }
                if (this.stockForTerminal.Current[ct].Type === 110) {
                    this.stockDetailInformation.depositPaid = this.stockForTerminal.Current[ct].Amount;
                }
                // this.stockForTerminal.Hopper[ho].Sum = (this.stockForTerminal.MediaOut[ho].Amount * this.stockForTerminal.Hopper[ho].Pieces);
                // this.coinDispenserTotalSum += (this.stockForTerminal.Hopper[ho].Amount * this.stockForTerminal.Hopper[ho].Pieces);
            }
            this.stockDetailInformation.roundingTotal = this.stockDetailInformation.roundingTicket + this.stockDetailInformation.roundingPlayerCards + this.stockDetailInformation.roundingBonusCards + this.stockDetailInformation.roundingJackpots + this.stockDetailInformation.roundingBills;
            // console.log("  this.coinDispenserFullSum -> ",   this.coinDispenserTotalSum);
            //this.stockForTerminal.Calculated.media.withheld = this.mediaInFullSum;
        }

        return this.stockDetailInformation;
    }
}
