<p-dialog styleClass="art-details-dialog" appendTo="body" [closeOnEscape]="true" [responsive]="true"
          [resizable]="false" (onShow)="showDialogMaximized(artDetailDialog)" #artDetailDialog
          [style]="{width: '100vw', 'min-height': '100vh'}" [draggable]="false" [closable]="false"
          [(visible)]="viewReady" [modal]="true">

    <p-header>{{ artInstallationDetails?.ArtName }}
        <i class="close-button fa fa-times" (click)="closeARTDetailsModal()"></i>
    </p-header>

    <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 205px)'}" *ngIf="viewReady">

        <div class="p-grid">
            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">

                        <p-card header="{{ artInstallationDetails.AddressExt.LocationName }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <ng-container *ngIf="artInstallationDetails.ArtWebClientEnabled == false">
                                            <b>{{ goToWebsiteLabel }}</b>
                                        </ng-container>
                                        <ng-container *ngIf="artInstallationDetails.ArtWebClientEnabled == true">
                                            <ng-container *ngIf="artInstallationDetails.ArtWebClientUri != ''">
                                                <b>
                                                    <a href="https://{{artInstallationDetails.ArtWebClientUri}}"
                                                       class="external-link" target="_blank">
                                                        <i class="pi pi-external-link"></i>
                                                        {{ goToWebsiteLabel }}
                                                    </a>
                                                </b>
                                            </ng-container>
                                            <ng-container *ngIf="artInstallationDetails.ArtWebClientUri == ''">
                                                <b>{{ goToWebsiteLabel }}</b>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ locationLabel.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ locationLabel.street  }}</div>
                                        <div class="p-col-12">{{ locationLabel.address }}</div>
                                        <div class="p-col-12">{{ locationLabel.zipCode }}</div>
                                        <div class="p-col-12">{{ locationLabel.city    }}</div>
                                        <div class="p-col-12">{{ locationLabel.county  }}</div>
                                        <div class="p-col-12">{{ locationLabel.state   }}</div>
                                        <div class="p-col-12">{{ locationLabel.country }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.PostalAddress?.Street       }} &nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.PostalAddress?.AddressLine2 }} &nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.PostalAddress?.ZipCode      }} &nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.PostalAddress?.City         }} &nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.PostalAddress?.County       }} &nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.PostalAddress?.State        }} &nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.PostalAddress?.Country      }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ contactInfosLabel.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ contactInfosLabel.email }}</div>
                                        <div class="p-col-12">{{ contactInfosLabel.phone }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.EmailAddress }} &nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.PhoneNumber  }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ contactPersonLabel.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ contactPersonLabel.name  }}</div>
                                        <div class="p-col-12">{{ contactPersonLabel.email }}</div>
                                        <div class="p-col-12">{{ contactPersonLabel.phone }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.ContactPerson?.Name         }} &nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.ContactPerson?.EmailAddress }} &nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.ContactPerson?.PhoneNumber  }} &nbsp;
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ locationLabel.timeZone }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <div class="p-col-12">{{ artInstallationDetails.AddressExt?.TimeZone }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                </div>
            </div>
            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">

                        <p-card header="{{ artDetailsLabel.artStatisticsLabels.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ artDetailsLabel.artStatisticsLabels.terminalCount  }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.artStatisticsLabels.operatorsCount }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.artStatisticsLabels.firstSync      }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.artStatisticsLabels.lastSync       }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ terminalCountD            }}</div>
                                        <div class="p-col-12">{{ operatorsCountD           }}</div>
                                        <div class="p-col-12">{{ artInstallationDetails.FirstSync }}</div>
                                        <div class="p-col-12">{{ artInstallationDetails.Timestamp }}</div>
                                    </div>
                                </div>
                            </ng-container>

                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ artDetailsLabel.artConfigLabels.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <div class="p-col-12">{{ artDetailsLabel.artConfigLabels.id  }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.artConfigLabels.installationMode  }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.artConfigLabels.version }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.artConfigLabels.currencyCode  }}</div>
                                    </div>
                                    <div class="p-col-8">
                                        <div class="p-col-12">{{ artInstallationDetails.ArtId        }}&nbsp;</div>
                                        <div class="p-col-12">{{ artInstModeD                 }}&nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.ArtVersion   }}&nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.CurrencyCode }}&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ artDetailsLabel.environmentInformation.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <div class="p-col-12">{{ artDetailsLabel.environmentInformation.runTime }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.environmentInformation.dotNetVersion  }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.environmentInformation.osBuildVersion    }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.environmentInformation.osName         }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.environmentInformation.osVersion       }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.environmentInformation.sqlServerVersion   }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.environmentInformation.computerName   }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.environmentInformation.ipAddress      }}</div>
                                    </div>
                                    <div class="p-col-8">
                                        <div class="p-col-12">{{ artInstallationDetails.EnvironmentInformation?.DotNetClrVersion }}&nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.EnvironmentInformation?.DotNetVersion    }}&nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.EnvironmentInformation?.OsBuildVersion   }}&nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.EnvironmentInformation?.OsName           }}&nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.EnvironmentInformation?.OsVersion        }}&nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.EnvironmentInformation?.SqlServerVersion }}&nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.EnvironmentInformation?.ComputerName     }}&nbsp;</div>
                                        <div class="p-col-12">{{ artInstallationDetails.EnvironmentInformation?.IpAddress        }}&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ artDetailsLabel.baseLicenseLabels.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ artDetailsLabel.baseLicenseLabels.registered  }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.baseLicenseLabels.terminalCount    }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.baseLicenseLabels.artClientCount }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.baseLicenseLabels.propertyNumber  }}</div>
                                        <div class="p-col-12">{{ artDetailsLabel.baseLicenseLabels.baseLicenseNumber  }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ registeredD  }}&nbsp;</div>
                                        <div class="p-col-12">{{ terCountD    }}&nbsp;</div>
                                        <div class="p-col-12">{{ clientCountD }}&nbsp;</div>
                                        <div class="p-col-12">
                                            {{ artInstallationDetails.LicenseInformation.DefaultProperty   }}&nbsp;</div>
                                        <div class="p-col-12">
                                            {{ artInstallationDetails.LicenseInformation.BaseLicenseNumber }}&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ artDetailsLabel.titleModuleLicense }}">
                            <ng-container>

                                <div class="p-grid" *ngFor="let license of moduleLicensesD">
                                    <div class="p-col-6">{{ license.label }}</div>
                                    <div class="p-col-6">
                                        <div [class]="license.class"></div>
                                        <span style="margin-left:10px">{{ license.state }}</span>
                                    </div>
                                </div>

                            </ng-container>
                        </p-card>

                    </div>
                </div>
            </div>
            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">

                        <p-card header="{{ artDetailsLabel.tableLabels.properties }} ({{ propertyCount }}/{{ propertyCountMax }})">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-12">

                                        <p-table #pt [columns]="columnsProperty" [value]="propertyList"
                                                 [scrollable]="true" scrollHeight="480px"
                                                 (onFilter)="onFilterProperty($event)"
                                                 sortField="PropertyName"
                                                 [globalFilterFields]="['PropertyName', 'PropertyNo', 'PropertyDescription']"
                                                 class="media-log-table content-table" *ngIf="propertyListTableView">
                                            <ng-template pTemplate="caption">
                                                <div style="text-align: right">
                                                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                                    <input type="text" pInputText size="40" placeholder="{{ artDetailsLabel.tableLabels.findButton }}"
                                                           (input)="pt.filterGlobal($event.target.value, 'contains');"
                                                           style="width:auto">
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="colgroup">
                                                <colgroup>
                                                    <col style="width:50px">
                                                    <col style="width:150px">
                                                    <col style="width:150px">
                                                </colgroup>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-columns>
                                                <tr>
                                                    <th class="{{col.class}}" *ngFor="let col of columnsProperty"
                                                        [pSortableColumn]="col.field">
                                                        {{ col.header }}
                                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-property>
                                                <tr (click)="openPropertyDetail(property)">
                                                    <td class="tb-right">{{ property?.PropertyNo          }}</td>
                                                    <td class="tb-right">{{ property?.TerminalCount       }}</td>
                                                    <td class="tb-left" >{{ property?.PropertyName        }}</td>
                                                    <td class="tb-left" >{{ property?.PropertyDescription }}</td>
                                                </tr>
                                            </ng-template>

                                        </p-table>

                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card
                            header="{{ artDetailsLabel.tableLabels.terminals }} ({{ terminalCount }}/{{ terminalCountMax }})">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <p-table #dt [columns]="columns" [value]="terminalList"
                                                 [scrollable]="true" scrollHeight="480px"
                                                 (onFilter)="onFilterTerminal($event)"
                                                 sortField="TerminalName"
                                                 [globalFilterFields]="['TerminalName', 'TerminalId']"
                                                 class="media-log-table content-table" *ngIf="terminalListTableView">
                                            <ng-template pTemplate="caption">
                                                <div style="text-align: right">
                                                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                                    <input type="text" pInputText size="40" placeholder="{{ artDetailsLabel.tableLabels.findButton }}"
                                                           (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                           style="width:auto">
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="colgroup">
                                                <colgroup>
                                                    <col style="width:30px">
                                                    <col style="width:70px">
                                                    <col style="width:150px">
                                                    <col style="width:150px">
                                                    <col style="width:100px">
                                                    <col style="width:50px">

                                                </colgroup>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-colums>
                                                <tr>
                                                    <th class="{{col.class}}" *ngFor="let col of columns"
                                                        [pSortableColumn]="col.field">
                                                        {{col.header}}
                                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-terminal>
                                                <tr (click)="openTerminalDetail(terminal)">
                                                    <td><div class="{{ terminal.Connected.class }}">
                                                        {{terminal.Connected.text}}</div></td>
                                                    <td class="tb-right">     {{ terminal?.TerminalId        }}</td>
                                                    <td class="tb-left">      {{ terminal?.TerminalName      }}</td>
                                                    <td class="tb-left">      {{ terminal?.TerminalSCVersion }}</td>
                                                    <td class="tb-left">      {{ terminal?.TerminalType      }}</td>
                                                    <td><div class="{{ terminal.TerminalState.class }}">
                                                        {{ terminal.TerminalState.text }}</div></td>
                                                </tr>
                                            </ng-template>

                                        </p-table>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                </div>
            </div>
        </div>

    </p-scrollPanel>

    <p-footer>

        <p-toolbar>
            <div class="p-toolbar-group-left">

                <button type="button" class="send-button" pButton icon="fa fa-pencil"
                        (click)="openEditArtInstallationModal(artInstallationDetails)"
                        label="{{ artDetailsLabel.tableLabels.editArtInstallation }}"></button>

            </div>
        </p-toolbar>

    </p-footer>

</p-dialog>

<p-dialog styleClass="art-details-edit-installation-dialog" appendTo="body" [closeOnEscape]="true" [responsive]="true"
          [resizable]="false" [draggable]="false" [closable]="false" [(visible)]="displayArtDetailsEdit"
          *ngIf="artDetailsEditView === true" [modal]="true">

    <p-header>{{ editDialogHeader }}
        <i class="close-button fa fa-times" (click)="artDetailsEditView=false"></i>
    </p-header>

    <span>{{ artDetailsLabel.artConfigLabels.installationMode }}: </span>
    <p-dropdown [options]="artInstallationModes" [(ngModel)]="currentArtInstallationEdit.ArtMode"
                filter="true" appendTo="body" class="installations-modes-dropdown">
        <ng-template let-item pTemplate="selectedItem">
            <span style="width:24px;position:absolute; top:3px;left:5px; height: 24px; border-radius:50%; background-color: var({{item.class}});"></span>
            <span style="vertical-align:middle; margin-left: 2em">{{item.label}}</span>
        </ng-template>
        <ng-template let-installationMode pTemplate="item">
            <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                    <span style="width:24px;position:absolute;top:1px;left:5px; height: 24px; border-radius:50%; background-color: var({{installationMode.class}});"></span>
                    <div style="font-size:14px;float:right;margin-top:4px">{{installationMode.label}}</div>
                </div>
            </div>
        </ng-template>
    </p-dropdown>

    <p-footer>
        <p-toolbar>
            <div class="p-toolbar-group-left"></div>

            <div class="p-toolbar-group-right">

                <button type="button" class="send-button" pButton icon="pi pi-check"
                        (click)="saveEditArtInstallationModal()"
                        label="{{ artDetailsLabel.tableLabels.saveButton }}"></button>

                <button type="button" class="send-button" pButton icon="fa fa-close" (click)="artDetailsEditView=false"
                        label="{{ artDetailsLabel.tableLabels.cancelButton }}"></button>

            </div>
        </p-toolbar>
    </p-footer>

</p-dialog>

<art-installation-terminal-details *ngIf="artDetailsTerminalView === true"
                                   (closeTerminalDetails)    ='closeTerminalDetails()'
                                   [artInstallationDetails]  ="artInstallationDetails"
                                   [currentTerminalDetails]  ="currentTerminalDetails"
                                   [currentTerminalDetailsId]="currentTerminalDetailsID"
                                   [locationLabel]           ="locationLabel"
                                   [contactInfosLabel]       ="contactInfosLabel"
                                   [contactPersonLabel]      ="contactPersonLabel"></art-installation-terminal-details>

<art-installation-property-details *ngIf="artDetailsPropertyView === true"
                                   (closePropertyDetails)    ='closePropertyDetails()'
                                   [artInstallationDetails]  ="artInstallationDetails"
                                   [currentPropertyDetails]  ="currentPropertyDetails"
                                   [currentPropertyDetailsId]="currentPropertyDetailsID"
                                   [locationLabel]           ="locationLabel"
                                   [contactInfosLabel]       ="contactInfosLabel"
                                   [contactPersonLabel]      ="contactPersonLabel"></art-installation-property-details>
