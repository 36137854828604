export enum UpdateTermViewEventType {
    Basic = 1,
    Condition = 2,
    Transaction = 3,
    OperatorLogin = 4,
    Versions = 5,
    CoinIn = 6,
    CoinOut = 7,
    BillIn = 8,
    BillOut = 9,
    Media = 10,
    Setting = 11,
    BalanceLog = 16,
    Functions = 18,
    AtmClient = 21,
    ComponentsChanged = 29,
    FeaturesChanged = 30,
    StockChanged = 31,
}
