export enum AccessType {
    None = 0,
    LoginName = 1,
    OperatorCard = 2,
    Fingerprint = 3,
    PalmScan = 4,
    UsbStick = 5,
    UserIDGiven = 6,
    FaceScan = 7,
    RfidCard = 8,
    RemoteAccess = 9,
}
