<div class="terminal-edit" *ngIf="terminalSingleDetailsEdit">
    <p-checkbox label="{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_LOCATION.TITLE' |translate}}"
                [(ngModel)]="selectedCategories" inputId="location" name="group" value="location"></p-checkbox>
    <div class="checkbox-address-container">
        <div class="p-grid">
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_LOCATION.LOCATION_NAME' |translate}}:</label></div>
            <div class="p-col-7">
                <input type="text" [disabled]="!checkDisabled('location')" pInputText [(ngModel)]="locationName" value="{{locationName}}">
            </div>
        </div>
    </div>
    <p-checkbox label="{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_POSTAL_ADDRESS.TITLE' |translate}}"
                [(ngModel)]="selectedCategories" inputId="postalAddress" name="group" value="postalAddress"></p-checkbox>
    <div class="checkbox-address-container">
        <div class="p-grid">
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_POSTAL_ADDRESS.ADDRESS_LINE_2' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('postalAddress')" type="text" pInputText [(ngModel)]="addressLine2" value="{{addressLine2}}">
            </div>
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_POSTAL_ADDRESS.ADDRESS_LINE_1' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('postalAddress')" type="text" pInputText [(ngModel)]="addressLine1" value="{{addressLine1}}">
            </div>
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_POSTAL_ADDRESS.COUNTY' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('postalAddress')" type="text" pInputText [(ngModel)]="county" value="{{county}}">
            </div>
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_POSTAL_ADDRESS.CITY' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('postalAddress')" type="text" pInputText [(ngModel)]="city" value="{{city}}">
            </div>
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_POSTAL_ADDRESS.COUNTRY.TITLE' |translate}}:</label></div>
            <div class="p-col-7">
                <p-dropdown [disabled]="!checkDisabled('postalAddress')" [options]="countries" appendTo="body"
                            [(ngModel)]="selectedCountry" optionLabel="countryName" name="country"
                            [filter]="true" filterBy="countryName">
                    <ng-template pTemplate="selectedItem">
                        <div class="country-item country-item-value">
                            <div>{{selectedCountry.countryName}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                        <div class="country-item">
                            <div>{{country.countryName}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_POSTAL_ADDRESS.STATE' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('postalAddress')" type="text" pInputText [(ngModel)]="state" value="{{state}}">
            </div>
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_POSTAL_ADDRESS.ZIP_CODE' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('postalAddress')" type="text" pInputText [(ngModel)]="zipCode" value="{{zipCode}}">
            </div>
        </div>
    </div>
    <p-checkbox label="{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_CONTACT_INFORMATION.TITLE' |translate}}"
                [(ngModel)]="selectedCategories" inputId="contactInf" name="group" value="contactInf"></p-checkbox>
    <div class="checkbox-address-container">
        <div class="p-grid">
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_CONTACT_INFORMATION.PHONE' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('contactInf')" type="text" pInputText [(ngModel)]="phoneNumber" value="{{phoneNumber}}">
            </div>
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_CONTACT_INFORMATION.EMAIL_ADDRESS' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('contactInf')" type="text" pInputText [(ngModel)]="eMailAddress" value="{{eMailAddress}}">
            </div>
        </div>
    </div>
    <p-checkbox label="{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_CONTACT_PERSON.TITLE' |translate}}"
                [(ngModel)]="selectedCategories" inputId="contactPerson" name="group" value="contactPerson"></p-checkbox>
    <div class="checkbox-address-container">
        <div class="p-grid">
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_CONTACT_PERSON.CONTACT_NAME' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('contactPerson')" type="text" pInputText [(ngModel)]="contactName" value="{{contactName}}">
            </div>
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_CONTACT_INFORMATION.PHONE' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('contactPerson')" type="text" pInputText [(ngModel)]="contactPersonPhone" value="{{contactPersonPhone}}">
            </div>
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_CONTACT_INFORMATION.EMAIL_ADDRESS' |translate}}:</label></div>
            <div class="p-col-7">
                <input [disabled]="!checkDisabled('contactPerson')" type="text" pInputText [(ngModel)]="contactPersonEmail" value="{{contactPersonEmail}}">
            </div>
        </div>
    </div>
    <p-checkbox label="{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_TIME_ZONE.TITLE' |translate}}"
                [(ngModel)]="selectedCategories" inputId="timeZone" name="group" value="timeZone"></p-checkbox>
    <div class="checkbox-address-container">
        <div class="p-grid">
            <div class="p-col-5"><label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_TIME_ZONE.TIME_ZONE' |translate}}:</label></div>
            <div class="p-col-7">
                <p-dropdown [disabled]="!checkDisabled('timeZone')" appendTo="body"
                            [options]="timeZones" [(ngModel)]="selectedTimeZone" optionLabel="timeZone" name="timeZone"
                            [filter]="true" filterBy="timeZone">
                    <ng-template pTemplate="selectedItem">
                            <div>{{selectedTimeZone.timeZone}}</div>
                    </ng-template>
                    <ng-template let-timeZone pTemplate="item">
                            <div>{{timeZone.timeZone}}</div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
    </div>
    <div class="button-line">
        <button class="btn btn-success btn-save" style="line-height: 24px;" (click)="saveAddress()">{{'BASE.BUTTONS.SAVE' | translate}}</button>
    </div>
</div>
