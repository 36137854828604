import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalCacheVariable } from '../_globals/globals';
import { map, catchError } from 'rxjs/operators';
import { ApiDataConverter } from "../_helpers/api-data-converter";
import { ApiCallType } from "../_enums/apiCallType";


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class JackpotService {

    constructor(
        private http: HttpClient,
        public cache: GlobalCacheVariable) {
    }

    private static extractBridge(res, apiType) {
        let response = ApiDataConverter.extractData(res, apiType);
        return response || {};
    }

    public handleError(errorMessage: any, apiType) {
        ApiDataConverter.errorHandling(errorMessage, apiType);
        return observableThrowError(errorMessage);
    }

    getJackpots(from, to) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                from: from,
                to: to
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'GetJackpotForms', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetJackpotForms);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.GetJackpotForms);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getJackpotList(from, to) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                from: from,
                to: to,
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'GetJackpotSystemJackpots', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetJackpotSystemJackpots);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.GetJackpotSystemJackpots);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    preLoginLnw(name, password) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                username: name,
                password: password
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'JackpotSystemPreLogin', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.JackpotSystemPreLogin);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.JackpotSystemPreLogin);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    loginLnw(roleName, siteID) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                roleName: roleName,
                siteID: siteID
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'JackpotSystemLogin', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.JackpotSystemLogin);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.JackpotSystemLogin);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getJackpotsWithSearch(searchParams) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                searchParams: searchParams
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'SearchForJackpotForms', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.SearchForJackpotForms);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.SearchForJackpotForms);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    CheckTinJackpotForm(tinData, name) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                tin: tinData,
                lastName: name
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'CheckTinListMatch', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.CheckTinListMatch);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.CheckTinListMatch);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    validityCheck(jackpot) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                // redeemJackpot: jackpot,
                redeemJackpot: {
                    "payoutType": "SPLIT",
                    "jackpotData": {
                        "SeqNumber": 1223,
                        "EgmID": "DADA",
                        "JpType": "REGULAR",
                        "JpStatus": "PENDING",
                        "Shift": "D",
                        "SlipType": "JACKPOT"
                    }
                }
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'ValidateJackpot', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.ValidateJackpot);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.ValidateJackpot);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    ReprintJackpotForm(jackpotID) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                jackpotFormID: jackpotID
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'ReprintJackpotForm', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.ReprintJackpotForm);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.ReprintJackpotForm);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    UpdateSettlementStatus(jackpotID, amountType) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                jackpotFormID: jackpotID,
                splitAmountType: amountType
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'UpdateJackpotSettlementStatus', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.UpdateJackpotSettlementStatus);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.UpdateJackpotSettlementStatus);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getJackpotDetails(jackpotID) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                jackpotFormID: jackpotID
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'GetJackpotFormDetails', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetJackpotFormDetails);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.GetJackpotFormDetails);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    removeJackpotForm(jackpotID) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                jackpotFormID: jackpotID
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'RemoveJackpotForm', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.RemoveJackpotForm);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.RemoveJackpotForm);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    extractBarcode(image) {
        try {
            let dataSession = {
                sessionKey: this.cache.getSessionKey(),
                base64Image: image
            };
            let body = dataSession;
            return this.http.post(this.cache.ARTApi + 'ExtractBarcodeData', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.ExtractBarcodeData);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.ExtractBarcodeData);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    addJackpot(jackpot, generalJackpotData, formType) {
        try {
            let FormData1042S = null;
            let FormDataW2G = null;
            if (formType === 1) {
                FormDataW2G = jackpot;
            }
            else {
                FormData1042S = jackpot;
            }
            let dataAdd = {
                sessionKey: this.cache.getSessionKey(),
                jackpotForm: {
                    FormType: formType,
                    FormData1042S: FormData1042S,
                    FormDataW2G: FormDataW2G,
                    SignatureImage: generalJackpotData.signature,
                    DateSigned: generalJackpotData.dateSigned,
                    //TODO CHANGE TEST CODE
                    IDFrontImage: generalJackpotData.licenseFront,
                    IDRearImage: generalJackpotData.licenseBack,
                    SecondaryIDFrontImage: generalJackpotData.SecondaryIDFrontImage,
                    SecondaryIDRearImage: generalJackpotData.SecondaryIDRearImage,
                    WinningCombinationImage: generalJackpotData.winningCombination,
                    WinnersEMailAddress: generalJackpotData.email,
                    TicketPayoutAmount: generalJackpotData.paidToTicket,
                    ReturnToSlotAmount: generalJackpotData.paidToSlot,
                    CheckPayoutAmount: generalJackpotData.paidToCheck,
                    WalletPayoutAmount: generalJackpotData.paidToWallet,
                    WireAchPayoutAmount: generalJackpotData.paidToWire,
                    CashPayoutAmount: generalJackpotData.paidToCash,
                    SlotNumber: generalJackpotData.slotNumber,
                    JackpotAmount: generalJackpotData.jackpotAmount,
                    WinnersName: generalJackpotData.winnersName,
                    JackpotState: 0,
                    PaidAtTerminalID: 0,
                    ResponsibleOperatorID: 0,
                    LnwJackpotData: generalJackpotData.lnw,
                    TinCheckResult: generalJackpotData.tin,
                    TinCheckStatus: generalJackpotData.TinCheckStatus
                },
            };
            let body = dataAdd;
            return this.http.post(this.cache.ARTApi + 'AddJackpotForm', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.AddJackpotForm);
                    }),
                    map(data => {
                        return JackpotService.extractBridge(data, ApiCallType.AddJackpotForm);
                    }));
        } catch (error) {
            console.log(error);
        }
    }
}

