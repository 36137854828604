export enum TerminalEvent {
    OpenWithoutLogin = 1,
    // just LoginFailed and parameter 'FailureType' (WrongPassword, OperatorNotFound, AccountLocked, etc.) & 'MismatchCount'
    LoginFailedWrongPassword = 2,
    CoinAcceptorNotInEndPosition = 3,
    CoinDispenserNotInEndPosition = 4,
    AcceptorRemovedWhileDoorClosed = 5,
    DispenserRemovedWhileDoorClosed = 6,
    HopperUnderRunsMinimalValue = 7,
    DispenserUnderRunsMinimalValue = 8,
    AcceptorExceedsMaximumCapacity = 9,
    // more than 3 times within 24 hours (or add parameter 'AdjustCount' & 'TimeSpan')
    TimeSyncTooOften = 10,
    AlreadyAssignedTerminalId = 11,
    MaximumNumberOfTerminalsReached = 12,
    CommunicationProblem = 13,
    DoorOpened = 14,
    // more than twelve hours (or add parameter 'Hours')
    ServerTimeAndTerminalTimeDiffer = 15,
    InvalidPropertyNumber = 16,

    // write those to ART Event Log
    //case 17: return "ATM could not connect to FTP server";
    //case 18: return "ATM recon file is invalid";
    //case 19: return "ATM adjustment file could not be created";
    //case 20: return "ATM adjustment file could not be sent";
    //case 21: return "ATM adjustment file could not update database";

    PinAlert = 17,
    TransactionApprovalRequired = 18,
    TransactionApproved = 19,
    RemoteAccessConfirmation = 20,
    PlayerIsBanned = 21,

    PortForwardingStatus = 23,

    OnlineSystemMediaInvalidHash = 31,
    AttendanceRequired = 32,

    MagneticalCardReaderWriteError = 8706,
    CardJammed = 8196,
}
