import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services/terminal.service';

import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';

import { LoggingService } from '../../../../../../auth/_services/log.service';
import { TransactionType } from '../../../../../../auth/_enums/transactionType';

import { TransactionFlag } from '../../../../../../auth/_enums/transactionFlag';
import { TransActionLogListItem } from '../../../../../../auth/_models/transactionloglistitem';
import { TransActionLogDetail } from '../../../../../../auth/_models/transactionlogdetail';
import * as moment from "moment";
import { fromEvent, Subscription } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";


@Component({
    selector: "terminal-single-details-transaction-log",
    templateUrl: "./terminal-single-details-transaction-log.component.html",
    encapsulation: ViewEncapsulation.None,
})

export class TerminalSingleDetailsTransactionLogComponent implements OnInit, AfterViewInit, OnChanges {

    @Input('terminalID') terminalID: number;

    transactionLog: any;
    transactionLogView: boolean = false;

    entryPoint: string = "DM";
    transactionLogDetailView: boolean = false;
    displayTransactionLogDetail: boolean = false;
    currentTransactionID: number = 0;
    currentTransactionLogDetailTerminalID: number = 0;
    private subscription: Subscription;

    constructor(
        private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        private loggingService: LoggingService,
        public globalCacheVariable: GlobalCacheVariable,
        private zone: NgZone,
    ) {
        this.transactionLog = new Array<TransActionLogListItem>();
    }

    openTransactionDetailDialog(transactionID, terminalID) {
        // console.log("open transactionlog -> ", transactionID);
        this.currentTransactionLogDetailTerminalID = terminalID;
        this.currentTransactionID = transactionID;

        this.transactionLogDetailView = true;
        this.displayTransactionLogDetail = true;
    }

    getTransactionLogData() {
        this.loggingService.getRecentTransactions(this.terminalID, 10)
            .subscribe(data => {
                this.zone.run(() => {
                    /*
                    if(this.globalCacheVariable.artConfigurationEntries.get("Basics/MaskAllMediaNumbers").Value){
                        for (let i = 0; i < data.Transactions.length; i++) {
                            if(data.Transactions[i].MediaNumber !== '' && data.Transactions[i].MediaNumber !== undefined){
                                data.Transactions[i].MediaNumber = '*******' + data.Transactions[i].MediaNumber.substring(data.Transactions[i].MediaNumber.length - 4, data.Transactions[i].MediaNumber.length);
                            }


                        }
                    }else{
                        for (let i = 0; i < data.Transactions.length; i++) {

                        }
                    }*/

                    this.transactionLog = data;
                    this.transactionLogView = true;
                    setTimeout(function() {
                        //$(".terminal-single-details-container .transaction-log-table .ui-table-scrollable-body").mCustomScrollbar("destroy");
                        /*$(".terminal-single-details-container .transaction-log-table2 .ui-table-scrollable-body").mCustomScrollbar({
                            "scrollInertia": 0,
                            scrollbarPosition: "outside",
                            axis: "yx",
                            callbacks: {    // callback for thead,tbody scroll
                                whileScrolling: function () {
                                    $(".ui-table-scrollable-header").scrollLeft(this.mcs.left * -1);
                                },
                            },
                        });*/

                    }, 0);
                });
            });
    }
    ngOnInit(): void {
        const keyDowns = fromEvent(document, 'keydown').pipe(
            filter((e: KeyboardEvent) => e.keyCode === 27),
            distinctUntilChanged()
        );
        this.subscription = keyDowns.subscribe(escpress => {
            if (escpress.type === 'keydown') {
                this.displayTransactionLogDetail = false;
            }
        });
        //this.getTransactionLogData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getTransactionLogData();
        }
    }

    ngAfterViewInit(): void {
        // this.getTerminalIdentifierList();
    }
}
