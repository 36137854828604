import {
    Component,
    OnInit,
    ViewEncapsulation,
    SimpleChanges,
    Input,
    NgZone,
    OnChanges
} from '@angular/core';
import { OperatorService } from '../../../auth/_services/operator.service';
import { GlobalCacheVariable } from "../../../auth/_globals/globals";
import { TranslatePipe } from "@ngx-translate/core";
import { GroupServerService } from "../../../auth/_services/groupserver.service";
import { GeneralRewritePipe } from "../../../auth/_pipes/generalRewrite.pipe";
import { ArtClientType } from "../../../auth/_enums/artClientType";


@Component({
    selector: "operator-current-session-list",
    templateUrl: "./operator-current-session-list.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [TranslatePipe, GeneralRewritePipe]
})
export class OperatorCurrentSessionListComponent implements OnInit, OnChanges {

    @Input('currentTimestamp') currentTimestamp: number;
    currentOperatorSession: any;
    columns: any[];

    constructor(
        private operatorService: OperatorService,
        private groupServerService: GroupServerService,
        private zone: NgZone,
        public globalCacheVariable: GlobalCacheVariable,
        public translate: TranslatePipe,
        private generalRewrite: GeneralRewritePipe,
    ) {
        if (this.globalCacheVariable.singleWebClient()) {
            this.columns = [
                { field: 'UserID', header: this.translate.transform('USER.CURRENT_OPERATOR_SESSIONS.ID'), class: 'tb-left' },
                { field: 'UserName', header: this.translate.transform('USER.CURRENT_OPERATOR_SESSIONS.OPERATOR'), class: 'tb-left' },
                { field: 'LoginDate', header: this.translate.transform('USER.CURRENT_OPERATOR_SESSIONS.LOGGED_IN_SINCE'), class: 'tb-right' },
                { field: 'ClientType', header: this.translate.transform('USER.CURRENT_OPERATOR_SESSIONS.CLIENT_TYPE'), class: 'tb-left' },
                { field: 'Endpoint', header: this.translate.transform('USER.CURRENT_OPERATOR_SESSIONS.ENDPOINT'), class: 'tb-right' },
                { field: 'ComputerName', header: this.translate.transform('USER.CURRENT_OPERATOR_SESSIONS.COMPUTER'), class: 'tb-left' },
            ];
        } else {
            this.columns = [
                { field: 'User.Username', header: this.translate.transform('USER.CURRENT_OPERATOR_SESSIONS.OPERATOR'), class: 'tb-left' },
                { field: 'LoginTime', header: this.translate.transform('USER.CURRENT_OPERATOR_SESSIONS.LOGGED_IN_SINCE'), class: 'tb-right' },
                { field: 'Endpoint', header: this.translate.transform('USER.CURRENT_OPERATOR_SESSIONS.ENDPOINT'), class: 'tb-right' },
            ];
        }


    }
    refreshOperatorSession() {
        this.getCurrentOperatorSessionList();
    }

    getCurrentOperatorSessionList() {
        if (this.globalCacheVariable.singleWebClient()) {
            this.operatorService.getUserSessions()
                .subscribe(data => {
                    this.zone.run(() => {
                        for (let operator of data) {
                            operator.ClientType = this.generalRewrite.enumTranslation(ArtClientType, operator.ClientType, 'ENUMS.ART_CLIENT_TYPE.');
                        }
                        this.currentOperatorSession = data;
                        this.globalCacheVariable.modal.currentOperatorSession.currentOperatorSessionCount = this.currentOperatorSession.length;
                    });
                });
        }
        else {
            this.groupServerService.getUserSessions()
                .subscribe(data => {
                    this.zone.run(() => {
                        this.currentOperatorSession = data;
                        this.globalCacheVariable.modal.currentOperatorSession.currentOperatorSessionCount = this.currentOperatorSession.length;
                    });
                });
        }
    }

    ngOnInit(): void {
        this.getCurrentOperatorSessionList();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentTimestamp.currentValue !== changes.currentTimestamp.previousValue) {
            this.getCurrentOperatorSessionList();
        }
    }

}
