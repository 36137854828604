export enum AccessState {
    None = 0,
    InvalidLoginName = 1,
    InvalidPassword = 2,
    AccountLocked = 3, // a.k.a. LoginDisabled
    SessionCountExceeded = 4,
    AccountTypeNotSupported = 5,
    LoggedIn = 6,
    LoggedOut = 7,
    RfidCard = 8,
    RemoteAccess = 9
}
