import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'terminalTypeNaming'
})
export class TerminalTypeNamingPipe implements PipeTransform {

    transform(value): string {
        let terminalTypeName: string = "unknown";
        switch (value) {
            case 'Acs':
                terminalTypeName = "ACS";
                break;
            case 'AdPlayer':
                terminalTypeName = "Ad Player";
                break;
            case 'Caion':
                terminalTypeName = "CAION";
                break;
            case 'Caro':
                terminalTypeName = "CARO";
                break;
            case 'Cashio':
                terminalTypeName = "CASHIO Platinum (Gen 1)";
                break;
            case 'CashioChrome':
                terminalTypeName = "CASHIO Chrome";
                break;
            case 'CashioGold':
                terminalTypeName = "CASHIO Gold";
                break;
            case 'CashioPlatinumGen2':
                terminalTypeName = "CASHIO Platinum";
                break;
            case 'CashioSilver':
                terminalTypeName = "CASHIO Silver";
                break;
            case 'CashioTitanium':
                terminalTypeName = "CASHIO Titanium (Gen 1)";
                break;
            case 'CashioTitaniumGen2':
                terminalTypeName = "CASHIO Titanium";
                break;
            case 'CashDispenser':
                terminalTypeName = "CASHIO TCD";
                break;
            case 'CashioJps':
                terminalTypeName = "CASHIO JPS";
                break;
            case 'Cata':
                terminalTypeName = "CATA";
                break;
            case 'Ccs':
                terminalTypeName = "CCS";
                break;
            case 'Ces':
                terminalTypeName = "CES";
                break;
            case 'Jps':
                terminalTypeName = "JPS";
                break;
            case 'Pos':
                terminalTypeName = "POS";
                break;
            case 'Rodi':
                terminalTypeName = "RODI";
                break;
            case 'Tita':
                terminalTypeName = "TITA";
                break;
            case 'TwoPay':
                terminalTypeName = "2Pay";
                break;
            case 'Vt':
                terminalTypeName = "VT";
                break;
        }
        return terminalTypeName;
    }
}
