export enum DateFormat {
    Now = 0,
    Add = 1,
    Subtract = 2,
    Between = 3,

}

export enum DateFormatUnit {
    Day = 'days',
    Week = 'weeks',
    Month = 'months',
    Year = 'years'
}
