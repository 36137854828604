import { Pipe, PipeTransform } from '@angular/core';
import { TransactionType } from '../_enums/transactionType';
@Pipe({
    name: 'transactionType'
})
export class TransactionTypePipe implements PipeTransform {

    transform(value: number): string {

        let transationType = TransactionType[value];

        return transationType;
    }
}
