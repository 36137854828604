export enum TerminalUpdateResultType {
    None = 0,

    TerminalAdded = 1,
    TerminalUpdated = 2,
    TerminalDeleted = 3,

    InvalidSessionKey = 4,
    InsufficientRights = 5,
    InvalidTerminalID = 6,

    TerminalNameRequired = 8,
    TerminalNameAlreadyExists = 10,
}
