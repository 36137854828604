<p-table [value]="configurationChangeLog" [scrollable]="true" [style]="{width:'auto'}" class="configuration-change-log-table content-table" scrollHeight="flex" *ngIf="configurationLogView">
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:200px">
            <col style="width:180px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th class="tb-left">{{'LOGS.CONFIGURATION_CHANGE.TABLE.CONFIGURATION' | translate}}</th>
            <th class="tb-left">{{'BASE.DESCRIPTION' | translate}}</th>
            <th class="tb-left">{{'LOGS.CONFIGURATION_CHANGE.TABLE.CONFIGURATION_KEY' | translate}}</th>
            <th class="tb-left">{{'LOGS.CONFIGURATION_CHANGE.TABLE.OLD_VALUE' | translate}}</th>
            <th class="tb-left">{{'LOGS.CONFIGURATION_CHANGE.TABLE.NEW_VALUE' | translate}}</th>
            <th class="tb-left">{{'LOGS.CONFIGURATION_CHANGE.TABLE.CHANGE_DATE' | translate}}</th>
            <th class="tb-left">{{'LOGS.CONFIGURATION_CHANGE.TABLE.RESPONSIBLE_OPERATOR_ID' | translate}}</th>
            <th class="tb-left">{{'LOGS.CONFIGURATION_CHANGE.TABLE.RESPONSIBLE_OPERATOR' | translate}}</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-log>
        <tr>
            <td class="tb-left">{{log.ConfigurationIdentifier}}</td>
            <td class="tb-left">{{log.Description}}</td>
            <td class="tb-left">{{log.ConfigKey}}</td>
            <td class="tb-left">{{log.OldValue}}</td>
            <td class="tb-left">{{log.NewValue}}</td>
            <td class="tb-left">{{log.ChangeDate | date: globalCacheVariable.globalFormatSettings.dateFormatLong}}</td>
            <td class="tb-left">{{log.ResponsibleOperatorID}}</td>
            <td class="tb-left">{{log.ResponsibleOperatorID | operatorName}}</td>
        </tr>
    </ng-template>

</p-table>
