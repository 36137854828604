import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from "./helpers";
declare var require: any;
//import { MessageSocketService } from './auth/_services/message-socket.service';
import { TerminalService } from './auth/_services/terminal.service';
import { UpdateTermViewEventType } from "./auth/_enums/updateTermViewEventType";
import { GlobalCacheVariable } from './auth/_globals/globals';
//import {TerminalsComponent} from "./theme/pages/default/monitoring/terminals/terminals.component";
import { Terminal } from "./auth/_models/terminal";
import { TranslationService } from './auth/_services/translation.service';
import { MessageService } from 'primeng/api';
// language list
import { locale as enLang } from './auth/i18n/en';
import { locale as deLang } from './auth/i18n/de';
import { locale as esLang } from './auth/i18n/es';

import { RewriteService } from "./auth/_services/rewrite.service";
import { fromEvent, Observable, Subscription } from "rxjs";
import * as jquery from 'jquery';
import { ToastService } from "./auth/_services/toast.service";
import { ProductType } from "./auth/_enums/productType";
import { TranslatePipe } from '@ngx-translate/core';
import { ToastSeverity } from "./auth/_enums/messages/toastSeverity";
import { map } from "rxjs/operators";
import { MessageType } from "./auth/_enums/messages/messageType";



@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [MessageService, ToastService, TranslatePipe]
})


export class AppComponent implements OnInit {
    projectConfig: any;
    title = 'app';
    globalBodyClass = 'desktop m-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';
    globalBodyClassMobile = 'mobile m-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';
    globalBodyClassClosedSidebar = 'desktop m-brand--minimize m-aside-left--minimize m-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';
    globalBodyClassMobileClosedSidebar = 'mobile m-brand--minimize m-aside-left--minimize m-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';
    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;
    newTerminals = new Map();
    dataObject: any = {};
    //ErrorDetails
    visibleSidebarErrorDetail: boolean = false;
    currentFatalMessage: any;

    constructor(
        private _router: Router,
        //private messageSocketService: MessageSocketService,
        private messageService: MessageService,
        private globalCacheVariable: GlobalCacheVariable,
        private terminalService: TerminalService,
        private translationService: TranslationService,
        private rewriteService: RewriteService,
        public _toastService: ToastService,
        private translate: TranslatePipe
    ) {
        // register translations
        this.translationService.loadTranslations(enLang, deLang, esLang);
        this.projectConfig = this.terminalService.getProjectConfig();
        this.globalCacheVariable.currentARTWebClientVersion = this.projectConfig.version;
        this.globalCacheVariable.allowUnsecureConnections = this.projectConfig.allow_unsecure_connections;
    }

    onConfirm() {
        this.messageService.clear('toastMessagesModal');
    }

    onReject() {
        this.messageService.clear('toastMessagesModal');
    }

    ngOnInit() {
        //Different initialize of Webclients

        this.dataObject = this.terminalService.getProjectConfig()
            .subscribe(data => {
                this.globalCacheVariable.getDefaultOperatorSetting(data.product_type);
                if (data.product_type == ProductType.WebClient) {
                    this.globalCacheVariable.artViewMode = ProductType.WebClient;
                    this.globalCacheVariable.worldMode = false;
                    this.globalCacheVariable.currentARTWebClientVersion = data.art_web_client.version;
                    document.title = data.art_web_client.title;
                    this.translationService.setLanguage(data.art_web_client.default_language);
                    this.globalCacheVariable.currentLanguage = data.art_web_client.default_language;
                    this.globalCacheVariable.allowUnsecureConnections = data.art_web_client.allow_unsecure_access;
                    if (data.art_web_client.custom_rest_api != '') {
                        this.globalCacheVariable.ARTServerBaseIP = data.art_web_client.custom_rest_api;
                    }
                } else if (data.product_type === ProductType.GroupClient) {
                    this.globalCacheVariable.artViewMode = ProductType.GroupClient;
                    this.globalCacheVariable.worldMode = false;
                    this.globalCacheVariable.currentARTWebClientGroupVersion = data.art_group_web_client.version;
                    document.title = data.art_group_web_client.title;
                    this.translationService.setLanguage(data.art_group_web_client.default_language);
                    this.globalCacheVariable.currentLanguage = data.art_group_web_client.default_language;
                    this.globalCacheVariable.allowUnsecureConnections = data.art_group_web_client.allow_unsecure_access;
                    if (data.art_group_web_client.custom_rest_api != '') {
                        this.globalCacheVariable.ARTGroupServerBaseIP = data.art_group_web_client.custom_rest_api;
                    }
                } else if (data.product_type === ProductType.GroupWebClientWorld) {
                    this.globalCacheVariable.artViewMode = ProductType.GroupClient;
                    this.globalCacheVariable.worldMode = true;
                    this.globalCacheVariable.currentARTWebClientGroupVersion = data.art_group_web_client.version;
                    document.title = data.art_group_web_client.title;
                    this.translationService.setLanguage(data.art_group_web_client.default_language);
                    this.globalCacheVariable.currentLanguage = data.art_group_web_client.default_language;
                    this.globalCacheVariable.allowUnsecureConnections = data.art_group_web_client.allow_unsecure_access;
                    if (data.art_group_web_client.custom_rest_api != '') {
                        this.globalCacheVariable.ARTGroupServerBaseIP = data.art_group_web_client.custom_rest_api;
                    }
                }

                if (location.protocol !== 'https:' && document.location.port !== "4200") {

                    if (data.allow_unsecure_connections == false) {
                        location.replace(`https:${location.href.substring(location.protocol.length)}`);
                    }

                }
            });

        this.globalCacheVariable.checkMobile();
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                Helpers.setLoading(true, this.translate.transform('BASE.LOADING.TITLE'));
                if (this.globalCacheVariable.isMobile) {
                    if (this.globalCacheVariable.sideNavbarMinimizedState) {
                        Helpers.bodyClass(this.globalBodyClassMobileClosedSidebar);
                    } else {
                        Helpers.bodyClass(this.globalBodyClassMobile);
                    }

                } else {
                    if (this.globalCacheVariable.sideNavbarMinimizedState) {
                        Helpers.bodyClass(this.globalBodyClassClosedSidebar);
                    } else {
                        Helpers.bodyClass(this.globalBodyClass);
                    }
                }

            }
            if (route instanceof NavigationEnd) {
                Helpers.setLoading(false);
            }
        });
    }


    showErrorDetailView() {
        this.visibleSidebarErrorDetail = true;
    }

    closeErrorDetailView() {
        this.visibleSidebarErrorDetail = false;
    }

    onRejectErrorDetailView() {
        this.closeErrorDetailView();
        this.messageService.clear('toastMessagesErrorModal');
    }

    copyToClipboard() {
        var body = document.body,
            range, sel;
        var element = document.getElementById('copyBody');
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            range.selectNodeContents(element);
            sel.addRange(range);
        }
        document.execCommand("Copy");
        GlobalCacheVariable.toast.next({ severity: ToastSeverity.Success, text: 'Copy to clipboard successful', title: 'Copy successful', life: 3000, type: MessageType.Toast });
    }

    ngAfterViewInit() {
        if (!this.globalCacheVariable.checkMobile()) {
            this.resizeObservable$ = fromEvent(window, 'resize');
            this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
                //console.log('event: ', evt);
                let event = evt;
                //console.log("width: -> ", event.currentTarget['innerWidth']);
                if (event.currentTarget['innerWidth'] < 992) {
                    if (this.globalCacheVariable.sideNavbarMinimizedState === false) {
                        $("body").addClass('m-brand--minimize m-aside-left--minimize');
                        $("#m_aside_left_offcanvas_toggle").addClass('m-brand__toggler--active');
                        this.globalCacheVariable.sideNavbarMinimizedState = true;
                    }
                }

                if (event.currentTarget['innerWidth'] > 992) {
                    if (this.globalCacheVariable.sideNavbarMinimizedState === true) {
                        /*
                        $("body").removeClass('m-brand--minimize m-aside-left--minimize');
                        $("#m_aside_left_offcanvas_toggle").removeClass('m-brand__toggler--active');
                        this.globalCacheVariable.sideNavbarMinimizedState = false;
                        */
                    }
                }
            });
        }

        for (let l = 0; l < this.globalCacheVariable.supportedLanguage.length; l++) {
            if (this.globalCacheVariable.supportedLanguage[l].value.lang === this.globalCacheVariable.currentLanguage) {
                this.globalCacheVariable.currentSelectedLanguage = this.globalCacheVariable.supportedLanguage[l].value;
                break;
            }
        }

        this._toastService.getMessage().subscribe(message => {
            if (message != null && message != undefined && message != '') {

                if (message.kind == 'toast') {
                    this.messageService.add({
                        key: 'toastMessages',
                        severity: message.type,
                        summary: message.title,
                        life: message.life,
                        detail: message.text,
                    });
                }
                else if (message.kind == 'modal') {
                    this.messageService.clear('toastMessagesModal');
                    this.messageService.add({
                        key: 'toastMessagesModal',
                        sticky: true,
                        severity: message.type,
                        summary: message.title,
                        life: message.life,
                        detail: message.text,
                    });
                }

                else if (message.kind == 'errorModal') {
                    this.messageService.clear('toastMessagesErrorModal');
                    this.messageService.add({
                        key: 'toastMessagesErrorModal',
                        sticky: true,
                        severity: message.type,
                        summary: message.title,
                        life: message.life,
                        detail: message.text,
                    });
                }
            }
        });

        this.globalCacheVariable.getToasts().subscribe(message => {
            if (message != null && message != undefined && message != '') {
                switch (message.type) {
                    case MessageType.Toast:
                        console.log("data in toast", message.data);
                        switch (message.severity) {
                            case ToastSeverity.Error:
                                this.messageService.add({
                                    key: 'toastMessages',
                                    sticky: false,
                                    severity: 'error',
                                    summary: message.title,
                                    life: message.life,
                                    detail: message.text,
                                    data: message.data,
                                });
                                break;

                            case ToastSeverity.Fatal:
                                this.messageService.add({
                                    key: 'toastMessages',
                                    sticky: true,
                                    severity: 'error',
                                    summary: message.data.statusText,
                                    life: message.life,
                                    detail: message.text,
                                    data: message.data,
                                });
                                break;

                            case ToastSeverity.Success:
                                this.messageService.add({
                                    key: 'toastMessages',
                                    sticky: false,
                                    severity: 'success',
                                    summary: message.title,
                                    life: message.life,
                                    detail: message.text,
                                    data: message.data,
                                });
                                break;

                            case ToastSeverity.Warning:
                                this.messageService.add({
                                    key: 'toastMessages',
                                    sticky: false,
                                    severity: 'warning',
                                    summary: message.title,
                                    life: message.life,
                                    detail: message.text,
                                    data: message.data,
                                });
                                break
                        }
                        break;
                    case MessageType.Modal:
                        this.messageService.clear('toastMessagesModal');
                        this.messageService.add({
                            key: 'toastMessagesModal',
                            sticky: true,
                            severity: message.type,
                            summary: message.title,
                            life: message.life,
                            detail: message.text,
                        });
                        break;

                    case MessageType.ErrorModal:
                        this.currentFatalMessage = message.data;
                        this.messageService.clear('toastMessagesErrorModal');
                        this.messageService.add({
                            key: 'toastMessagesErrorModal',
                            sticky: true,
                            severity: message.type,
                            summary: message.title,
                            life: message.life,
                            detail: message.text,
                        });
                        break;
                }
            }
        });

        //const container = document.querySelector('.m-body .m-wrapper');
        //const ps = new PerfectScrollbar(container);
    }

    ngOnDestroy() {
        this.resizeSubscription$.unsubscribe()
    }

}
