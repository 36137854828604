<!-- BEGIN: Header -->
<header class="m-grid__item    m-header" data-minimize-offset="200" data-minimize-mobile-offset="200" appunwraptag="">
    <div class="m-container m-container--fluid m-container--full-height">
        <div class="m-stack m-stack--ver m-stack--desktop">
            <!-- BEGIN: Brand -->
            <div class="m-stack__item m-brand  m-brand--skin-dark">
                <div class="m-stack m-stack--ver m-stack--general">
                    <div class="m-stack__item m-stack__item--middle m-brand__tools" (click)="toggleAsideNavbar()">
                        <!-- BEGIN: Left Aside Minimize Toggle -->
                        <a href="" id="m_aside_left_minimize_toggle"
                           class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block">
                            <i class="fa fa-bars"></i>
                        </a>
                        <!-- END -->
                        <!-- BEGIN: Responsive Aside Left Menu Toggler -->
                        <a href="" id="m_aside_left_offcanvas_toggle"
                           class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
                            <i class="fa fa-bars"></i>
                        </a>
                        <!-- END -->
                    </div>
                </div>
            </div>
            <!-- END: Brand -->
            <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                <!-- BEGIN: Horizontal Menu -->
                <div class="art-logo">
                    <a routerLink="/index" class="m-brand__logo-wrapper">
                        <img class="centerabsolute" src="../../assets/app/media/img/logos/art-logo.png" alt=""/>
                    </a>

                </div>
                <div class="view-site-header-title">
                    {{globalCacheVariable.currentSiteHeaderTitle}}
                </div>
                <button class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark"
                        id="m_aside_header_menu_mobile_close_btn">
                    <i class="la la-close"></i>
                </button>
                <div id="m_header_menu"
                     class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark">
                    <!--
                    <p-toolbar *ngIf="globalCacheVariable.groupWebClient()">
                        <div class="p-toolbar-group-left">
                            <span>{{'LOGS.ART_EVENT.FILTER.EVENT_TYPE.TITLE' | translate}}: </span>
                            <p-dropdown appendTo="body"  [options]="searchCategories"
                                        [(ngModel)]="globalCacheVariable.Search.selectedSearchId" filter="true">
                                <ng-template let-item pTemplate="selectedItem">
                                    <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
                                </ng-template>
                                <ng-template let-eventType pTemplate="item">
                                    <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                        <div style="font-size:14px;margin-top:4px">{{eventType.label}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <input type="text" pInputText name="selectedKeyword" id="selectedKeyword" required
                                   [(ngModel)]="globalCacheVariable.Search.selectedKeyword">
                            <button class="send-button" style="line-height: 24px; margin-left: 20px;"
                                    (click)="searchLoad()">{{'BASE.BUTTONS.SEARCH' | translate}}</button>
                        </div>
                    </p-toolbar>
                    -->

                </div>

                <!-- END: Horizontal Menu -->
                <!-- BEGIN: Topbar -->
                <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
                    <div class="m-stack__item m-topbar__nav-wrapper">
                        <ul class="m-topbar__nav m-nav m-nav--inline">

                            <ng-container *ngIf="globalCacheVariable.groupWebClient()">
                            <!-- BEGINN SEARCHBAR -->
                            <li class="m-nav__item m-dropdown m-dropdown--large m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width m-dropdown--skin-light	m-list-search m-list-search--skin-light"
                                data-dropdown-toggle="click" data-dropdown-persistent="true" id="m_quicksearch"
                                data-search-type="dropdown">
                                <a href="#" class="m-nav__link m-dropdown__toggle">
                                    <i class="la la-search"></i>
                                </a>
                                <div class="m-dropdown__wrapper">
                                    <span class="m-drop	down__arrow m-dropdown__arrow--center"></span>
                                    <div class="m-dropdown__inner">
                                        <div class="m-dropdown__header">
                                            <form class="m-list-search__form" id="searchForm">
                                                <div class="m-list-search__form-wrapper">
                                                                                <span
                                                                                    class="m-list-search__form-input-wrapper">

                                                                                    <input id="m_quicksearch_input"
                                                                                           (change)="searchLoad()"
                                                                                           autocomplete="off"
                                                                                           type="text"
                                                                                           name="q"
                                                                                           class="m-list-search__form-input"
                                                                                           value="{{globalCacheVariable.Search.selectedKeyword}}"
                                                                                           placeholder="Search... (min. 4 chars)">
                                                                                </span>
                                                    <span (click)="clearSearch()" class="m-list-search__form-icon-close"
                                                          id="m_quicksearch_empty" title="clear search results">
                                                                                    <i class="la la-trash"></i>
                                                                                </span>
                                                    <!--
                                                    <span (click)="closeSearch()" class="m-list-search__form-icon-close"
                                                          id="m_quicksearch_close" title="close">
                                                                                    <i class="la la-remove"></i>
                                                                                </span>
                                                                                -->
                                                </div>
                                            </form>
                                        </div>
                                        <div class="m-dropdown__body">
                                            <div class="m-dropdown__scrollable m-scrollable" data-scrollable="true"
                                                 data-max-height="300" data-mobile-max-height="200">
                                                <div class="m-dropdown__content">
                                                    <ng-container *ngIf="searchResults.arts.length > 0">
                                                        <div class="group-container-art">
                                                            <ng-container *ngIf="searchResults.arts.length == 1">
                                                                <h3>ART Installation</h3>
                                                            </ng-container>
                                                            <ng-container *ngIf="searchResults.arts.length > 1">
                                                                <h3>ART Installations ({{searchResults.arts.length}})</h3>
                                                            </ng-container>
                                                            <ng-container *ngFor="let art of searchResults.arts">
                                                                <div>
                                                                    <div style="cursor:pointer;" (click)="openArtDetails(art)">
                                                                        {{art.Name}}
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </ng-container>
                                                            <br/>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container *ngIf="searchResults.terminals.length > 0">
                                                        <div class="group-container-terminal">
                                                            <ng-container *ngIf="searchResults.terminals.length == 1">
                                                                <h3>Terminal</h3>
                                                            </ng-container>
                                                            <ng-container *ngIf="searchResults.terminals.length > 1">
                                                                <h3>Terminals ({{searchResults.terminals.length}})</h3>
                                                            </ng-container>
                                                            <ng-container *ngFor="let terminal of searchResults.terminals">
                                                                <div>
                                                                    <div style="cursor:pointer;" (click)="openTerminalDetails(terminal)">
                                                                        {{terminal.Name}}<br />
                                                                        {{terminal.ArtName}}
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </ng-container>

                                                            <br/>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="searchResults.properties.length > 0">
                                                        <div class="group-container-property">
                                                            <ng-container *ngIf="searchResults.properties.length == 1">
                                                                <h3>Property</h3>
                                                            </ng-container>
                                                            <ng-container *ngIf="searchResults.properties.length > 1">
                                                                <h3>Properties ({{searchResults.properties.length}})</h3>
                                                            </ng-container>
                                                            <ng-container *ngFor="let property of searchResults.properties">
                                                                <div>
                                                                    <div style="cursor:pointer;" (click)="openPropertyDetails(property)">
                                                                        {{property.Name}}<br />
                                                                        {{property.ArtName}}
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </ng-container>
                                                            <br/>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="searchResults.operators.length > 0">
                                                        <div class="group-container-operator">
                                                            <ng-container *ngIf="searchResults.operators.length == 1">
                                                                <h3>Operator</h3>
                                                            </ng-container>
                                                            <ng-container *ngIf="searchResults.operators.length > 1">
                                                                <h3>Operators ({{searchResults.operators.length}})</h3>
                                                            </ng-container>
                                                            <ng-container *ngFor="let operator of searchResults.operators">
                                                                <div>
                                                                    <div>
                                                                        {{operator.Name}}<br />
                                                                        {{operator.ArtName}}
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                            </ng-container>
                                                            <br/>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!-- END SEARCHBAR -->
                            </ng-container>
                            <li class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width"
                                data-dropdown-toggle="click" data-dropdown-persistent="true">
                                <a href="#" class="m-nav__link m-dropdown__toggle" id="m_topbar_notification_icon">
                                    <ng-container
                                        *ngIf="globalCacheVariable.notifications.Logs.length > 0 && globalCacheVariable.notifications.Logs.length < 10">
                                        <div class="notifications-counter-logs">
                                            <span
                                                class="centerrelative">{{globalCacheVariable.notifications.Logs.length}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="globalCacheVariable.notifications.Logs.length > 9 && globalCacheVariable.notifications.Logs.length < 100">
                                        <div class="notifications-counter-logs middle-size">
                                            <span
                                                class="centerrelative">{{globalCacheVariable.notifications.Logs.length}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="globalCacheVariable.notifications.Logs.length > 99">
                                        <div class="notifications-counter-logs bigger-size">
                                            <span
                                                class="centerrelative">{{globalCacheVariable.notifications.Logs.length}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="globalCacheVariable.notifications.Events.length > 0 && globalCacheVariable.notifications.Events.length < 100">
                                        <div class="notifications-counter">
                                            <span
                                                class="centerrelative">{{globalCacheVariable.notifications.Events.length}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="globalCacheVariable.notifications.Events.length > 99">
                                        <div class="notifications-counter bigger-size">
                                            <span
                                                class="centerrelative">{{globalCacheVariable.notifications.Events.length}}</span>
                                        </div>
                                    </ng-container>
                                    <span class="m-nav__link-icon">
                                        <i class="notification-icon la la-bell-o"></i>
                                    </span>
                                </a>
                                <div class="m-dropdown__wrapper">
                                    <div class="menu_arrow"></div>
                                    <span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
                                    <div class="m-dropdown__inner">
                                        <div class="m-dropdown__header m--align-center"
                                             style="background: url(./assets/app/media/img/misc/notification_bg.jpg); background-size: cover;">
                                            <span class="m-dropdown__header-title">
                                                {{globalCacheVariable.notifications.Count}} New
                                            </span>
                                            <span class="m-dropdown__header-subtitle notification">
                                                User Notifications
                                            </span>
                                        </div>
                                        <div class="m-dropdown__body">
                                            <div class="m-dropdown__content">
                                                <ul class="nav nav-tabs notification m-tabs m-tabs-line m-tabs-line--brand"
                                                    role="tablist">
                                                    <!--
                                                                                                        <li class="nav-item m-tabs__item">
                                                                                                            <a class="nav-link m-tabs__link" data-toggle="tab" href="#topbar_notifications_notifications" role="tab">
                                                                                                                Alerts
                                                                                                            </a>
                                                                                                        </li>
                                                    -->
                                                    <li class="nav-item m-tabs__item">
                                                        <a class="nav-link m-tabs__link active" data-toggle="tab"
                                                           href="#topbar_notifications_events" role="tab">
                                                            Events
                                                        </a>
                                                    </li>

                                                    <li class="nav-item m-tabs__item">
                                                        <a class="nav-link m-tabs__link" data-toggle="tab"
                                                           href="#topbar_notifications_logs" role="tab">
                                                            Requested Downloads
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content">
                                                    <!--
                                                    <div class="tab-pane" id="topbar_notifications_notifications"
                                                         role="tabpanel">
                                                        <div class="m-scrollable" data-scrollable="true"
                                                             data-max-height="250" data-mobile-max-height="200">
                                                            <div class="m-list-timeline m-list-timeline--skin-light">
                                                                <div class="m-list-timeline__items">
                                                                    <div class="m-list-timeline__item">
                                                                        <span
                                                                            class="m-list-timeline__badge -m-list-timeline__badge--state-success"></span>
                                                                        <span class="m-list-timeline__text">
                                                                            12 new users registered
                                                                        </span>
                                                                        <span class="m-list-timeline__time">
                                                                            Just now
                                                                        </span>
                                                                    </div>
                                                                    <div class="m-list-timeline__item">
                                                                        <span class="m-list-timeline__badge"></span>
                                                                        <span class="m-list-timeline__text">
                                                                            System shutdown
                                                                            <span
                                                                                class="m-badge m-badge--success m-badge--wide">
                                                                                pending
                                                                            </span>
                                                                        </span>
                                                                        <span class="m-list-timeline__time">
                                                                            14 mins
                                                                        </span>
                                                                    </div>
                                                                    <div class="m-list-timeline__item">
                                                                        <span class="m-list-timeline__badge"></span>
                                                                        <span class="m-list-timeline__text">
                                                                            New invoice received
                                                                        </span>
                                                                        <span class="m-list-timeline__time">
                                                                            20 mins
                                                                        </span>
                                                                    </div>
                                                                    <div class="m-list-timeline__item">
                                                                        <span class="m-list-timeline__badge"></span>
                                                                        <span class="m-list-timeline__text">
                                                                            DB overloaded 80%
                                                                            <span
                                                                                class="m-badge m-badge--info m-badge--wide">
                                                                                settled
                                                                            </span>
                                                                        </span>
                                                                        <span class="m-list-timeline__time">
                                                                            1 hr
                                                                        </span>
                                                                    </div>
                                                                    <div class="m-list-timeline__item">
                                                                        <span class="m-list-timeline__badge"></span>
                                                                        <span class="m-list-timeline__text">
                                                                            System error -
                                                                            <a href="#" class="m-link">
                                                                                Check
                                                                            </a>
                                                                        </span>
                                                                        <span class="m-list-timeline__time">
                                                                            2 hrs
                                                                        </span>
                                                                    </div>
                                                                    <div class="m-list-timeline__item">
                                                                        <span class="m-list-timeline__badge"></span>
                                                                        <span class="m-list-timeline__text">
                                                                            Production server down
                                                                        </span>
                                                                        <span class="m-list-timeline__time">
                                                                            3 hrs
                                                                        </span>
                                                                    </div>
                                                                    <div class="m-list-timeline__item">
                                                                        <span class="m-list-timeline__badge"></span>
                                                                        <span class="m-list-timeline__text">
                                                                            Production server up
                                                                        </span>
                                                                        <span class="m-list-timeline__time">
                                                                            5 hrs
                                                                        </span>
                                                                    </div>
                                                                    <div class="m-list-timeline__item">
                                                                        <span class="m-list-timeline__badge"></span>
                                                                        <span href="" class="m-list-timeline__text">
                                                                            New order received
                                                                            <span
                                                                                class="m-badge m-badge--danger m-badge--wide">
                                                                                urgent
                                                                            </span>
                                                                        </span>
                                                                        <span class="m-list-timeline__time">
                                                                            7 hrs
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    -->
                                                    <div class="tab-pane active" id="topbar_notifications_events"
                                                         role="tabpanel">
                                                        <div class="m-scrollable" data-scrollable="true"
                                                             data-max-height="250" data-mobile-max-height="200">
                                                            <ng-container
                                                                *ngIf="globalCacheVariable.notifications.Events.length < 1">

                                                                <div class="m-stack m-stack--ver m-stack--general"
                                                                     style="min-height: 180px;">
                                                                    <div
                                                                        class="m-stack__item m-stack__item--center m-stack__item--middle">
                                                                <span class="">
                                                                    All caught up!
                                                                    <br>
                                                                    No new events.
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="globalCacheVariable.notifications.Events.length > 0">

                                                                <div
                                                                    class="m-list-timeline m-list-timeline--skin-light">
                                                                    <div class="m-list-timeline__items">
                                                                        <ng-container
                                                                            *ngFor="let event of globalCacheVariable.notifications.Events let index = index;">
                                                                            <div class="m-list-timeline__item"
                                                                                 (click)="closeEventMessage(event, index)">
                                                                                <span
                                                                                    class="m-list-timeline__badge m-list-timeline__badge--state1-success">

                                                                                </span>
                                                                                <span class="m-list-timeline__text">
                                                                                    {{event.terminalName}}
                                                                                    ({{event.terminalID}})
                                                                                    <span
                                                                                        class="m-badge m-badge--success m-badge--wide-logs">
                                                                                        {{'ENUMS.TERMINAL_EVENT.' + (event.terminalEvent | terminalEvent) | rewriteEnumEntriesForTranslation : 'ENUMS.TERMINAL_EVENT.' | uppercase | translate }}
                                                                                    </span>
                                                                                </span>
                                                                                <span class="m-list-timeline__time">
                                                                                    {{event.timestampShown}}
                                                                                </span>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                                <div ><button  style="margin-top:10px;" class="send-button" (click)="closeEventMessages()">Clear Events</button></div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane" id="topbar_notifications_logs"
                                                         role="tabpanel">
                                                        <div class="m-scrollable" data-scrollable="true"
                                                             data-max-height="250" data-mobile-max-height="200">
                                                            <ng-container
                                                                *ngIf="globalCacheVariable.notifications.Logs.length < 1">

                                                                <div class="m-stack m-stack--ver m-stack--general"
                                                                     style="min-height: 180px;">
                                                                    <div
                                                                        class="m-stack__item m-stack__item--center m-stack__item--middle">
                                                                <span class="">
                                                                    All caught up!
                                                                    <br>
                                                                    No new logs.
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="globalCacheVariable.notifications.Logs.length > 0">

                                                                <div
                                                                    class="m-list-timeline m-list-timeline--skin-light">
                                                                    <div class="m-list-timeline__items">
                                                                        <ng-container
                                                                            *ngFor="let log of globalCacheVariable.notifications.Logs let index = index;">
                                                                            <div class="m-list-timeline__item"
                                                                                 (click)="openLogfiles(log, index)">
                                                                                <span
                                                                                    class="m-list-timeline__badge m-list-timeline__badge--state1-success">

                                                                                </span>
                                                                                <span class="m-list-timeline__text">
                                                                                    {{log.terminalName}}
                                                                                    ({{log.terminalID}})
                                                                                    <span
                                                                                        class="m-badge m-badge--success m-badge--wide-logs">
                                                                                        {{log.from | date : globalCacheVariable.globalFormatSettings.dateFormatMiddle}} - {{log.to | date : globalCacheVariable.globalFormatSettings.dateFormatMiddle}}
                                                                                    </span>
                                                                                </span>
                                                                                <span class="m-list-timeline__time">
                                                                                    {{log.timestampShown}}
                                                                                </span>
                                                                            </div>
                                                                        </ng-container>

                                                                    </div>
                                                                </div>

                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!--
                            <li class="m-nav__item m-topbar__quick-actions m-topbar__quick-actions--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push m-dropdown--mobile-full-width m-dropdown--skin-light"  data-dropdown-toggle="click">
                                <a href="#" class="m-nav__link m-dropdown__toggle">
                                    <span class="m-nav__link-badge m-badge m-badge--dot m-badge--info m--hide"></span>
                                    <span class="m-nav__link-icon">
                                        <i class="flaticon-share"></i>
                                    </span>
                                </a>
                                <div class="m-dropdown__wrapper">
                                    <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                                    <div class="m-dropdown__inner">
                                        <div class="m-dropdown__header m--align-center" style="background: url(./assets/app/media/img/misc/quick_actions_bg.jpg); background-size: cover;">
                                            <span class="m-dropdown__header-title">
                                                Quick Actions
                                            </span>
                                            <span class="m-dropdown__header-subtitle">
                                                Shortcuts
                                            </span>
                                        </div>
                                        <div class="m-dropdown__body m-dropdown__body--paddingless">
                                            <div class="m-dropdown__content">
                                                <div class="m-scrollable" data-scrollable="false" data-max-height="380" data-mobile-max-height="200">
                                                    <div class="m-nav-grid m-nav-grid--skin-light">
                                                        <div class="m-nav-grid__row">
                                                            <a href="#" class="m-nav-grid__item">
                                                                <i class="m-nav-grid__icon flaticon-file"></i>
                                                                <span class="m-nav-grid__text">
                                                                    Generate Report
                                                                </span>
                                                            </a>
                                                            <a href="#" class="m-nav-grid__item">
                                                                <i class="m-nav-grid__icon flaticon-time"></i>
                                                                <span class="m-nav-grid__text">
                                                                    Add New Event
                                                                </span>
                                                            </a>
                                                        </div>
                                                        <div class="m-nav-grid__row">
                                                            <a href="#" class="m-nav-grid__item">
                                                                <i class="m-nav-grid__icon flaticon-folder"></i>
                                                                <span class="m-nav-grid__text">
                                                                    Create New Task
                                                                </span>
                                                            </a>
                                                            <a href="#" class="m-nav-grid__item">
                                                                <i class="m-nav-grid__icon flaticon-clipboard"></i>
                                                                <span class="m-nav-grid__text">
                                                                    Completed Tasks
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            -->
                            <li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                                data-dropdown-toggle="click">
                                <a href="#" class="m-nav__link m-dropdown__toggle">
									<span class="m-topbar__username navigation-link">
										<ng-container *ngIf="globalCacheVariable.singleWebClient()">
                                            {{globalCacheVariable.currentOperator.OperatorName}}
                                        </ng-container>
                                        <ng-container *ngIf="globalCacheVariable.groupWebClient()">
                                            {{globalCacheVariable.currentOperator.FirstName}} {{globalCacheVariable.currentOperator.LastName}}
                                        </ng-container>
									</span>
                                    <span class="m-topbar__userpic navigation-link">
										<i class="fa fa-user-circle navigation-link"></i>
									</span>
                                </a>
                                <div class="m-dropdown__wrapper">
                                    <span
                                        class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                                    <div class="m-dropdown__inner">
                                        <div class="m-dropdown__header m--align-center"
                                             style="background: url(./assets/app/media/img/misc/user_profile_bg.jpg); background-size: cover;">
                                            <div class="m-card-user m-card-user--skin-dark">
                                                <div class="m-card-user__pic">
                                                    <i class="fa fa-user-circle"></i>
                                                    <!--<img src="./assets/app/media/img/users/user4.jpg" class="m--img-rounded m--marginless" alt=""/> -->
                                                </div>
                                                <div class="m-card-user__details">
													<span class="m-card-user__name m--font-weight-500">
														<ng-container *ngIf="globalCacheVariable.singleWebClient()">
                                                            {{globalCacheVariable.currentOperator.OperatorName}}
                                                        </ng-container>
                                                        <ng-container *ngIf="globalCacheVariable.groupWebClient()">
                                                            {{globalCacheVariable.currentOperator.FirstName}} {{globalCacheVariable.currentOperator.LastName}}
                                                        </ng-container>
													</span>
                                                    <a href="" routerLink="/user/profile"
                                                       class="m-card-user__email m--font-weight-300 m-link">
                                                        <i class="m-nav__link-icon pi pi-user-edit navigation-link"></i>
                                                        <span class="m-nav__link-text navigation-link">
																{{'USER.MENU.USER_PROFILE' | translate}}
															</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-dropdown__body">
                                            <div class="m-dropdown__content">
                                                <ul class="m-nav m-nav--skin-light">
                                                    <li class="m-nav__section m--hide">
														<span class="m-nav__section-text">
															Section
														</span>
                                                    </li>
                                                    <ng-container *ngIf="globalCacheVariable.singleWebClient()">
                                                        <li class="m-nav__item">
                                                            <a (click)="openCurrentOperator()" class="m-nav__link">
                                                                <i class="m-nav__link-icon pi pi-users navigation-link"></i>
                                                                <span class="m-nav__link-text navigation-link">
																{{'USER.MENU.CURRENT_USER_SESSIONS' | translate}}
															</span>
                                                            </a>
                                                        </li>
                                                    </ng-container>
                                                    <ng-container *ngIf="globalCacheVariable.groupWebClient()">
                                                        <li class="m-nav__item" *ngIf="isAllowedUserManagement">
                                                            <a (click)="openCurrentOperator()" class="m-nav__link">
                                                                <i class="m-nav__link-icon pi pi-users navigation-link"></i>
                                                                <span class="m-nav__link-text navigation-link">
																{{'USER.MENU.CURRENT_USER_SESSIONS' | translate}}
															</span>
                                                            </a>
                                                        </li>
                                                    </ng-container>

                                                    <!--
                                                    <li class="m-nav__item">
                                                        <a routerLink="/user/profile" class="m-nav__link">
                                                            <i class="m-nav__link-icon fa fa-user-circle"></i>
                                                            <span class="m-nav__link-text">
                                                                My Profile
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li class="m-nav__item">
                                                        <a routerLink="/user/messages" class="m-nav__link">
                                                            <i class="m-nav__link-icon fa fa-comments"></i>
                                                            <span class="m-nav__link-title">
                                                                <span class="m-nav__link-wrap">
                                                                    <span class="m-nav__link-text">
                                                                        Messages
                                                                    </span>
                                                                    <span class="m-nav__link-badge">
                                                                        <span class="m-badge m-badge--success">
                                                                            2
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </li>

                                                    <li class="m-nav__separator m-nav__separator--fit"></li>
                                                    <li class="m-nav__item">
                                                        <a routerLink="/header/profile" class="m-nav__link">
                                                            <i class="m-nav__link-icon fa fa-question-circle"></i>
                                                            <span class="m-nav__link-text">
                                                                FAQ
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li class="m-nav__item">
                                                        <a routerLink="/header/profile" class="m-nav__link">
                                                            <i class="m-nav__link-icon fa fa-life-ring"></i>
                                                            <span class="m-nav__link-text">
                                                                Support
                                                            </span>
                                                        </a>
                                                    </li>
                                                    -->
                                                    <li class="m-nav__item">
                                                        <a (click)="openArtAbout()" class="m-nav__link">
                                                            <i class="m-nav__link-icon pi pi-info navigation-link"></i>
                                                            <span class="m-nav__link-text navigation-link">
                                                                {{'USER.MENU.ART_ABOUT' | translate}}
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li class="m-nav__separator m-nav__separator--fit"></li>
                                                    <li class="m-nav__item">
                                                        <!--<p-button (click)="logout()"></p-button>-->

                                                        <a routerLink="/snippets/pages/user/login-1"
                                                           class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                                           routerLink="/logout">
                                                            <i class="m-nav__link-icon flaticon-logout navigation-link"></i>
                                                            <span class="m-nav__link-text navigation-link">
																{{'USER.MENU.LOGOUT' | translate}}
															</span>
                                                        </a>

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="m-nav__item help-container" title="help">
                                <div class="help centerrelative">
                                    <i class="centerrelative fa fa-question"></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- END: Topbar -->
            </div>
        </div>
    </div>
</header>
<p-dialog styleClass="current-operator-dialog" [closeOnEscape]="true" positionTop="150" [resizable]="false"
          [draggable]="false" [closable]="false" [(visible)]="displayCurrentOperatorSessionView"
          *ngIf="currentOperatorSessionView === true" [modal]="true" [style]="{width: '50vw'}">
    <p-header>{{'USER.MENU.CURRENT_USER_SESSIONS' | translate}}
        ({{globalCacheVariable.modal.currentOperatorSession.currentOperatorSessionCount}})
        <i class="close-button fa fa-times" (click)="currentOperatorSessionView=false"></i></p-header>
    <operator-current-session-list></operator-current-session-list>
</p-dialog>

<p-dialog styleClass="about-art-dialog" appendTo="body" [closeOnEscape]="true" [minX]="250" [minY]="250"
          [breakpoint]="250" [responsive]="true" [resizable]="false" [draggable]="false" [closable]="false"
          [(visible)]="displayArtAboutView" *ngIf="artAboutView === true" [modal]="true">
    <p-header>{{'USER.MENU.ART_ABOUT' | translate}}
        <i class="close-button fa fa-times" (click)="artAboutView=false"></i></p-header>
    <art-about></art-about>
</p-dialog>
<!-- END: Header -->

<art-installation-terminal-details
    *ngIf="artDetailsTerminalView === true"
    (closeTerminalDetails)='closeTerminalDetails()'
    [artInstallationDetails]="ARTInstallation"
    [currentTerminalDetails]="currentTerminalDetails"
    [currentTerminalDetailsId]="currentTerminalDetailsID">
</art-installation-terminal-details>

<art-installation-property-details
    *ngIf="artDetailsPropertyView === true"
    (closePropertyDetails)='closePropertyDetails()'
    [artInstallationDetails]="ARTInstallation"
    [currentPropertyDetails]="currentPropertyDetails"
    [currentPropertyDetailsId]="currentPropertyDetailsID">
</art-installation-property-details>

<ng-container *ngIf="artInstallationDetailsView === true">
    <art-installation-details (closeARTDetails)='closeARTDetails()'
                              [currentARTDetailsID]="currentARTDetailsID"></art-installation-details>
</ng-container>
<p-toast key="searchMessage" position="top-right"></p-toast>
