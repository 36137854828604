<div class="p-grid transaction-dialog-new" *ngIf="viewDetail">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="p-grid">
            <div class="p-col-12 p-md-6 p-lg-6">
                <p-card
                    header="{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TITLE' | translate}}">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <table style="width: 100%;">
                                <tbody>
                                <tr>
                                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TERMINAL' | translate}}
                                        :
                                    </td>
                                    <td class="tb-left">{{detailTransactionData.TerminalID | terminalName}}</td>
                                </tr>
                                <tr>
                                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TRANSACTION_TYPE' | translate}}
                                        :
                                    </td>
                                    <td class="tb-left">{{'ENUMS.TRANSACTION_TYPE.' + (detailTransactionData.TransactionType | transactionType) | rewriteEnumEntriesForTranslation : 'ENUMS.TRANSACTION_TYPE.'| uppercase | translate }}</td>

                                </tr>
                                <tr>
                                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.DATE' | translate}}:</td>
                                    <td class="tb-left">{{detailTransactionData.TransactionDate | date: globalCacheVariable.globalFormatSettings.dateFormatLong}}</td>
                                </tr>
                                <tr>
                                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TRANSACTION_NUMBER' | translate}}
                                        :
                                    </td>
                                    <td class="tb-left">{{detailTransactionData.TransactionNumber}}</td>
                                </tr>
                                <tr>
                                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TOTAL_MEDIA_IN' | translate}}
                                        :
                                    </td>
                                    <td class="tb-left">{{detailTransactionData.TotalIn | artCurrency:detailTransactionData.SystemCurrency:'code'}}</td>
                                </tr>
                                <tr>
                                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TOTAL_MEDIA_OUT' | translate}}
                                        :
                                    </td>
                                    <td class="tb-left">{{detailTransactionData.TotalOut | artCurrency:detailTransactionData.SystemCurrency:'code'}}</td>
                                </tr>
                                <tr>
                                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.OPERATOR' | translate}}
                                        :
                                    </td>
                                    <td class="tb-left">{{detailTransactionData.OperatorName}} </td>
                                </tr>
                                <tr>
                                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.FLAGS' | translate}}:
                                    </td>
                                    <ng-container *ngIf="entryPoint === 'SM'">
                                        <td class="tb-left">
                                            <div *ngFor="let flag of detailTransactionData.FlagArray"
                                                 class="transaction-flag-bubble {{flag.class}}"
                                                 pTooltip="{{'ENUMS.TRANSACTION_FLAG.' + (flag.name | flagName) | rewriteEnumEntriesForTranslation : 'ENUMS.TRANSACTION_FLAG.'| uppercase | translate }}"></div>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="entryPoint === 'DM'">
                                        <td class="tb-left">
                                            <div *ngFor="let flag of detailTransactionData.FlagArray"
                                                 class="transaction-flag-bubble {{flag.class}}"
                                                 title="{{'ENUMS.TRANSACTION_FLAG.' + (flag.name | flagName) | rewriteEnumEntriesForTranslation : 'ENUMS.TRANSACTION_FLAG.'| uppercase | translate }}"></div>
                                            <div #flagDiv>&nbsp;</div>
                                        </td>
                                    </ng-container>
                                </tr>
                                </tbody>
                            </table>
                            <p-toolbar>
                                <div class="p-toolbar-group-left">

                                </div>
                                <div class="p-toolbar-group-right">
                                    <button class="send-button" (click)="openLogfiles(detailTransactionData.TerminalID, detailTransactionData.TransactionDate)">{{'TERMINAL.CONTEXT_MENU.LOGFILES' | translate}}</button>
                                </div>
                            </p-toolbar>
                        </div>
                    </div>
                </p-card>

            </div>
            <div class="p-col-12 p-md-3 p-lg-3">
                <p-card
                    header="{{'LOGS.TRANSACTION.MODAL.EXCHANGE_RATE.TITLE' | translate}}">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <table style="width:100%;">
                                <thead>
                                <tr>
                                    <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.EXCHANGE_RATE.TABLE.SOURCE' | translate}}</th>
                                    <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.EXCHANGE_RATE.TABLE.TARGET' | translate}}</th>
                                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.EXCHANGE_RATE.TABLE.EXCHANGE_RATE' | translate}}</th>
                                </tr>
                                </thead>
                                <tbody
                                    *ngIf="(detailTransactionData.ExchangeRates !== undefined && detailTransactionData.ExchangeRates !== null)">
                                <tr *ngFor="let rates of detailTransactionData.ExchangeRates">
                                    <td class="tb-left">{{rates.Source}}</td>
                                    <td class="tb-left">{{rates.Target}}</td>
                                    <td class="tb-right">{{rates.Rate}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="p-col-12 p-md-3 p-lg-3">
                <p-card
                    header="{{'LOGS.TRANSACTION.MODAL.IMAGES' | translate}} ({{(currentShownTransactionPictureIndex +1)}}/{{transactionPicsNavigation.length}})">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <ng-container
                                *ngIf="transactionPicsNavigation === null || transactionPicsNavigation.length == 0 || transactionLogImageRights == false">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td><img style="width:100%;"
                                                 src="./assets/app/media/img/error/no-transaction-img.png" alt=""></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-container
                                *ngIf="transactionPicsNavigation != null && transactionPicsNavigation.length > 0 && transactionLogImageRights == true">
                                <table style="width: 100%;">
                                    <tbody>
                                    <tr>
                                        <td><img
                                            style="height: 280px; width: auto; max-height:280px; min-height:280px; cursor: zoom-in;"
                                            class="e2e-trusted-url"
                                            (click)="openImagesModal()"
                                            src="{{currentShownTransactionPicture.imageSrc}}" alt=""
                                            title="{{currentShownTransactionPicture.title}}"/></td>
                                    </tr>

                                    <div
                                        style="width:100px; text-align:center;display: flex; justify-content: space-between;">
                            <span *ngIf="currentShownTransactionPictureIndex == 0" title="No previous elements left"><div
                                style="width:45px; height: 45px;"
                                class="transaction-pic-previous"><i
                                style="font-size: 45px; color: var(--darkblue-color)!important;"
                                class="pi pi-angle-left"></i></div></span>
                                        <span *ngIf="currentShownTransactionPictureIndex > 0"
                                              (click)="showPreviousTransactionLogPicture()"
                                              title="{{previousTransactionPics.title}}"><div
                                            style="width:45px; height: 45px;"
                                            class="transaction-pic-previous"><i
                                            style="font-size: 45px; color: var(--darkblue-color)!important;"
                                            class="pi pi-angle-left"></i></div></span>
                                        <!-- <ng-container
                                             *ngFor="let transactionPics of transactionPicsNavigation; let i = index">
                                 <span (click)="showTransactionLogPicture(i, transactionPics)"
                                       title="{{transactionPics.title}}"><div
                                     class="{{transactionPics.class}}"></div></span>
                                         </ng-container> -->
                                        <span
                                            *ngIf="currentShownTransactionPictureIndex < transactionPicsNavigation.length-1"
                                            (click)="showNextTransactionLogPicture()"
                                            title="{{nextTransactionPics.title}}"><div
                                            class="transaction-pic-next" style="width:45px; height: 45px;"><i
                                            style="font-size: 45px; color: var(--darkblue-color)!important;"
                                            class="pi pi-angle-right"></i></div></span>
                                        <span
                                            *ngIf="currentShownTransactionPictureIndex === (transactionPicsNavigation.length-1)"
                                            title="No more elements left"><div class="transaction-pic-next"
                                                                               style="width:45px; height: 45px;"><i
                                            style="font-size: 45px; color: var(--darkblue-color)!important;"
                                            class="pi pi-angle-right"></i></div></span>
                                    </div>

                                    </tbody>
                                </table>
                            </ng-container>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6 p-lg-6">
                <p-card
                    header="{{'LOGS.TRANSACTION.MODAL.MEDIA_IN.TITLE' | translate}}">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <table style="width: 100%;">
                                <thead>
                                <tr>
                                    <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.MEDIA_IN.TABLE.MEDIUM' | translate}}</th>
                                    <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.MEDIA_IN.TABLE.MEDIA_NUMBER' | translate}}</th>
                                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.MEDIA_IN.TABLE.PIECES' | translate}}</th>
                                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.MEDIA_IN.TABLE.AMOUNT' | translate}}</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="detailTransactionData.MediaIn !== null">

                                <tr *ngFor="let mediaIn of detailTransactionData.MediaIn">
                                    <td class="tb-left" *ngIf="mediaIn.MediumType === 2 || mediaIn.MediumType === 1">
                                        {{'ENUMS.MEDIUM_TYPE.' + (mediaIn.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}
                                        <ng-container
                                            *ngIf="mediaIn.ForeignCurrencyCode != null && mediaIn.ForeignCurrencyCode != ''">
                                            {{mediaIn.ForeignAmount | artCurrency:mediaIn.ForeignCurrencyCode:'code'}}
                                        </ng-container>
                                        <ng-container
                                            *ngIf="mediaIn.ForeignCurrencyCode == null || mediaIn.ForeignCurrencyCode == ''">
                                            {{mediaIn.Amount | artCurrency:mediaIn.CurrencyCode:'code'}}
                                        </ng-container>

                                    </td>
                                    <td class="tb-left" *ngIf="mediaIn.MediumType !== 2 && mediaIn.MediumType !== 1">
                                        {{'ENUMS.MEDIUM_TYPE.' + (mediaIn.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}
                                    </td>
                                    <td class="tb-left">{{mediaIn.Identifier}}</td>
                                    <td class="tb-right">{{mediaIn.Pieces}}</td>
                                    <td class="tb-right" *ngIf="mediaIn.MediumType === 2 || mediaIn.MediumType === 1">
                                        {{(mediaIn.Amount * mediaIn.Pieces)| artCurrency:mediaIn.CurrencyCode:'code'}}
                                    </td>
                                    <td class="tb-right" *ngIf="mediaIn.MediumType !== 2 && mediaIn.MediumType !== 1">
                                        {{mediaIn.Amount | artCurrency:mediaIn.CurrencyCode:'code'}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="p-col-12 p-md-6 p-lg-6">
                <p-card
                    header="{{'LOGS.TRANSACTION.MODAL.MEDIA_OUT.TITLE' | translate}}">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <table style="width: 100%;">
                                <thead>
                                <tr>
                                    <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.MEDIA_OUT.TABLE.MEDIUM' | translate}}</th>
                                    <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.MEDIA_OUT.TABLE.MEDIA_NUMBER' | translate}}</th>
                                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.MEDIA_OUT.TABLE.PIECES' | translate}}</th>
                                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.MEDIA_OUT.TABLE.AMOUNT' | translate}}</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="detailTransactionData.MediaOut !== null">
                                <tr *ngFor="let mediaReject of detailTransactionData.MediaReject">

                                    <td class="tb-left"
                                        *ngIf="mediaReject.MediumType === 2 || mediaReject.MediumType === 1">
                                        {{'ENUMS.MEDIUM_TYPE.' + (mediaReject.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}
                                        <ng-container
                                            *ngIf="mediaReject.ForeignCurrencyCode != null && mediaReject.ForeignCurrencyCode != ''">
                                            {{mediaReject.ForeignAmount | artCurrency:mediaReject.ForeignCurrencyCode:'code'}}
                                        </ng-container>
                                        <ng-container
                                            *ngIf="mediaReject.ForeignCurrencyCode == null || mediaReject.ForeignCurrencyCode == ''">
                                            {{mediaReject.Amount | artCurrency:mediaReject.CurrencyCode:'code'}}
                                        </ng-container>
                                    </td>
                                    <td class="tb-left"
                                        *ngIf="mediaReject.MediumType !== 2 && mediaReject.MediumType !== 1">{{'ENUMS.MEDIUM_TYPE.' + (mediaReject.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}</td>
                                    <td class="tb-left">{{mediaReject.Identifier}}</td>
                                    <td class="tb-right">{{mediaReject.Pieces}}</td>
                                    <td class="tb-right"
                                        *ngIf="mediaReject.MediumType === 2 || mediaReject.MediumType === 1">{{(mediaReject.Amount * mediaReject.Pieces)| artCurrency:mediaReject.CurrencyCode:'code'}}</td>
                                    <td class="tb-right"
                                        *ngIf="mediaReject.MediumType !== 2 && mediaReject.MediumType !== 1">{{mediaReject.Amount | artCurrency:mediaReject.CurrencyCode:'code'}}</td>

                                </tr>

                                <tr *ngFor="let mediaOut of detailTransactionData.MediaOut">
                                    <td class="tb-left" *ngIf="mediaOut.MediumType === 2 || mediaOut.MediumType === 1">
                                        {{'ENUMS.MEDIUM_TYPE.' + (mediaOut.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}
                                        <ng-container
                                            *ngIf="mediaOut.ForeignCurrencyCode != null && mediaOut.ForeignCurrencyCode != ''">
                                            {{mediaOut.ForeignAmount | artCurrency:mediaOut.ForeignCurrencyCode:'code'}}
                                        </ng-container>
                                        <ng-container
                                            *ngIf="mediaOut.ForeignCurrencyCode == null || mediaOut.ForeignCurrencyCode == ''">
                                            {{mediaOut.Amount | artCurrency:mediaOut.CurrencyCode:'code'}}
                                        </ng-container>
                                    </td>
                                    <td class="tb-left" *ngIf="mediaOut.MediumType !== 2 && mediaOut.MediumType !== 1">
                                        {{'ENUMS.MEDIUM_TYPE.' + (mediaOut.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}</td>
                                    <td class="tb-left">{{mediaOut.Identifier}}</td>
                                    <td class="tb-right">{{mediaOut.Pieces}}</td>
                                    <td class="tb-right"
                                        *ngIf="mediaOut.MediumType === 2 || mediaOut.MediumType === 1">{{(mediaOut.Amount * mediaOut.Pieces)| artCurrency:mediaOut.CurrencyCode:'code'}}</td>
                                    <td class="tb-right"
                                        *ngIf="mediaOut.MediumType !== 2 && mediaOut.MediumType !== 1">{{mediaOut.Amount | artCurrency:mediaOut.CurrencyCode:'code'}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12">
                <p-card
                    header="{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.TITLE' | translate}}">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <table style="width: 100%;">
                                <thead>
                                <tr>
                                    <th style="width: 25%;"
                                        class="tb-left">{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.TABLE.TYPE' | translate}}</th>
                                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.TABLE.VALUE' | translate}}</th>
                                </tr>
                                </thead>

                                <tbody *ngIf="detailTransactionData.AdditionalInfos !== null">
                                <tr *ngFor="let additionalInfo of detailTransactionData.AdditionalInfos">
                                    <td class="tb-left">{{'ENUMS.INFO_TYPE.' + (additionalInfo.InfoType | infoType) | rewriteEnumEntriesForTranslation : 'ENUMS.INFO_TYPE.'| uppercase | translate }}</td>
                                    <td class="tb-right" *ngIf="additionalInfo.InfoType === 49"
                                        (click)="openAdditionalInformationModal(1, additionalInfo.AdditionalInfoStringAI, additionalInfo.AdditionalInfoStringData )"
                                        style="cursor:pointer;">{{additionalInfo.InfoString}}</td>
                                    <td class="tb-right" *ngIf="additionalInfo.InfoType === 50"
                                        (click)="openAdditionalInformationModal(2, additionalInfo.AdditionalInfoStringAI, additionalInfo.AdditionalInfoStringData)"
                                        style="cursor:pointer;">open POS Journal
                                    </td>
                                    <td class="tb-right"
                                        *ngIf="additionalInfo.InfoType !== 50 && additionalInfo.InfoType !== 49">{{additionalInfo.InfoString}}</td>
                                </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </div>
</div>

<!--
<div class="transaction-dialog" *ngIf="viewDetail">
    <div class="transaction-dialog-row">
        <div class="general-info">
            <h3>{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TITLE' | translate}}</h3>
            <table>
                <tbody>
                <tr>
                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TERMINAL' | translate}}:</td>
                    <td class="tb-left">{{detailTransactionData.TerminalID | terminalName}}</td>
                </tr>
                <tr>
                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TRANSACTION_TYPE' | translate}}:</td>
                    <td class="tb-left">{{'ENUMS.TRANSACTION_TYPE.' + (detailTransactionData.TransactionType | transactionType) | rewriteEnumEntriesForTranslation : 'ENUMS.TRANSACTION_TYPE.'| uppercase | translate }}</td>

                </tr>
                <tr>
                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.DATE' | translate}}:</td>
                    <td class="tb-left">{{detailTransactionData.TransactionDate | date: globalCacheVariable.globalFormatSettings.dateFormatLong}}</td>
                </tr>
                <tr>
                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TRANSACTION_NUMBER' | translate}}:</td>
                    <td class="tb-left">{{detailTransactionData.TransactionNumber}}</td>
                </tr>
                <tr>
                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TOTAL_MEDIA_IN' | translate}}:</td>
                    <td class="tb-left">{{detailTransactionData.TotalIn | artCurrency:detailTransactionData.SystemCurrency:'code'}}</td>
                </tr>
                <tr>
                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.TOTAL_MEDIA_OUT' | translate}}:</td>
                    <td class="tb-left">{{detailTransactionData.TotalOut | artCurrency:detailTransactionData.SystemCurrency:'code'}}</td>
                </tr>
                <tr>
                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.OPERATOR' | translate}}:</td>
                    <td class="tb-left">{{detailTransactionData.OperatorName}} </td>
                </tr>
                <tr>
                    <td class="tb-left">{{'LOGS.TRANSACTION.MODAL.GENERAL_INFO.FLAGS' | translate}}:</td>
                    <ng-container *ngIf="entryPoint === 'SM'">
                        <td class="tb-left">
                            <div *ngFor="let flag of detailTransactionData.FlagArray"
                                 class="transaction-flag-bubble {{flag.class}}"
                                 pTooltip="{{'ENUMS.TRANSACTION_FLAG.' + (flag.name | flagName) | rewriteEnumEntriesForTranslation : 'ENUMS.TRANSACTION_FLAG.'| uppercase | translate }}"></div>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="entryPoint === 'DM'">
                        <td class="tb-left">
                            <div *ngFor="let flag of detailTransactionData.FlagArray"
                                 class="transaction-flag-bubble {{flag.class}}"
                                 title="{{'ENUMS.TRANSACTION_FLAG.' + (flag.name | flagName) | rewriteEnumEntriesForTranslation : 'ENUMS.TRANSACTION_FLAG.'| uppercase | translate }}"></div>
                            <div #flagDiv>&nbsp;</div>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="media-info">
            <div class="exchange_rate">
                <h3>{{'LOGS.TRANSACTION.MODAL.EXCHANGE_RATE.TITLE' | translate}}</h3>
                <table>
                    <thead>
                    <tr>
                        <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.EXCHANGE_RATE.TABLE.SOURCE' | translate}}</th>
                        <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.EXCHANGE_RATE.TABLE.TARGET' | translate}}</th>
                        <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.EXCHANGE_RATE.TABLE.EXCHANGE_RATE' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody
                        *ngIf="(detailTransactionData.ExchangeRates !== undefined && detailTransactionData.ExchangeRates !== null)">
                    <tr *ngFor="let rates of detailTransactionData.ExchangeRates">
                        <td class="tb-left">{{rates.Source}}</td>
                        <td class="tb-left">{{rates.Target}}</td>
                        <td class="tb-right">{{rates.Rate}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="images" *ngIf="showTransactionPics">
                <h3>{{'LOGS.TRANSACTION.MODAL.IMAGES' | translate}}</h3>
                <ng-container *ngIf="transactionPicsNavigation === null || transactionPicsNavigation.length == 0">
                    <table>
                        <tbody>
                        <tr>
                            <td><img src="./assets/app/media/img/error/no-transaction-img.png" alt=""></td>
                        </tr>
                        </tbody>
                    </table>
                </ng-container>
                <ng-container *ngIf="transactionPicsNavigation != null && transactionPicsNavigation.length > 0">
                    <table>
                        <tbody>
                        <tr>
                            <td><img style="height: 280px; width: auto;" class="e2e-trusted-url"
                                     src="{{currentShownTransactionPicture.imageSrc}}" alt=""
                                     title="{{currentShownTransactionPicture.title}}"/></td>
                        </tr>

                        <div style="width:100%; text-align:center;display: flex; justify-content: space-between;">
                            <span *ngIf="currentShownTransactionPictureIndex == 0" title="No previous elements left"><div
                                class="transaction-pic-previous"><i style="color: var(--darkblue-color)!important;"
                                                                    class="pi pi-angle-left"></i></div></span>
                            <span *ngIf="currentShownTransactionPictureIndex > 0"
                                  (click)="showPreviousTransactionLogPicture()"
                                  title="{{previousTransactionPics.title}}"><div class="transaction-pic-previous"><i
                                style="color: var(--darkblue-color)!important;"
                                class="pi pi-angle-left"></i></div></span>
                            <ng-container *ngFor="let transactionPics of transactionPicsNavigation; let i = index">
                                <span (click)="showTransactionLogPicture(i, transactionPics)"
                                      title="{{transactionPics.title}}"><div
                                    class="{{transactionPics.class}}"></div></span>
                            </ng-container>
                            <span *ngIf="currentShownTransactionPictureIndex < transactionPicsNavigation.length-1"
                                  (click)="showNextTransactionLogPicture()" title="{{nextTransactionPics.title}}"><div
                                class="transaction-pic-next"><i style="color: var(--darkblue-color)!important;"
                                                                class="pi pi-angle-right"></i></div></span>
                            <span *ngIf="currentShownTransactionPictureIndex === (transactionPicsNavigation.length-1)"
                                  title="No more elements left"><div class="transaction-pic-next"><i
                                style="color: var(--darkblue-color)!important;"
                                class="pi pi-angle-right"></i></div></span>
                        </div>

                        </tbody>
                    </table>
                </ng-container>

            </div>
        </div>

    </div>

    <div class="transaction-dialog-row">
        <div class="media-in">
            <h3>{{'LOGS.TRANSACTION.MODAL.MEDIA_IN.TITLE' | translate}}</h3>
            <table>
                <thead>
                <tr>
                    <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.MEDIA_IN.TABLE.MEDIUM' | translate}}</th>
                    <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.MEDIA_IN.TABLE.MEDIA_NUMBER' | translate}}</th>
                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.MEDIA_IN.TABLE.PIECES' | translate}}</th>
                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.MEDIA_IN.TABLE.AMOUNT' | translate}}</th>
                </tr>
                </thead>
                <tbody *ngIf="detailTransactionData.MediaIn !== null">

                <tr *ngFor="let mediaIn of detailTransactionData.MediaIn">
                    <td class="tb-left" *ngIf="mediaIn.MediumType === 2 || mediaIn.MediumType === 1">
                        {{'ENUMS.MEDIUM_TYPE.' + (mediaIn.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}
                        <ng-container *ngIf="mediaIn.ForeignCurrencyCode != null && mediaIn.ForeignCurrencyCode != ''">
                            {{mediaIn.ForeignAmount | artCurrency:mediaIn.ForeignCurrencyCode:'code'}}
                        </ng-container>
                        <ng-container *ngIf="mediaIn.ForeignCurrencyCode == null || mediaIn.ForeignCurrencyCode == ''">
                            {{mediaIn.Amount | artCurrency:mediaIn.CurrencyCode:'code'}}
                        </ng-container>

                    </td>
                    <td class="tb-left" *ngIf="mediaIn.MediumType !== 2 && mediaIn.MediumType !== 1">
                        {{'ENUMS.MEDIUM_TYPE.' + (mediaIn.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}
                    </td>
                    <td class="tb-left">{{mediaIn.Identifier}}</td>
                    <td class="tb-right">{{mediaIn.Pieces}}</td>
                    <td class="tb-right" *ngIf="mediaIn.MediumType === 2 || mediaIn.MediumType === 1">
                        {{(mediaIn.Amount * mediaIn.Pieces)| artCurrency:mediaIn.CurrencyCode:'code'}}
                    </td>
                    <td class="tb-right" *ngIf="mediaIn.MediumType !== 2 && mediaIn.MediumType !== 1">
                        {{mediaIn.Amount | artCurrency:mediaIn.CurrencyCode:'code'}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="media-out">
            <h3>{{'LOGS.TRANSACTION.MODAL.MEDIA_OUT.TITLE' | translate}}</h3>
            <table>
                <thead>
                <tr>
                    <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.MEDIA_OUT.TABLE.MEDIUM' | translate}}</th>
                    <th class="tb-left">{{'LOGS.TRANSACTION.MODAL.MEDIA_OUT.TABLE.MEDIA_NUMBER' | translate}}</th>
                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.MEDIA_OUT.TABLE.PIECES' | translate}}</th>
                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.MEDIA_OUT.TABLE.AMOUNT' | translate}}</th>
                </tr>
                </thead>
                <tbody *ngIf="detailTransactionData.MediaOut !== null">
                <tr *ngFor="let mediaReject of detailTransactionData.MediaReject">

                    <td class="tb-left" *ngIf="mediaReject.MediumType === 2 || mediaReject.MediumType === 1">
                        {{'ENUMS.MEDIUM_TYPE.' + (mediaReject.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}
                        <ng-container
                            *ngIf="mediaReject.ForeignCurrencyCode != null && mediaReject.ForeignCurrencyCode != ''">
                            {{mediaReject.ForeignAmount | artCurrency:mediaReject.ForeignCurrencyCode:'code'}}
                        </ng-container>
                        <ng-container
                            *ngIf="mediaReject.ForeignCurrencyCode == null || mediaReject.ForeignCurrencyCode == ''">
                            {{mediaReject.Amount | artCurrency:mediaReject.CurrencyCode:'code'}}
                        </ng-container>
                    </td>
                    <td class="tb-left"
                        *ngIf="mediaReject.MediumType !== 2 && mediaReject.MediumType !== 1">{{'ENUMS.MEDIUM_TYPE.' + (mediaReject.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}</td>
                    <td class="tb-left">{{mediaReject.Identifier}}</td>
                    <td class="tb-right">{{mediaReject.Pieces}}</td>
                    <td class="tb-right"
                        *ngIf="mediaReject.MediumType === 2 || mediaReject.MediumType === 1">{{(mediaReject.Amount * mediaReject.Pieces)| artCurrency:mediaReject.CurrencyCode:'code'}}</td>
                    <td class="tb-right"
                        *ngIf="mediaReject.MediumType !== 2 && mediaReject.MediumType !== 1">{{mediaReject.Amount | artCurrency:mediaReject.CurrencyCode:'code'}}</td>

                </tr>

                <tr *ngFor="let mediaOut of detailTransactionData.MediaOut">
                    <td class="tb-left" *ngIf="mediaOut.MediumType === 2 || mediaOut.MediumType === 1">
                        {{'ENUMS.MEDIUM_TYPE.' + (mediaOut.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}
                        <ng-container
                            *ngIf="mediaOut.ForeignCurrencyCode != null && mediaOut.ForeignCurrencyCode != ''">
                            {{mediaOut.ForeignAmount | artCurrency:mediaOut.ForeignCurrencyCode:'code'}}
                        </ng-container>
                        <ng-container
                            *ngIf="mediaOut.ForeignCurrencyCode == null || mediaOut.ForeignCurrencyCode == ''">
                            {{mediaOut.Amount | artCurrency:mediaOut.CurrencyCode:'code'}}
                        </ng-container>
                    </td>
                    <td class="tb-left" *ngIf="mediaOut.MediumType !== 2 && mediaOut.MediumType !== 1">
                        {{'ENUMS.MEDIUM_TYPE.' + (mediaOut.MediumType | mediumType) | rewriteEnumEntriesForTranslation : 'ENUMS.MEDIUM_TYPE.'| uppercase | translate }}</td>
                    <td class="tb-left">{{mediaOut.Identifier}}</td>
                    <td class="tb-right">{{mediaOut.Pieces}}</td>
                    <td class="tb-right"
                        *ngIf="mediaOut.MediumType === 2 || mediaOut.MediumType === 1">{{(mediaOut.Amount * mediaOut.Pieces)| artCurrency:mediaOut.CurrencyCode:'code'}}</td>
                    <td class="tb-right"
                        *ngIf="mediaOut.MediumType !== 2 && mediaOut.MediumType !== 1">{{mediaOut.Amount | artCurrency:mediaOut.CurrencyCode:'code'}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="transaction-dialog-row">
        <div class="additional-info">
            <h3>{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.TITLE' | translate}}</h3>

            <table>
                <thead>
                <tr>
                    <th style="width: 25%;"
                        class="tb-left">{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.TABLE.TYPE' | translate}}</th>
                    <th class="tb-right">{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.TABLE.VALUE' | translate}}</th>
                </tr>
                </thead>

                <tbody *ngIf="detailTransactionData.AdditionalInfos !== null">
                <tr *ngFor="let additionalInfo of detailTransactionData.AdditionalInfos">
                    <td class="tb-left">{{'ENUMS.INFO_TYPE.' + (additionalInfo.InfoType | infoType) | rewriteEnumEntriesForTranslation : 'ENUMS.INFO_TYPE.'| uppercase | translate }}</td>
                    <td class="tb-right">{{additionalInfo.InfoValue}}</td>
                </tr>
                </tbody>

            </table>
        </div>
    </div>
</div>
-->
<p-dialog styleClass="transaction-dialog-view" [closeOnEscape]="true" [resizable]="false" [draggable]="false"
          [closable]="false" [(visible)]="displayTransactionLogDetail"
          *ngIf="transactionLogDetailAdditionalInfoView === true"
          [modal]="true">
    <p-header>Additional Information for Terminal '{{detailTransactionData.TerminalID | terminalName}}' and
        TransactionNr. '{{transactionID}}'
        <i class="close-button fa fa-times" (click)="closeAdditionalInformationModal()"></i></p-header>
    <ng-container *ngIf="currentAdditionalViewState === 1">
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12">

                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="p-grid">
                            <div class="p-col-6 p-md-6 p-lg-6">
                                <div class="p-grid">
                                    <div class="p-col-6 p-md-6 p-lg-6">
                                        <div>{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.MODAL.GIVEN_NAME' | translate}}</div>
                                        <div>{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.MODAL.SURNAME' | translate}}</div>
                                        <div>{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.MODAL.GENDER' | translate}}</div>
                                        <div>{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.MODAL.NATIONALITY' | translate}}</div>
                                        <div>{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.MODAL.I_D_TYPE' | translate}}</div>
                                        <div>{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.MODAL.I_D_NUMBER' | translate}}</div>
                                        <div>{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.MODAL.BIRTHDAY' | translate}}</div>
                                        <div>{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.MODAL.MEDIA_NUMBER' | translate}}</div>
                                        <div>{{'LOGS.TRANSACTION.MODAL.ADDITIONAL_INFO.MODAL.STATUS' | translate}}</div>
                                    </div>
                                    <div class="p-col-6 p-md-6 p-lg-6">
                                        <div>{{currentPlayerInformationForAdditional.GivenName}}&nbsp;</div>
                                        <div>{{currentPlayerInformationForAdditional.Surname}}&nbsp;</div>
                                        <div>{{currentPlayerInformationForAdditional.Gender}}&nbsp;</div>
                                        <div>{{currentPlayerInformationForAdditional.Nationality}}&nbsp;</div>
                                        <div>{{currentPlayerInformationForAdditional.IDType}}&nbsp;</div>
                                        <div>{{currentPlayerInformationForAdditional.IDNumber}}&nbsp;</div>
                                        <div>{{currentPlayerInformationForAdditional.Birthday}}&nbsp;</div>
                                        <div>{{currentPlayerInformationForAdditional.MediaNumber}}&nbsp;</div>
                                        <div>{{currentPlayerInformationForAdditional.Status}}&nbsp;</div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-col-6 p-md-6 p-lg-6">
                                <div>
                                    <!-- PIC -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="currentAdditionalViewState === 2">
        {{currentPOSJournalForAdditional}}
    </ng-container>
    <!-- content here -->


</p-dialog>

<p-dialog styleClass="transaction-dialog-view dialog-with-footer" [closeOnEscape]="true" [resizable]="false"
          [draggable]="false" [style]="{width: '100vw', 'min-height': '100vh'}"
          (onShow)="showDialogMaximized($event,transactionDetailImagesDialog)" #transactionDetailImagesDialog
          [closable]="false" [(visible)]="displayTransactionLogDetailImages"
          *ngIf="transactionLogDetailImagesView === true"
          [modal]="true">
    <p-header>Images for Terminal '{{detailTransactionData.TerminalID | terminalName}}' and
        TransactionNr. '{{transactionID}}'
        <i class="close-button fa fa-times" (click)="closeImagesModal()"></i></p-header>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12">
                <ng-container
                    *ngIf="transactionPicsNavigation === null || transactionPicsNavigation.length == 0">
                    <table>
                        <tbody>
                        <tr>
                            <td><img style="width:100%;"
                                     src="./assets/app/media/img/error/no-transaction-img.png" alt=""></td>
                        </tr>
                        </tbody>
                    </table>
                </ng-container>
                <ng-container
                    *ngIf="transactionPicsNavigation != null && transactionPicsNavigation.length > 0">
                   <div style="text-align:center">
                    <img style="height: 400px; width: auto; max-height:600px; min-height:280px;"
                                     class="e2e-trusted-url"
                                     src="{{currentShownTransactionPicture.imageSrc}}" alt=""
                                     title="{{currentShownTransactionPicture.title}}"/>
                   </div>

                        <!--
                        <div
                            style="width:100px; text-align:center;display: flex; justify-content: space-between;">
                            <span *ngIf="currentShownTransactionPictureIndex == 0" title="No previous elements left"><div
                                style="width:45px; height: 45px;"
                                class="transaction-pic-previous"><i
                                style="font-size: 45px; color: var(--darkblue-color)!important;"
                                class="pi pi-angle-left"></i></div></span>
                            <span *ngIf="currentShownTransactionPictureIndex > 0"
                                  (click)="showPreviousTransactionLogPicture()"
                                  title="{{previousTransactionPics.title}}"><div style="width:45px; height: 45px;"
                                                                                 class="transaction-pic-previous"><i
                                style="font-size: 45px; color: var(--darkblue-color)!important;"
                                class="pi pi-angle-left"></i></div></span>
                                -->
                            <!-- <ng-container
                                 *ngFor="let transactionPics of transactionPicsNavigation; let i = index">
                     <span (click)="showTransactionLogPicture(i, transactionPics)"
                           title="{{transactionPics.title}}"><div
                         class="{{transactionPics.class}}"></div></span>
                             </ng-container> -->
                        <!--
                            <span
                                *ngIf="currentShownTransactionPictureIndex < transactionPicsNavigation.length-1"
                                (click)="showNextTransactionLogPicture()"
                                title="{{nextTransactionPics.title}}"><div
                                class="transaction-pic-next" style="width:45px; height: 45px;"><i
                                style="font-size: 45px; color: var(--darkblue-color)!important;"
                                class="pi pi-angle-right"></i></div></span>
                            <span
                                *ngIf="currentShownTransactionPictureIndex === (transactionPicsNavigation.length-1)"
                                title="No more elements left"><div class="transaction-pic-next"
                                                                   style="width:45px; height: 45px;"><i
                                style="font-size: 45px; color: var(--darkblue-color)!important;"
                                class="pi pi-angle-right"></i></div></span>

                        </div>
                        -->

                </ng-container>
            </div>
        </div>
    <p-footer>
        <p-toolbar>
            <div class="p-toolbar-group-left">
                <button *ngIf="currentShownTransactionPictureIndex == 0" type="button" class="send-button" pButton
                        icon="pi pi-angle-left"
                        title="first picture"
                        disabled style="cursor:default!important;"
                        label="{{'MANAGEMENT.JACKPOT_PAYOUTS.BUTTONS.BACK' | translate}}"></button>
                <button *ngIf="currentShownTransactionPictureIndex > 0" type="button" class="send-button" pButton
                        icon="pi pi-angle-left"
                        (click)="showPreviousTransactionLogPicture()"
                        label="{{'MANAGEMENT.JACKPOT_PAYOUTS.BUTTONS.BACK' | translate}}"></button>
                <button *ngIf="currentShownTransactionPictureIndex < transactionPicsNavigation.length-1" type="button"
                        class="send-button" pButton icon="pi pi-angle-right"
                        (click)="showNextTransactionLogPicture()"
                        label="{{'MANAGEMENT.JACKPOT_PAYOUTS.BUTTONS.NEXT' | translate}}"></button>
                <button *ngIf="currentShownTransactionPictureIndex === (transactionPicsNavigation.length-1)"
                        type="button" class="send-button" pButton icon="pi pi-angle-right"
                        title="last picture"
                        disabled style="cursor:default!important;"
                        label="{{'MANAGEMENT.JACKPOT_PAYOUTS.BUTTONS.NEXT' | translate}}"></button>
                ({{(currentShownTransactionPictureIndex +1)}}/{{transactionPicsNavigation.length}})
            </div>
            <div class="p-toolbar-group-right">
                <button type="button" class="send-button" pButton icon="fa fa-times"
                        (click)="closeImagesModal()"
                        label="{{'MANAGEMENT.JACKPOT_PAYOUTS.BUTTONS.CLOSE' | translate}}"></button>
            </div>
        </p-toolbar>
    </p-footer>
</p-dialog>

<p-dialog styleClass="terminal-logfiles-dialog-view" [closeOnEscape]="true"
          [responsive]="true" [resizable]="false" [draggable]="false" [closable]="false"
          [(visible)]="displayLogfilesView"
          appendTo="body"
          *ngIf="logfilesView === true" [modal]="true">
    <p-header>{{currentTerminalName}} - {{'TERMINAL.GENERAL.REQUEST_LOG_FILES' | translate}}
    </p-header>
    <div class="p-grid">
        <div class="p-col-12 p-md-12">
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-12">
                        {{'TERMINAL.LOGFILES.MODAL.TIMESPAN' | translate}}
                    </div>
                    <div class="p-col-12">
                        <span style=" margin-left: 10px;">{{'BASE.FILTER.FROM' | translate}}: </span>
                        <p-calendar showTime="true" [style]="{marginLeft: '5px'}" styleClass="calendar-input-field" hourFormat="24"
                                    dateFormat="{{globalCacheVariable.DefaultTimeFormatPNG}}" name="dateFrom" required
                                    stepMinute="60"
                                    appendTo="body"
                                    [(ngModel)]="dateFromLogfiles" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-calendar>
                        <span style=" margin-left: 10px;">{{'BASE.FILTER.TO' | translate}}: </span>
                        <p-calendar showTime="true" [style]="{marginLeft: '5px'}" styleClass="calendar-input-field" hourFormat="24"
                                    dateFormat="{{globalCacheVariable.DefaultTimeFormatPNG}}" name="dateTo" required
                                    appendTo="body"
                                    stepMinute="60"
                                    [(ngModel)]="dateToLogfiles" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-calendar>
                    </div>
                </div>
                <p-toolbar>
                    <div class="p-toolbar-group-left">

                    </div>
                    <div class="p-toolbar-group-right">
                        <button class="send-button" (click)="cancelLogfiles()">Cancel</button>
                        <button class="send-button" (click)="requestLogfiles(currentTerminalID)">Send Request</button>
                    </div>
                </p-toolbar>
            </div>
        </div>
    </div>
</p-dialog>
