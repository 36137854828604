import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalCacheVariable } from '../_globals/globals';
import { Utils } from "../_helpers/utils";
import { catchError, map } from 'rxjs/operators';
import { ApiDataConverter } from "../_helpers/api-data-converter";
import { ApiCallType } from "../_enums/apiCallType";
import { throwError as observableThrowError } from "rxjs/internal/observable/throwError";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class BalancingService {

    constructor(
        private http: HttpClient,
        public cache: GlobalCacheVariable) {
    }

    private static extractBridge(res, apiType) {
        let response = ApiDataConverter.extractData(res, apiType);
        return response || {};
    }

    public handleError(errorMessage: any, apiType) {
        ApiDataConverter.errorHandling(errorMessage, apiType);
        return observableThrowError(errorMessage);
    }

    UpdateMoneyService(MoneyServiceEntry) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                moneyServiceEntry: MoneyServiceEntry,
            };
            return this.http.post(this.cache.ARTApi + 'UpdateMoneyService', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.UpdateMoneyService);
                    }),
                    map(data => {
                        return BalancingService.extractBridge(data, ApiCallType.UpdateMoneyService);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    GetBalanceReportTypes() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetBalanceReportTypes', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetBalanceReportTypes);
                    }),
                    map(data => {
                        return BalancingService.extractBridge(data, ApiCallType.GetBalanceReportTypes);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    GetMoneyServices(terminalID) {
        try {
            let terminalIDsCache = 0;
            if (terminalID === 'all' || terminalID === 0) {

            } else {
                terminalIDsCache = terminalID
            }

            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetMoneyServices', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetMoneyServices);
                    }),
                    map(data => {
                        return BalancingService.extractBridge(data, ApiCallType.GetMoneyServices);
                    }));
        } catch (error) {
            console.log(error);
        }

    }

    GetBalanceSnapshots(terminalID, date) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                date: date,
            };
            return this.http.post(this.cache.ARTApi + 'GetBalanceSnapshots', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetBalanceSnapshots);
                    }),
                    map(data => {
                        return BalancingService.extractBridge(data, ApiCallType.GetBalanceSnapshots);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    GetBalanceSnapshotsForReportType(terminalID, reportType, endDate) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                reportType: reportType,
                endDate: endDate
            };
            return this.http.post(this.cache.ARTApi + 'GetBalanceSnapshotsForReportType', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetBalanceSnapshotsForReportType);
                    }),
                    map(data => {
                        return BalancingService.extractBridge(data, ApiCallType.GetBalanceSnapshotsForReportType);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    GetBalancingReport(terminalID: number, snapshotStart: number, snapshotEnd: number) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                snapshotStartID: snapshotStart,
                snapshotEndID: snapshotEnd
            };
            return this.http.post(this.cache.ARTApi + 'GetBalancingReport', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetBalancingReport);
                    }),
                    map(data => {
                        return BalancingService.extractBridge(data, ApiCallType.GetBalancingReport);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    GetBalancingReportForMoneyService(moneyService) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                moneyService: moneyService,
            };
            return this.http.post(this.cache.ARTApi + 'GetBalancingReportForMoneyService', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetBalancingReportForMoneyService);
                    }),
                    map(data => {
                        return BalancingService.extractBridge(data, ApiCallType.GetBalancingReportForMoneyService);
                    }));
        } catch (error) {
            console.log(error);
        }
    }
}
