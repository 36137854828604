export enum StockMediumType {
    None = 0,
    Coin = 1,
    Bill = 2,
    Ticket = 3,
    MagneticCard = 4,
    SmartCard = 5,
    Deposit = 6,
    Chip = 7,
    Coupon = 8,
    Marker = 9,

    Reject = 62,

    RoundingTickets = 71,
    RoundingPlayerCards = 72,
    RoundingBonusCards = 73,
    RoundingJackpots = 74,
    RoundingBills = 75,

    CardBinCount = 100,
    CardStackerCount = 101,
    CurrentSam = 102,
    CurrentDeposit = 104,

    ManuallyCountedChips = 105,

    BillInTotal = 107,
    BillOutTotal = 108,

    DepositWithheld = 109,
    DepositPaid = 110,
}
