import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services/terminal.service';
import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';
import { LoggingService } from '../../../../../../auth/_services/log.service';
import { MediaLogListItem } from '../../../../../../auth/_models/medialoglistitem';
import * as moment from "moment";
import { AcceptorMedium } from "../../../../../../auth/_enums/acceptorMedium";
import { GeneralRewritePipe } from "../../../../../../auth/_pipes/generalRewrite.pipe";

@Component({
    selector: "terminal-single-details-media-log",
    templateUrl: "./terminal-single-details-media-log.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [GeneralRewritePipe]
})

export class TerminalSingleDetailsMediaLogComponent implements OnInit, AfterViewInit, OnChanges {

    @Input('terminalID') terminalID: number;

    mediaLog: any;
    paginatorPage: number = 1;
    paginatorRowsPerPage: number = 20;
    mediaLogView: boolean = false;

    constructor(
        private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        private loggingService: LoggingService,
        public globalCacheVariable: GlobalCacheVariable,
        private zone: NgZone,
        private generalRewrite: GeneralRewritePipe,
    ) {
        this.mediaLog = new Array<MediaLogListItem>();
    }

    getMediaLogData() {
        let startDate = moment().subtract(1000, "days");
        let endDate = moment();
        this.loggingService.getRecentMediaLogs(this.terminalID, 10)
            .subscribe(data => {
                this.zone.run(() => {
                    for (let media of data) {
                        data.MediumType = this.generalRewrite.enumTranslation(AcceptorMedium, media.MediumType, 'ENUMS.MEDIUM_TYPE.');
                    }
                    this.mediaLog = data;
                    this.mediaLogView = true;
                });
            });
    }

    ngOnInit(): void {
        this.getMediaLogData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getMediaLogData();
        }
    }

    ngAfterViewInit(): void {
        // this.getTerminalIdentifierList();
    }
}
