import { Injectable } from "@angular/core";
import { GlobalCacheVariable } from '../_globals/globals';

@Injectable()
export class DebugService {

    constructor(public globalCacheVariable: GlobalCacheVariable) {
    }

    logging(severity = null, message = null, params = null) {
        let currentLogState = this.globalCacheVariable.logState;
        if (currentLogState) {
            //trace, debug, info, warn, error, fatal
            let currentLogLevel = this.globalCacheVariable.logLevel;

            switch (severity) {
                case 'trace':
                    if (currentLogLevel <= 0) {
                        console.log(message, params);
                    }
                    break;
                case 'debug':
                    if (currentLogLevel <= 1) {
                        console.log(message, params);
                    }
                    break;
                case 'info':
                    if (currentLogLevel <= 2) {
                        console.log(message, params);
                    }
                    break;
                case 'warn':
                    if (currentLogLevel <= 3) {
                        console.warn(message, params);
                    }
                    break;
                case 'error':
                    if (currentLogLevel <= 4) {
                        console.error(message, params);
                    }
                    break;
                case 'fatal':
                    if (currentLogLevel <= 5) {
                        console.error(message, params);
                    }
                    break;
            }
        }
    }
}

