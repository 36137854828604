<p-table [value]="mediaLog" [rows]="paginatorRowsPerPage" [scrollable]="true" [style]="{width:'auto'}" class="media-log-table content-table" scrollHeight="flex" *ngIf="mediaLogView">
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width:150px">
            <col style="width:100px">
            <col style="width:100px">
            <col style="width:100px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th class="tb-left">{{'BASE.DATE' | translate}}</th>
            <th class="tb-right">{{'LOGS.MEDIA.TABLE.TRANSACTION_NUMBER' | translate}}</th>
            <th class="tb-right">{{'BASE.AMOUNT' | translate}}</th>
            <th class="tb-right">{{'BASE.CURRENCY' | translate}}</th>
            <th class="tb-left">{{'LOGS.MEDIA.TABLE.MEDIA' | translate}}</th>
            <th class="tb-right">{{'LOGS.MEDIA.TABLE.MEDIA_NUMBER' | translate}}</th>
            <th class="tb-left">{{'LOGS.MEDIA.TABLE.DIRECTION' | translate}}</th>
            <th class="tb-left">{{'BASE.STATE' | translate}}</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-log>
        <tr>
            <td class="tb-left">{{log.EntryDate | date: globalCacheVariable.globalFormatSettings.dateFormatLong}}</td>
            <td class="tb-right">{{log.TransactionNumber}}</td>
            <td class="tb-right">{{log.Amount | artCurrency:log.Currency:'code'}}</td>
            <td class="tb-right">{{log.Currency}}</td>
            <td class="tb-left">{{log.MediumType}}</td>
            <td class="tb-right">{{log.MediumNumber}}</td>
            <td class="tb-left">{{log.Direction | mediaDirection}}</td>
            <td class="tb-left">{{'LOGS.MEDIA.MEDIUM_ACTION.'+(log.State | mediumAction) | rewriteEnumEntriesForTranslation : 'LOGS.MEDIA.MEDIUM_ACTION.'| uppercase | translate }}</td>

        </tr>
    </ng-template>

</p-table>
