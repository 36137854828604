export enum InfoType {
    // ATM
    AtmRbsLynkSequenceNumber = 0,
    AtmRbsLynkDateTime = 1,
    AtmCurrentBankTermID = 3,
    AtmCurrentBankTransNumber = 7,
    AtmCurrentBankSequenceNumber = 8,
    // used because for RBS Lynk terminals act as a single ATM and
    // ATM Proxy needs to differentiate their transactions
    TerminalSequenceNumber = 9,

    // Slot Area
    SlotNumber = 4,

    // Jackpot transaction number
    JackpotTransactionNumber = 5,
    // Aristocrat Technologies Inc. Jackpot Fill ID
    AtiJackpotFillID = 21,
    // Aristocrat Technologies Inc. Jackpot Ticket Number
    AtiJackpotTicketNumber = 22,

    // Certegy E-Connect
    CecTransactionID = 31,

    // displayed in Transaction Log in the 'Online System #' column
    DevSITrackingNr = 6,

    // Cards
    CardsToStacker = 10,
    CardsToBin = 11,
    CardsFromStacker = 12,
    CardsFromBin = 13,

    // Tickets
    TicketOutMediaNumber = 16,
    TicketInMediaNumber = 17,

    // Warning
    Warning = 20,

    // POS Card Limits
    CreditCardHolder = 41,

    // Pin Alerts
    PinAlert = 42,

    // Transaction Approvals
    ApprovingOperatorID = 43,

    // CashIO Titanium Dual Bill Acceptor
    BillAcceptorRank = 44,

    // Hopper Empty Mode -> Kiosk Reports > Cash Out Report
    HopperEmptyMode = 45,

    // (Progressive) Jackpot Name
    JackpotName = 46,

    // Terminal Information
    TerminalInfo = 47,

    // Component Message
    ComponentMessage = 48,
    // Player Information
    PlayerMediaData = 49,

    // POS Journal
    PosJournal = 50,

    // EMV ATM
    EmvAtmTransactionType = 51,
    AtmAcquirerResponseCode = 52,
    AtmAccount = 53,
    EmvAtmApplicationID = 54,
    EmvAtmApplicationName = 55,
    EmvAtmAuthorizationResponseCode = 56,
    EmvAtmAuthCode = 57,

    // Transaction Approvals
    DecliningOperatorID = 58,

    TransactionAbortStep = 59,
    AtmSequenceNumber = 60,
    EmvKioskTransactionOutcome = 61,
    // External Terminal ID assigned by 3rd party system (InComm Payments, etc.)
    ExternalTerminalID = 62,
    // Additional jackpot information as JSON document
    JackpotAdditionalInfo = 63,
    // Accepted gross amount
    GrossAmountIn = 64,
}
