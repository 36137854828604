import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'rewriteEnumEntriesForTranslation'
})
export class RewriteEnumEntriesForTranslationPipe implements PipeTransform {

    transform(value: string, except: string): string {

        let newWord: string = '';
        let checkWord = value.split(except);
        for (var i = 0, len = checkWord[1].length, ch; i < len; ++i) {
            ch = checkWord[1].charAt(i);
            if (ch >= 'A' && ch <= 'Z') {
                if (i !== 0) {
                    newWord += '_';
                }
                newWord += ch;
            } else {
                newWord += ch;
            }
        }

        return except + newWord;
    }
}
