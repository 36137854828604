<!-- begin::Page loader -->
<div class="m-page-loader m-page-loader--non-block" style="margin-left: -80px; margin-top: -20px;">
	<div class="m-blockui">
		<span class="spinnercontent">
			 {{'BASE.LOADING.TITLE' | translate}}
		</span>
		<span>
			<div class="m-loader m-loader--brand"></div>
		</span>
	</div>
</div>
<div id="portraitmodus">
  <div class="popup-orientationmode centerabsolute">
    <i class="fa fa-exclamation"></i><span>Please rotate your device!</span>
  </div>
</div>
<p-toast position="top-right" key="transApproveState">
</p-toast>
<p-toast position="top-right" key="infoMessages">
</p-toast>
<p-toast key="toastMessages" position="top-right"></p-toast>

<p-toast position="center" key="toastMessagesModal" styleClass="toast-message-modal" (onClose)="onReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
        <div class="text-center">
            <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
            <h4>{{message.summary}}</h4>
            <p>{{message.detail}}</p>
        </div>
        <div class="grid p-fluid">
            <p-toolbar>
                <div class="p-toolbar-group-left" style="margin-left:0px; padding-bottom: 0px; width: 50%;">
                    <button type="button" pButton style="width:100%" (click)="onConfirm()" label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-toolbar-group-right" style="margin-left:0px; width: 50%;">
                    <button type="button" pButton style="width:100%" (click)="onReject()" label="No" class="p-button-secondary"></button>
                </div>
            </p-toolbar>
        </div>
    </div>
    </ng-template>
</p-toast>

<p-toast position="center" key="toastMessagesErrorModal" styleClass="toast-message-error-modal" (onClose)="onRejectErrorDetailView()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <p-toolbar>
                    <div class="p-toolbar-group-left" style="margin-left:0px; padding-bottom: 0px; width: 50%;">
                        <button type="button" pButton style="width:100%" (click)="showErrorDetailView()" label="Details" class="p-button-error"></button>
                    </div>
                    <div class="p-toolbar-group-right" style="margin-left:0px; width: 50%;">
                        <button type="button" pButton style="width:100%" (click)="onRejectErrorDetailView()" label="Close" class="p-button-secondary"></button>
                    </div>
                </p-toolbar>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-sidebar styleClass="ui-sidebar-lg" [showCloseIcon]="false" position="right" *ngIf="visibleSidebarErrorDetail" [(visible)]="visibleSidebarErrorDetail"
           [baseZIndex]="10000000"
           [modal]="false">
    <ng-template pTemplate="header">Detailed Error Message</ng-template>
    <ng-template pTemplate="content">
        <p-scrollPanel [style]="{width: '95%'}">
            <div class="p-grid" id="copyBody">
                <div class="p-col-12">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <h2>General Information</h2>
                            <div class="p-col-12">
                                <div class="p-col-12">
                                    <h3>API</h3>
                                    <div>{{currentFatalMessage?.url}}</div>
                                </div>
                                <div class="p-col-12">
                                    <h3>Statustext</h3>
                                    <div>{{currentFatalMessage?.statusText}}</div>
                                </div>
                                <div class="p-col-12">
                                    <h3>Status</h3>
                                    <div>{{currentFatalMessage?.status}}</div>
                                </div>
                                <div class="p-col-12">
                                    <h3>Message</h3>
                                    <div>{{currentFatalMessage?.message}}</div>
                                </div>
                                <div class="p-col-12">
                                    <h3>Kind</h3>
                                    <div>{{currentFatalMessage?.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12">
                            <h3>Error Detail</h3>
                            <div class="p-col-12">
                                <h3>Exception Type</h3>
                                <div>{{currentFatalMessage?.error?.ExceptionType}}</div>
                            </div>
                            <div class="p-col-12">
                                <h3>Message</h3>
                                <div>{{currentFatalMessage?.error?.Message}}</div>
                            </div>
                            <div class="p-col-12">
                                <h3>Stacktrace</h3>
                                <div>{{currentFatalMessage?.error?.StackTrace}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-scrollPanel>
    </ng-template>
    <ng-template pTemplate="footer">
        <p-toolbar>
            <div class="p-toolbar-group-left">

            </div>
            <div class="p-toolbar-group-right">
                <button class="send-button" style="line-height: 24px;"
                        (click)="closeErrorDetailView()">{{'BASE.BUTTONS.CANCEL' | translate}}</button>
                <button class="send-button" style="line-height: 24px;"
                        (click)="copyToClipboard()">{{'BASE.BUTTONS.COPY_TO_CLIPBOARD' | translate}}</button>
            </div>
        </p-toolbar>
    </ng-template>
</p-sidebar>
<!-- end::Page loader -->
<!-- begin:: Page -->
<router-outlet></router-outlet>
<!-- end:: Page -->
<app-quick-sidebar></app-quick-sidebar>
<app-scroll-top></app-scroll-top>
<app-tooltips></app-tooltips>
<!--begin::Base Scripts -->
<!--end::Base Scripts -->
<!--begin::Page Vendors -->
<!--end::Page Vendors -->
<!--begin::Page Snippets -->
<!--end::Page Snippets -->
