import { Injectable } from '@angular/core';
import { asapScheduler, of, from, interval, merge, fromEvent, Observable, Subject, pipe } from 'rxjs';
import * as Rx from 'rxjs';
import { webSocket } from 'rxjs/webSocket';

import { map, takeUntil, tap } from 'rxjs/operators';
import { DebugService } from "./debug.service";
@Injectable()
export class WebsocketService {
    constructor(private debugService: DebugService,) {
    }

    public subject: Rx.Subject<MessageEvent>;

    public connect(url): Rx.Subject<MessageEvent> {
        if (!this.subject) {
            this.subject = this.create(url);
            this.debugService.logging('info', "Websocket connected: @url ", url);
        }
        return this.subject;
    }

    public complete() {
        //console.log("try to complete");
        return this.subject.complete();
    }

    public unsubscribe() {
        //console.log("try to unsubscribe");
        return this.subject.unsubscribe();
    }



    private create(url): Rx.Subject<MessageEvent> {
        try {
            let ws = new WebSocket(url);
            let observable = Rx.Observable.create(
                (obs: Rx.Observer<MessageEvent>) => {
                    //console.log("obs", obs);
                    ws.onmessage = obs.next.bind(obs);
                    ws.onerror = obs.error.bind(obs);
                    ws.onclose = obs.complete.bind(obs);
                    //console.log("obs", obs);
                    return ws.close.bind(ws);
                });
            let observer = {
                next: (data: Object) => {
                    //console.log("ws data", data);
                    if (ws.readyState === WebSocket.OPEN) {
                        ws.send(JSON.stringify(data));
                    }
                }
            };
            return Rx.Subject.create(observer, observable);
        }
        catch (error) {
            console.log("error websocket ", error)
        }




    }

}
