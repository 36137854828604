import { Pipe, PipeTransform } from '@angular/core';
import { TransactionFlag } from '../_enums/transactionFlag';
@Pipe({
    name: 'flagName'
})
export class FlagNamePipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];

        return TransactionFlag[value];
    }
}
