<p-dialog styleClass="art-details-terminal-dialog" appendTo="body" [closeOnEscape]="true" [responsive]="true" [resizable]="false"
          [draggable]="false" [closable]="false" [style]="{width: '100vw', 'min-height': '100vh'}"
          (onShow)="showDialogMaximized(propertyDetailDialog)" #propertyDetailDialog
          [(visible)]="viewReady" [modal]="true">

    <p-header>{{artInstallationDetails?.ArtName}} - {{propertyDetailsView.PropertyName}}
        <i class="close-button fa fa-times" (click)="closePropertyDetailsModal()"></i>
    </p-header>

    <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 205px)'}" *ngIf="viewReady">
        <div class="p-grid">
            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">

                        <p-card header="{{currentAddress?.LocationName}}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <ng-container *ngIf="artInstallationDetails.ArtWebClientEnabled == false">
                                            <b>{{ goToWebSiteLabel }}</b>
                                        </ng-container>
                                        <ng-container *ngIf="artInstallationDetails.ArtWebClientEnabled == true">
                                            <ng-container *ngIf="artInstallationDetails.ArtWebClientUri != ''">
                                                <b>
                                                    <a href="https://{{artInstallationDetails.ArtWebClientUri}}"
                                                       class="external-link" target="_blank">
                                                        <i class="pi pi-external-link"></i>
                                                        {{ goToWebSiteLabel }}
                                                    </a>
                                                </b>
                                            </ng-container>
                                            <ng-container *ngIf="artInstallationDetails.ArtWebClientUri == ''">
                                                <b>{{ goToWebSiteLabel }}</b>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ locationLabel.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ locationLabel.street  }}</div>
                                        <div class="p-col-12">{{ locationLabel.address }}</div>
                                        <div class="p-col-12">{{ locationLabel.zipCode }}</div>
                                        <div class="p-col-12">{{ locationLabel.city    }}</div>
                                        <div class="p-col-12">{{ locationLabel.county  }}</div>
                                        <div class="p-col-12">{{ locationLabel.state   }}</div>
                                        <div class="p-col-12">{{ locationLabel.country }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.Street       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.AddressLine2 }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.ZipCode      }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.City         }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.County       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.State        }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.Country      }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ contactInfosLabel.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ contactInfosLabel.email }}</div>
                                        <div class="p-col-12">{{ contactInfosLabel.phone }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ currentAddress?.EmailAddress }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PhoneNumber  }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ contactPersonLabel.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ contactPersonLabel.name  }}</div>
                                        <div class="p-col-12">{{ contactPersonLabel.email }}</div>
                                        <div class="p-col-12">{{ contactPersonLabel.phone }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ currentAddress?.ContactPerson?.Name         }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.ContactPerson?.EmailAddress }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.ContactPerson?.PhoneNumber  }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ locationLabel.timeZone }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <div class="p-col-12">{{currentAddress?.TimeZone}} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                </div>
            </div>
            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">

                        <p-card header="{{ labels.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ labels.propertyNr          }}</div>
                                        <div class="p-col-12">{{ labels.propertyName        }}</div>
                                        <div class="p-col-12">{{ labels.default             }}</div>
                                        <div class="p-col-12">{{ labels.propertyDescription }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ propertyDetailsView.PropertyNo          }} &nbsp;</div>
                                        <div class="p-col-12">{{ propertyDetailsView.PropertyName        }} &nbsp;</div>
                                        <div class="p-col-12">{{ propertyDetailsView.DefaultProperty     }} &nbsp;</div>
                                        <div class="p-col-12">{{ propertyDetailsView.PropertyDescription }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                </div>
            </div>
            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">

                        <p-card
                            header="{{ labels.terminals }} ({{terminalCount}}/{{terminalCountMax}})">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <p-table #dt [columns]="columns" [value]="terminalList" [scrollable]="true"
                                                 [style]="{width:'auto', height: '600px'}" scrollHeight="580px"
                                                 (onFilter)="onFilterTerminal($event)"
                                                 [globalFilterFields]="['TerminalName', 'TerminalId']"
                                                 class="media-log-table content-table" *ngIf="terminalListTableView">
                                            <ng-template pTemplate="caption">
                                                <div style="text-align: right">
                                                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                                    <input type="text" pInputText size="40" placeholder="{{ findButtonLabel }}"
                                                           (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                           style="width:auto">
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="colgroup">
                                                <colgroup>
                                                    <col style="width:30px" >
                                                    <col style="width:70px" >
                                                    <col style="width:150px">
                                                    <col style="width:150px">
                                                    <col style="width:100px">
                                                    <col style="width:50px" >

                                                </colgroup>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-colums>
                                                <tr>
                                                    <th class="{{col.class}}" *ngFor="let col of columns"
                                                        [pSortableColumn]="col.field">
                                                        {{col.header}}
                                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-terminal>
                                                <tr (click)="openTerminalDetail(terminal)">
                                                    <td>
                                                        <div class="{{terminal.TerminalState.class}}">
                                                            {{terminal.TerminalState.text}}</div>
                                                    </td>
                                                    <td class="tb-right">{{ terminal?.TerminalId               }}</td>
                                                    <td class="tb-left" >{{ terminal?.TerminalName             }}</td>
                                                    <td class="tb-left" >{{ terminal?.TerminalSCVersion        }}</td>
                                                    <td class="tb-left" >{{ terminal?.TerminalType             }}</td>
                                                    <td class="tb-right">{{ terminal?.TerminalVersions?.length }}</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                </div>
            </div>
        </div>

    </p-scrollPanel>

    <p-footer>
        <p-toolbar>
            <div class="p-toolbar-group-left"></div>
        </p-toolbar>
    </p-footer>

</p-dialog>

<art-installation-terminal-details *ngIf="artDetailsTerminalView === true"
                                   (closeTerminalDetails)     ='closeTerminalDetails()'
                                   [artInstallationDetails]   ="artInstallationDetails"
                                   [currentTerminalDetails]   ="currentTerminalDetails"
                                   [currentTerminalDetailsId] ="currentTerminalDetailsID"
                                   [locationLabel]            ="locationLabel"
                                   [contactInfosLabel]        ="contactInfosLabel"
                                   [contactPersonLabel]       ="contactPersonLabel"></art-installation-terminal-details>
