export enum ResponseType {
    None = 0,
    // basic results
    Created = 1,
    Updated = 2,
    Deleted = 3,
    Synchronized = 4,
    Rejected = 5,
    // validity results
    InvalidToken = 11,
    InvalidUsername = 12,
    InvalidPassword = 13,
    SuccessfulLogIn = 14,
    SuccessfulLogOut = 15,
    PasswordIsUsedRecently = 16,
    PasswordTooShort = 17,
    InvalidEmailFormat = 18,
    InvalidRequest = 19,
    InvalidFileFormat = 20,
    // rights results
    InsufficientRights = 21,
    AdminMustHaveAdminRight = 22,
    LoginDisabled = 23,
    LoginDenied = 24,
    // constraints
    UsernameRequired = 101,
    PasswordRequired = 102,
    FirstNameRequired = 103,
    LastNameRequired = 104,
    UsernameAlreadyExists = 112,
    AdminCannotBeDeleted = 121,
    CurrentUserCannotBeDeleted = 122,
    // Add KioskRegistration
    SerialNumberRequired = 201,
    InvalidSerialNumber = 202,
    MacAddressRequired = 203,
    InvalidMacAddressLength = 204,
    HardDiskIDRequired = 205,
    InvalidHardDiskLength = 206,
    DistributorNumberRequired = 207,
    InvalidRegistrationType = 208,
    InvalidValidityEndDate = 209,
    InvalidTerminalType = 210,
    InvalidLicenseValidity = 211,
    //GetKioskRegistrationsDataUpd2
    SerialNumbersNotFoundInDatabase = 301,
    Upd2ReadyToDownload = 302,
    IdMismatch = 305,
    // server error
    ServerException = 500,
}
