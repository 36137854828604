export enum ArtRightX {
    // region Global
    Global = 1000,
    TerminalOperationState = 1001,
    TerminalRestart = 1002,
    ViewSecurityAlerts = 1003,
    SuspendNotifications = 1004,

    // region Monitoring
    Monitoring = 2000,
    Dashboard = 2001,
    DashInstallation = 2100,
    DiTransactionCounts = 2101,
    DiTransactionAverages = 2102,
    DiTransactionTotals = 2103,
    DiTransactionTypes = 2104,
    DiDonationCounts = 2105,
    DashProperty = 2200,
    DpTransactionCounts = 2201,
    DpTransactionAverages = 2202,
    DpTransactionTotals = 2203,
    DpTransactionTypes = 2204,
    DpDonationCounts = 2205,
    DashKiosk = 2300,
    DkTransactionCounts = 2301,
    DkTransactionAverages = 2302,
    DkTransactionTotals = 2303,
    DkTransactionTypes = 2304,
    DkDonationCounts = 2305,
    DashboardDowntime = 2002,
    Terminals = 2003,
    TermIconView = 2401,
    TermListView = 2402,
    TermFillLevelView = 2403,
    ComponentOverview = 2004,
    TerminalIdentifiers = 2005,
    StockOverview = 2006,
    TerminalHardware = 2007,
    MaintenanceOverview = 2008,

    // region Log Views
    LogViews = 3000,
    LvTransactionLog = 3001,
    LvErrorLog = 3002,
    LvEventLog = 3003,
    LvMediaLog = 3004,
    LvMaintenanceLog = 3005,
    LvOperatorAccessLog = 3006,
    LvConfigurationChangeLog = 3007,
    LvArtEventLog = 3008,

    // region Balancing
    Balancing = 4000,
    BalanceReport = 4001,
    AtmReconciliation = 4002,
    TicketReconciliation = 4003,

    // region Statistics
    Statistics = 5000,
    TransactionStatistics = 5001,
    TransactionCounts = 5101,
    TransactionReport = 5102,
    TerminalStatistics = 5002,
    UtilizationReport = 5201,
    TerminalAvailability = 5202,
    SlaReport = 5203,
    MachineReport = 5204,
    LifetimeMeters = 5205,
    TerminalState = 5206,
    OperatorStatistics = 5005,
    OperatorAccountLocks = 5501,
    OperatorLogouts = 5502,
    DebitShifts = 5503,
    PlayerStatistics = 5006,
    PlayerTurnover = 5601,
    PlayerBans = 5602,
    MediaStatistics = 5003,
    CashStatistics = 5301,
    CardStatistics = 5302,
    CardTurnover = 5303,
    ChipTurnover = 5304,
    OnlineSystemStatistics = 5305,
    DonationReport = 5306,
    CurrencyExchangeProfitReport = 5307,
    EndOfShiftReport = 5308,
    TicketStatisticsPerOperator = 5309,
    TicketStatisticsPerPeriod = 5310,
    PaymentStatistics = 5004,
    PsCommissionReport = 5401,
    PsDistributorReport = 5402,
    PsStoreReport = 5403,
    PsTransactionReport = 5404,
    PsCustomerReport = 5405,
    PsAbortedTransactionsReport = 5406,
    KioskStatistics = 5007,
    KsWageringInstrumentReport = 5701,
    KsFillReport = 5702,
    KsCashOutReport = 5703,
    KsDropReport = 5704,
    KsExceptionReport = 5705,
    KsJackpotOpenCreditReport = 5706,
    KsTicketLiabilityReport = 5707,
    KsTicketDropVarianceReport = 5708,

    // region Management
    Management = 6000,
    OperatorManagement = 6001,
    PlayerManagement = 6002,
    CustomerManagement = 6003,
    JackpotFormsManagement = 6004,
    Advertising = 6005,
    ShopManagement = 6006,
    OnlineSystemMedia = 6007,
    BonusSystemMedia = 6008,
    JackpotSystemJackpots = 6009,
    WorkAccountManagement = 6010,
    ProductionOrders = 6011,
    LifecycleRecords = 6012,
    OnlineSystemServerStatistics = 5008,
    OsTicketLiabilityReport = 5801,
    OsTicketDropVarianceReport = 5802,
    OsEgmRevenueReport = 5803,
}
