import { GlobalCacheVariable } from "../_globals/globals";
import { Injectable } from "@angular/core";

@Injectable()
export class TerminalHelper {
    constructor(public cache: GlobalCacheVariable) {

    }

    getTerminalByID(ID) {
        return this.cache.TerminalsMap.get(ID);
    }

    getTerminals() {
        if (this.cache.TerminalsMap === null || this.cache.TerminalsMap === undefined) {
            //return 'No terminal name';
        } else {
            return this.cache.TerminalsMap;
        }
    }

    getTerminalByName(Name) {

    }
}
