// Spanish
export const locale = {
    "lang": "es",
    "data": {
        "TRANSLATOR": {
            "SELECT": "Wähle deine Sprache"
        },
        "MANAGEMENT": {
            "JACKPOT_PAYOUTS": {
                "TITLE": "Jackpot Forms",
                "MODAL": {
                    "ADD_NEW_JACKPOT_PAYOUT": "Add Jackpot Payout Form W-2G",
                    "ADD_NEW_JACKPOT_PAYOUT_1042": "Add Jackpot Payout Form 1042-S",
                    "ADD_NEW_JACKPOT_PAYOUT_SIGNATURE": "Add new jackpot payout signature",
                    "ADD_NEW_JACKPOT_PAYOUT_CHECK": "Confirm Jackpot",
                    "ADD_NEW_JACKPOT_PAYOUT_GENERAL_INFORMATION": "New jackpot payout general information",
                    "VIEW_JACKPOT_PAYOUT": "View Jackpot Payout",
                },
                "BUTTONS": {
                    "TAKE_PICTURE": "Take a picture",
                    "PICK_PICTURE": "Pick a picture",
                    "SIGNATURE_NEW": "Take new signature",
                    "SIGNATURE_CLEAR": "Clear signature",
                    "CLOSE": "Close",
                    "SAVE": "Save",
                    "CONFIRM_SAVE": "Confirm and save",
                    "CANCEL": "Cancel",
                    "NEXT": "Next",
                    "BACK": "Back",
                    "CHECK": "Check",
                    "SCAN": "Scan Barcode",
                    "CHECK_TIN": "Start TIN Check"
                },
                "FIELDS": {
                    "GENERAL": {
                        "KEYBACK_AMOUNT": "Keyback Importe",
                        "PAY_AS_TICKET_AMOUNT": "Pagar como importe del billete",
                        "PAY_AS_CHECK_AMOUNT": "Pagar con cheque Importe",
                        "PAY_AS_WALLET_AMOUNT": "Pagar como monedero Importe",
                        "PAY_AS_WIRE_ACH_AMOUNT": "Pago por transferencia bancaria/ACH Importe",
                        "LICENSE_FRONT": "Licencia Frontal",
                        "LICENSE_REAR": "Licencia Trasera",
                        "WINNING_COMBINATION": "Combinación ganadora",
                        "SECONDARY_ID_FRONT": "Identificación secundaria Anverso",
                        "SECONDARY_ID_REAR": "Identificación secundaria Trasera",
                        "SIGNATURE": "Firma"
                    },
                    "W2G": {
                        "REPORTABLE_WINNINGS": "Ganancias declarables",
                        "DATE_WON": "Fecha ganada",
                        "TYPE_OF_WAGER": "Tipo de apuestar",
                        "FEDERAL_INCOME_TAX_WITHHELD": "Impuesto federal sobre la renta retenido",
                        "TRANSACTION": "Transacción",
                        "RACE": "Raza",
                        "WINNINGS_FROM_IDENTICAL_WAGERS": "Ganancias de apuestas idénticas",
                        "CASHIER": "Cajero",
                        "WINNERS_TAXPAYER_IDENTIFICATION_NUMBER": "Número de identificación fiscal del ganador",
                        "WINDOW": "Ventana",
                        "FIRST_IDENTIFICATION": "Primera identificación",
                        "SECOND_IDENTIFICATION": "Segunda identificación",
                        "STATE_PAYERS_STATE_IDENTIFICATION_NUMBER": "Estado/Nº de identificación del contribuyente",
                        "STATE_WINNINGS": "Ganancias del Estado",
                        "STATE_INCOME_TAX_WITHHELD": "Impuesto estatal sobre la renta retenido",
                        "LOCAL_WINNINGS": "Ganancias locales",
                        "LOCAL_INCOME_TAX_WITHHELD": "Impuesto sobre la renta local retenido",
                        "NAME_OF_LOCALITY": "Nombre de la localidad",
                        "NAME": "Nombre",
                        "NUMBER_STREET": "Número/calle",
                        "CITY_OR_TOWN": "Ciudad o pueblo",
                        "STATE": "Estado",
                        "ZIP_CODE": "Código postal",
                        "FEDERAL_IDENTIFICATION_NUMBER": "Número de identificación federal",
                        "TELEPHONE_NUMBER": "Número de teléfono",
                        "EMAIL": "Correo electrónico"
                    }
                },
                "HEADLINES": {
                    "GENERAL_INFORMATION": "Información general",
                    "PAYERS_INFORMATION": "Información del pagador",
                    "WINNERS_INFORMATION": "Información del ganador",
                    "SETTLEMENT_INFORMATION": "Información sobre la liquidación",
                    "DOCUMENT_INFORMATION": "Información documental"
                },
                "STEPS": {
                    "GENERAL": {
                        "GENERAL": "General",
                        "TAX": "Tax",
                        "AMOUNTS": "Amounts",
                        "DOCUMENTS": "Documents"
                    },
                    "1042": {
                        "GENERAL_INFORMATION": "General",
                        "WITHHOLDING_INFORMATION": "Withholding",
                        "RECIPIENT_INFORMATION": "Recipient",
                        "PRIMARY_WITHHOLDING_INFORMATION": "Primary withholding",
                        "INTERMEDIARY_INFORMATION": "Intermediary",
                        "PAYER_INFORMATION": "Payer",
                        "ADDITIONAL_INFORMATION": "Additional",
                        "CONFIRM": "Confirm"
                    },
                    "W2G": {
                        "GENERAL_INFORMATION": "General",
                        "PAYER_INFORMATION": "Payer",
                        "WINNER_INFORMATION": "Winner",
                        "ADDITIONAL_INFORMATION": "Secondary ID",
                        "CONFIRM": "Confirm"
                    }
                }
            }
        }
    }
};
