export enum TransactionType {
    None = 0,
    CoinRedemption = 1,
    BillBreaker = 2,
    TicketRedemption = 3,
    MagneticCardRedemption = 4,
    SmartCardRedemption = 5,
    JackpotPayout = 6,
    FillBagFront = 7,
    EmptyHopper = 8,
    JackpotRear = 9,
    FillBackRear = 10,
    CassetteRemoved = 11,
    CassetteInserted = 12,
    BillValidatorEmptied = 13,
    CoinsInserted = 14,
    RejectBoxEmptied = 15,
    OpenCreditCleared = 16,
    TestHopper = 17,
    TicketPrinted = 18,
    MagneticCardCharged = 19,
    SmartCardCharged = 20,
    CoinsDispensed = 21,
    MediaOutEmptied = 22,
    MediaInEmptied = 23,
    CashDifference = 24,
    CashboxFilled = 25,
    CashboxWithdrawn = 26,
    CashboxReload = 27,
    AtmWithdrawal = 28,
    AtmCashAdvance = 29,
    MediaRefill = 30,
    AtmBalanceInquiry = 31,
    DepositWithheld = 32,
    DepositPaid = 33,
    RoundingDifferenceCleared = 34,
    SamToSuperSam = 35,
    JackpotCancelCredit = 36,
    JackpotShortPay = 37,
    GMHopperRefill = 38,
    ProgressiveJackpot = 39,
    CaptureBinEmptied = 40,
    StackerStockAdded = 41,
    PrintedTicketsCleared = 42,
    LostMoney = 43,
    LostMoneyCleared = 44,
    JackpotAdditional = 45,
    CoinAcceptorEmptied = 46,
    PosWithdrawal = 47,
    ChipRedemption = 48,
    PosCleared = 49,
    ChipsPaidOut = 50,
    CouponRedemption = 51,
    CheckCashing = 52,
    CheckCashingCleared = 53,
    AtmCleared = 54,
    TicketHandPayCleared = 55,
    DropBoxEmptied = 56,
    LoanRaised = 57,
    LoanRepaidCash = 58,
    LoanRepaidCard = 59,
    LoansCleared = 60,
    DonationsCleared = 61,
    MarkerRedemption = 62,
    TaxWithheldCleared = 63,
    DebitPayout = 64,
    DebitReturn = 65,
    CashierCashPayout = 66,
    CashierCashReturn = 67,
    StackerEmptied = 68,
    EWalletWithdrawal = 69,
    EWalletDeposit = 70,
    EWalletWithdrawalCleared = 71,
    EWalletDepositCleared = 72,
    RejectedBill = 73,
    RejectedTicket = 74,
    RejectedCard = 75,
    TicketRecreated = 76,
    BonusCardRedemption = 77,
    BonusCardCharged = 78,
    BillPay = 79,
    CheckCashingPayment = 80,
    MobileTopUp = 81,
    GiftCardSale = 82,
    BillPayCleared = 83,
    CheckCashingPaymentCleared = 84,
    MobileTopUpCleared = 85,
    GiftCardSaleCleared = 86,
    WireTransfer = 87,
    WireTransferCleared = 88,
    DebitCardSale = 89,
    DebitCardSaleCleared = 90,
    GiftCardBuy = 91,
    CryptoCurrencyBuy = 92,
    PrepaidCardCharged = 93,
    GiftCardBuyCleared = 94,
    CryptoCurrencyBuyCleared = 95,
    PrepaidCardChargedCleared = 96,
    IDScannerCleared = 97,
    CryptoCurrencySale = 98,
    CryptoCurrencySaleCleared = 99,
    BookingAccountCleared = 100,
    PrepaidCardIssued = 101,
    FeeWithheldCleared = 102,
    PosCashAdvance = 103,
    IouReceiptRedemption = 104,
    PrintedIouReceiptsCleared = 105,
    RfidCardRedemption = 106,
    RfidCardCharged = 107
}
