import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "../auth/_guards/auth.guard";
import { SharingModule } from "../shared.module";

const routes: Routes = [
    {
        "path": "",
        "component": ThemeComponent,
        "canActivate": [AuthGuard],
        "children": [
            //ART GROUPSERVER
            {
                "path": "art-group-server-overview",
                loadChildren: () => import('./pages/default/group-server/general/art-overview/art-overview.module').then(m => m.ArtOverviewModule)
            },
            {
                "path": "maintenance-overview",
                loadChildren: () => import('./pages/default/group-server/general/maintenance-overview/maintenance-overview.module').then(m => m.MaintenanceOverviewModule)

            },
            {
                "path": "quarterly-dashboard",
                loadChildren: () => import('./pages/default/group-server/statistics/quarterly-dashboard/quarterly-dashboard.module').then(m => m.QuarterlyDashboardModule)
            },
            {
                "path": "quarterly-dashboard/:artID",
                loadChildren: () => import('./pages/default/group-server/statistics/quarterly-dashboard/quarterly-dashboard.module').then(m => m.QuarterlyDashboardModule)
            },
            {
                "path": "user-management",
                loadChildren: () => import('./pages/default/group-server/management/user-management/user-management.module').then(m => m.UserManagementModule)
            },
            {
                "path": "manufacturing-orders",
                loadChildren: () => import('./pages/default/group-server/management/manufacturing-orders/manufacturing-orders.module').then(m => m.ManufacturingOrdersModule)
            },
            {
                "path": "terminal-lifecycle",
                loadChildren: () => import('./pages/default/group-server/management/terminal-lifecycle/terminal-lifecycle.module').then(m => m.TerminalLifecycleModule)
            },
            {
                "path": "computer-serial-registration",
                loadChildren: () => import('./pages/default/group-server/management/computer-serial-registration/computer-serial-registration.module').then(m => m.ComputerSerialRegistrationModule)
            },
            {
                "path": "license-key-usage-overview",
                loadChildren: () => import('./pages/default/group-server/management/license-key-usage-overview/license-key-usage-overview.module').then(m => m.LicenseKeyUsageOverviewModule)
            },
            {
                "path": "manufacturing-order-overview",
                loadChildren: () => import('./pages/default/group-server/management/manufacturing-order-overview/manufacturing-order-overview.module').then(m => m.ManufacturingOrderOverviewModule)
            },
            {
                "path": "configuration-management",
                loadChildren: () => import('./pages/default/group-server/settings/configuration-management/configuration-management.module').then(m => m.ConfigurationManagementModule)
            },
            {
                "path": "group-search",
                loadChildren: () => import('./pages/default/group-server/search/group-search.module').then(m => m.GroupSearchModule)
            },
            {
                "path": "group-search/:searchID",
                loadChildren: () => import('./pages/default/group-server/search/group-search.module').then(m => m.GroupSearchModule)
            },
            {
                "path": "group-search/:searchID/:keyword",
                loadChildren: () => import('./pages/default/group-server/search/group-search.module').then(m => m.GroupSearchModule)
            },
            {
                "path": "map-view",
                loadChildren: () => import('./pages/default/group-server/statistics/map-overview/map-overview.module').then(m => m.MapOverviewModule)
            },
            {
                "path": "stock-overview",
                loadChildren: () => import('./pages/default/group-server/statistics/stock-overview/stock-overview.module').then(m => m.StockOverviewModule)
            },
            {
                "path": "component-overview-group",
                loadChildren: () => import('./pages/default/group-server/statistics/component-overview/component-overview.module').then(m => m.ComponentOverviewModule)
            },
            {
                "path": "kiosk-registration",
                loadChildren: () => import('./pages/default/group-server/management/kiosk-registration/kiosk-registration.module').then(m => m.KioskRegistrationModule)
            },

            //ART SINGLESERVER
            {
                "path": "index",
                loadChildren: () => import('./pages/default/index/index.module').then(m => m.IndexModule)
            },
            {
                "path": "dashboard",
                loadChildren: () => import('./pages/default/monitoring/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                "path": "dashboard-downtime",
                loadChildren: () => import('./pages/default/monitoring/dashboard-downtime/dashboard-downtime.module').then(m => m.DashboardDowntimeModule)
            },
            {
                "path": "dashboard-lasttest",
                loadChildren: () => import('./pages/default/monitoring/dashboard/dashboard-lasttest/dashboard-lasttest.module').then(m => m.DashboardLasttestModule)
            },
            /*
          {
            "path": "terminals",
            "loadChildren": ".\/pages\/default\/monitoring\/terminals\/terminals.module#TerminalsModule"
          },*/
            {
                "path": "terminal",
                loadChildren: () => import('./pages/default/monitoring/terminal/terminal.module').then(m => m.TerminalModule)
            },
            {
                "path": "terminal-icon",
                loadChildren: () => import('./pages/default/monitoring/terminal/terminal-view-icon/terminal-icon.module').then(m => m.TerminalIconModule)
            },
            {
                "path": "terminal-list",
                loadChildren: () => import('./pages/default/monitoring/terminal/terminal-view-list/terminal-list.module').then(m => m.TerminalListModule)
            },
            {
                "path": "terminal-fill-level",
                loadChildren: () => import('./pages/default/monitoring/terminal/terminal-view-fill-level/terminal-fill-level.module').then(m => m.TerminalFillLevelModule)
            },

            {
                "path": "component-overview",
                loadChildren: () => import('./pages/default/monitoring/component-overview/component-overview.module').then(m => m.ComponentOverviewModule)
            },
            {
                "path": "component-overview/:terminalID",
                loadChildren: () => import('./pages/default/monitoring/component-overview/component-overview.module').then(m => m.ComponentOverviewModule)
            },
            {
                "path": "component-overview/:terminalID/:componentID/:componentRank",
                loadChildren: () => import('./pages/default/monitoring/component-overview/component-overview.module').then(m => m.ComponentOverviewModule)
            },
            {
                "path": "maintenance-overview",
                loadChildren: () => import('./pages/default/monitoring/maintenance-overview/maintenance-overview.module').then(m => m.MaintenanceOverviewModule)
            },
            {
                "path": "terminal-identifiers",
                loadChildren: () => import('./pages/default/monitoring/terminal-identifiers/terminal-identifiers.module').then(m => m.TerminalIdentifiersModule)
            },
            {
                "path": "transaction-log",
                loadChildren: () => import('./pages/default/logs/transaction-log/transaction-log-list.module').then(m => m.TransactionLogListModule)
            },
            {
                "path": "transaction-log/:terminalID",
                loadChildren: () => import('./pages/default/logs/transaction-log/transaction-log-list.module').then(m => m.TransactionLogListModule)
            },
            {
                "path": "error-log",
                loadChildren: () => import('./pages/default/logs/error-log/error-log.module').then(m => m.ErrorLogModule)
            },
            {
                "path": "operator-access-log",
                loadChildren: () => import('./pages/default/logs/operator-access-log/operator-access-log.module').then(m => m.OperatorAccessLogModule)
            },
            {
                "path": "configuration-change-log",
                loadChildren: () => import('./pages/default/logs/configuration-change-log/configuration-change-log.module').then(m => m.ConfigurationChangeLogModule)
            },
            {
                "path": "event-log",
                loadChildren: () => import('./pages/default/logs/event-log/event-log.module').then(m => m.EventLogModule)
            },
            {
                "path": "event-log/:terminalID",
                loadChildren: () => import('./pages/default/logs/event-log/event-log.module').then(m => m.EventLogModule)
            },
            {
                "path": "media-log",
                loadChildren: () => import('./pages/default/logs/media-log/media-log.module').then(m => m.MediaLogModule)
            },
            {
                "path": "art-event-log",
                loadChildren: () => import('./pages/default/logs/art-event-log/art-event-log.module').then(m => m.ArtEventLogModule)
            },
            {
                "path": "maintenance-log",
                loadChildren: () => import('./pages/default/logs/maintenance-log/maintenance-log.module').then(m => m.MaintenanceLogModule)
            },
            {
                "path": "transaction-count",
                loadChildren: () => import('./pages/default/statistics/transaction-counts/transaction-counts.module').then(m => m.TransactionCountsModule)
            },
            {
                "path": "utilization-report",
                loadChildren: () => import('./pages/default/statistics/utilization-report/utilization-report.module').then(m => m.UtilizationReportModule)
            },
            {
                "path": "transaction-statistics",
                loadChildren: () => import('./pages/default/statistics/transaction-statistics/transaction-statistics.module').then(m => m.TransactionStatisticsModule)

            },
            {
                "path": "terminal-availability",
                loadChildren: () => import('./pages/default/statistics/terminal-availability/terminal-availability.module').then(m => m.TerminalAvailabilityModule)
            },
            {
                "path": "terminal-state",
                loadChildren: () => import('./pages/default/statistics/terminal-state/terminal-state.module').then(m => m.TerminalStateModule)
            },
            {
                "path": "media-cash-statistics",
                loadChildren: () => import('./pages/default/statistics/media-statistics/cash-statistics/media-cash-statistics.module').then(m => m.MediaCashStatisticsModule)

            },
            {
                "path": "media-card-statistics",
                loadChildren: () => import('./pages/default/statistics/media-statistics/card-statistics/media-card-statistics.module').then(m => m.MediaCardStatisticsModule)
            },
            {
                "path": "media-card-turnover",
                loadChildren: () => import('./pages/default/statistics/media-statistics/card-turnover/media-card-turnover.module').then(m => m.MediaCardTurnoverModule)
            },
            {
                "path": "media-chip-turnover",
                loadChildren: () => import('./pages/default/statistics/media-statistics/chip-turnover/media-chip-turnover.module').then(m => m.MediaChipTurnoverModule)
            },
            {
                "path": "media-ticket-statistics-operator",
                loadChildren: () => import('./pages/default/statistics/media-statistics/ticket-statistics-operator/media-ticket-statistics-operator.module').then(m => m.MediaTicketStatisticsOperatorModule)
            },
            {
                "path": "media-ticket-statistics-period",
                loadChildren: () => import('./pages/default/statistics/media-statistics/ticket-statistics-period/media-ticket-statistics-period.module').then(m => m.MediaTicketStatisticsPeriodModule)
            },
            {
                "path": "media-online-system-statistics",
                loadChildren: () => import('./pages/default/statistics/media-statistics/online-system-statistics/media-online-system-statistics.module').then(m => m.MediaOnlineSystemStatisticsModule)
            },
            {
                "path": 'balancing-reports/:terminalID',
                loadChildren: () => import('./pages/default/balancing/balancing-reports/balancing-reports.module').then(m => m.BalancingReportsModule)
            },
            {
                "path": 'balancing-reports',
                loadChildren: () => import('./pages/default/balancing/balancing-reports/balancing-reports.module').then(m => m.BalancingReportsModule)
            },
            {
                "path": "payment-reports",
                loadChildren: () => import('./pages/default/statistics/reports/payment-reports/payment-reports.module').then(m => m.PaymentReportsModule)
            },
            {
                "path": "donations",
                loadChildren: () => import('./pages/default/statistics/donations/donations.module').then(m => m.DonationsModule)
            },
            {
                "path": "end-of-shift",
                loadChildren: () => import('./pages/default/statistics/end-of-shift/end-of-shift-reports.module').then(m => m.EndOfShiftReportsModule)
            },
            {
                "path": "operators",
                loadChildren: () => import('./pages/default/management/operators/operators.module').then(m => m.OperatorsModule)
            },
            {
                "path": "site-assignment",
                loadChildren: () => import('./pages/default/management/site-assignment/site-assignment.module').then(m => m.SiteAssignmentModule)
            },
            {
                "path": "player-information",
                loadChildren: () => import('./pages/default/management/player-information/player-information.module').then(m => m.PlayerInformationModule)
            },
            {
                "path": "payment-customer",
                loadChildren: () => import('./pages/default/management/payment-customer/payment-customer.module').then(m => m.PaymentCustomerModule)
            },

            {
                "path": "jackpot-payouts",
                loadChildren: () => import('./pages/default/management/jackpot-payouts/jackpot-payouts.module').then(m => m.JackpotPayoutsModule)
            },
            {
                "path": "user/profile",
                loadChildren: () => import('./pages/default/user/profile/user-profile.module').then(m => m.UserProfileModule)
            },
            {
                "path": "user/messages",
                loadChildren: () => import('./pages/default/user/messages/user-messages.module').then(m => m.UserMessagesModule)

            },
            {
                "path": "",
                "redirectTo": "index",
                "pathMatch": "full"
            }
        ]
    },
    {
        "path": "snippets/pages/user/login-1",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/user/user-login-1/user-login-1.module').then(m => m.UserLogin1Module)
    },
    {
        "path": "snippets/pages/user/login-2",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/user/user-login-2/user-login-2.module').then(m => m.UserLogin2Module)
    },
    {
        "path": "snippets/pages/user/login-3",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/user/user-login-3/user-login-3.module').then(m => m.UserLogin3Module)
    },
    {
        "path": "snippets\/pages\/user\/login-4",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/user/user-login-4/user-login-4.module').then(m => m.UserLogin4Module)
    },
    {
        "path": "snippets\/pages\/user\/login-5",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/user/user-login-5/user-login-5.module').then(m => m.UserLogin5Module)
    },
    {
        "path": "snippets\/pages\/errors\/error-1",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-1/errors-error-1.module').then(m => m.ErrorsError1Module)
    },
    {
        "path": "snippets\/pages\/errors\/error-2",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-2/errors-error-2.module').then(m => m.ErrorsError2Module)
    },
    {
        "path": "snippets\/pages\/errors\/error-3",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-3/errors-error-3.module').then(m => m.ErrorsError3Module)
    },
    {
        "path": "snippets\/pages\/errors\/error-4",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-4/errors-error-4.module').then(m => m.ErrorsError4Module)
    },
    {
        "path": "snippets\/pages\/errors\/error-5",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-5/errors-error-5.module').then(m => m.ErrorsError5Module)
    },
    {
        "path": "snippets\/pages\/errors\/error-6",
        loadChildren: () => import('./pages/self-layout-blank/snippets/pages/errors/errors-error-6/errors-error-6.module').then(m => m.ErrorsError6Module)
    },
    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule]
})
export class ThemeRoutingModule {
}
