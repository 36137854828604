import { Address } from "./address";
import { Person } from "./person";

export class AddressExt {
    ContactPerson: Person;
    PostalAddress: Address;
    EmailAddress: string;
    LocationName: string;
    PhoneNumber: string;
    TimeZone: string;
    Coordinates: Coordinates
}

export interface Coordinates {
    Lat: number
    Long: number
}

export interface CoordinatesView {
    lat: number,
    long: number
}
