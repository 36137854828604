import { GlobalCacheVariable } from '../_globals/globals'
import { TerminalIconRewritePipe } from "../_pipes/terminalIconRewrite.pipe";
import { Injectable } from "@angular/core";
import { MachineStatus } from "../_enums/machineStatus";
import { TerminalType } from "../_enums/terminalType";
import { SelectItemGroup } from "primeng/api";
import { TerminalTypeNamingPipe } from "../_pipes/terminalTypeNaming.pipe";
import { SharingModule } from "../../shared.module";

@Injectable()
export class RewriteService {
    public currentUser: any;
    public sessionKey: any;
    terminalsGroup: SelectItemGroup[];
    terminalTypeArray: any;

    constructor(
        private globalCacheVariable: GlobalCacheVariable,
        private terminalIconRewrite: TerminalIconRewritePipe = new TerminalIconRewritePipe(),
        private terminalTypeNamingPipe: TerminalTypeNamingPipe = new TerminalTypeNamingPipe(),
    ) {

    }

    public createPropertyChooserArray(kind, allState = null): any {
        let property = [];
        if (this.globalCacheVariable?.Properties?.length > 0) {
            if (allState !== null) {
                property.push({
                    label: "All",
                    value: "all"
                });
            }
            for (let p = 0; p < this.globalCacheVariable.Properties.length; p++) {
                let currentProperty = this.globalCacheVariable.Properties[p];
                property.push({
                    label: currentProperty.PropertyName + ' (#: ' + currentProperty.PropertyID + ')',
                    value: currentProperty.PropertyID
                });
            }
        }
        return property;
    }

    public createTerminalChooserArray(kind): any {
        this.terminalsGroup = [];
        this.terminalTypeArray = [];

        //Grouped Terminals
        for (let item in TerminalType) {
            if (!isNaN(Number(item))) {
                this.terminalTypeArray.push(Number(item));
            }
        }

        this.terminalTypeArray.sort();

        for (let tt = 0; tt < this.terminalTypeArray.length; tt++) {
            if (tt === 0) {
                this.terminalsGroup.push({
                    label:
                        "All Terminals",
                    value: 'all',
                    items: [{
                        label: "All",
                        value: 'all',
                    }]
                });
            } else {
                this.terminalsGroup.push({
                    label:
                        this.terminalTypeNamingPipe.transform(TerminalType[this.terminalTypeArray[tt]]),
                    value: this.terminalTypeArray[tt],
                    items: [],
                });
                for (let t = 0; t < this.globalCacheVariable.Terminals.length; t++) {
                    if (this.globalCacheVariable.Terminals[t].TerminalType === this.terminalTypeArray[tt]) {
                        this.terminalsGroup[tt].items.push({
                            label: this.globalCacheVariable.Terminals[t].TerminalName,
                            value: this.globalCacheVariable.Terminals[t].TerminalID
                        });
                    }
                }
            }
        }

        if (kind === 'without-All') {
            this.terminalsGroup.shift();
        }

        return this.terminalsGroup;
    }

    public rewriteTerminalIconInformations(terminals, kind): any {

        if (kind === 'multi' && terminals.length >= 1) {
            for (let i = 0; i < terminals.length; i++) {

                terminals[i].TemplateParams = this.rewriteTerminalIconInformationSingle(terminals[i]);

            }
        }
        else {
            terminals.TemplateParams = this.rewriteTerminalIconInformationSingle(terminals);
        }

        return Promise.resolve(terminals);

    }

    rewriteTerminalIconInformationSingle(terminal) {
        let terminalParams: any = {};

        //COS
        terminalParams.CosClass = this.terminalIconRewrite.transform('', terminal.CosState, 'cos', 'class');
        terminalParams.CosTitle = this.terminalIconRewrite.transform('', terminal.CosState, 'cos', 'title');
        terminalParams.CosImage = this.terminalIconRewrite.transform('', terminal.CosState, 'cos', 'image');
        //ATM
        terminalParams.AtmClass = this.terminalIconRewrite.transform('', terminal.AtmState, 'atm', 'class');
        terminalParams.AtmTitle = this.terminalIconRewrite.transform('', terminal.AtmState, 'atm', 'title');
        terminalParams.AtmImage = this.terminalIconRewrite.transform('', terminal.AtmState, 'atm', 'image');
        //JOS
        terminalParams.JosClass = this.terminalIconRewrite.transform('', terminal.JosState, 'jos', 'class');
        terminalParams.JosTitle = this.terminalIconRewrite.transform('', terminal.JosState, 'jos', 'title');
        terminalParams.JosImage = this.terminalIconRewrite.transform('', terminal.JosState, 'jos', 'image');
        //TERMINAL STATE
        terminalParams.TerminalStatusClass = this.terminalIconRewrite.transform('', terminal.Status, 'connection-status', 'class');
        terminalParams.TerminalStatusTitle = this.terminalIconRewrite.transform('', terminal.Status, 'connection-status', 'title');
        terminalParams.TerminalStatusImage = this.terminalIconRewrite.transform('', terminal.Status, 'connection-status', 'image');

        return terminalParams;
    }
}
