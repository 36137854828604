import { Pipe, PipeTransform } from '@angular/core';
import { MediumAction } from '../_enums/mediumAction';
@Pipe({
    name: 'mediumAction'
})
export class MediumActionPipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];

        return MediumAction[value];
    }
}
