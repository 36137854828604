import {
    Component,
    OnInit,
    ViewEncapsulation,
    SimpleChanges,
    Input,
    OnChanges,
    Output,
    EventEmitter, NgZone
} from '@angular/core';
import { GlobalCacheVariable } from "../../../../../../auth/_globals/globals";
import { EffectiveAddressPipe } from "../../../../../../auth/_pipes/effectiveAddress.pipe";
import { AddressExt } from "../../../../../../auth/_models/addressExt";
import { Address } from "../../../../../../auth/_models/address";
import { Person } from "../../../../../../auth/_models/person";
import { GeneralRewritePipe } from '../../../../../../auth/_pipes/generalRewrite.pipe';
import { IArtInstallationDetails } from '../../../../../../auth/_models/group/artInstallationDetails';
import { ITableColumn } from '../../../../../../auth/_models/tableColumn';
import {
    IArtDetailsTerminalDetailsView, IArtDetailsTerminalState,
    IArtDetailsTerminalView
} from '../../../../../../auth/_models/group/artDetailsTerminalView';
import { DatePipe } from '@angular/common';
import { IProperty } from '../../../../../../auth/_models/group/property';
import { ITerminalDetailsLabels } from '../../../../../../auth/_models/group/artIntallationDetailsLabels';
import { GroupServerService } from "../../../../../../auth/_services/groupserver.service";
import { ViewTypeBlockUnblock } from "../../../../../../auth/_enums/group/viewTypeBlockUnblock";
import { DateFormat } from "../../../../../../auth/_enums/general/date";
import { CurrentGroupUser } from "../../../../../../auth/_models/group/currentGroupUser";
import { MenuItem } from "primeng/api";
import { MachineStatus } from "../../../../../../auth/_enums/machineStatus";
import { TerminalState } from "../../../../../../auth/_enums/terminalState";
import { ToastSeverity } from "../../../../../../auth/_enums/messages/toastSeverity";
import { MessageType } from "../../../../../../auth/_enums/messages/messageType";
import { state } from "@angular/animations";
import { LifecycleValue, TerminalLifeCycle } from "../../../../../../auth/_models/group/ITerminalRegistration";
import { LifecycleRecordType } from "../../../../../../auth/_enums/lifecycle/lifeCycleRecordType";
import { transform } from "ol/proj";


@Component({
    selector: "art-installation-terminal-block-unblock",
    templateUrl: "./block-unblock-change.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [EffectiveAddressPipe, DatePipe]
})
export class BlockUnblockChangeComponent implements OnInit, OnChanges {

    @Input('artInstallationDetails')
    artInstallationDetails: IArtInstallationDetails;

    @Input('currentTerminalDetails')
    currentTerminalDetails: IArtDetailsTerminalDetailsView;

    @Input('viewReadyBlockUnblock')
    viewReady: boolean;

    @Output()
    closeTerminalBlockUnblock: EventEmitter<object> = new EventEmitter<object>();

    viewKind: number;
    //viewReady: boolean = false;
    reason: string = "";
    currentTime: any;
    currentUser: CurrentGroupUser;
    blockMenuItems: MenuItem[];
    historyList: TerminalLifeCycle[];
    historyListTableView: boolean = false;
    columns: ITableColumn[];

    labels: ITerminalDetailsLabels;
    localeId: string = 'STATISTICS.ART_OVERVIEW.MODALS';
    constructor(
        public cache: GlobalCacheVariable,
        private generalRewrite: GeneralRewritePipe,
        private datePipe: DatePipe,
        private groupServerService: GroupServerService,
        private zone: NgZone,
    ) {

    }

    ngOnInit(): void {

        this.initGenerals();
        this.initColumns();
        this.initLocalization();
        this.loadBlockedHistory(this.currentTerminalDetails.SerialNumber);
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.currentTerminalDetails.currentValue !== changes.currentTerminalDetails.previousValue) {
            //this.openTerminalDetail(this.currentTerminalDetails);
        }

    }

    initGenerals(): void {
        this.viewKind = ViewTypeBlockUnblock.Terminal;

        this.currentTime = this.generalRewrite.getDate(DateFormat.Now);
        this.currentUser = this.cache.getCurrentGroupUser();
    }

    initColumns(): void {
        this.columns = [
            {
                field: 'SerialNumber',
                header: this.generalRewrite.translate('MANAGEMENT.TERMINAL_LIFECYCLE.FILTER.SERIAL_NUMBER'),
                class: 'tb-right'
            },
            {
                field: 'LifecycleCreated',
                header: this.generalRewrite.translate('MANAGEMENT.TERMINAL_LIFECYCLE.TABLE.LIFECYCLE_CREATED'),
                class: 'tb-right'
            },
            {
                field: 'LifecycleValue',
                header: this.generalRewrite.translate('MANAGEMENT.TERMINAL_LIFECYCLE.TABLE.LIFECYCLE_VALUE'),
                class: 'tb-left'
            },
            {
                field: 'LifecycleTypeView',
                header: this.generalRewrite.translate('MANAGEMENT.TERMINAL_LIFECYCLE.TABLE.LIFECYCLE_TYPE'),
                class: 'tb-left'
            },
        ];
    }

    initLocalization(): void {
        this.labels = {
            terminalState: {
                title: this.generalRewrite.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.TITLE'),
                connected: this.generalRewrite.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.CONNECTED'),
                main: this.generalRewrite.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.MAIN'),
                componentState: this.generalRewrite.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.COMPONENT_STATE'),
            },
            terminalStatistics: {
                title: this.generalRewrite.translate(this.localeId + '.TERMINAL_STATISTICS.TITLE'),
                firstSeen: this.generalRewrite.translate(this.localeId + '.TERMINAL_STATISTICS.FIRST_SEEN'),
                lastSeen: this.generalRewrite.translate(this.localeId + '.TERMINAL_STATISTICS.LAST_SEEN'),
                lifeCycleStep: this.generalRewrite.translate(this.localeId + '.TERMINAL_STATISTICS.LIFE_CYCLE_STEP'),
            },
            terminalConfigLabels: {
                title: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.TITLE'),
                id: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.ID'),
                type: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.TYPE'),
                name: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.NAME'),
                timeZone: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.TIMEZONE'),
                macAddress: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.MAC_ADDRESS'),
                serialNr: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.SERIAL_NUMBER'),
                systemCurrency: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.SYSTEM_CURRENCY'),
                distributorNr: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.DISTRIBUTOR_NUMBER'),
                customerNr: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.CUSTOMER_NUMBER'),
                propertyNr: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.PROPERTY_NUMBER'),
                locationId: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.LOCATION_ID'),
            },
            terminalVersionLabel: this.generalRewrite.translate(this.localeId + '.TERMINALS.VERSIONS.TITLE',)
        }
    }


    blockUnblockTerminal(state) {
        let reason = this.reason;
        let blockList = [];


        blockList.push({
            SerialNumber: this.currentTerminalDetails.SerialNumber,
        });

        this.groupServerService.SetTerminalState(blockList, state, reason)
            .subscribe(data => {
                this.zone.run(() => {
                    if (data == 2) {
                        GlobalCacheVariable.toast.next({ severity: ToastSeverity.Success, text: 'Block/Unblock successful', title: 'Block/Unblock successful', life: 5000, type: MessageType.Toast })
                        if (state == MachineStatus.ManufacturerBlock) {
                            this.currentTerminalDetails.ManufacturerBlocked = true;
                        } else {
                            this.currentTerminalDetails.ManufacturerBlocked = false;
                        }
                        this.closeTerminalBlockUnblockModal(state);
                    } else {
                        GlobalCacheVariable.toast.next({ severity: ToastSeverity.Error, text: 'Block/Unblock unsuccessful', title: 'Block/Unblock unsuccessful', life: 5000, type: MessageType.Toast })
                    }
                    console.log("data", data);
                    //this.loadTable(data);
                });
            });
    }

    //TODO filling table
    loadBlockedHistory(serialNumber) {
        if (serialNumber != "") {
            this.groupServerService.GetManufacturerBlockHistory(serialNumber)
                .subscribe((data: TerminalLifeCycle[]) => {
                    this.zone.run(() => {
                        this.historyList = this.transformHistoryData(data);
                        this.historyListTableView = true;
                        console.log("data", data);
                        //this.loadTable(data);
                    });
                });
        }
    }

    transformHistoryData(data: TerminalLifeCycle[]): Array<TerminalLifeCycle> {
        let lifeCycleList = Array<TerminalLifeCycle>();
        for (let entry of data) {
            let lifeCycleEntry = new TerminalLifeCycle();
            let lifeCycleValueEntry = new LifecycleValue();
            lifeCycleValueEntry.ArtID = entry.LifecycleValue.ArtID;
            lifeCycleValueEntry.Reason = entry.LifecycleValue.Reason;
            lifeCycleValueEntry.SerialNumber = entry.LifecycleValue.SerialNumber;
            lifeCycleValueEntry.MachineStatus = entry.LifecycleValue.MachineStatus;
            lifeCycleValueEntry.TerminalID = entry.LifecycleValue.TerminalID;
            lifeCycleValueEntry.MacAddress = entry.LifecycleValue.MacAddress;
            lifeCycleValueEntry.UserName = entry.LifecycleValue.UserName;
            lifeCycleValueEntry.MachineStatus = entry.LifecycleValue.MachineStatus;
            lifeCycleValueEntry.MachineStatusView = this.generalRewrite.enumTranslation(MachineStatus, entry.LifecycleValue.MachineStatus, 'ENUMS.MACHINE_STATUS');


            lifeCycleEntry.Id = entry.Id;
            lifeCycleEntry.ArtInstallationId = entry.ArtInstallationId;
            lifeCycleEntry.SerialNumber = entry.SerialNumber;
            lifeCycleEntry.LifecycleType = entry.LifecycleType;
            lifeCycleEntry.LifecycleTypeView = this.generalRewrite.enumTranslation(LifecycleRecordType, entry.LifecycleType, 'ENUMS.LIFE_CYCLE_RECORD_TYPE.')
            lifeCycleEntry.LifecycleUpdated = entry.LifecycleUpdated;
            lifeCycleEntry.LifecycleCreated = entry.LifecycleCreated;
            lifeCycleEntry.LifecycleValue = lifeCycleValueEntry;
            lifeCycleEntry.Timestamp = entry.Timestamp;

            lifeCycleList.push(lifeCycleEntry);
        }
        return lifeCycleList;

    }

    closeTerminalBlockUnblockModal(state) {
        this.closeTerminalBlockUnblock.emit(state);
        this.viewReady = false;
    }

    protected readonly ViewTypeBlockUnblock = ViewTypeBlockUnblock;
    protected readonly MachineStatus = MachineStatus;
    protected readonly TerminalState = TerminalState;
    protected readonly transform = transform;
}
