import { Component, OnInit, ViewEncapsulation, NgZone, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { ScriptLoaderService } from '../../../../../_services/script-loader.service';
import { GlobalCacheVariable } from '../../../../../auth/_globals/globals';
import { TerminalType } from "../../../../../auth/_enums/terminalType";

@Component({
    selector: "terminal-single-details",
    templateUrl: "./terminal-single-details.component.html",
    encapsulation: ViewEncapsulation.None,
})

export class TerminalSingleDetailsComponent implements OnInit, AfterViewInit, OnChanges {

    //terminalIdentifierListData: any;
    //displayTerminalIdentifierList: boolean = false;
    //@Input() filterSection: boolean;
    @Input('terminalID') terminalID: number;
    // @Input()
    terminal: any;
    displayTerminalIcon: boolean = false;
    sourceComponent: string = 'terminal-single-detail';
    public terminalType: typeof TerminalType = TerminalType;

    constructor(
        private _script: ScriptLoaderService,
        public globalCacheVariable: GlobalCacheVariable,
    ) { }

    getTerminalInformation() {
        for (let i = 0; i < this.globalCacheVariable.Terminals.length; i++) {
            if (this.globalCacheVariable.Terminals[i].TerminalID === this.terminalID) {
                this.terminal = this.globalCacheVariable.Terminals[i];
                this.displayTerminalIcon = true;
            }
        }

        /*
        setTimeout(function () {
            $(".ui-dialog-content").mCustomScrollbar({
                "scrollInertia": 0,
                scrollbarPosition: "outside",
            });

        }, 0);*/
    }

    ngOnInit(): void {
        //this.getTerminalInformation();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getTerminalInformation();
        }
    }

    ngAfterViewInit(): void {
        // this.getTerminalIdentifierList();
    }
}
