import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";

@Injectable()
export class ToastService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private _router: Router) {
        // clear alert message on route change
        _router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    success(message: string, title: string, kind: string = 'toast', life: number = 3000, keepAfterNavigationChange: boolean = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message, title: title, life: life, kind: kind });
    }

    warning(message: string, title: string, kind: string = 'toast', life: number = 3000, keepAfterNavigationChange: boolean = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'warning', text: message, title: title, life: life, kind: kind });
    }

    error(message: string, title: string, kind: string = 'toast', life: number = 3000, keepAfterNavigationChange: boolean = false) {
        console.log("errortoast -> ", message);
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message, title: title, life: life, kind: kind });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
