import { Pipe, PipeTransform } from '@angular/core';
import { CustomAddressPartFlag } from '../_enums/customAddressPartFlag';
import { AddressExt } from "../_models/addressExt";
import { Address } from "../_models/address";
import { Person } from "../_models/person";

@Pipe({
    name: 'effectiveAddress'
})
export class EffectiveAddressPipe implements PipeTransform {
    effectiveAddress: AddressExt;

    transform(adr1: AddressExt, adr2: AddressExt, adr2Flags): AddressExt {
        this.effectiveAddress = new AddressExt();
        this.effectiveAddress.PostalAddress = new Address();
        this.effectiveAddress.ContactPerson = new Person();


        this.effectiveAddress = this.MergeAddressExt(adr1, adr2, adr2Flags);
        return this.effectiveAddress;
    }

    public hasFlag(flags, flag) {
        return (flags & flag) == flag;
    }

    public MergeAddressExt(adr1: AddressExt, adr2: AddressExt, adr2Flags: CustomAddressPartFlag): AddressExt {
        this.effectiveAddress = Object.assign({}, adr1);
        if (adr2Flags == CustomAddressPartFlag.All) {
            return adr2;
        }

        if (this.hasFlag(adr2Flags, CustomAddressPartFlag.Location)) {
            this.effectiveAddress.LocationName = adr2.LocationName;
        }


        if (this.hasFlag(adr2Flags, CustomAddressPartFlag.Location)) {
            this.effectiveAddress.LocationName = adr2.LocationName;
        }


        if (this.hasFlag(adr2Flags, CustomAddressPartFlag.PostalAddress)) {
            this.effectiveAddress.PostalAddress.AddressLine2 = adr2.PostalAddress.AddressLine2;
            this.effectiveAddress.PostalAddress.ZipCode = adr2.PostalAddress.ZipCode;
            this.effectiveAddress.PostalAddress.City = adr2.PostalAddress.City;
            this.effectiveAddress.PostalAddress.County = adr2.PostalAddress.County;
            this.effectiveAddress.PostalAddress.State = adr2.PostalAddress.State;
            this.effectiveAddress.PostalAddress.Country = adr2.PostalAddress.Country;
            this.effectiveAddress.PostalAddress.Street = adr2.PostalAddress.Street;
        }

        if (this.hasFlag(adr2Flags, CustomAddressPartFlag.ContactInfo)) {
            this.effectiveAddress.PhoneNumber = adr2.PhoneNumber;
            this.effectiveAddress.EmailAddress = adr2.EmailAddress;
        }


        if (this.hasFlag(adr2Flags, CustomAddressPartFlag.ContactPerson)) {
            this.effectiveAddress.ContactPerson.Name = adr2.ContactPerson.Name;
            this.effectiveAddress.ContactPerson.PhoneNumber = adr2.ContactPerson.PhoneNumber;
            this.effectiveAddress.ContactPerson.EmailAddress = adr2.ContactPerson.EmailAddress;
        }

        if (this.hasFlag(adr2Flags, CustomAddressPartFlag.TimeZone)) {
            this.effectiveAddress.TimeZone = adr2.TimeZone;
        }

        return this.effectiveAddress;

    }


}
