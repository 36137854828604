import { Pipe, PipeTransform } from '@angular/core';
import { GlobalCacheVariable } from '../../auth/_globals/globals';


@Pipe({
    name: 'terminalName'
})
export class TerminalNamePipe implements PipeTransform {
    terminalArray: any;
    constructor(
        public globalCacheVariable: GlobalCacheVariable,
    ) {
        this.terminalArray = this.globalCacheVariable.TerminalsMap;
    }

    transform(value: number): string {

        let terminalName = this.getTerminalNameFromID(value);
        return terminalName;
    }

    getTerminalNameFromID(value) {
        try {
            if (value === 0) {
                return 'all';
            }
            else {
                this.terminalArray = this.globalCacheVariable.TerminalsMap;
                let name = this.terminalArray.get(value);
                return name.TerminalName;
            }
        }
        catch (e) {
            return undefined;
        }

    }

}
