import { Pipe, PipeTransform } from '@angular/core';
import { VpnState } from '../_enums/vpnState';

@Pipe({
    name: 'vpnState'
})
export class VpnStatePipe implements PipeTransform {

    transform(vpnSate: number): any {
        switch (vpnSate) {
            case VpnState.None:
                return "None";
            case VpnState.NotInstalled:
                return "Not Installed";
            case VpnState.Installed:
                return "Installed";
            case VpnState.Configured:
                return "Configured";
            case VpnState.Usable:
                return "Usable";
            case VpnState.UsingFallback:
                return "Using Fallback";
            default:
                return vpnSate
        }
    }
}
