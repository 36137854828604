import { Pipe, PipeTransform } from '@angular/core';
import { ComponentType } from '../_enums/componentType';
@Pipe({
    name: 'componentType'
})
export class ComponentTypePipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];

        return ComponentType[value];
    }
}
