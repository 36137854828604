import { Injectable } from "@angular/core";
import { ChartColors } from "../_models/chartColors";

@Injectable()
export class GraphConfigurations {

    chartDefaultsPie: ChartColors = {
        colors: [
            "#FFCE56",
            "#ce5952",
            "#125471",
            "#6daf6a",
            "#a799c8",
            "#efdd11",
            "#4c8198",
            "#bdc520",
            "#ffffff",
            "#f4516c",
            "#8d8d8d",
            "#589560",
            "#e1abd4",
            "#ea9d04",
            "#aaebcb",
            "#dd8ca7",
            "#3e7187",
            "#9a9a9a",
            "#ab5759"
        ],

        hoverColors: [
            "#FFCE56",
            "#ce5952",
            "#125471",
            "#6daf6a",
            "#a799c8",
            "#efdd11",
            "#4c8198",
            "#bdc520",
            "#ffffff",
            "#f4516c",
            "#8d8d8d",
            "#589560",
            "#e1abd4",
            "#ea9d04",
            "#aaebcb",
            "#dd8ca7",
            "#3e7187",
            "#9a9a9a",
            "#ab5759"
        ],
    };

    chartDefaultsLine: ChartColors = {
        colors: ["#FFCE56",
            "#ce5952",
            "#6daf6a",
            "#a799c8",
            "#ffffff",
            "#f4516c",
            "#efdd11",
            "#4c8198",
            "#bdc520",
            "#8d8d8d",
            "#589560",
            "#e1abd4",
            "#ea9d04",
            "#aaebcb",
            "#dd8ca7",
            "#3e7187",
            "#9a9a9a",
            "#ab5759",
            "#125471"],

        hoverColors: ["#FFCE56",
            "#ce5952",
            "#6daf6a",
            "#a799c8",
            "#ffffff",
            "#f4516c",
            "#efdd11",
            "#4c8198",
            "#bdc520",
            "#8d8d8d",
            "#589560",
            "#e1abd4",
            "#ea9d04",
            "#aaebcb",
            "#dd8ca7",
            "#3e7187",
            "#9a9a9a",
            "#ab5759",
            "#125471",],
    };

    chartDowntimePie: ChartColors = {
        colors: [
            "#FFCE56",
            "#ce5952",
            "#4c8198",
            "#6daf6a",
            "#a799c8",
            "#8d8d8d",
            "#125471",
            "#9a9a9a",
            "#efdd11",
            "#f4516c",
            "#ffffff",
            "#589560",
            "#e1abd4",
            "#ea9d04",
            "#aaebcb",
            "#dd8ca7",
            "#3e7187",
            "#bdc520",
            "#ab5759"
        ],

        hoverColors: [
            "#FFCE56",
            "#ce5952",
            "#4c8198",
            "#6daf6a",
            "#a799c8",
            "#8d8d8d",
            "#125471",
            "#9a9a9a",
            "#efdd11",
            "#f4516c",
            "#ffffff",
            "#589560",
            "#e1abd4",
            "#ea9d04",
            "#aaebcb",
            "#dd8ca7",
            "#3e7187",
            "#bdc520",
            "#ab5759"
        ],
    };

    graphOptions: any = {
        optionsBar: {
            legend: {
                position: 'top',
                labels: {
                    fontColor: '#fff'
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        fontColor: '#fff'
                    },
                }],
                xAxes: [{
                    ticks: {
                        fontColor: '#fff'
                    },
                }]
            }
        },
    }
}
