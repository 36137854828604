import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges, Output, EventEmitter
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services/terminal.service';

import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';

import { Location, UpperCasePipe } from "@angular/common";
import { Helpers } from "../../../../../../helpers";
import { TerminalType } from "../../../../../../auth/_enums/terminalType";
import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { TerminalConnectionType } from "../../../../../../auth/_enums/terminalConnectionType";
import { TerminalTypeNamingPipe } from "../../../../../../auth/_pipes/terminalTypeNaming.pipe";
import { TranslatePipe } from "@ngx-translate/core";
import { RewriteEnumEntriesForTranslationPipe } from "../../../../../../auth/_pipes/rewriteEnumEntriesForTranslation.pipe";
import { RewriteService } from "../../../../../../auth/_services/rewrite.service";


@Component({
    selector: "terminal-identifier-filter",
    templateUrl: "./terminal-identifier-filter.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [TerminalTypeNamingPipe, TranslatePipe, RewriteEnumEntriesForTranslationPipe, UpperCasePipe]
})

export class TerminalIdentifierFilterComponent implements OnInit, AfterViewInit {
    @Output()
    terminalRefresh: EventEmitter<String> = new EventEmitter<String>(); //creating an output event

    terminalType: any;
    terminalTypes: any;
    propertyArray: any;
    terminalConnection: any;
    terminalState: any;
    types: any;
    faDeleteLeft = faDeleteLeft;

    constructor(private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        private location: Location,
        public globalCacheVariable: GlobalCacheVariable,
        private rewriteService: RewriteService,
        private terminalTypeNaming: TerminalTypeNamingPipe,
        private translatePipe: TranslatePipe,
        private rewriteEnumEntriesForTranslationPipe: RewriteEnumEntriesForTranslationPipe,
        private upperCasePipe: UpperCasePipe,
        private zone: NgZone,
    ) {
        this.terminalType = this.fillTerminalType();
        this.terminalConnection = this.fillTerminalConnection();
        this.terminalTypes = this.fillTerminalTypes();
        this.propertyArray = this.rewriteService.createPropertyChooserArray('test', 'all');
    }

    onFilterSubmit(a, b) {

    }

    fillTerminalConnection() {
        let terminalConnectionState = []
        for (let item in TerminalConnectionType) {
            if (isNaN(Number(item))) {
                if (item != TerminalConnectionType[2]) {
                    terminalConnectionState.push({
                        label: this.translatePipe.transform(this.upperCasePipe.transform(this.rewriteEnumEntriesForTranslationPipe.transform('ENUMS.TERMINAL_CONNECTION_STATE.' + item, 'ENUMS.TERMINAL_CONNECTION_STATE.'))),
                        value: TerminalConnectionType[item]
                    });
                }
            }
        }
        var sortingByLabel = terminalConnectionState.slice(0);
        sortingByLabel.sort(function(a, b) {
            var x = a.label.toLowerCase();
            var y = b.label.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        return terminalConnectionState.sort();
    }

    fillTerminalTypes() {
        let terminalTypeArray = [];
        let terminalType = [];
        terminalTypeArray = this.globalCacheVariable.TerminalTypes;
        for (let i = 0; i < terminalTypeArray.length; i++) {
            terminalTypeArray[i] = TerminalType[terminalTypeArray[i]];
        }
        let terminalTypeArraySorted = terminalTypeArray.sort();

        terminalType.push({
            label: "All",
            value: "all",
        });
        for (let i = 0; i < terminalTypeArraySorted.length; i++) {
            terminalType.push({
                label: this.terminalTypeNaming.transform(terminalTypeArraySorted[i]),
                value: TerminalType[terminalTypeArraySorted[i]],
            });
        }
        //console.log("terminalTypes", terminalType);
        return terminalType;
    }

    fillTerminalType() {
        let terminalTypeArray = [];
        for (let item in TerminalType) {
            if (isNaN(Number(item))) {
                terminalTypeArray.push(item);
                //console.log(item);
            }
        }

        return terminalTypeArray.sort();
    }

    fillTerminalState() {
        this.terminalState = [];
        this.terminalState.push({
            label: "All",
            value: "all",
        }, {
            label: "Idle",
            value: "Idle",
        }, {
            label: "In Transaction",
            value: "InTransaction",
        }, {
            label: "Operator Login",
            value: "OperatorLogin",
        }, {
            label: "Service Login",
            value: "ServiceLogin",
        }, {
            label: "Out of Operation",
            value: "OutOfOperation ",
        });
    }

    resetFreeText() {
        this.globalCacheVariable.filter.terminalIdentifierFilter.freetext = "";
        this.filterTerminalView();
    }

    refreshTerminal() {
        this.terminalRefresh.emit();

    }

    filterTerminalView() {
        let filter = {
            "terminalType": this.globalCacheVariable.filter.terminalIdentifierFilter.terminalType,
            "property": this.globalCacheVariable.filter.terminalIdentifierFilter.property,
            "freetext": this.globalCacheVariable.filter.terminalIdentifierFilter.freeText,
            "terminalState": this.globalCacheVariable.filter.terminalIdentifierFilter.terminalState,
        };

        this.globalCacheVariable.setFilter("terminalIdentifierFilter", filter);
        this.terminalService.filterTerminalIdentifierVisibility(this.globalCacheVariable.TerminalsIdentifier, "multiple");
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
    }


}
