<div class="terminal-single-details-container">
    <p-card [style]="{width: '25%', float:'left'}">
        <p-tabView>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.STATUS' | translate}}">
                <div class="icon-size-oversized">
                    <terminal-icon [terminal]='terminal' *ngIf="displayTerminalIcon" class="terminal-single-details-icon-container"></terminal-icon>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.SETTINGS' | translate}}">
                <terminal-single-details-terminal-edit [terminalID]='terminalID'></terminal-single-details-terminal-edit>
            </p-tabPanel>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.ADDRESS' | translate}}">
                <terminal-single-details-terminal-address [terminalID]='terminalID'></terminal-single-details-terminal-address>
            </p-tabPanel>
        </p-tabView>
    </p-card>
    <p-card [style]="{width: '70%', float:'right', marginRight:'15px'}">
        <p-tabView [scrollable]="true">
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.TRANSACTIONS' | translate}}" *ngIf="terminal.TerminalType !== terminalType.AdPlayer">
                <div>
                    <terminal-single-details-transaction-log [terminalID]='terminalID'></terminal-single-details-transaction-log>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.ERRORS' | translate}}">
                <div>
                    <terminal-single-details-error-log [terminalID]='terminalID'></terminal-single-details-error-log>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.EVENTS' | translate}}">
                <div><terminal-single-details-event-log [terminalID]='terminalID'></terminal-single-details-event-log></div>
            </p-tabPanel>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.MEDIA' | translate}}" *ngIf="terminal.TerminalType !== terminalType.AdPlayer">
                <div>
                    <terminal-single-details-media-log [terminalID]='terminalID'></terminal-single-details-media-log>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.MAINTENANCE' | translate}}">
                <div>
                    <terminal-single-details-maintenance-log [terminalID]='terminalID'></terminal-single-details-maintenance-log>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.OPERATOR_ACCESS' | translate}}">
                <div>
                    <terminal-single-details-operator-access-log [terminalID]='terminalID'></terminal-single-details-operator-access-log>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.CONFIGURATION_CHANGES' | translate}}">
                <div>
                    <terminal-single-details-configuration-log [terminalID]='terminalID'></terminal-single-details-configuration-log>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.STOCK_VERSION' | translate}}">
                <div class="detail-open">
                    <terminal-details [terminal]='terminal' [terminalID]='terminalID' [sourceComponent]='sourceComponent'></terminal-details>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{'TERMINAL.SINGLE_DETAILS.TABS.IDENTIFIERS' | translate}}">
                <div>
                    <terminal-single-details-identifier [terminalID]='terminalID'></terminal-single-details-identifier>
                </div>
            </p-tabPanel>
        </p-tabView>
    </p-card>
</div>
