<terminal-identifier-filter *ngIf="filterSection" (terminalRefresh)="terminalRefresh($event)"></terminal-identifier-filter>
<p-table [(contextMenuSelection)]="currentContextMenuID" [contextMenu]="cm" [columns]="columns" [value]="globalCacheVariable.TerminalsIdentifier" [scrollable]="true" [style]="{width:'auto'}"
         class="terminal-identifier-table content-table" scrollHeight="flex" *ngIf="displayTerminalIdentifierList">
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:200px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col *ngIf="includeConfigVersion == true" style="width:150px">
            <col style="width:150px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
                <ng-container *ngIf="col.field !== 'ConfigVersion'">
                    <th class="{{col.class}}" [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </ng-container>
                <ng-container *ngIf="col.field == 'ConfigVersion'">
                    <ng-container *ngIf="includeConfigVersion == true">
                        <th class="{{col.class}}" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                    </ng-container>
                </ng-container>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-entry>
        <tr (contextmenu)="contextMenuChange($event, entry.TerminalID)"  [pContextMenuRow]="rowData" *ngIf="entry.Visible === true">
            <td class="tb-right">{{entry.TerminalID}}</td>
            <td class="tb-left">{{entry.IPAddress}}</td>
            <td class="tb-left">{{entry.SerialNumber}}</td>
            <td class="tb-left">{{entry.MacAddress}}</td>
            <td class="tb-left">{{entry.CpuArchitectureHardware | cpuArch}}</td>
            <td class="tb-left">{{entry.CpuArchitectureSoftware | cpuArch}}</td>
            <td class="tb-left">{{entry.TerminalName}}</td>
            <td class="tb-left">{{entry.LocationName}}</td>
            <td class="tb-left">{{entry.TimeZone}}</td>
            <td class="tb-left">{{entry.SystemCurrency}}</td>
            <td class="tb-right">{{entry.DistributorID}}</td>
            <td class="tb-right">{{entry.CustomerID}}</td>
            <td class="tb-right">{{entry.PropertyNumber}}</td>
            <td class="tb-right">{{entry.LocationID}}</td>
            <td class="tb-right">{{entry.AtmTerminalID}}</td>
            <td class="tb-left">{{entry.OperatingSystem}}</td>
            <td class="tb-left">{{entry.RuntimeEnvironment}}</td>
            <td class="tb-left">{{entry.SoftwareVersion}}</td>
            <td *ngIf="includeConfigVersion == true" class="tb-left">{{entry.ConfigVersion}}</td>
            <td class="tb-left">{{entry.OnlineSystem}}</td>
            <td class="tb-left">{{entry.VpnState | vpnState}}</td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cm [model]="contextMenuMachine" appendTo="body"></p-contextMenu>

<p-dialog styleClass="terminal-single-dialog-view" [closeOnEscape]="true" [minX]="500" [minY]="500" [breakpoint]="500"
          [responsive]="true" [resizable]="false" [draggable]="false" [closable]="false"
          (onShow)="showDialogMaximized($event, terminalDashboardView)" #terminalDashboardView
          [(visible)]="globalCacheVariable.modal.terminalSingleDetail.displayTerminalSingleDetail"
          *ngIf="terminalSingleDetailView === true" [modal]="true">
    <p-header>{{currentTerminalName}} - Dashboard
        <i class="close-button fa fa-times"
           (click)="closeTerminalSingleDetails()"></i></p-header>
    <terminal-single-details [terminalID]='currentTerminalID'></terminal-single-details>
</p-dialog>

<p-dialog styleClass="terminal-feauture-dialog-view" [closeOnEscape]="true" [minX]="1000" [minY]="700" [breakpoint]="500"
          [responsive]="true" [resizable]="false" [draggable]="false" [closable]="false"
          [(visible)]="displayFeatureOverview" [style]="{width: '100vw', 'min-height': '100vh'}"
          (onShow)="showDialogMaximized($event,featureOverviewDialog)" #featureOverviewDialog
          *ngIf="featureOverviewView === true" [modal]="true">
    <p-header>{{currentTerminalName}} - Feature Overview
        <i class="close-button fa fa-times"
           (click)="displayFeatureOverview=false"></i></p-header>
    <feature-overview [currentTerminalID]='currentTerminalID'></feature-overview>
</p-dialog>

<p-toast styleClass="terminal-confirmation-toast" position="center" key="s" (onClose)="onRejectSoftwareRestart()"
         [baseZIndex]="10000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center" style="text-align:center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-4">
                    <p-button (click)="onConfirmSoftwareRestart(false)"
                              style="width:100%; height:100%" styleClass="full-width-button">{{'TERMINAL.CONFIRMATION_TOASTS.SOFTWARE_RESTART.IDLE' | translate}}</p-button>
                </div>
                <div class="p-col-4">
                    <p-button (click)="onConfirmSoftwareRestart(true)"
                              style="width:100%; height:100%" styleClass="ui-button-delete full-width-button">{{'TERMINAL.CONFIRMATION_TOASTS.SOFTWARE_RESTART.IMMEDIATELY' | translate}}</p-button>
                </div>
                <div class="p-col-4">
                    <p-button (click)="onRejectSoftwareRestart()"
                              style="width:100%; height:100%" styleClass="full-width-button">{{'TERMINAL.CONFIRMATION_TOASTS.GENERAL.CANCEL' | translate}}</p-button>
                </div>
            </div>
        </div>

    </ng-template>
</p-toast>

<p-toast styleClass="terminal-confirmation-toast" position="center" key="h"
         (onClose)="onRejectHardwareRestart()" [baseZIndex]="10000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center" style="text-align:center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-4">
                    <p-button (click)="onConfirmHardwareRestart(false)"
                              style="width:100%; height:100%" styleClass="full-width-button">{{'TERMINAL.CONFIRMATION_TOASTS.HARDWARE_RESTART.IDLE' | translate}}</p-button>
                </div>
                <div class="p-col-4">
                    <p-button (click)="onConfirmHardwareRestart(true)"
                              style="width:100%; height:100%" styleClass="full-width-button">{{'TERMINAL.CONFIRMATION_TOASTS.HARDWARE_RESTART.IMMEDIATELY' | translate}}</p-button>
                </div>
                <div class="p-col-4">
                    <p-button (click)="onRejectHardwareRestart()"
                              style="width:100%; height:100%" styleClass="full-width-button">{{'TERMINAL.CONFIRMATION_TOASTS.GENERAL.CANCEL' | translate}}</p-button>
                </div>
            </div>
        </div>

    </ng-template>
</p-toast>

<p-toast styleClass="terminal-confirmation-toast" position="center" key="sh"
         (onClose)="onRejectShutdown()" [baseZIndex]="10000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center" style="text-align:center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-4">
                    <p-button (click)="onConfirmShutdown(false)"
                              style="width:100%; height:100%" styleClass="full-width-button">{{'TERMINAL.CONFIRMATION_TOASTS.SHUTDOWN.IDLE' | translate}}</p-button>
                </div>
                <div class="p-col-4">
                    <p-button (click)="onConfirmShutdown(true)"
                              style="width:100%; height:100%" styleClass="full-width-button">{{'TERMINAL.CONFIRMATION_TOASTS.SHUTDOWN.IMMEDIATELY' | translate}}</p-button>
                </div>
                <div class="p-col-4">
                    <p-button (click)="onRejectShutdown()"
                              style="width:100%; height:100%" styleClass="full-width-button">{{'TERMINAL.CONFIRMATION_TOASTS.GENERAL.CANCEL' | translate}}</p-button>


                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

