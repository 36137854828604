import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { GlobalCacheVariable } from "../../../../../../auth/_globals/globals";
import { EffectiveAddressPipe } from "../../../../../../auth/_pipes/effectiveAddress.pipe";
import { AddressExt } from "../../../../../../auth/_models/addressExt";
import { Address } from "../../../../../../auth/_models/address";
import { Person } from "../../../../../../auth/_models/person";
import { ComponentStateMapper } from '../../../../../../auth/_helpers/componentState';
import { ComponentState } from '../../../../../../auth/_enums/componentState';
import { GeneralRewritePipe } from '../../../../../../auth/_pipes/generalRewrite.pipe';
import { IArtInstallationDetails } from '../../../../../../auth/_models/group/artInstallationDetails';
import { ITableColumn } from '../../../../../../auth/_models/tableColumn';
import { VersionType } from '../../../../../../auth/_enums/versionType';
import {
    IArtDetailsTerminalDetailsView,
    IArtDetailsTerminalState,
    IArtDetailsTerminalView
} from '../../../../../../auth/_models/group/artDetailsTerminalView';
import { DatePipe } from '@angular/common';
import { IContactInfosLabel, IContactPersonLabel, ILocationLabel } from '../../../../../../auth/_models/group/locationL';
import { LifeCycleStep } from '../../../../../../auth/_enums/lifeCycleStep';
import { Dialog } from 'primeng/dialog';
import { IProperty } from '../../../../../../auth/_models/group/property';
import { ITerminalDetailsLabels } from '../../../../../../auth/_models/group/artIntallationDetailsLabels';
import { GroupServerService } from "../../../../../../auth/_services/groupserver.service";
import { MenuItem } from "primeng/api";
import { TerminalState } from "../../../../../../auth/_enums/terminalState";
import { MachineStatus } from "../../../../../../auth/_enums/machineStatus";

@Component({
    selector: "art-installation-terminal-details",
    templateUrl: "./terminal-details.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [EffectiveAddressPipe, DatePipe]
})
export class ARTInstallationTerminalDetailsComponent implements OnInit, OnChanges {

    @Input('artInstallationDetails')
    artInstallationDetails: IArtInstallationDetails;

    @Input('currentTerminalDetails')
    currentTerminalDetails: IArtDetailsTerminalView;

    @Input('currentTerminalDetailsId')
    currentTerminalId: number;

    // Location
    @Input('locationLabel')
    locationLabel: ILocationLabel;

    // Contact infos
    @Input('contactInfosLabel')
    contactInfosLabel: IContactInfosLabel;

    // Contact person
    @Input('contactPersonLabel')
    contactPersonLabel: IContactPersonLabel;

    @Output()
    closeTerminalDetails: EventEmitter<object> = new EventEmitter<object>();

    columns: ITableColumn[];
    viewReady: boolean = false;
    artInstallationCheck: IArtInstallationDetails;
    currentTerminalDetailsView: IArtDetailsTerminalDetailsView;

    // TerminalDetails
    columnsTerminalDetails: ITableColumn[];

    // TerminalDetails Dialog
    displayArtDetailsTerminal: boolean = false;
    artDetailsTerminalView: boolean = false;
    terminalDetailTableView: boolean = false;
    artDetailsTerminalBlockUnblockView: boolean = false;
    terminalVersionsList: { name: string, type: string, version: string }[];
    terminalCount: number = 0;
    versionCount: number = 0;
    versionCountMax: number = 0;
    currentPropertyAddress: AddressExt;
    currentAddress: AddressExt;
    currentProperty: IProperty;
    terminalConnected: IArtDetailsTerminalState;
    terminalState: IArtDetailsTerminalState;
    blockState: boolean;
    componentState: IArtDetailsTerminalState;
    blockMenuItems: MenuItem[];

    // Labels
    goToWebSiteLabel: string;
    labels: ITerminalDetailsLabels;
    findButton: string;
    localeId: string = 'STATISTICS.ART_OVERVIEW.MODALS';
    LabelBlockUnblock: any;

    constructor(
        public globalCacheVariable: GlobalCacheVariable,
        private generalRewritePipe: GeneralRewritePipe,
        private effectiveAddressPipe: EffectiveAddressPipe,
        private componentStates: ComponentStateMapper,
        private datePipe: DatePipe,
        private groupServerService: GroupServerService,
        private zone: NgZone
    ) {

        // Labels
        this.labels = {
            terminalState: {
                title: generalRewritePipe.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.TITLE'),
                connected: generalRewritePipe.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.CONNECTED'),
                main: generalRewritePipe.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.MAIN'),
                componentState: generalRewritePipe.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.COMPONENT_STATE'),
            },
            terminalStatistics: {
                title: generalRewritePipe.translate(this.localeId + '.TERMINAL_STATISTICS.TITLE'),
                firstSeen: generalRewritePipe.translate(this.localeId + '.TERMINAL_STATISTICS.FIRST_SEEN'),
                lastSeen: generalRewritePipe.translate(this.localeId + '.TERMINAL_STATISTICS.LAST_SEEN'),
                lifeCycleStep: generalRewritePipe.translate(this.localeId + '.TERMINAL_STATISTICS.LIFE_CYCLE_STEP'),
            },
            terminalConfigLabels: {
                title: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.TITLE'),
                id: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.ID'),
                type: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.TYPE'),
                name: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.NAME'),
                timeZone: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.TIMEZONE'),
                macAddress: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.MAC_ADDRESS'),
                serialNr: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.SERIAL_NUMBER'),
                systemCurrency: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.SYSTEM_CURRENCY'),
                distributorNr: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.DISTRIBUTOR_NUMBER'),
                customerNr: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.CUSTOMER_NUMBER'),
                propertyNr: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.PROPERTY_NUMBER'),
                locationId: generalRewritePipe.translate(this.localeId + '.TERMINAL_CONFIGURATION.LOCATION_ID'),
            },
            terminalVersionLabel: generalRewritePipe.translate(this.localeId + '.TERMINALS.VERSIONS.TITLE',)
        }
        this.LabelBlockUnblock = {
            BlockUnblock: this.generalRewritePipe.translate('BASE.TERMINAL_BLOCK_UNBLOCK'),
            History: this.generalRewritePipe.translate('BASE.HISTORY'),
            ManufactureBlock: 'Manufacturer Block',
            ManufactureUnblock: 'Manufacturer Unblock'
        }

        this.findButton = generalRewritePipe.translate('BASE.SEARCH.FIND');

        this.columnsTerminalDetails = [
            {
                field: 'Type',
                header: generalRewritePipe.translate(this.localeId + '.TERMINALS.VERSIONS.TABLE.VERSION_TYPE'),
                class: 'tb-left'
            },
            {
                field: 'Name',
                header: generalRewritePipe.translate(this.localeId + '.TERMINALS.VERSIONS.TABLE.NAME'),
                class: 'tb-left'
            },
            {
                field: 'Version',
                header: generalRewritePipe.translate(this.localeId + '.TERMINALS.VERSIONS.TABLE.VERSION'),
                class: 'tb-right'
            },
        ];
    }

    ngOnInit(): void {
        this.goToWebSiteLabel = this.generalRewritePipe.translate(
            this.localeId + '.GO_TO_WEBSITE', { Website: this.artInstallationDetails.ArtWebClientUri }
        );
        // console.log("artInstallationDetails", this.artInstallationDetails);
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.currentTerminalId.currentValue !== changes.currentTerminalId.previousValue) {
            this.openTerminalDetail(this.currentTerminalDetails);
        }

    }

    onFilter(event) {
        this.terminalCount = event.filteredValue.length;
    }

    onFilterVersions(event) {
        this.versionCount = event.filteredValue.length;
    }

    openTerminalBlockUnblock(terminal: IArtDetailsTerminalDetailsView) {
        // console.log("terminalId blockUnblock", terminal);
        this.artDetailsTerminalBlockUnblockView = true;
    }

    openTerminalBlockUnblockHistory(terminal: IArtDetailsTerminalDetailsView) {
        // console.log("terminalId blockUnblock", terminal);
        //this.artDetailsTerminalBlockUnblockView = true;
    }

    closeTerminalBlockUnblock() {
        this.artDetailsTerminalBlockUnblockView = false;
    }


    closeTerminalDetailsModal() {
        this.closeTerminalDetails.emit();
        this.viewReady = false;
    }

    showDialogMaximized(event, dialog: Dialog) {
        dialog.maximize();
    }

    openTerminalDetail(terminal: IArtDetailsTerminalView) {
        this.terminalVersionsList = [];
        this.currentAddress = new AddressExt();
        this.currentPropertyAddress = new AddressExt();
        this.currentPropertyAddress.PostalAddress = new Address();
        this.currentPropertyAddress.ContactPerson = new Person();
        this.currentAddress.PostalAddress = new Address();
        this.currentAddress.ContactPerson = new Person();
        this.artInstallationCheck = Object.assign({}, this.artInstallationDetails);

        if (this.artInstallationCheck.Properties.length > 0) {

            for (let property of this.artInstallationCheck.Properties) {

                if (terminal.TerminalFull.PropertyNo === property.PropertyNo) {
                    this.currentProperty = property;
                    break;
                }

            }

            if (this.currentProperty.AddressFlags !== 0) {
                //merge address
                this.currentPropertyAddress = this.effectiveAddressPipe.transform(
                    this.artInstallationCheck.AddressExt, this.currentProperty.AddressExt, this.currentProperty.AddressFlags
                );
            }
            else {
                this.currentPropertyAddress = this.artInstallationCheck.AddressExt;
            }

            if (terminal.TerminalFull.AddressFlags !== 0) {
                //merge address
                this.currentAddress = this.effectiveAddressPipe.transform(
                    this.currentPropertyAddress, terminal.TerminalFull.AddressExt, terminal.TerminalFull.AddressFlags
                );
            }
            else {
                this.currentAddress = this.currentPropertyAddress;
            }

        }
        else {

            if (terminal.TerminalFull.AddressFlags !== 0) {
                //merge address
                this.currentAddress = this.effectiveAddressPipe.transform(
                    this.artInstallationCheck.AddressExt, terminal.TerminalFull.AddressExt, terminal.TerminalFull.AddressFlags
                );
            }
            else {
                this.currentAddress = this.artInstallationCheck.AddressExt;
            }

        }

        // Terminal State Card
        this.terminalConnected = terminal.Connected;
        this.terminalState = terminal.TerminalState;
        this.blockState = terminal.ManufacturedBlocked;
        let componentState = ComponentState.None;

        let foundState = terminal.Components.find(component => component.ComponentState == ComponentState.Error);

        if (foundState) {
            componentState = foundState.ComponentState;
        }
        else {
            foundState = terminal.Components.find(component => component.ComponentState == ComponentState.Warning);

            if (foundState) {
                componentState = foundState.ComponentState;
            }
            else {
                foundState = terminal.Components.find(component => component.ComponentState == ComponentState.Warning);

                if (foundState) {
                    componentState = foundState.ComponentState;
                }

            }

        }

        this.componentState = {
            class: this.componentStates.componentStateClass.get(componentState),
            text: this.generalRewritePipe.translate(this.componentStates.componentStateTexts.get(componentState))
        }

        for (let terminalVersion of terminal.TerminalVersions) {

            this.terminalVersionsList.push({
                name: terminalVersion.Name,
                type: this.generalRewritePipe.enumTranslation(VersionType, terminalVersion.Type, 'ENUMS.VERSION.'),
                version: terminalVersion.Version,
            })

        }

        this.currentTerminalDetailsView = {
            ...terminal.TerminalFull,
            TerminalTypeName: terminal.TerminalType,
            LifeCycleStepTxt: this.generalRewritePipe.enumTranslation(
                LifeCycleStep, terminal.TerminalFull.LifeCycleStep, 'ENUMS.LIFE_CYCLE_STEP.'
            )
        };

        this.currentTerminalDetailsView.FirstSeen = this.datePipe.transform(
            this.currentTerminalDetailsView.FirstSeen,
            this.globalCacheVariable.globalFormatSettings.dateFormatLong
        )

        this.currentTerminalDetailsView.LastSeen = this.datePipe.transform(
            this.currentTerminalDetailsView.LastSeen,
            this.globalCacheVariable.globalFormatSettings.dateFormatLong
        )

        let items = [];
        items.push({ separator: true });
        if (this.currentTerminalDetailsView.TerminalState == TerminalState.ManufacturedBlocked || this.currentTerminalDetailsView.ManufacturerBlocked) {
            items.push({ label: this.LabelBlockUnblock.ManufactureUnblock, icon: 'pi pi-thumbs-up', command: () => { this.openTerminalBlockUnblock(this.currentTerminalDetailsView); } })
        }
        if (this.currentTerminalDetailsView.TerminalState != TerminalState.ManufacturedBlocked && !this.currentTerminalDetailsView.ManufacturerBlocked) {
            items.push({ label: this.LabelBlockUnblock.ManufactureBlock, icon: 'pi pi-thumbs-down', command: () => { this.openTerminalBlockUnblock(this.currentTerminalDetailsView); } })
        }
        items.push({ separator: true });
        items.push({ label: this.LabelBlockUnblock.History, icon: 'pi pi-list', command: () => { this.openTerminalBlockUnblockHistory(this.currentTerminalDetailsView); } })

        this.blockMenuItems = [
            {
                label: this.LabelBlockUnblock.BlockUnblock,
                items: items
            }
        ];

        this.versionCount = terminal.TerminalVersions.length;
        this.versionCountMax = terminal.TerminalVersions.length;
        this.displayArtDetailsTerminal = true;
        this.artDetailsTerminalView = true;
        this.terminalDetailTableView = true;
        this.viewReady = true;
    }

    protected readonly MachineStatus = MachineStatus;
}
