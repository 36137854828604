import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalCacheVariable } from '../_globals/globals';
import { map } from 'rxjs/operators';
import { ApiDataConverter } from "../_helpers/api-data-converter";
import { ApiCallType } from "../_enums/apiCallType";
import { UserRight } from '../_enums/group/userRight';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GeneralService {

    public currentUser: any;
    public sessionKey: any;

    constructor(
        private http: HttpClient,
        public cache: GlobalCacheVariable
    ) { }

    private static extractBridge(res, apiType) {
        let response = ApiDataConverter.extractData(res, apiType);
        return response || {};
    }

    getArtServerVersionNumber() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.sessionKey = this.currentUser.SessionKey;
        try {
            const data = { strSessionKey: this.sessionKey };
            const body = JSON.stringify(data);
            return this.http.post(this.cache.ARTApi + 'GetArtServerVersionNumber', body, httpOptions)
                .pipe(map(data => {
                    return GeneralService.extractBridge(data, ApiCallType.GetArtServerVersionNumber);
                })
                );
        } catch (error) {
            console.log(error);
        }
    }

    hasRight(flags: number, flag: UserRight): boolean {
        return (flags & flag) == flag;
    }

}

