import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services/terminal.service';

import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';

import { LoggingService } from '../../../../../../auth/_services/log.service';
import { ConfigurationChangeLogListItem } from '../../../../../../auth/_models/configurationchangeloglistitem';
import * as moment from "moment";


@Component({
    selector: "terminal-single-details-configuration-log",
    templateUrl: "./terminal-single-details-configuration-log.component.html",
    encapsulation: ViewEncapsulation.None,
})

export class TerminalSingleDetailsConfigurationChanceLogComponent implements OnInit, AfterViewInit, OnChanges {

    @Input('terminalID') terminalID: number;

    configurationChangeLog: any;
    paginatorPage: number = 1;
    paginatorRowsPerPage: number = 20;
    configurationLogView: boolean = false;

    constructor(
        private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        private loggingService: LoggingService,
        public globalCacheVariable: GlobalCacheVariable,
        private zone: NgZone,
    ) {
        this.configurationChangeLog = new Array<ConfigurationChangeLogListItem>();
    }

    getConfigurationLogData() {
        let startDate = moment().subtract(1000, "days");
        let endDate = moment();
        this.loggingService.getRecentConfigurationChangeLogs(this.terminalID, 10)
            .subscribe(data => {
                this.zone.run(() => {
                    this.configurationChangeLog = data;
                    this.configurationLogView = true;
                    setTimeout(function() {
                        //$(".terminal-single-details-container .configuration-log-table .ui-table-scrollable-body").mCustomScrollbar("destroy");
                        /*$(".terminal-single-details-container .configuration-change-log .ui-table-scrollable-body").mCustomScrollbar({
                            "scrollInertia": 0,
                            scrollbarPosition: "outside",
                            axis: "yx",
                            callbacks: {    // callback for thead,tbody scroll
                                whileScrolling: function () {
                                    $(".ui-table-scrollable-header").scrollLeft(this.mcs.left * -1);
                                },
                            },
                        });*/

                    }, 0);
                });
            });
    }

    ngOnInit(): void {
        //this.getConfigurationLogData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getConfigurationLogData();
        }
    }

    ngAfterViewInit(): void {
        // this.getTerminalIdentifierList();
    }
}
