import { Pipe, PipeTransform } from '@angular/core';
import { ArtInstallationMode } from '../_enums/group/artInstallationMode';
@Pipe({
    name: 'artInstallationMode'
})
export class ArtInstallationModePipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];

        return ArtInstallationMode[value];
    }
}
