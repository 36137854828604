<div class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--singin m-login--2 m-login-2--skin-1"
     id="m_login" style="background-image: url(./assets/app/media/img/bg/art-login-screen-2023.webp);">
    <div class="m-grid__item m-grid__item--fluid m-login__wrapper">
        <div class="m-login__container">
            <div class="m-login__logo">
                <img src="./assets/app/media/img/logos/art-logo.png" width="50%">
            </div>
            <div class="m-login__signin">
                <div class="m-login__head">
                    <h3 class="m-login__title">
                        <ng-container *ngIf="cache.singleWebClient()">
                            {{'AUTH.GENERAL.TITLE'| translate}}
                        </ng-container>
                        <ng-container *ngIf="cache.groupWebClient()">
                            {{'AUTH.GENERAL.TITLE_GROUP_SERVER'| translate}}
                        </ng-container>
                        <ng-container *ngIf="cache.groupWebClientWorld()">
                            {{'AUTH.GENERAL.TITLE_GROUP_SERVER_WORLD'| translate}}
                        </ng-container>

                    </h3>
                </div>

                <div style="width:100%;">
                    <ng-template #alertSignIn></ng-template>
                </div>
                <div style="width:100%;">
                    <input type="text" id="login-email" placeholder="{{'AUTH.INPUT.FULLNAME' | translate}}" name="email"
                           pInputText [(ngModel)]="model.email" [style]="{width:' 100%', marginTop: '10px'}"/>
                </div>
                <div style="width:100%;">
                    <p-password id="login-password" name="password" placeholder="{{'AUTH.INPUT.PASSWORD' | translate}}" [feedback]="false" [(ngModel)]="model.password" [style]="{width:' 100%', marginTop: '10px'}"></p-password>
                </div>


                <!--
                <div class="form-group m-form__group">
                    <input class="form-control m-input" id="login-email" type="text" placeholder="{{'AUTH.INPUT.FULLNAME' | translate}}" name="email" [(ngModel)]="model.email" #email="ngModel" autocomplete="off">
                </div>
                <div class="form-group m-form__group">
                    <input class="form-control m-input m-login__form-input--last" id="login-password" type="password" placeholder="{{'AUTH.INPUT.PASSWORD' | translate}}" name="password" [(ngModel)]="model.password" #password="ngModel">
                </div>
                -->
                <!--
                <div class="row m-login__form-sub">
                    <div class="col m--align-left m-login__form-left">
                        <label class="m-checkbox  m-checkbox--light">
                            <input type="checkbox" name="remember" [(ngModel)]="model.remember" #remember="ngModel">
                            Remember me
                            <span></span>
                        </label>
                    </div>
                </div>-->
                <div style="width:100%;">
                    <button (click)="signIn()" [disabled]="loading" [style]="{width:' 100%', marginLeft: '0px', marginTop: '10px', height: '50px', lineHeight: '1.25rem', borderRadius: '60px'}"
                            [ngClass]="{'m-loader m-loader--right m-loader--light': loading}"
                            class="send-button"
                            translate="AUTH.GENERAL.SIGN_IN_BUTTON">

                    </button>
                </div>
                <!--</form>-->
            </div>
        </div>
        <div class="powered-by">
            <span>powered by</span>
            <a href="https://countr.de" title="go to countr.de" target="_blank">
                <img src="./assets/app/media/img/logos/countr-logo.png" width="100%" alt=""/>
            </a>
        </div>
    </div>
</div>
<p-toast key="LoginMessage" position="top-right"></p-toast>
