import { Pipe, PipeTransform } from '@angular/core';
import { TerminalEvent } from '../_enums/terminalEvent';
@Pipe({
    name: 'terminalEvent'
})
export class TerminalEventPipe implements PipeTransform {

    transform(value: number): string {
        return TerminalEvent[value];
    }
}
