import { Pipe, PipeTransform } from '@angular/core';
import { GlobalCacheVariable } from '../../auth/_globals/globals';

@Pipe({
    name: 'timeSpan',
})
export class TimeSpanPipe implements PipeTransform {

    systemCurrency: any;

    constructor(
        public globalCacheVariable: GlobalCacheVariable,
    ) {
        //this.systemCurrency = this.globalCacheVariable.artConfigurationEntries.get("Basics/SystemCurrencyCode").Value;
    }

    transform(
        value: number,
        maxPeriod: string,
        locale: string = 'en-US',
    ): string | null {

        var oneyear = (60 * 60 * 24 * 365),
            onemonth = (60 * 60 * 24 * 30),
            oneweek = (60 * 60 * 24 * 7),
            oneday = (60 * 60 * 24),
            onehour = (60 * 60);


        var years = Math.floor(value / oneyear),
            months = Math.floor((value - (years * oneyear)) / onemonth),
            weeks = Math.floor((value - ((years * oneyear) + (months * onemonth))) / oneweek),
            days = Math.floor((value - ((years * oneyear) + (months * onemonth) + (weeks * oneweek))) / oneday),
            hours = Math.floor((value - ((years * oneyear) + (months * onemonth) + (weeks * oneweek) + (days * oneday))) / onehour),
            minutes = Math.floor((value - ((years * oneyear) + (months * onemonth) + (weeks * oneweek) + (days * oneday) + (hours * onehour))) / 60),
            seconds = Math.round(value - ((years * oneyear) + (months * onemonth) + (weeks * oneweek) + (days * oneday) + (hours * onehour) + (minutes * 60)));
        if (years != 0 && maxPeriod === 'y') {
            return years + 'y ' + months + 'm ' + weeks + 'w ' + days + 'd ' + ((hours < 10) ? '0' + hours : hours) + ':' + ((minutes < 10) ? '0' + minutes : minutes) + ':' + ((seconds < 10) ? '0' + seconds : seconds);
        } else if (months != 0 && (maxPeriod === 'm' || maxPeriod === 'y')) {
            return months + 'm ' + weeks + 'w ' + days + 'd ' + ((hours < 10) ? '0' + hours : hours) + ':' + ((minutes < 10) ? '0' + minutes : minutes) + ':' + ((seconds < 10) ? '0' + seconds : seconds);
        } else if (weeks != 0 && (maxPeriod === 'w' || maxPeriod === 'm' || maxPeriod === 'y')) {
            return weeks + 'w ' + days + 'd ' + ((hours < 10) ? '0' + hours : hours) + ':' + ((minutes < 10) ? '0' + minutes : minutes) + ':' + ((seconds < 10) ? '0' + seconds : seconds);
        } else if (days != 0 && (maxPeriod === 'd' || maxPeriod === 'w' || maxPeriod === 'm' || maxPeriod === 'y')) {
            return days + 'd ' + ((hours < 10) ? '0' + hours : hours) + ':' + ((minutes < 10) ? '0' + minutes : minutes) + ':' + ((seconds < 10) ? '0' + seconds : seconds);
        }
        else {
            return ((hours < 10) ? '0' + hours : hours) + ':' + ((minutes < 10) ? '0' + minutes : minutes) + ':' + ((seconds < 10) ? '0' + seconds : seconds);
        }
    }
}
