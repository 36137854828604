/**
Specifies the type of a terminal.
 1: ACS
 2: JPS
 3: VT
 4: ART-1000/CAION (Casino All In One)
 5: CCS (Card Coding System)
 6: CES (Card Encoding System)
 7: RODI (Rotating Display)
 8: POS (Point of Sale)
 9: CASHIO Platinum (Cash In Out Platinum)
10: TITA (Ticket at Table)
11: CARO (Card Out)
12: CATA (Card at Table)
13: CASHIO Titanium (Cash In Out Titanium)
14: CASHIO Gold (Cash In Out Gold)
15: CASHIO Silver (Cash In Out Silver)
16: CASHIO Chrome (Cash In Out Chrome)
17: Ad Player
18: 2pay
19: CASHIO Platinum (Gen 2) (Cash In Out Platinum (Gen 2))
20: Cash Dispenser
*/
export enum TerminalType {
    Acs = 1,
    Jps = 2,
    Vt = 3,
    Caion = 4,
    Ccs = 5,
    Ces = 6,
    Rodi = 7,
    Pos = 8,
    Cashio = 9,
    Tita = 10,
    Caro = 11,
    Cata = 12,
    CashioTitanium = 13,
    CashioGold = 14,
    CashioSilver = 15,
    CashioChrome = 16,
    AdPlayer = 17,
    TwoPay = 18,
    CashioPlatinumGen2 = 19,
    CashDispenser = 20,
    CashioJps = 21,
    CashioTitaniumGen2 = 22
}
