import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'terminalIconRewrite'
})
export class TerminalIconRewritePipe implements PipeTransform {
    transform(unimportant: any, value: number, kind: string, position: string) {

        let returnValue: any = '';
        //console.log("TerminalIconRewritePipe -> value -> ", value);
        //console.log("TerminalIconRewritePipe -> kind -> ", kind);
        //console.log("TerminalIconRewritePipe -> position -> ", position);

        switch (kind) {
            case 'connection-status':
                switch (value) {
                    case 0:
                        switch (position) {
                            case 'class':
                                returnValue = 'offline-mode active';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.CONNECTION.TERMINAL_OFFLINE';
                                break;
                            case 'image':
                                returnValue = '../../assets/app/media/img/icons/offline-mode-icon.png';
                                break;
                        }
                        break;
                    case 1:
                        switch (position) {
                            case 'class':
                                returnValue = 'online-mode active';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.CONNECTION.TERMINAL_CONNECTED';
                                break;
                            case 'image':
                                returnValue = '../../assets/app/media/img/icons/online-mode-icon.png';
                                break;
                        }

                        break;
                    default:
                        switch (position) {
                            case 'class':
                                returnValue = 'offline-mode active';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.CONNECTION.TERMINAL_OFFLINE';
                                break;
                            case 'image':
                                returnValue = '../../assets/app/media/img/icons/offline-mode-icon.png';
                                break;
                        }
                        break;

                }
                break;

            case 'cos':
                switch (value) {
                    case 0:
                        switch (position) {
                            case 'class':
                                returnValue = 'not-connected';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.COS_NOT_CONNECTED';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }
                        break;
                    case 1:
                        switch (position) {
                            case 'class':
                                returnValue = '';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.COS_ONLINE_READY';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }

                        break;

                    default:
                        switch (position) {
                            case 'class':
                                returnValue = 'not-show';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.COS_NOT_CONNECTED';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }
                        break;

                }
                break;

            case 'jos':
                switch (value) {
                    case 0:
                        switch (position) {
                            case 'class':
                                returnValue = 'not-connected';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.JACKPOT_NOT_CONNECTED';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }
                        break;
                    case 1:
                        switch (position) {
                            case 'class':
                                returnValue = '';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.JACKPOT_ONLINE_READY';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }

                        break;
                    default:
                        switch (position) {
                            case 'class':
                                returnValue = 'not-show';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.JACKPOT_NOT_CONNECTED';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }
                        break;

                }
                break;

            case 'atm':
                switch (value) {
                    case 0:
                        switch (position) {
                            case 'class':
                                returnValue = 'no-information';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.ATM_NO_INFORMATION';
                                break;
                            case 'image':
                                returnValue = '../../assets/app/media/img/icons/no-info-icon.png';
                                break;
                        }
                        break;
                    case 1:
                        switch (position) {
                            case 'class':
                                returnValue = 'function-deactivated';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.ATM_DEACTIVATED';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }

                        break;

                    case 2:
                        switch (position) {
                            case 'class':
                                returnValue = 'not-connected';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.ATM_NOT_CONNECTED';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }

                        break;

                    case 3:
                        switch (position) {
                            case 'class':
                                returnValue = 'not-logged-in';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.ATM_CONNECTED_NOT_LOGGED_IN';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }

                        break;

                    case 4:
                        switch (position) {
                            case 'class':
                                returnValue = 'no-keys';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.ATM_CONNECTED_NO_KEYS';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }

                        break;

                    case 5:
                        switch (position) {
                            case 'class':
                                returnValue = '';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.ATM_ONLINE_READY';
                                break;
                            case 'image':
                                returnValue = '';
                                break;
                        }

                        break;
                    default:
                        switch (position) {
                            case 'class':
                                returnValue = 'not-show';
                                break;
                            case 'title':
                                returnValue = 'TERMINAL.TERMINAL_ICON.MODULE.ATM_NO_INFORMATION';
                                break;
                            case 'image':
                                returnValue = '../../assets/app/media/img/icons/no-info-icon.png';
                                break;
                        }
                        break;

                }
                break;


        }

        //console.log("RETURN_VALUE -> ", returnValue);
        return returnValue;
    }
}
