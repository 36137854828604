import { Injectable } from "@angular/core";
//import { Http, Response, Headers, RequestOptions} from "@angular/http";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GlobalCacheVariable } from '../_globals/globals';
import { ResponseType } from "../_enums/group/responseType";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class AuthenticationService {
    //s: any = 'test';

    constructor(
        private http: HttpClient,
        public cache: GlobalCacheVariable) {
    }

    login(email: string, password: string, state: string = 'name') {
        let productionMode = 'active';
        if (productionMode == 'active') {
            if (this.cache.singleWebClient()) {
                if (state === 'id') {
                    let data = { operatorID: Number(email), password: password };
                    let body = JSON.stringify(data);

                    return this.http.post(this.cache.ARTServerBaseIP + this.cache.ARTServerApiPath + 'LoginWithOperatorID ', body, httpOptions)
                        .pipe(map((response: Response) => {
                            // login successful if there's a jwt token in the response

                            let z = response;
                            let user = JSON.parse(JSON.stringify(response));
                            //console.log("test Response of authentication user sttrrr" +  JSON.stringify(user));
                            //console.log("user new ", user)
                            this.cache.loginResult = user.d.Result;
                            if (user && user.d.SessionKey !== "") {
                                // store user details and jwt token in local storage to keep user logged in between page refreshes
                                let userData = JSON.stringify(user.d);
                                //console.log("test Response of authentication user after stringify userdata" +  userData);
                                localStorage.setItem('currentUser', userData);
                                //console.log("test Response of authentication user stringify" +  JSON.stringify(user.d));
                            }
                        }));
                } else {
                    let data = { strUserName: email, strPassword: password };
                    let body = JSON.stringify(data);

                    return this.http.post(this.cache.ARTServerBaseIP + this.cache.ARTServerApiPath + 'Login', body, httpOptions)
                        .pipe(map((response: Response) => {
                            // login successful if there's a jwt token in the response

                            let z = response;
                            let user = JSON.parse(JSON.stringify(response));
                            //console.log("test Response of authentication user sttrrr" +  JSON.stringify(user));
                            //console.log("user new ", user)
                            this.cache.loginResult = user.d.Result;
                            if (user && user.d.SessionKey !== "") {
                                // store user details and jwt token in local storage to keep user logged in between page refreshes
                                let userData = JSON.stringify(user.d);
                                //console.log("test Response of authentication user after stringify userdata" +  userData);
                                localStorage.setItem('currentUser', userData);
                                //console.log("test Response of authentication user stringify" +  JSON.stringify(user.d));
                            }
                        }));
                }
            }
            else {
                //console.log("inside group Login");
                try {
                    let body = {
                        Username: email,
                        Password: password
                    };
                    let self = this;
                    return this.http.post(this.cache.ARTGroupServerBaseIP + 'Administration/Login', body, httpOptions)
                        .pipe(map((response: Response) => {
                            // login successful if there's a jwt token in the response

                            let z = response;
                            let loginResponse = JSON.parse(JSON.stringify(response));
                            //console.log("test Response of authentication user sttrrr" +  JSON.stringify(user));
                            if (loginResponse.ResponseType == ResponseType.SuccessfulLogIn) {
                                if (loginResponse && loginResponse.token !== "" && loginResponse.token !== null) {
                                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                                    let userData = JSON.stringify(loginResponse);
                                    //console.log("test Response of authentication user after stringify userdata" +  userData);
                                    localStorage.setItem('currentGroupUser', userData);
                                    //console.log("test Response of authentication user stringify" +  JSON.stringify(user.d));
                                    self.cache.artToken = loginResponse.token;
                                    //console.log("grouptoken ",self.cache.artToken);
                                }
                            }
                            else if (loginResponse.ResponseType != ResponseType.InvalidPassword && loginResponse.ResponseType != ResponseType.InvalidUsername && loginResponse.ResponseType != ResponseType.LoginDisabled && loginResponse.ResponseType != ResponseType.LoginDenied) {
                                localStorage.removeItem('currentGroupUser');
                            }
                            else {
                                this.cache.loginResult = loginResponse.ResponseType;
                            }
                        }));
                } catch (error) {
                    console.log(error);
                }
            }
        }
        else {
            /*
            return this.http.post('/api/authenticate', JSON.stringify({ email: email, password: password }))
                .pipe(map((response: Response) => {
                    // login successful if there's a jwt token in the response
                    let s = Response;
                    console.log("test Response of authentication s" + s);

                    let z = response;
                    console.log("test Response of authentication z" + z);

                    let user = response.json();
                    console.log("test Response of authentication user" + user);
                    console.log("test Response of authentication user sttrrr" +  JSON.stringify(user));
                    if (user && user.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        console.log("test Response of authentication user stringify" +  JSON.stringify(user));
                    }
                }));
                */
        }


    }
    buildHeaderInformations() {
        let currentGroupUser = JSON.parse(localStorage.getItem('currentGroupUser'));
        /*
        if(localStorage.getItem('currentGroupUser')){
            this.currentGroupUser = JSON.parse(localStorage.getItem('currentUser'));
            return {
                headers: new HttpHeaders({'Content-Type': 'application/json', 'token': this.currentGroupUser.token})
            };
        }*/
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': currentGroupUser.Token })
        };

    }


    logout() {
        // remove user from local storage to log user out

        try {
            if (this.cache.groupWebClient()) {
                let httpOptionsData = this.buildHeaderInformations();
                //TODO when Groupserver get logoutresponse
                //localStorage.removeItem('currentGroupUser');
                let body = {};
                return this.http.post(this.cache.ARTGroupServerBaseIP + 'Administration/' + 'Logout', body, httpOptionsData)
                    .pipe(map((response: Response) => {
                        this.logoutResult(response);

                        // login successful if there's a jwt token in the response
                    }));
            } else {

                let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                let data = { strSessionKey: currentUser.SessionKey };
                let body = JSON.stringify(data);

                return this.http.post(this.cache.ARTApi + 'Logout', body, httpOptions)
                    .pipe(map((response: Response) => {
                        this.cache.currentSiteHeaderTitle = 'Welcome to ART';
                        // login successful if there's a jwt token in the response
                        localStorage.removeItem('currentUser');
                    }));
            }

        } catch (error) {
            console.log(error);
        }

    }

    logoutResult(response) {

        switch (response) {
            case ResponseType.SuccessfulLogOut:
                localStorage.removeItem('currentGroupUser');
                break;

            default:
                break;

        }

    }
}
