import { Injectable } from "@angular/core";
import { Languages } from "../_enums/languages";
import { GlobalCacheVariable } from '../_globals/globals';
import { FormatSettings } from '../_models/operatorCustomSettings';

@Injectable()
export class FormatService {

    constructor(
        private globalCache: GlobalCacheVariable
    ) {
    }

    public getFormatForLanguage(lang): FormatSettings {
        switch (lang) {
            case Languages.en:
                return {
                    countryCode: 'en-US',
                    currency: 'USD',
                    currencySymbol: '$',
                    numberFormat: '0,0.00',
                    symbolInsteadCurrency: false,
                    dateFormatLongMs: 'MM/dd/yyyy hh:mm:ss:ms a',
                    dateFormatLong: 'MM/dd/yyyy hh:mm:ss a',
                    dateFormatMiddle: 'MM/dd/yyyy hh:mm:ss a',
                    dateFormatShort: 'MM/dd/yyyy',
                    dateFormatHour: 'hh:mm a',
                    defaultTimeFormatPNG: 'mm/dd/yy',
                    dateFormatMoment: 'MM/DD/YYYY hh:mm:ss a Z',
                    hourFormatPNG: 12,
                }
            case Languages.de:
                return {
                    countryCode: 'de-DE',
                    currency: 'EUR',
                    currencySymbol: '€',
                    numberFormat: '0.0,00',
                    symbolInsteadCurrency: false,
                    dateFormatLongMs: 'dd.MM.yyyy HH:mm:ss:ms',
                    dateFormatLong: 'dd.MM.yyyy HH:mm:ss',
                    dateFormatMiddle: 'dd.MM.yyyy HH:mm:ss',
                    dateFormatShort: 'dd.MM.yyyy',
                    dateFormatHour: 'HH:mm',
                    defaultTimeFormatPNG: "dd.mm.yy",
                    dateFormatMoment: 'DD.MM.YYYY HH:mm:ss Z',
                    hourFormatPNG: 24,
                }
            case Languages.fr:
                return {
                    countryCode: 'fr-FR',
                    currency: 'EUR',
                    currencySymbol: '€',
                    numberFormat: '0.0,00',
                    symbolInsteadCurrency: false,
                    dateFormatLongMs: 'dd.MM.yyyy HH:mm:ss:ms',
                    dateFormatLong: 'dd.MM.yyyy HH:mm:ss',
                    dateFormatMiddle: 'dd.MM.yyyy HH:mm:ss',
                    dateFormatShort: 'dd.MM.yyyy',
                    dateFormatHour: 'HH:mm',
                    defaultTimeFormatPNG: "dd.mm.yy",
                    dateFormatMoment: 'DD.MM.YYYY HH:mm:ss Z',
                    hourFormatPNG: 24,
                }
            case Languages.es:
                return {
                    countryCode: 'es-ES',
                    currency: 'EUR',
                    currencySymbol: '€',
                    numberFormat: '0.0,00',
                    symbolInsteadCurrency: false,
                    dateFormatLongMs: 'dd.MM.yyyy HH:mm:ss:ms',
                    dateFormatLong: 'dd.MM.yyyy HH:mm:ss',
                    dateFormatMiddle: 'dd.MM.yyyy HH:mm:ss',
                    dateFormatShort: 'dd.MM.yyyy',
                    dateFormatHour: 'HH:mm',
                    defaultTimeFormatPNG: "dd.mm.yy",
                    dateFormatMoment: 'DD.MM.YYYY HH:mm:ss Z',
                    hourFormatPNG: 24,
                }
        }
    }

    changeGlobalFormat(value: string) {
        this.globalCache.globalFormatSettings = this.getFormatForLanguage(value);
    }
}
