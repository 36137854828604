import { Pipe, PipeTransform } from '@angular/core';

import { ErrorType } from "../_enums/errorType";
@Pipe({
    name: 'terminalErrorType'
})
export class TerminalErrorTypePipe implements PipeTransform {

    transform(value: number): string {

        let terminalErrorType = ErrorType[value];

        return terminalErrorType;
    }
}
