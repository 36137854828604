import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services';
import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';
import { Terminal } from '../../../../../../auth/_models';
import { MessageService } from 'primeng/api';
import { CustomAddress } from '../../../../../../auth/_models/customAddress';
import { CustomAddressPartFlag } from '../../../../../../auth/_enums/customAddressPartFlag';
import { TerminalUpdateResultType } from '../../../../../../auth/_enums/terminalUpdateResultType';
import { TranslatePipe } from '@ngx-translate/core';
import { Utils } from '../../../../../../auth/_helpers/utils';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'terminal-single-details-terminal-address',
    templateUrl: './terminal-single-details-terminal-address.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe]
})

export class TerminalSingleDetailsTerminalAddressComponent implements OnInit, AfterViewInit, OnChanges {

    @Input('terminalID') terminalID: number;
    terminalSingleDetailsEdit: boolean = false;
    terminalData: Terminal;
    terminalEditData: Terminal;
    terminalAddress: CustomAddress;
    customAddressPartFlags: number;
    locationName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    selectedCountry: any;
    selectedTimeZone: any;
    countries: any[];
    timeZones: any[];
    state: string;
    zipCode: string;
    phoneNumber: string;
    eMailAddress: string;
    contactName: string;
    contactPersonPhone: string;
    contactPersonEmail: string;
    timeZone: string;
    selectedCategories: string[];

    constructor(
        private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        public globalCacheVariable: GlobalCacheVariable,
        private zone: NgZone,
        private messageService: MessageService,
        private translate: TranslatePipe,
        private datePipe: DatePipe
    ) {
    }

    ngOnInit(): void {
        //this.getAddressEditData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getAddressEditData();
        }
    }

    ngOnDestroy() {
    }

    ngAfterViewInit(): void {
    }

    saveAddress() {
        this.saveTempEditableData();
        this.setSpecificCheckbox();
        this.terminalData.CustomAddress = this.terminalAddress;
        this.terminalData.CustomAddressPartFlags = this.customAddressPartFlags;
        this.terminalService.updateTerminal(this.terminalData)
            .subscribe(data => {
                this.zone.run(() => {
                    if (data === TerminalUpdateResultType.TerminalUpdated) {
                        this.messageService.add({
                            key: 'infoMessages',
                            severity: 'success',
                            summary: 'Terminal update',
                            life: 5000,
                            detail: 'Terminal  (' + this.terminalData.TerminalName + ', ' + this.terminalData.TerminalID + ') - Terminal update successful!'
                        });
                    } else {
                        console.log(data);
                    }
                });
            });
    }

    setTempEditableData() {
        this.locationName = this.terminalAddress.LocationName;
        this.addressLine1 = this.terminalAddress.PostalAddress.Line1;
        this.addressLine2 = this.terminalAddress.PostalAddress.Line2;
        this.city = this.terminalAddress.PostalAddress.City;
        this.state = this.terminalAddress.PostalAddress.State;
        this.zipCode = this.terminalAddress.PostalAddress.ZipCode;
        this.phoneNumber = this.terminalAddress.PhoneNumber;
        this.eMailAddress = this.terminalAddress.EMailAddress;
        this.contactName = this.terminalAddress.ContactPerson.ContactName;
        this.contactPersonPhone = this.terminalAddress.ContactPerson.PhoneNumber;
        this.contactPersonEmail = this.terminalAddress.ContactPerson.EMailAddress;
    }

    saveTempEditableData() {
        this.terminalAddress.LocationName = this.locationName;
        this.terminalAddress.PostalAddress.Line1 = this.addressLine1;
        this.terminalAddress.PostalAddress.Line2 = this.addressLine2;
        this.terminalAddress.PostalAddress.City = this.city;
        this.terminalAddress.PostalAddress.Country = this.selectedCountry.countryCode;
        this.terminalAddress.PostalAddress.State = this.state;
        this.terminalAddress.PostalAddress.ZipCode = this.zipCode;
        this.terminalAddress.PhoneNumber = this.phoneNumber;
        this.terminalAddress.EMailAddress = this.eMailAddress;
        this.terminalAddress.ContactPerson.ContactName = this.contactName;
        this.terminalAddress.ContactPerson.PhoneNumber = this.contactPersonPhone;
        this.terminalAddress.ContactPerson.EMailAddress = this.contactPersonEmail;
        this.terminalAddress.TimeZone = this.selectedTimeZone.value;
    }

    getAddressEditData() {
        this.terminalService.getSingleTerminal(this.terminalID)
            .subscribe(singleTerminal => {
                this.zone.run(() => {
                    this.terminalData = singleTerminal;
                    this.terminalData.FirstSeen = this.datePipe.transform(Utils.replaceDateString(this.terminalData.FirstSeen), this.globalCacheVariable.globalFormatSettings.dateFormatLong)
                    this.terminalData.LastSeen = this.datePipe.transform(Utils.replaceDateString(this.terminalData.LastSeen), this.globalCacheVariable.globalFormatSettings.dateFormatLong)
                    if (this.terminalData.CustomAddress != null) {
                        this.terminalAddress = Object.assign({}, this.terminalData.CustomAddress);
                        this.customAddressPartFlags = this.terminalData.CustomAddressPartFlags;
                        this.setTempEditableData();
                        this.getSpecificCheckbox();
                        this.getCountryCodes();
                    }
                });
            });
    }

    getSpecificCheckbox() {
        this.selectedCategories = [];
        if (this.globalCacheVariable.hasFlag(this.customAddressPartFlags, CustomAddressPartFlag.Location)) {
            this.selectedCategories.push('location');
        }
        if (this.globalCacheVariable.hasFlag(this.customAddressPartFlags, CustomAddressPartFlag.PostalAddress)) {
            this.selectedCategories.push('postalAddress');
        }
        if (this.globalCacheVariable.hasFlag(this.customAddressPartFlags, CustomAddressPartFlag.ContactInfo)) {
            this.selectedCategories.push('contactInf');
        }
        if (this.globalCacheVariable.hasFlag(this.customAddressPartFlags, CustomAddressPartFlag.ContactPerson)) {
            this.selectedCategories.push('contactPerson');
        }
        if (this.globalCacheVariable.hasFlag(this.customAddressPartFlags, CustomAddressPartFlag.TimeZone)) {
            this.selectedCategories.push('timeZone');
        }
    }

    setSpecificCheckbox() {
        this.customAddressPartFlags = 0;
        if (this.checkDisabled('location')) {
            this.customAddressPartFlags = this.globalCacheVariable.setFlag(this.customAddressPartFlags, CustomAddressPartFlag.Location);
        }
        if (this.checkDisabled('postalAddress')) {
            this.customAddressPartFlags = this.globalCacheVariable.setFlag(this.customAddressPartFlags, CustomAddressPartFlag.PostalAddress);
        }
        if (this.checkDisabled('contactInf')) {
            this.customAddressPartFlags = this.globalCacheVariable.setFlag(this.customAddressPartFlags, CustomAddressPartFlag.ContactInfo);
        }
        if (this.checkDisabled('contactPerson')) {
            this.customAddressPartFlags = this.globalCacheVariable.setFlag(this.customAddressPartFlags, CustomAddressPartFlag.ContactPerson);
        }
        if (this.checkDisabled('timeZone')) {
            this.customAddressPartFlags = this.globalCacheVariable.setFlag(this.customAddressPartFlags, CustomAddressPartFlag.TimeZone);
        }
    }

    checkDisabled(property: string) {
        return this.selectedCategories.includes(property);
    }

    getCountryCodes() {
        this.terminalService.getCountryInfos()
            .subscribe(countryCodes => {
                this.zone.run(() => {
                    this.countries = [];
                    this.countries.push({
                        countryName: this.translate.transform('TERMINAL.TERMINAL_ADDRESS.SPECIFIC_POSTAL_ADDRESS.COUNTRY.NOT_SPECIFIED'),
                        countryCode: ''
                    });
                    countryCodes = countryCodes.sort((a, b) => (a.CountryName > b.CountryName) ? 1 : ((b.CountryName > a.CountryName) ? -1 : 0))
                    countryCodes.forEach(country => {
                        this.countries.push({
                            countryName: country.CountryName,
                            countryCode: country.CountryCode
                        });
                    });
                    this.selectedCountry = this.countries.find(
                        country => country.countryCode === this.terminalAddress.PostalAddress.Country
                    );
                    this.getTimeZones();
                });
            });
    }

    getTimeZones() {
        this.terminalService.getTimeZoneInfos()
            .subscribe(timeZones => {
                this.zone.run(() => {
                    this.timeZones = [];
                    this.timeZones.push({
                        timeZone: this.translate.transform('TERMINAL.TERMINAL_ADDRESS.SPECIFIC_POSTAL_ADDRESS.COUNTRY.NOT_SPECIFIED'),
                        value: ''
                    });
                    timeZones.forEach(timeZone => {
                        this.timeZones.push({
                            timeZone: timeZone.replace(/_/g, ' '),
                            value: timeZone
                        });
                    });
                    this.selectedTimeZone = this.timeZones.find(
                        timeZone => timeZone.value === this.terminalAddress.TimeZone
                    );
                    this.terminalSingleDetailsEdit = true;
                })
            })
    }
}
