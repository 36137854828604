import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";


import { AuthRoutingModule } from "./auth-routing.routing";
import { AuthComponent } from "./auth.component";
import { AlertComponent } from "./_directives/alert.component";
import { LogoutComponent } from "./logout/logout.component";
import { AuthGuard } from "./_guards/auth.guard";
import { AlertService } from "./_services/alert.service";
import { AuthenticationService } from "./_services/authentication.service";
import { UserService } from "./_services/user.service";

import { DropdownModule } from 'primeng/dropdown';
import { SharingModule } from "../shared.module";
import { TranslateModule } from '@ngx-translate/core';
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';
@NgModule({
    declarations: [
        AuthComponent,
        AlertComponent,
        LogoutComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ToastModule,
        AuthRoutingModule,
        DropdownModule,
        SharingModule,
        PasswordModule,
        TranslateModule.forChild()
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService
    ]
})

export class AuthModule {
}
