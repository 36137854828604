<app-header-nav></app-header-nav>
<!-- begin::Body -->
<router-outlet></router-outlet>
<!-- end:: Body -->
<!--<app-footer></app-footer>-->
<p-toast position="top-right" key="transApprove" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <h3>{{message.summary}}</h3>
        <div class="toast-inner">
            <p class="popup-terminal"><span>Terminal:</span><span>{{message.detail.terminal}}</span></p>
            <p><span>Sequence Number:</span><span>{{message.detail.sequenceNumber}}</span></p>
            <p><span>Transaction Type:</span><span>{{message.detail.transactionType}}</span></p>
            <p><span>Requested Amount:</span><span>{{message.detail.requestedAmount | artCurrency : message.detail.currencyCode}} </span></p>
            <div class="ui-g ui-fluid">
                <div class="ui-g-6">
                    <button type="button" pButton (click)="onTransactionApprovalConfirm(message)" label="Approve" class="ui-button-success"></button>
                </div>
                <div class="ui-g-6">
                    <button type="button" pButton (click)="onTransactionApprovalReject(message)" label="Decline" class="ui-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-sidebar [(visible)]="displayWebsocketError" *ngIf="globalCacheVariable.singleWebClient()" [modal]="false" [dismissible]="false" position="bottom" [style]="{height:'50px'}" styleClass="bottombar-websocket-error">
   {{"BASE.MESSAGES.WEBSOCKET_ERROR" | translate}}
</p-sidebar>
<p-toast key="remoteMessage" position="top-right"></p-toast>
