export enum LicencedModule {
    Advertising = 1 << 0,
    GrccPaging = 1 << 1,
    PropertyManagement = 1 << 2,
    Replication = 1 << 3,
    CardBurning = 1 << 4,
    ShopManagement = 1 << 5,
    CurrencyCounting = 1 << 6,
    TerminalLicensing = 1 << 7,
    MultiCurrency = 1 << 8,
    RemoteAccess = 1 << 9,
    DebitAccounts = 1 << 10,
    PreventiveMaintenance = 1 << 11,
    FaceRecognition = 1 << 12,
}
