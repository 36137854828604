import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'eventSource'
})
export class EventSourcePipe implements PipeTransform {

    transform(value: number): string {
        switch (value) {
            case 0: return "ART Machine"; // error base 'CAION'
            case 1: return "Button";
            // Acceptor
            case 10: return "Acceptor";
            case 11: return "Acceptor WBA";
            case 12: return "Acceptor Sodeco";
            case 13: return "Acceptor Sanwa";
            case 14: return "Futurelogic Gen2"; // ticket printer
            // Card
            case 20: return "Card";
            case 21: return "Card CIM1000";
            case 22: return "Card SCD2000";
            case 23: return "Card XACM 9501";
            case 24: return "Card ICM300";
            case 25: return "Card Renaissance";
            case 26: return "Card Perconta";
            case 27: return "Card KYT6XXX";
            case 28: return "Card ICM 300 new";
            case 29: return "Card Forth"; // Itautec Fourth backpanel card reader
            // Bill Dispenser
            case 30: return "Dispenser";
            case 31: return "Dispenser Delarue";
            case 32: return "Dispenser Perto";
            case 33: return "Dispenser NMD50"; // built into CASHIO
            // Hopper
            case 40: return "Hopper";
            case 41: return "Hopper Itautec";
            case 42: return "Hopper Perconta";
            case 43: return "Hopper ccTalk"; // built into CASHIO
            // Controller
            case 50: return "Controller";
            case 51: return "Controller Itautec";
            case 52: return "Controller Perconta";
            // ART Server
            case 60: return "ART"; // error base 'ART Server'
            case 61: return "ART Server";
            // Jackpot System
            case 70: return "Jackpot";
            case 71: return "Jackpot Atronic";
            case 72: return "Jackpot CDS";
            case 73: return "Jackpot MCC";
            case 74: return "Jackpot GEMS";
            case 75: return "Jackpot SDS";
            case 76: return "Jackpot C2000";
            case 78: return "Jackpot Novomatic";
            // Online System
            case 80: return "Online";
            case 81: return "Online Bally";
            case 82: return "Online MCC";
            case 83: return "Online CDS";
            case 84: return "Online IGT";
            case 85: return "Online Acres";
            case 86: return "Online Acsc";
            case 87: return "Online Atronic";
            case 88: return "Online SSG";
            case 89: return "Online Sprintquip";
            case 90: return "Online Novomatic";
            case 91: return "Online S2S";
            case 92: return "Online Forcise";
            case 93: return "Online Prime";
            case 94: return "Online IGT Tico";
            case 95: return "Online Advansys";
            case 96: return "Online Flexinet";
            case 97: return "Online IGT SCMS";
            // Text Display
            case 100: return "Textdisplay";
            case 101: return "Textdisplay Jarltech";
            case 102: return "Textdisplay Fourth"; // Itautec Fourth backpanel display
            // Touchscreen
            case 110: return "Touchscreen";
            case 111: return "Touchscreen 1";
            case 120: return "Atronic Card Protocol"; // error base is already named 'Atronic Card Protocol' [x]
            case 121: return "EZPay Card Protocol";
            //case 130: ?
            case 140: return "UPS";
            case 150: return "ATM";
            case 151: return "ATM Bank24";
            //case 160: error base 'Keyboard' ?
            case 160: return "Standard Keyboard";
            case 161: return "Itautec Keyboard";
            case 162: return "Sagem Keyboard";
            case 163: return "Fourth Keyboard"; // Itautec Fourth backpanel keyboard
            // secondary card reader/writer/dispenser
            case 170: return "Secondary Card Reader";
            // camera
            case 180: return "Standard Camera";
            case 181: return "USB Camera";
            case 182: return "IP Camera";
            //case 200: error base 'Rear Controller' ?
            case 200: return "Rear Controller";
            case 201: return "Card Rear Controller";
            case 202: return "Display Rear Controller";
            // Coin Acceptor
            case 220: return "Coin Acceptor";
            // Printer
            case 300: return "Printer";
            // Bonus System
            case 320: return "Bonus System";
            // Monitoring System
            case 400: return "Monitoring System";
            // Fiscal Connection
            case 800: return "Fiscal Connection";
            // Transaction Confirmation
            case 900: return "Transaction Confirmation";
            // ccTalk
            case 1000: return "ccTalk";
            // Reward System
            case 1400: return "Reward System";
            // Payment System
            case 1500: return "Payment System";
            // Security Module
            case 1600: return "Security Module";
            // Process Control Module
            case 1700: return "Process Control Module";
            // SPC Module
            case 2100: return "SPC Module";
            case 2110: return "Sign-in Sign-up System";
            case 2120: return "Bill Pay System";
            case 2130: return "Check Cashing System";
            case 2140: return "Gift Card Sale System";
            case 2150: return "Mobile Top-up System";
            case 2160: return "Wire Transfer System";
            case 2170: return "Gift Card Buy System";
            case 2180: return "Crypto Currency System";
            case 2190: return "Prepaid Card System";
            case 2200: return "EWallet System";
            case 2210: return "Unified Signal System";
            case 2220: return "Teller Cash Dispenser";
            case -1: return "Empty";
            default: return "Devicecode: " + value;

        }
    }
}
