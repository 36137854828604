import { Injectable } from "@angular/core";
import { ArtInstallationMode } from '../_enums/group/artInstallationMode';
import { ArtView } from '../_helpers/art-view';
import { GlobalCacheVariable } from '../_globals/globals';
import { ArtInstallation } from '../_models/group/artInstallation';

@Injectable()
export class ArtFilterService {

    artsCount: number;
    shownArtsCount: number;
    artIDs: any;
    ARTS: any;
    // filter properties
    artInstallationMode: ArtInstallationMode;
    selectedArtConnection: any;
    searchElement: string;
    excludeElement: string;

    constructor(
        private globalCache: GlobalCacheVariable
    ) { }

    filterArts() {
        this.shownArtsCount = 0;

        for (let art of this.globalCache.arts) {
            art.setVisible(this.artInstallationMode, this.selectedArtConnection, this.searchElement, this.excludeElement);

            if (art.visible)
                this.shownArtsCount++;

        }

    }

    initArts(artInstallations: ArtInstallation[]) {
        this.globalCache.arts = [];
        this.artIDs = [];
        this.ARTS = new Map();
        this.shownArtsCount = 0;

        for (let artInstallation of artInstallations) {
            let art = new ArtView(artInstallation);
            art.lastSync = art.lastSync.format(this.globalCache.DefaultTimeFormatMoment)
            art.setVisible(this.artInstallationMode, this.selectedArtConnection, this.searchElement, this.excludeElement);

            if (art.visible)
                this.shownArtsCount++;

            this.globalCache.arts.push(art);
            this.artIDs.push(art.id);
            this.ARTS.set(art.id, art);
        }

        this.artsCount = this.globalCache.arts.length;
    }

}
