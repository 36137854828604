import { Injectable } from "@angular/core";
import { GlobalCacheVariable } from '../_globals/globals';
import * as FileSaver from "file-saver";

@Injectable()
export class ExportService {

    constructor(public globalCacheVariable: GlobalCacheVariable) {
    }

    exportTablePDF(exportColumns: any, exportData: any, fileName: string) {
        import("jspdf").then(jsPDF => {
            import("jspdf-autotable").then(x => {
                const doc = new jsPDF.default({
                    orientation: 'landscape',
                });
                //doc.text(['This is a test','22222 This is a test'], 20, 10 );
                //doc.text(, 20, 40 );
                (doc as any).autoTable(exportColumns, exportData);
                doc.save(fileName + '.pdf');
            })
        });
    }

    exportExcel(exportData: any, fileName: string, sheetName: string) {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(exportData);
            const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, fileName);
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
    }

    exportTablePDFWithOptions(exportColumns: any, exportData: any, fileName: string) {
        import("jspdf").then(jsPDF => {
            import("jspdf-autotable").then(x => {
                var doc = new jsPDF.default({
                    orientation: 'portrait',
                });
                //doc.text('This is a test', 20, 10 );
                (doc as any).autoTable(exportColumns, exportData);
                doc.save(fileName + '.pdf');
            })
        });
    }

    exportPDF() {

    }

    exportCSV() {

    }
}

