import { Injectable } from '@angular/core';
import { Terminal } from '../_models';
import { Operator } from '../_models/operator';
import { Property } from '../_models/Property';
import { TerminalListData } from '../_models';
import {
    BehaviorSubject,
    Observable,
    Subject
} from "rxjs";
import { LicencedModule } from "../_enums/licencedModule";
import { TerminalComponentsEntry } from "../_models/terminalComponentsEntry";
import { TerminalIdentifierEntry } from "../_models/terminalIdentifierEntry";
import {
    FormatSettings,
    IGroupUserCustomSettings,
    IOperatorCustomSettings
} from '../_models/operatorCustomSettings';
import { UserRight } from "../_enums/group/userRight";
import { DistributorSite } from "../_models/DistributorSite";
import { environment } from '../../../environments/environment';
import { ProductType } from '../_enums/productType';
import { DashboardScope } from '../_enums/dashboardScope';
import { IConfigurationEntry } from '../_models/group/configurationEntry';
import { ArtView } from '../_helpers/art-view';
import { CurrentGroupUser } from '../_models/group/currentGroupUser';
import { IDashboard } from '../_models/IDashboard';

@Injectable()
export class GlobalCacheVariable {

    static toast = new Subject<any>();

    constructor() { }

    //General ART Configurations
    ARTEntryPoint: string;

    //Loglevel for Debug
    logState: boolean = environment.logState; //true, false
    logLevel: number = environment.logLevel; //trace = 0, debug = 1, info = 2, warn = 3, error = 4, fatal = 5


    allowUnsecureConnections: boolean = false; //check if hard redirect for https

    // region CACHE-DATA-SINGLE-ART start
    //todo rewrite all cache elements into grouped Object
    /*cacheSingleArtClient:{

    }*/

    Terminals: Terminal[];
    TerminalsIdentifier: TerminalIdentifierEntry[];
    DashboardConfiguration: any;
    TerminalsComponentMonitorList: TerminalComponentsEntry[];

    public TerminalCache: BehaviorSubject<any> = new BehaviorSubject<any>(1);
    Operators: Operator[];
    OperatorsMap = new Map<number, Operator[]>();
    TerminalListData: TerminalListData[];
    TerminalsMap = new Map<number, Terminal[]>();
    TerminalTypes: any = [];
    Properties: Property[];
    Distributors: DistributorSite[];

    artConfigurationEntries: any = [];
    requestedLogfilesArray: any[] = [];

    requestedRemoteSessions: any[] = [];
    currentRemoteSession: any;

    notifications: any = {
        Count: 0,
        Alerts: [] = [],
        Events: [] = [],
        Logs: [] = []
    }

    OwnArtRightsX: any = {
        ArtRightsXTree: '',
        ArtRightsXArray: '',
    };

    routeParams: any = {
        eventLog: {
            dateFrom: '',
            dateTo: '',
            terminalID: '',
        }
    }
    // endregion


    // region CACHE-DATA-GROUP-ART start
    //todo rewrite all cache elements into grouped Object
    /*cacheGroupArtClient:{

    }*/
    ARTGroupServerBaseIP: string = environment.ARTGroupServerBaseIP;
    ARTGroupEntryPoint: string = environment.ARTGroupEntryPoint;

    arts: ArtView[];
    artToken: string = '';
    artGroupServerConfigurationEntries: IConfigurationEntry[] = [];

    //loginResult
    loginResult: number;
    heartBeat: boolean = true;
    notification: boolean = true;
    // endregion CACHE-DATA-GROUP-ART start


    // region Global Variables General ART Start
    // Variable for different ART-Versions
    artViewMode: number = 1;
    worldMode: boolean = false;
    //Language System Data
    currentLanguage: string = 'en';
    currentSelectedLanguage: any;
    supportedLanguage: any = [
        { label: 'English (US)', value: { lang: 'en', country: 'USA', flag: 'us.svg' } },
        { label: 'German (DE)', value: { lang: 'de', country: 'Germany', flag: 'de.svg' } },
    ];
    //Header Title
    currentSiteHeaderTitle: string = 'Welcome to ART';

    // endregion Global Variables General ART End

    // region Global Variables Single ART Start
    currentARTWebClientVersion: string = '1.0.0.0'; //ARTClient
    currentARTServerVersion: string = '1.0.0.0'; //ARTClient
    currentUrlLoginParameter: any;

    //REST API
    ARTServerBaseIP: string = environment.baseUrl;
    ARTServerApiPath: string = environment.apiPath;

    ARTApi: string = this.ARTServerBaseIP + this.ARTServerApiPath;

    //General ART Single Client Variables
    TerminalIconSize: string = "medium";
    currentOperator: any;
    sideNavbarMinimizedState: boolean = false;

    SystemWideCurrency: string = 'EUR';
    DefaultTimeFormat: string = 'yyyy-MM-dd HH:mm:ss';
    DefaultTimeFormatPNG: string = 'yy.mm.dd';
    DefaultTimeFormatMoment: string = 'YYYY-MM-DD HH:mm:ss';
    isMobile: boolean = false;
    iconSizeChangerVisible: boolean = true;
    PredefinedReportTypes: any[];
    Search: any = {
        selectedSearchId: 0,
        selectedKeyword: '',
    };

    ArtRightState: boolean = false;


    // endregion Global Variables Single ART End

    // region Global Variables Group ART Start

    currentARTWebClientGroupVersion: string = '1.0.0.0'; //GroupClient
    currentARTGroupServerVersion: string = '1.0.0.0'; //GroupClient

    // endregion Global Variables Group ART End


    //Needs to create own Helpers
    modal: any = {
        ["terminalSingleDetail"]: {
            "displayTerminalSingleDetail": false,
        },
        ["currentOperatorSession"]: {
            "currentOperatorSessionCount": 0,
        }
    };
    filter: any = {
        ["terminalFilter"]: {
            "terminalType": "all",
            "property": "all",
            "freetext": "",
            "terminalState": "all",
            "connection": -1,
            "terminalFilteredCount": 0,
            "terminalFilteredFillLevelCount": 0,
            "terminalCount": 0,
        },
        ["terminalIdentifierFilter"]: {
            "terminalType": "all",
            "property": "all",
            "freetext": "",
            "terminalState": "all",
            "connection": -1,
            "terminalFilteredCount": 0,
            "terminalCount": 0,
        },
        ["operatorFilter"]: {
            "operatorRole": "all",
            "freetext": "",
            "operatorFilteredCount": 0,
            "operatorCount": 0,
        },
    };

    operatorCustomSettings: IOperatorCustomSettings;
    groupOperatorCustomSettings: IGroupUserCustomSettings;
    globalFormatSettings: FormatSettings;

    dashboard: IDashboard = {
        presentationMode: 'normal',
        presentationModeState: true,
        widgetSize: {
            small: 3,
            medium: 6,
            large: 9,
            ultra: 12
        },
        dashboardDemo: false,
        dashboardSwitcherCurrentItem: {
            kiosk: 0,
            property: 0,
            art: "0",
            group: 0,
            distributor: 0,
            world: 0,
        },
        dashboardSwitchView: DashboardScope.Installation,
        dashboardOperatorsCount: {
            operatorOnline: 0,
            operatorSum: 0,
            operatorCurrentSessionsCount: 0,
        },
        dashboardTerminalCount: {
            terminalsOnline: 0,
            terminalSum: 0,
        },
        dashboardTransactionCount: {
            currency: 'USD',
            day: {
                count: 0,
                sum: 0,
            },
            week: {
                count: 0,
                sum: 0,
            },
            month: {
                count: 0,
                sum: 0,
            }
        },
        dashboardRegisteredCustomers: {
            day: {
                count: 0,
            },
            week: {
                count: 0,
            },
            month: {
                count: 0,
            },
            full: {
                count: 0,
            }
        },
        dashboardRegisteredPlayers: {
            currency: 'USD',
            day: {
                count: 0,
                sum: 0,
            },
            week: {
                count: 0,
                sum: 0,
            },
            month: {
                count: 0,
                sum: 0,
            }
        },
        dashboardDonations: {
            currency: 'USD',
            day: {
                count: 0,
                sum: 0,
            },
            week: {
                count: 0,
                sum: 0,
            },
            month: {
                count: 0,
                sum: 0,
            }
        },
        dashboardTransactionTotals: {
            currency: 'USD',
            day: {
                in: 0,
                out: 0,
            },
            week: {
                in: 0,
                out: 0,
            },
            month: {
                in: 0,
                out: 0,
            }
        },
        dashboardTransactionAverages: {
            currency: 'USD',
            day: {
                in: 0,
                out: 0,
            },
            week: {
                in: 0,
                out: 0,
            },
            month: {
                in: 0,
                out: 0,
            }
        },
        dashboardTerminalUptime: {
            switchState: 'D',
            dashboardTerminalUptimeCount: 0,
        },
        dashboardTerminalUptimeExtended: {
            TerminalTotalUptime: 0,
            TerminalTotalDowntime: 0,
            TerminalDowntimeComponentErrors: 0,
            TerminalDowntimeOperational: 0,
        },
        dashboardTransactionTypeGraph: {
            sum: [0, 0, 0, 0, 0],
            percent: [0, 0, 0, 0, 0],
            time: [0, 0, 0, 0, 0],
            groupSwitcher: {
                sum: true,
                percent: true,
                time: true,
            }
        },
        dashboardSoftwareDistributionGraph: {
            cashio: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
            },
            subCashio: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
            },
            titaCata: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
            },
            subTitaCata: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
            },
            atm: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
            },
            subAtm: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
            },

        },
        dashboardSoftwareDistributionOSGraph: {
            cashio: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
                ataValue: []
            },
            subCashio: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
                ataValue: []
            },
            titaCata: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
            },
            subTitaCata: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
            },
            atm: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
            },
            subAtm: {
                fullData: [],
                dataLabel: [],
                dataValue: [],
            },

        },

        dashboardTerminalGraph: [1, 1, 1],
        dashboardTransactionGraph: {
            dashboardTransactionGraphKiosk: [430092, 288998, 1170477],
            dashboardTransactionGraphSite: [12, 17, 51],
            dashboardTransactionGraphGroup: [38, 19, 132],
        },

        dashboardCustomerCount: 16,
        dashboardReplacedPartsCount: "-",
        dashboardMaintenanceCallsCount: "-",
        dashboardSoftwareUpdateCount: "-",
        dashboardMoneyServiceCount: 0,
        dashboardIssuedTerminalCount: 0,
        dashboardDonatedCount: "-",
        dashboardMoneyServiceActionRequiredCountForOverview: 0,
        dashboardTerminalOutOfOperationCountForOverview: 0,


        dashboardUpdateInterval: {
            cycleState: false,
            autoRefresh: true,
            cycleIntervalTimeGroup: 20000,
            IntervalTimeGroup: 300000,
            cycleIntervalTime: 60000,
            machineStateOverview: 60000,
            default: 60000,
            softwareDistribution: 60000,
            softwareDistributionOS: 60000,
            transactionGraph: 60000,
            operatorsCount: 60000,
            operatorsLast: 60000,
            terminalCount: 60000,
            transactionCount: 60000,
            transactionTotals: 60000,
            transactionAverages: 60000,
            customerCount: 60000,
            transactionLast: 60000,
            donatedCount: 60000,
            issuedTerminalCount: 60000,
            replacedPartsCount: 60000,
            moneyServiceCount: 60000,
            moneyServiceActionRequireCount: 60000,
            terminalOutOfOperation: 60000,
            terminalUptime: 60000,
            terminalUptimeExtended: 60000,
            dashboardDowntime: 60000,
            registeredCustomers: 60000,
            registeredPlayers: 60000,
        },/*
        "dashboardUpdateInterval": {
            "machineStateOverview": 600000,
            "default": 600000,
            "transactionGraph": 600000,
            "operatorsCount": 600000,
            "operatorsLast": 600000,
            "terminalCount": 600000,
            "transactionCount": 600000,
            "transactionTotals": 600000,
            "transactionAverages": 600000,
            "customerCount": 600000,
            "transactionLast": 600000,
            "donatedCount": 600000,
            "issuedTerminalCount": 600000,
            "replacedPartsCount": 600000,
            "moneyServiceCount": 600000,
            "terminalUptime": 600000,
            "dashboardDowntime": 600000,
        },*/
    };

    dashboardTransactionCount: number = 47;
    dashboardUpdateInterval: any = {
        "machineStateOverview": 10000,
        "default": 5000,
        "transactionGraph": 3000,
        "operatorsCount": 30000,
        "operatorsLast": 60000,
        "transactionCount": 3000,
        "transactionLast": 30000
    };
    dashboardOperatorsOnlineCount: number = 0;

    dashboardToolTipLocale: any = {
        "DAY": "DAY",
        "WEEK": "WEEK",
        "MONTH": "MONTH",
        "REFRESHES_EVERY": "REFRESHES EVERY",
        "DATA_SHOWN_FOR_THE_PAST_TIME": "DATA SHOWN FOR THE PAST TIME",
        "LIVE_DATA": "LIVE DATA"
    }

    /*start Functionspart*/

    getToasts(): Observable<any> {
        return GlobalCacheVariable.toast.asObservable();
    }

    checkMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.isMobile = true;
            return true;
            //console.log("MOBILE");
            //document.body.classList.add("mobile");
        } else {
            this.isMobile = false;
            return false;
            //console.log("DESKTOP");
            //document.body.classList.add("desktop");
        }
    }

    setFilter(filterKind, filter) {
        //console.log("set actualy Filterkind key -> ", this.filter[filterKind]);
        //console.log("set actualy Filterkind value -> ", this.filter[filterKind] = filter)
    }

    getFilter(filterKind) {
        return this.filter[filterKind];
    }

    getSessionKey() {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser !== null && currentUser !== '') {
            return currentUser.SessionKey;
        } else {
        }
    }

    getGroupUserToken() {
        let currentUser = JSON.parse(localStorage.getItem('currentGroupUser'));
        if (currentUser !== null && currentUser !== '') {
            return currentUser.token;
        } else {
        }
    }

    checkARTRight(artRight) {
        let currentOperator = this.getCurrentOperator();
        let currentOperatorRights = currentOperator.ArtRights;
        return this.hasFlag(currentOperatorRights, artRight)
    }

    checkUserRight(userRight: UserRight): boolean {
        let currentUser: CurrentGroupUser = this.getCurrentGroupUser();
        let currentUserRights: number = currentUser.Rights;
        return this.hasFlag(currentUserRights, userRight)
    }

    checkLicencedModules(kind) {
        let currentOperator = this.getCurrentOperator();
        let currentOperatorRights = currentOperator.LicensedModules;
        switch (kind) {
            case "PM":
                if (this.hasFlag(currentOperatorRights, LicencedModule.PropertyManagement)) {
                    return true;
                }
                break;

            case "RA":
                if (this.hasFlag(currentOperatorRights, LicencedModule.RemoteAccess)) {
                    return true;
                }
                break;
        }
    }

    hasFlag(flags, flag) {
        return (flags & flag) == flag;
    }

    setFlag(flags, flag) {
        return flags | flag;
    }

    getCurrentOperator() {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (currentUser !== null && currentUser !== '') {
            this.currentOperator = currentUser;
            return currentUser;
        }
    }

    getCurrentGroupUser(): CurrentGroupUser {
        let currentUser: CurrentGroupUser = JSON.parse(localStorage.getItem('currentGroupUser'));

        if (currentUser !== null) {
            this.currentOperator = currentUser;
            return currentUser;
        }

    }

    getTerminals() {
        if (this.Terminals === null || this.Terminals === undefined) {
            //return 'No terminal name';
        } else {
            return this.Terminals;
        }
    }

    getOperators() {
        if (this.Operators === null || this.Operators === undefined) {
            //return 'No terminal name';
        } else {
            return this.Operators;
        }
    }

    getDefaultOperatorSetting(product_type: number) {

        if (product_type == ProductType.WebClient) {
            this.operatorCustomSettings = {
                userProfileSettings: {
                    entryPage: {
                        webClient: environment.artEntryPoint
                    },
                    language: "en",
                },
            };
            this.ARTEntryPoint = environment.defaultEntryPoint;
        }
        else {
            this.groupOperatorCustomSettings = {
                userProfileSettings: {
                    entryPage: {
                        groupWebClient: UserRight.ArtOverview
                    },
                    language: "en",
                },
                mapViewSettings: {
                    satelliteMode: false,
                    showColorBoard: true,
                    showInstallations: true,
                    showTerminals: false,
                    showProperties: false,
                },
                dashboardSettings: []
            };
            this.ARTGroupEntryPoint = environment.defaultEntryPoint;
        }

    }

    singleWebClient() {
        return this.artViewMode === ProductType.WebClient;
    }

    groupWebClient() {
        // return this.artViewMode === ProductType.GroupClient || this.artViewMode === ProductType.GroupWebClientWorld;
        return this.artViewMode === ProductType.GroupClient;
    }

    groupWebClientWorld() {
        return this.artViewMode === ProductType.GroupWebClientWorld;
    }
}
