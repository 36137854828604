import { HttpHeaders } from '@angular/common/http';
import { Utils } from './utils';
import { ApiCallType } from '../_enums/apiCallType';
import { TerminalType } from '../_enums/terminalType';
import { BalanceSnapshot } from '../_models/BalanceSnapshot';
import { Injectable } from "@angular/core";
import { GlobalCacheVariable } from "../_globals/globals";
import { ToastService } from "../_services/toast.service";
import { Helpers } from "../../helpers";
import { ToastSeverity } from "../_enums/messages/toastSeverity";
import { MessageType } from "../_enums/messages/messageType";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const httpOptionsSwagger = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable()
export class ApiDataConverter {
    public currentGroupUser: any;
    public currentUser: any;

    constructor(
        public cache: GlobalCacheVariable,
        public toastService: ToastService
    ) {

    }
    buildHeaderInformation() {
        this.currentGroupUser = JSON.parse(localStorage.getItem('currentGroupUser'));
        /*
        if(localStorage.getItem('currentGroupUser')){
            this.currentGroupUser = JSON.parse(localStorage.getItem('currentUser'));
            return {
                headers: new HttpHeaders({'Content-Type': 'application/json', 'token': this.currentGroupUser.token})
            };
        }*/
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.currentGroupUser.Token })
        };

    }

    public static errorHandling(error, api): void {
        Helpers.setLoading(false);
        GlobalCacheVariable.toast.next({ severity: ToastSeverity.Fatal, text: '(' + error.status + ') ' + error.name, title: error.statusText, life: 5000, type: MessageType.ErrorModal, data: error });
    }

    public static extractData(res: Response, apiCallType: ApiCallType) {
        //this.subject.next({ type: 'success', text: 'message', title: 'title', life: 3000, kind: 'toast' });
        const bodyRaw = JSON.parse(JSON.stringify(res));
        let body: any = '';
        //console.log("response adc", res);
        //console.log("apiCallType adc", apiCallType);
        if (bodyRaw != null) {

            if (bodyRaw.hasOwnProperty('d')) {
                body = bodyRaw.d;
            }
            else {
                body = bodyRaw;
            }

        }
        else {
            //GlobalCacheVariable.toast.next({ type: ToastSeverity.Error, text: 'message2', title: 'title2', life: 5000, kind: 'toast'});
            Helpers.setLoading(false);
            body = bodyRaw;
        }

        /*if (bodyRaw != null) {

            if (bodyRaw.d != null) {

                if (bodyRaw.d == 0) {
                    body = 0;
                }
                else {

                    if (Array.isArray(bodyRaw.d)) {

                        if (bodyRaw.d.length > 0) {
                            body = bodyRaw.d;
                        }
                        else {
                            body = [];
                        }

                    }
                    else {
                        body = bodyRaw.d;
                    }
                }

            }
            else {

                if (bodyRaw.hasOwnProperty('d')) {
                    body = bodyRaw.d;
                    // this.signOut();
                }
                else {
                    body = bodyRaw;
                }

            }

        }
        else {
            body = bodyRaw
            // this.signOut();
        }*/

        switch (apiCallType) {
            case ApiCallType.GetUserSettings:
                body = JSON.parse(body);
                break;

            case ApiCallType.SearchForTransactions:
                let searchedTransactions = body;

                for (let transaction of searchedTransactions) {
                    transaction.TransactionDate = Utils.replaceDateString(transaction.TransactionDate);
                }
                break;

            case ApiCallType.GetTransactionDetails2:
                let transactionDetails = body;
                transactionDetails.TransactionDate = Utils.replaceDateString(transactionDetails.TransactionDate);
                break;

            case ApiCallType.GetTransactionDetails:
                let transaction = body;
                transaction.TransactionDate = Utils.replaceDateString(transaction.TransactionDate);
                break;

            case ApiCallType.GetTransactions:
                let transactions = body.Transactions;

                for (let transaction of transactions) {
                    transaction.TransactionDate = Utils.replaceDateString(transaction.TransactionDate);
                    transaction.Visible = true;
                }
                break;

            case ApiCallType.GetRecentTransactions:
                let recentTransactions = body;

                for (let recentTransaction of recentTransactions) {
                    recentTransaction.TransactionDate = Utils.replaceDateString(recentTransaction.TransactionDate);
                }
                break;

            case ApiCallType.GetErrorLogEntries:
                let errorLogEntries = body.Entries;
                for (let errorLogEntry of errorLogEntries) {
                    errorLogEntry.ErrorDate = Utils.replaceDateString(errorLogEntry.ErrorDate)
                }
                break;

            case ApiCallType.GetRecentErrorLogEntries:
                let recentErrorLogEntries = body;

                for (let recentErrorLogEntry of recentErrorLogEntries) {
                    recentErrorLogEntry.ErrorDate = Utils.replaceDateString(recentErrorLogEntry.ErrorDate);
                }
                break;

            case ApiCallType.GetConfigurationChangeLogEntries:
                let configurationChangeLogEntries = body;

                for (let configurationChangeLogEntry of configurationChangeLogEntries) {
                    configurationChangeLogEntry.ChangeDate = Utils.replaceDateString(configurationChangeLogEntry.ChangeDate);
                }
                break;

            case ApiCallType.GetRecentConfigurationChangeLogEntries:
                let recentConfigurationChangeLogEntries = body;

                for (let recentConfigurationChangeLogEntry of recentConfigurationChangeLogEntries) {
                    recentConfigurationChangeLogEntry.ChangeDate = Utils.replaceDateString(recentConfigurationChangeLogEntry.ChangeDate);

                }
                break;

            case ApiCallType.GetOperatorAccessLogEntries:
                let operatorAccessLogEntries = body;

                for (let operatorAccessLogEntry of operatorAccessLogEntries) {
                    operatorAccessLogEntry.AccessDate = Utils.replaceDateString(operatorAccessLogEntry.AccessDate);
                }
                break;

            case ApiCallType.GetRecentOperatorAccessLogEntries:
                let recentOperatorAccessLogEntries = body;

                for (let recentOperatorAccessLogEntry of recentOperatorAccessLogEntries) {
                    recentOperatorAccessLogEntry.AccessDate = Utils.replaceDateString(recentOperatorAccessLogEntry.AccessDate);
                }
                break;

            case ApiCallType.GetMaintenanceLogEntries:
                let maintenanceLogEntries = body;

                for (let maintenanceLogEntry of maintenanceLogEntries) {
                    maintenanceLogEntry.MaintenanceDate = Utils.replaceDateString(maintenanceLogEntry.MaintenanceDate);
                }
                break;

            case ApiCallType.GetRecentMaintenanceLogEntries:
                let recentMaintenanceLogEntries = body;

                for (let recentMaintenanceLogEntry of recentMaintenanceLogEntries) {
                    recentMaintenanceLogEntry.MaintenanceDate = Utils.replaceDateString(recentMaintenanceLogEntry.MaintenanceDate);
                }
                break;

            case ApiCallType.GetMediaLogEntries:
                let mediaLogEntries = body.Entries;

                for (let mediaLogEntry of mediaLogEntries) {
                    mediaLogEntry.EntryDate = Utils.replaceDateString(mediaLogEntry.EntryDate);
                }
                break;

            case ApiCallType.GetRecentMediaLogEntries:
                let recentMediaLogEntries = body;

                for (let recentMediaLogEntry of recentMediaLogEntries) {
                    recentMediaLogEntry.EntryDate = Utils.replaceDateString(recentMediaLogEntry.EntryDate);
                }
                break;

            case ApiCallType.GetEventLogEntries:
                let eventLogEntries = body.Entries;

                for (let eventLogEntry of eventLogEntries) {
                    eventLogEntry.EventDate = Utils.replaceDateString(eventLogEntry.EventDate);
                }
                break;

            case ApiCallType.GetRecentEventLogEntries:
                let recentEventLogEntries = body;

                for (let recentEventLogEntry of recentEventLogEntries) {
                    recentEventLogEntry.EventDate = Utils.replaceDateString(recentEventLogEntry.EventDate);
                }
                break;

            case ApiCallType.GetArtEventLogEntries:
                let artEventLogEntries = body.Entries;

                for (let artEventLogEntry of artEventLogEntries) {
                    artEventLogEntry.EntryDate = Utils.replaceDateString(artEventLogEntry.EntryDate);
                }
                break;

            //Balancing
            case ApiCallType.GetMoneyServices:
                let moneyServices = body;

                for (let moneyService of moneyServices) {
                    moneyService.ServiceDate = Utils.replaceDateString(moneyService.ServiceDate);
                }
                break;

            case ApiCallType.GetBalanceSnapshots:
                let balanceSnapshots = body as BalanceSnapshot[];

                for (let balanceSnapshot of balanceSnapshots) {
                    balanceSnapshot.SnapshotTime = Utils.replaceDateString(balanceSnapshot.SnapshotTime);
                }
                break;

            //Operator
            case ApiCallType.GetPlayers:
                let players = body;

                for (let player of players) {
                    player.Birthday = Utils.replaceDateString(player.Birthday);
                    player.LastUsed = Utils.replaceDateString(player.LastUsed);
                    player.RegistrationDate = Utils.replaceDateString(player.RegistrationDate);
                }
                break;

            case ApiCallType.GetOperatorsFull:
                let operatorsFull = body.Operators;

                for (let operatorFull of operatorsFull) {
                    operatorFull.EntryCreated = Utils.replaceDateString(operatorFull.EntryCreated);
                    operatorFull.LastModified = Utils.replaceDateString(operatorFull.LastModified);
                    operatorFull.ArtPasswordChangeDate = Utils.replaceDateString(operatorFull.ArtPasswordChangeDate);
                    operatorFull.PasswordChangeDate = Utils.replaceDateString(operatorFull.PasswordChangeDate);
                    operatorFull.Visible = true;
                }
                break;

            case ApiCallType.GetUserSessions:
                let userSessions = body;

                for (let userSession of userSessions) {
                    userSession.LoginDate = Utils.replaceDateString(userSession.LoginDate);
                }
                break;

            //Payment Kiosk
            case ApiCallType.GetCustomers:
                let customers = body;

                for (let customer of customers) {
                    customer.Birthday = Utils.replaceDateString(customer.Birthday);
                    customer.RegistrationDate = Utils.replaceDateString(customer.RegistrationDate);
                }
                break;

            case ApiCallType.GetCustomerDetails:
                let customerDetails = body;
                customerDetails.Birthday = Utils.replaceDateString(customerDetails.Birthday);
                customerDetails.RegistrationDate = Utils.replaceDateString(customerDetails.RegistrationDate);

                if (customerDetails.IdentityDocument) {
                    customerDetails.IdentityDocument.ExpirationDate = Utils.replaceDateString((customerDetails.IdentityDocument.ExpirationDate));
                    customerDetails.IdentityDocument.IssueDate = Utils.replaceDateString((customerDetails.IdentityDocument.IssueDate));
                }
                break;

            //Statistics
            case ApiCallType.GetMediaCardTurnoverStatistics:
                let mediaCardTurnoverStatistics = body;

                for (let mediaCardTurnoverStatistic of mediaCardTurnoverStatistics) {
                    mediaCardTurnoverStatistic.StartTime = Utils.replaceDateString(mediaCardTurnoverStatistic.StartTime);
                    mediaCardTurnoverStatistic.EndTime = Utils.replaceDateString(mediaCardTurnoverStatistic.EndTime);
                }
                break;

            case ApiCallType.GetMediaChipTurnoverStatistics:
                let mediaChipTurnoverStatistics = body;

                for (let mediaChipTurnoverStatistic of mediaChipTurnoverStatistics) {
                    mediaChipTurnoverStatistic.StartTime = Utils.replaceDateString(mediaChipTurnoverStatistic.StartTime);
                    mediaChipTurnoverStatistic.EndTime = Utils.replaceDateString(mediaChipTurnoverStatistic.EndTime);
                }
                break;

            case ApiCallType.GetChipTurnoverDetails:
                let chipTurnoverDetails = body;

                for (let chipTurnoverDetail of chipTurnoverDetails) {
                    chipTurnoverDetail.EntryDate = Utils.replaceDateString(chipTurnoverDetail.EntryDate);
                }
                break;

            case ApiCallType.GetMediaOnlineSystemDetailsStatistics:
                let mediaOnlineSystemDetailsStatistics = body;

                for (let mediaOnlineSystemDetailsStatistic of mediaOnlineSystemDetailsStatistics) {
                    mediaOnlineSystemDetailsStatistic.TransactionDate = Utils.replaceDateString(mediaOnlineSystemDetailsStatistic.TransactionDate);

                }
                break;

            case ApiCallType.GetTerminalAvailability:
                let terminalAvailability = body;

                for (let terminalAvailabilityElement of terminalAvailability) {
                    for (let terminalStats of terminalAvailabilityElement.TerminalStats) {
                        terminalStats.CheckDateTime = Utils.replaceDateString(terminalStats.CheckDateTime);
                    }
                }
                break;

            case ApiCallType.GetTerminalState:
                let terminalState = body;

                for (let terminalStateElement of terminalState) {
                    for (let terminalState of terminalStateElement.TerminalStates) {
                        terminalState.DateTime = Utils.replaceDateString(terminalState.DateTime);

                    }
                }
                break;

            case ApiCallType.GetOpeningInventoryEntries:
                let openingInventoryEntries = body;

                for (let openingInventoryEntry of openingInventoryEntries) {
                    openingInventoryEntry.CountDate = Utils.replaceDateString(openingInventoryEntry.CountDate);
                }
                break;

            //Terminal
            case ApiCallType.GetComponentMonitorDetails:
                let component = body;
                ApiDataConverter.replaceDatesInComponent(component);
                break;

            case ApiCallType.GetStockForTerminal:
                let stockForTerminal = body;
                stockForTerminal.StockDate = Utils.replaceDateString(stockForTerminal.StockDate);
                break;

            case ApiCallType.GetTTerminalStock:
                let terminalStock = body;
                terminalStock.StockDate = Utils.replaceDateString(terminalStock.StockDate);
                break;

            case ApiCallType.GetTerminal:
                let terminal = body;
                terminal.Visible = false;
                terminal.TerminalTypeClass = ApiDataConverter.mapTerminalTypeToCssClass(terminal.TerminalType);
                break;

            case ApiCallType.GetTerminalListData:
                let terminalListData = body;

                for (let terminalData of terminalListData) {
                    terminalData.Visible = false;
                    terminalData.DashBoardVisibility = false;
                    terminalData.Terminal.FirstSeen = Utils.replaceDateString(terminalData.Terminal.FirstSeen);
                    terminalData.Terminal.LastSeen = Utils.replaceDateString(terminalData.Terminal.LastSeen);
                    terminalData.TerminalTypeClass = ApiDataConverter.mapTerminalTypeToCssClass(terminalData.TerminalType);
                }
                break;

            case ApiCallType.GetTerminalList:
                let terminalList = body

                for (let terminal of terminalList) {
                    terminal.Visible = false;
                    terminal.DashBoardVisibility = false;
                    terminal.FirstSeen = Utils.replaceDateString(terminal.FirstSeen);
                    terminal.LastSeen = Utils.replaceDateString(terminal.LastSeen);
                    terminal.TerminalTypeClass = ApiDataConverter.mapTerminalTypeToCssClass(terminal.TerminalType);
                }
                break;

            case ApiCallType.GetTerminalIdentifierDataForTerminal:
                let terminalIdentifierDataForTerminal = body
                terminalIdentifierDataForTerminal.Visible = true
                break;

            case ApiCallType.GetTerminalIdentifierData:
                let terminalIdentifierData = body;

                for (let terminalIdentifier of terminalIdentifierData) {
                    terminalIdentifier.Visible = true;
                }
                break;

            case ApiCallType.GetKioskUptimeTimeslotInfo:
                let kioskUptimeTimeslotInfo = body;

                for (let kioskUptimeTimeslotInfoElement of kioskUptimeTimeslotInfo) {
                    kioskUptimeTimeslotInfoElement.TimeSlot = Utils.replaceDateString(kioskUptimeTimeslotInfoElement.TimeSlot);
                }
                break;

            //Jackpot
            case ApiCallType.GetJackpotForms:
                let jackpotForms = body;

                for (let jackpotForm of jackpotForms) {
                    jackpotForm.DateSigned = Utils.replaceDateString(jackpotForm.DateSigned);
                    jackpotForm.FormCreated = Utils.replaceDateString(jackpotForm.FormCreated);
                    jackpotForm.FormLastModified = Utils.replaceDateString(jackpotForm.FormLastModified);
                }
                break;

            case ApiCallType.SearchForJackpotForms:
                let searchedJackpotForms = body;

                for (let searchedJackpotForm of searchedJackpotForms) {
                    searchedJackpotForm.DateSigned = Utils.replaceDateString(searchedJackpotForm.DateSigned);
                }
                break;

            case ApiCallType.GetJackpotFormDetails:
                let jackpotFormDetails = body;
                jackpotFormDetails.DateSigned = Utils.replaceDateString(jackpotFormDetails.DateSigned);
                jackpotFormDetails.FormCreated = Utils.replaceDateString(jackpotFormDetails.FormCreated);

                if (jackpotFormDetails.FormType === 1) {
                    jackpotFormDetails.FormDataW2G.DateWon = Utils.replaceDateString(jackpotFormDetails.FormDataW2G.DateWon);
                }
                else {
                    jackpotFormDetails.FormData1042S.RecipientsDateOfBirth = Utils.replaceDateString(jackpotFormDetails.FormData1042S.RecipientsDateOfBirth);
                }
                break;

            case ApiCallType.ExtractBarcodeData:
                let barcodeData = body

                if (barcodeData.Decoded == true) {
                    barcodeData.DateOfBirth = Utils.replaceDateString(barcodeData.DateOfBirth);
                    barcodeData.DocumentExpirationDate = Utils.replaceDateString(barcodeData.DocumentExpirationDate);
                }
                break;

            case ApiCallType.AddJackpotForm:
                let jackpotForm = body
                jackpotForm.Created = Utils.replaceDateString(jackpotForm.Created);
                break;

            case ApiCallType.GetBalancingReportForMoneyService:
                let balancingReportMoneyService = body
                balancingReportMoneyService.End.Date = Utils.replaceDateString(balancingReportMoneyService.End.Date);
                balancingReportMoneyService.Start.Date = Utils.replaceDateString(balancingReportMoneyService.Start.Date);
                for (const service of balancingReportMoneyService.Services) {
                    service.MoneyServiceDate = Utils.replaceDateString(service.MoneyServiceDate);
                    service.SavedByDate = Utils.replaceDateString(service.SavedByDate);
                }
                break;

            case ApiCallType.GetBalancingReport:
                let balancingReport = body
                balancingReport.End.Date = Utils.replaceDateString(balancingReport.End.Date);
                balancingReport.Start.Date = Utils.replaceDateString(balancingReport.Start.Date);
                for (const service of balancingReport.Services) {
                    service.MoneyServiceDate = Utils.replaceDateString(service.MoneyServiceDate);
                    service.SavedByDate = Utils.replaceDateString(service.SavedByDate);
                }
                break;
        }

        return body || {};
    }

    static signOut() {
        localStorage.removeItem('currentUser');
        location.reload();
    }

    static replaceDatesInComponent(component) {
        for (let eventEntry of component.EventEntries) {
            eventEntry.EventDate = Utils.replaceDateString(eventEntry.EventDate);
        }

        for (let stateChange of component.StateChanges) {
            stateChange.ChangeDate = Utils.replaceDateString(stateChange.ChangeDate);
        }

        for (let subComponent of component.SubComponents)
            ApiDataConverter.replaceDatesInComponent(subComponent);
    }

    static mapTerminalTypeToCssClass(terminalType: TerminalType): string {
        switch (terminalType) {
            case TerminalType.Caion: return "cashio-caion";
            case TerminalType.Cashio: return "cashio-platinum";
            case TerminalType.Tita: return "cati";
            case TerminalType.Caro: return "cashio-caro";
            case TerminalType.Cata: return "cati";
            case TerminalType.CashioTitanium: return "cashio-titanium";
            case TerminalType.CashioGold: return "cashio-gold";
            case TerminalType.CashioSilver: return "cashio-silver";
            case TerminalType.CashioChrome: return "cashio-chrome";
            case TerminalType.AdPlayer: return "ad_player";
            case TerminalType.TwoPay: return "cashio-2pay";
            case TerminalType.CashioPlatinumGen2: return "cashio-platinum-gen2";
            case TerminalType.CashDispenser: return "cash-dispenser";
            case TerminalType.CashioJps: return "cashio-jps";
            case TerminalType.CashioTitaniumGen2: return "cashio-titanium-gen2";
            default: return "unknown";
        }
    }
}
