import { Pipe, PipeTransform } from '@angular/core';
import { DependencyType } from '../_enums/dependencyType';
@Pipe({
    name: 'dependency'
})
export class DependencyPipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];
        if (DependencyType[value] === "None") {
            return "None";
        }
        else {
            return DependencyType[value];
        }
    }
}
