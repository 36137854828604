import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cpuArch'
})
export class CpuArchPipe implements PipeTransform {

    transform(cpuArchitecture: string): string {
        switch (cpuArchitecture) {
            case "x86":
                return "x86";
            case "x64":
                return "x86-64";
            case "arm":
                return "ARM";
            case "aarch64":
                return "ARM64";
            default:
                return cpuArchitecture;
        }
    }
}
