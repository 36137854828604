import * as moment from "moment/moment";
import { ArtInstallation } from '../_models/group/artInstallation';
import { ITerminal } from '../_models/group/terminal';
import { IMaintenanceOverviewView } from '../_models/group/maintenanceOverview';
import { IProperty } from '../_models/group/property';
import { Address, AddressView } from '../_models/address';
import { ArtInstallationMode } from '../_enums/group/artInstallationMode';

export class ArtMain {
    id: string;
    searchInfos: string;
    mode: ArtInstallationMode;
    lastSync: any;
    visible: boolean;

    constructor(art: ArtInstallation) {
        this.id = art.ArtId;
        this.lastSync = moment(art.Timestamp);
        this.visible = false;
    }

    setVisible(mode: number, connection: Date, search: string, exclude: string) {
        let filter1 = mode != null ? this.mode == mode : true;
        let filter2 = connection != null ? this.lastSync >= moment().subtract(7, 'days').toISOString() : true;
        let filter3 = search != null ? this.search(search) : true;
        let filter4 = exclude != null && exclude != "" ? this.exclude(exclude) : true;
        this.visible = filter1 && filter2 && filter3 && filter4;
    }

    private search(searchElement: string) {
        searchElement = searchElement.replace(/\s/g, "").toLowerCase();

        return this.searchInfos.includes(searchElement);
    }

    private exclude(excludeElement: string) {
        excludeElement = excludeElement.replace(/\s/g, "").toLowerCase();

        return !this.searchInfos.includes(excludeElement);
    }
}

export class ArtView extends ArtMain {
    name: string;
    address: AddressView;
    terminals: ITerminal[];
    terminalCount: number;
    transactions: any;
    months: IArtViewMonth;
    maintenance: IMaintenanceOverviewView;
    operators: any;
    operatorsCount: number;
    properties: IProperty[];
    propertiesCount: number;
    modeName: string;

    constructor(art: ArtInstallation) {
        super(art);

        this.name = art.ArtName !== '' ? art.ArtName : art.ArtId;
        this.mode = art.ArtMode;
        this.operators = art.Operators;
        this.operatorsCount = art.OperatorsCount;
        this.properties = art.Properties
        this.propertiesCount = art.Properties != null ? art.Properties.length : 0;
        this.transactions = {};

        this.setMonths();
        this.setAddress(art);
        this.setSearchInfos();
        this.setTerminals(art);

    }

    private setTerminals(art: ArtInstallation) {
        this.terminals = [];
        this.terminalCount = art.TerminalsCount;

        for (let terminal of art.Terminals) {
            this.terminals.push(terminal);
        }

    }

    private setSearchInfos() {
        let searchAddress = this.address.street + this.address.zipCode + this.address.city + this.address.state + this.address.country;
        let searchInfos = this.name + searchAddress + this.id;
        this.searchInfos = searchInfos.replace(/\s/g, "").toLowerCase();
    }

    private setAddress(art: ArtInstallation) {
        let PostalAddress: Address = art.AddressExt.PostalAddress;

        this.address = {
            street: PostalAddress.Street,
            zipCode: PostalAddress.ZipCode,
            city: PostalAddress.City,
            state: PostalAddress.State,
            country: PostalAddress.Country,
            full: PostalAddress.Street +
                PostalAddress.ZipCode + ' ' +
                PostalAddress.City + ' ' +
                PostalAddress.State + ' ' +
                PostalAddress.Country
        };

    }

    private setMonths() {

        this.months = {
            utilizationOverviews: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, ave: 0 },
            transactionTotals: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, ave: 0 },
            transactionTotal: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, ave: 0 },
        };

    }

    setMaintenance(maintenanceOverviewView: IMaintenanceOverviewView) {

        this.maintenance = {
            id: maintenanceOverviewView.id,
            mode: maintenanceOverviewView.mode,
            cleanings: maintenanceOverviewView.cleanings,
            maintenances: maintenanceOverviewView.maintenances,
            overViewData: maintenanceOverviewView.overViewData
        };

    }
}

export interface IArtViewMonth {
    utilizationOverviews: IArtViewMonthAttribute;
    transactionTotals: IArtViewMonthAttribute;
    transactionTotal: IArtViewMonthAttribute;
}

export interface IArtViewMonthAttribute {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
    6: number;
    7: number;
    8: number;
    9: number;
    10: number;
    11: number;
    12: number;
    ave: number;
}
