import { Pipe, PipeTransform } from '@angular/core';
import { TerminalType } from '../_enums/terminalType';
@Pipe({
    name: 'terminalTypeByID'
})
export class TerminalTypeByIDPipe implements PipeTransform {

    transform(value: number): string {
        return TerminalType[value];
    }
}
