import { Component, Input, NgZone, SimpleChanges, OnChanges } from '@angular/core';
import { Terminal } from "../../../../../../auth/_models";
import { StockForTerminal } from "../../../../../../auth/_models/stockForTerminal";
import { GlobalCacheVariable } from "../../../../../../auth/_globals/globals";
import { TerminalService } from "../../../../../../auth/_services";
import { Router } from "@angular/router";
import { LoggingService } from "../../../../../../auth/_services/log.service";
import { OperatorService } from "../../../../../../auth/_services/operator.service";
import { RewriteService } from "../../../../../../auth/_services/rewrite.service";
import { DebugService } from "../../../../../../auth/_services/debug.service";
import { Stock } from "../../../../../../auth/_helpers/stock";
import { TransActionLogListItem } from "../../../../../../auth/_models/transactionloglistitem";
import { DatePipe } from "@angular/common";
import { StockQueryType } from '../../../../../../auth/_enums/stockQueryType';
import { ExportService } from "../../../../../../auth/_services/export.service";
import { GeneralRewritePipe } from "../../../../../../auth/_pipes/generalRewrite.pipe";
import { ArtCurrencyPipe } from "../../../../../../auth/_pipes/artCurrency.pipe";
import { StockMediumType } from "../../../../../../auth/_enums/stockMediumType";
import { DateFormat, DateFormatUnit } from "../../../../../../auth/_enums/general/date";
import * as moment from "moment/moment";


@Component({
    selector: 'terminal-details',
    templateUrl: './terminal-details.component.html',
    styleUrls: ['./terminal-details.component.css'],
    providers: [DatePipe, GeneralRewritePipe, ArtCurrencyPipe]
})
export class TerminalDetailsComponent implements OnChanges {

    @Input('sourceComponent') sourceComponent;
    @Input('terminalID') terminalID;
    @Input('terminal') terminal;

    entryPoint: string = "DM";
    stockDetailInformations: any;
    stockForTerminal: any;
    terminalVersions: any;
    terminalVersionsMain: any;
    lastTransactions: Array<TransActionLogListItem>;
    currentStockDate: any = moment().subtract(1, 'days').hours(0).minutes(0).seconds(0).milliseconds(0).format('MMMM Do YYYY, h:mm:ss a');
    currentStockDateView: any;
    stockDateState: boolean = false;
    customData: boolean = false;
    StockQueryType = StockQueryType;
    exportColumns: any[];
    printData: any;

    transactionLogDetailView: boolean = false;
    displayTransactionLogDetail: boolean = false;
    currentTransactionID: number = 0;
    currentTransactionLogDetailTerminalID: number = 0;
    stockNewWay: any;
    stockDetailViewFull: boolean = false;
    printable: boolean = false;

    constructor(
        public globalCacheVariable: GlobalCacheVariable,
        public stock: Stock,
        public terminalService: TerminalService,
        private zone: NgZone,
        private _router: Router,
        public loggingService: LoggingService,
        private operatorService: OperatorService,
        private rewriteService: RewriteService,
        private debugService: DebugService,
        private datePipe: DatePipe,
        private exportService: ExportService,
        private generalRewrite: GeneralRewritePipe,
        public artCurrency: ArtCurrencyPipe
    ) {
        this.exportColumns = [
            { field: 'Name', header: this.generalRewrite.translate('BASE.CATEGORY') },
            { field: 'Amount', header: this.generalRewrite.translate('BASE.PIECES') },
            { field: 'Sum', header: this.generalRewrite.translate('BASE.SUM') },
        ];

    }


    ngOnInit(): void {
        this.exportColumns = this.exportColumns.map(col => ({ title: col.header, dataKey: col.field }));

        //this.getRecentTransactions(this.terminalID);
        //this.getCurrentVersions(this.terminalID);
        //this.calculateStockForTerminal(this.terminalID);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getRecentTransactions(this.terminalID);
            this.getCurrentVersions(this.terminalID);
            this.calculateStockForTerminal(this.terminalID);

        }
    }

    changeToCustomData(terminalID) {
        if (this.customData == false) {
            this.calculateStockForTerminal(terminalID);
        } else {

        }
    }

    stockDateStateChange() {
        if (this.stockDateState == false) {
            this.stockDateState = true;
        } else {
            this.stockDateState = false;
        }
    }

    changeStockDate(currentValue) {
        console.log("currentValue", currentValue);
    }

    getTerminalStock(terminalID, queryType) {
        //this.currentStockDate = this.datePipe.transform(this.currentStockDatePrime, this.globalCacheVariable.globalFormatSettings.dateFormatMiddle);
        this.terminalService.getTerminalStock(terminalID, this.currentStockDate, queryType)
            .subscribe((data: StockForTerminal) => {
                this.stockData(data, terminalID);
            });
    }

    calculateStockForTerminal(terminalID) {
        this.terminalService.getTerminalDetails(terminalID)
            .subscribe((data: StockForTerminal) => {
                this.stockData(data, terminalID);

            });
    }

    stockData(data, terminalID) {
        //console.log("temrinalID", data);


        this.zone.run(() => {
            this.stockForTerminal = data;
            this.stockForTerminal.Terminal = 0;
            this.stockForTerminal.TerminalData = this.terminal;
            this.printable = false;
            if (data.TerminalID != 0) {
                this.printable = true;
                this.currentStockDate = moment(Number(data.StockDate)).toDate();
                this.currentStockDateView = this.datePipe.transform(data.StockDate, this.globalCacheVariable.globalFormatSettings.dateFormatMiddle);
            }
            this.stockDetailInformations = this.stock.calculateStockForTerminal(data, terminalID);

            //console.log("this.stockDetailInformations", this.stockDetailInformations);
            this.stockDetailViewFull = true;
        });

    }

    getRecentTransactions(terminalID) {
        this.loggingService.getRecentTransactions(terminalID)
            .subscribe(dataTransactions => {
                this.zone.run(() => { // <== added
                    //console.log("dataTransactions -> ", dataTransactions);
                    this.lastTransactions = dataTransactions;
                });
            });
    }

    getCurrentVersions(terminalID) {
        this.terminalService.getTerminalVersions(terminalID)
            .subscribe(dataTransactions => {
                this.zone.run(() => { // <== added
                    //console.log("dataTransactions -> ", dataTransactions);
                    let terminalMainVersions = {
                        Software: "",
                        Configuration: "",
                        COS: "",
                        ICOS: "",
                    };
                    for (let i = 0; i < dataTransactions.length; i++) {
                        if (dataTransactions[i].Type === 1) {
                            terminalMainVersions.Software = dataTransactions[i].Version;
                        }
                        else if (dataTransactions[i].Type === 2) {
                            terminalMainVersions.Configuration = dataTransactions[i].Version;
                        }
                        else if (dataTransactions[i].Type === 5) {
                            terminalMainVersions.COS = dataTransactions[i].ComponentName + " " + dataTransactions[i].Version;
                        }
                        else if (dataTransactions[i].Type === 4) {
                            terminalMainVersions.ICOS = dataTransactions[i].Version;
                        }
                    }

                    this.terminalVersions = dataTransactions;
                    this.terminalVersionsMain = terminalMainVersions;
                });
            });
    }

    toggleStockDetails() {

        let container = $('.stock.parent.open');

        if (container.length > 0) {
            $(".stock-details").hide();
            $('.stock.parent').removeClass('open');
            $(".detail-open .show-more-button.close-details").hide();
            $(".detail-open .show-more-button.show-details").show();

            if (this.sourceComponent === 'terminal-list') {
                /*
                $(".terminal-list-detail-dialog").mCustomScrollbar("destroy");

                 */
            }
        } else {
            $(".stock-details").show();
            $('.stock.parent').addClass('open');
            $(".detail-open .show-more-button.show-details").hide();
            $(".detail-open .show-more-button.close-details").show();

            if (this.sourceComponent === 'terminal-list') {
                /*
                                    $(".terminal-list-detail-dialog").mCustomScrollbar({
                                        "scrollInertia": 0,
                                        scrollbarPosition: "outside"
                                    });
                */
            }


        }
    }

    onShow(event, dialog) {
        dialog.maximize();
    }

    openTransactionDetailDialog(transactionID, terminalID) {
        // console.log("open transactionlog -> ", transactionID);
        this.currentTransactionLogDetailTerminalID = terminalID;
        this.currentTransactionID = transactionID;

        this.transactionLogDetailView = true;
        this.displayTransactionLogDetail = true;
    }

    toggleVersionDetails() {

        let container = $('.version.parent.open');

        if (container.length > 0) {
            $(".version-details").hide();
            $('.version.parent').removeClass('open');
            $(".detail-open .show-more-button.close-details-version").hide();
            $(".detail-open .show-more-button.show-details-version").show();

        } else {
            $(".version-details").show();
            $('.version.parent').addClass('open');
            $(".detail-open .show-more-button.show-details-version").hide();
            $(".detail-open .show-more-button.close-details-version").show();
        }
    }

    closeDetailOpen() {
        $('.m-content.detail-open').removeClass('detail-open');
    }


    printStock() {
        let printData = [];
        printData = this.preparePrintable();
        this.exportService.exportTablePDFWithOptions(this.exportColumns, printData, "stock");
    }

    preparePrintable() {
        let printableData = [];

        printableData.push({
            Name: this.generalRewrite.translate('TERMINAL.DETAILS.STOCK.MAIN'),
            Amount: '',
            Sum: '',
        });

        //STOCK
        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.STOCK.BILL_ACCEPTOR'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.billAcceptorTotalSum + this.stockDetailInformations.ticketInAmount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            },
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.STOCK.BILL_DISPENSER'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.dispenserTotalSum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            },
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.STOCK.COIN_ACCEPTOR'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.coinAcceptorTotalSum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            },
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.STOCK.COIN_DISPENSER'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.coinDispenserTotalSum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            },
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.STOCK.TOTAL_CASH'),
                Amount: '',
                Sum: this.artCurrency.transform(((this.stockDetailInformations.billAcceptorTotalSum + this.stockDetailInformations.coinAcceptorTotalSum + this.stockDetailInformations.ticketInAmount) + (this.stockDetailInformations.dispenserTotalSum) + (this.stockDetailInformations.coinDispenserTotalSum)), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            },

        );

        //BILLS ACCEPTOR
        if (this.stockForTerminal.BillAcceptor !== null) {
            printableData.push(
                {
                    Name: this.generalRewrite.translate('TERMINAL.DETAILS.BILL_ACCEPTOR.MAIN'),
                    Amount: '',
                    Sum: '',
                });

            for (let billAcceptorData of this.stockForTerminal.BillAcceptor) {
                printableData.push(
                    {
                        Name: '   ' + StockMediumType[billAcceptorData.Type] + ' ' + this.artCurrency.transform((billAcceptorData.Amount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                        Amount: billAcceptorData.Pieces,
                        Sum: this.artCurrency.transform((billAcceptorData.Sum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                    });
            }

            printableData.push(
                {
                    Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.BILL_ACCEPTOR.TOTAL_BILLS'),
                    Amount: this.stockDetailInformations.billAcceptorTotalCount,
                    Sum: this.artCurrency.transform((this.stockDetailInformations.billAcceptorTotalSum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                });

            if (this.stockDetailInformations.ticketInCount !== 0) {
                printableData.push(
                    {
                        Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.BILL_ACCEPTOR.TICKETS_IN'),
                        Amount: this.stockDetailInformations.ticketInCount,
                        Sum: this.artCurrency.transform((this.stockDetailInformations.ticketInAmount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                    });
            }

            printableData.push(
                {
                    Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.BILL_ACCEPTOR.TOTAL_ACCEPTOR'),
                    Amount: (this.stockDetailInformations.ticketInCount + this.stockDetailInformations.billAcceptorTotalCount),
                    Sum: this.artCurrency.transform((this.stockDetailInformations.ticketInAmount + this.stockDetailInformations.billAcceptorTotalSum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                });


            if (this.stockDetailInformations.ticketOutCount !== 0) {
                printableData.push(
                    {
                        Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.BILL_ACCEPTOR.TICKETS_OUT'),
                        Amount: this.stockDetailInformations.ticketOutCount,
                        Sum: this.artCurrency.transform((this.stockDetailInformations.ticketOutAmount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                    });
            }
        }

        //BILL DISPENSER
        if (this.stockForTerminal.BillAcceptor !== null) {
            printableData.push(
                {
                    Name: this.generalRewrite.translate('TERMINAL.DETAILS.BILL_DISPENSER.MAIN'),
                    Amount: '',
                    Sum: '',
                });

            for (let billDispenserData of this.stockForTerminal.Dispenser) {
                if (billDispenserData.Type !== 62) {
                    printableData.push(
                        {
                            Name: '   ' + StockMediumType[billDispenserData.Type] + ' ' + this.artCurrency.transform((billDispenserData.Amount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                            Amount: billDispenserData.Pieces,
                            Sum: this.artCurrency.transform((billDispenserData.Sum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                        });
                } else {
                    printableData.push(
                        {
                            Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.BILL_DISPENSER.REJECT'),
                            Amount: billDispenserData.Pieces,
                            Sum: this.artCurrency.transform((billDispenserData.Amount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                        });
                }

            }

            printableData.push(
                {
                    Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.BILL_DISPENSER.TOTAL_BILLS'),
                    Amount: this.stockDetailInformations.dispenserTotalCount,
                    Sum: this.artCurrency.transform((this.stockDetailInformations.dispenserTotalSum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                });
        }

        //COIN DISPENSER
        if (this.stockForTerminal.Hopper !== null) {
            printableData.push(
                {
                    Name: this.generalRewrite.translate('TERMINAL.DETAILS.COIN_DISPENSER.MAIN'),
                    Amount: '',
                    Sum: '',
                });

            for (let coinDispenserData of this.stockForTerminal.Hopper) {
                printableData.push(
                    {
                        Name: '   ' + StockMediumType[coinDispenserData.Type] + ' ' + this.artCurrency.transform((coinDispenserData.Amount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                        Amount: coinDispenserData.Pieces,
                        Sum: this.artCurrency.transform((coinDispenserData.Sum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                    });
            }

            printableData.push(
                {
                    Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.COIN_DISPENSER.TOTAL_COINS'),
                    Amount: this.stockDetailInformations.coinDispenserTotalCount,
                    Sum: this.artCurrency.transform((this.stockDetailInformations.coinDispenserTotalSum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                });
        }
        //CARD STACKER
        printableData.push(
            {
                Name: this.generalRewrite.translate('TERMINAL.DETAILS.CARDS.MAIN'),
                Amount: '',
                Sum: '',
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.CARDS.STACKER'),
                Amount: '',
                Sum: this.stockDetailInformations.cardsStacker,
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.CARDS.CATCHING_BIN'),
                Amount: '',
                Sum: this.stockDetailInformations.cardsBin,
            });

        //ROUNDING DIFFERENCES
        printableData.push(
            {
                Name: this.generalRewrite.translate('TERMINAL.DETAILS.ROUNDING_DIFFERENCES.MAIN'),
                Amount: '',
                Sum: '',
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.ROUNDING_DIFFERENCES.TICKETS'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.roundingTicket), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.ROUNDING_DIFFERENCES.PLAYER_CARDS'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.roundingPlayerCards), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.ROUNDING_DIFFERENCES.BONUS_CARDS'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.roundingBonusCards), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.ROUNDING_DIFFERENCES.JACKPOT'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.roundingJackpots), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.ROUNDING_DIFFERENCES.BILLS'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.roundingBills), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.ROUNDING_DIFFERENCES.TOTAL'),
                Amount: '',
                Sum: this.artCurrency.transform(this.stockDetailInformations.roundingTotal, this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        //SAM
        printableData.push(
            {
                Name: this.generalRewrite.translate('TERMINAL.DETAILS.SAM.MAIN'),
                Amount: '',
                Sum: '',
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.SAM.VALUE'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.sam), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        //MEDIA
        printableData.push(
            {
                Name: this.generalRewrite.translate('TERMINAL.DETAILS.MEDIA.MAIN'),
                Amount: '',
                Sum: '',
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.MEDIA.WITHHELD'),
                Amount: this.stockDetailInformations.mediaInWithheldCount,
                Sum: this.artCurrency.transform((this.stockDetailInformations.mediaInWithheldAmount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.MEDIA.PAID'),
                Amount: this.stockDetailInformations.mediaOutPaidCount,
                Sum: this.artCurrency.transform((this.stockDetailInformations.mediaOutPaidAmount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.MEDIA.DIFFERENCES'),
                Amount: (this.stockDetailInformations.mediaInWithheldCount - this.stockDetailInformations.mediaOutPaidCount),
                Sum: this.artCurrency.transform((this.stockDetailInformations.mediaInWithheldAmount - this.stockDetailInformations.mediaOutPaidAmount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        //MEDIA DEPOSIT
        printableData.push(
            {
                Name: this.generalRewrite.translate('TERMINAL.DETAILS.MEDIA_DEPOSIT.MAIN'),
                Amount: '',
                Sum: '',
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.MEDIA_DEPOSIT.WITHHELD'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.depositWithheld), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.MEDIA.PAID'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.depositPaid), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        printableData.push(
            {
                Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.MEDIA.DIFFERENCES'),
                Amount: '',
                Sum: this.artCurrency.transform((this.stockDetailInformations.depositWithheld - this.stockDetailInformations.depositPaid), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
            });

        //COIN ACCEPTOR
        if (this.stockForTerminal.CoinAcceptor !== null) {
            printableData.push(
                {
                    Name: this.generalRewrite.translate('TERMINAL.DETAILS.COIN_ACCEPTOR.MAIN'),
                    Amount: '',
                    Sum: '',
                });

            for (let coinAcceptorData of this.stockForTerminal.CoinAcceptor) {
                printableData.push(
                    {
                        Name: '   ' + StockMediumType[coinAcceptorData.Type] + ' ' + this.artCurrency.transform((coinAcceptorData.Amount), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                        Amount: coinAcceptorData.Pieces,
                        Sum: this.artCurrency.transform((coinAcceptorData.Sum), this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                    });
            }

            printableData.push(
                {
                    Name: '   ' + this.generalRewrite.translate('TERMINAL.DETAILS.COIN_ACCEPTOR.TOTAL_COINS'),
                    Amount: this.stockDetailInformations.coinAcceptorTotalCount,
                    Sum: this.artCurrency.transform(this.stockDetailInformations.coinAcceptorTotalSum, this.stockForTerminal.TerminalData.SystemCurrency, 'code'),
                });
        }

        return printableData;
    }
}
