import { ComponentType } from '../_enums/componentType';
import { ComponentState } from '../_enums/componentState';
import { TerminalState } from '../_enums/terminalState';
import { TerminalConnectionType } from '../_enums/terminalConnectionType';
import { FeatureState } from '../_enums/featureState';

export class MainStateMapper {

    public iconUndefinedState: string = "pi pi-question-circle warning";
    public iconWorkingState: string = "pi pi-check-circle success";
    public iconErrorState: string = "pi pi-times-circle error";
    public iconWarningState: string = "pi pi-info-circle warning";

}

export class ComponentStateMapper extends MainStateMapper {

    public componentTypes: Map<ComponentType, string>;
    public componentStateClass: Map<ComponentState, string>;
    public componentStateTexts: Map<ComponentState, string>;
    public componentsToShow: ComponentType[];

    constructor() {
        super();

        this.componentTypes = new Map([
            [ComponentType.MachineIntegrity, "machineIntegrity"],
            [ComponentType.CoinAcceptor, "coinAcceptor"],
            [ComponentType.CoinDispenser, "coinDispenser"],
            [ComponentType.CombinedAcceptor, "combinedAcceptor"],
            [ComponentType.BillAcceptor, "billAcceptor"],
            [ComponentType.BillDispenser, "billDispenser"],
            [ComponentType.TicketPrinter, "ticketPrinter"],
            [ComponentType.CardSystem, "cardSystem"],
            [ComponentType.CardReader, "cardReader"],
            [ComponentType.CardDispenser, "cardDispenser"],
            [ComponentType.ReceiptPrinter, "receiptPrinter"],
            [ComponentType.Atm, "atm"],
            [ComponentType.Pos, "pos"],
            [ComponentType.ArtServer, "artServer"],
            [ComponentType.OnlineSystem, "onlineSystem"],
            [ComponentType.JackpotSystem, "jackpotSystem"],
            [ComponentType.MonitoringSystem, "monitoringSystem"],
        ]);

        this.componentStateClass = new Map([
            [ComponentState.None, this.iconUndefinedState],
            [ComponentState.StartUp, this.iconUndefinedState],
            [ComponentState.Working, this.iconWorkingState],
            [ComponentState.Warning, this.iconWarningState],
            [ComponentState.Error, this.iconErrorState],
        ]);

        this.componentStateTexts = new Map([
            [ComponentState.None, "ENUMS.COMPONENT_DEVICE_STATE.NONE"],
            [ComponentState.StartUp, "ENUMS.COMPONENT_DEVICE_STATE.START_UP"],
            [ComponentState.Working, 'ENUMS.COMPONENT_DEVICE_STATE.WORKING'],
            [ComponentState.Warning, 'ENUMS.COMPONENT_DEVICE_STATE.WARNING'],
            [ComponentState.Error, 'ENUMS.COMPONENT_DEVICE_STATE.ERROR'],
        ]);

        this.componentsToShow = [
            ComponentType.MachineIntegrity,
            ComponentType.OnlineSystem,
            ComponentType.JackpotSystem,
            ComponentType.Atm,
            ComponentType.Pos,
            ComponentType.CoinAcceptor,
            ComponentType.CoinDispenser,
            ComponentType.BillAcceptor,
            ComponentType.BillDispenser,
            ComponentType.CombinedAcceptor,
            ComponentType.TicketPrinter,
            ComponentType.ReceiptPrinter,
            ComponentType.CardSystem,
            ComponentType.CardReader,
            ComponentType.CardDispenser,
            ComponentType.MonitoringSystem,
            ComponentType.ArtServer
        ];

    }

}

export class TerminalStateClass {

    public states: Map<TerminalState, string>;
    public connected: Map<TerminalConnectionType, string>;

    constructor() {

        this.states = new Map([
            [TerminalState.OutOfService, 'terminal-state-background-inactive'],
            [TerminalState.Ready, 'terminal-state-background-success'],
            [TerminalState.InTransaction, 'terminal-state-background-success'],
            [TerminalState.Failure, 'terminal-state-background-error'],
            [TerminalState.Blocked, 'terminal-state-background-blocked'],
            [TerminalState.ManufacturedBlocked, 'terminal-state-background-manufactured-blocked'],
            [TerminalState.ShutOff, 'terminal-state-background-inactive'],
        ]);

        this.connected = new Map([
            [TerminalConnectionType.NotConnected, 'terminal-state-background-error'],
            [TerminalConnectionType.Connected, 'terminal-state-background-success'],
        ]);

    }

}

export class FeatureStateMapper extends MainStateMapper {

    public featureStateClass: Map<FeatureState, string>;

    constructor() {
        super();

        this.featureStateClass = new Map([
            [FeatureState.Working, this.iconWorkingState],
            [FeatureState.Warning, this.iconWarningState],
            [FeatureState.Error, this.iconErrorState],
        ]);

    }

}
