import { Injectable } from "@angular/core";
import * as moment from "moment";
import { DateFormatUnit } from "../../_enums/general/date";

@Injectable()
export class FormatDate {
    manipulateDateNow() {
        return moment();
    }

    manipulateDateSubtract(amount?: number, unit?: DateFormatUnit) {
        return moment().subtract(amount, unit).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
    }

    manipulateDateAdd(amount?: number, unit?: DateFormatUnit) {
        return moment().add(amount, unit).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
    }

    manipulateDateBetween(amount?: number, unit?: DateFormatUnit) {
        return moment().isBetween(amount, unit);
    }
}
