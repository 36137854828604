export enum DependencyType {
    None = 0,
    MachineIntegrity = 1,
    CryptoKeyboard = 2,
    CoinAcceptor = 3,
    BillAcceptor = 4,
    CardReader = 5,
    TicketAcceptor = 6,
    CoinDispenser = 7,
    BillDispenser = 8,
    CardDispenser = 9,
    TicketPrinter = 10,
    ArtServer = 11,
    OnlineSystem = 12,
    JackpotSystem = 13,
    Sam = 14,
    Atm = 15,
    Pos = 16,
    Cec = 17,
    ReceiptPrinter = 18,
    FiscalConnection = 19,
    EmvAtmTransactions = 20,
    AtsTransactions = 21,
    AdvertisingServer = 22,
    BonusSystem = 23,
    TransactionApproval = 24,
    RewardSystem = 25,
    Handheld = 26,
    EWallet = 27,
    CryptoKeys = 28,
    SignInSignUpSystem = 29,
    BillPaySystem = 30,
    CheckCashingSystem = 31,
    GiftCardSaleSystem = 32,
    MobileTopUpSystem = 33,
    WireTransferSystem = 34,
    DocumentScanner = 35,
    Camera = 36,
    GiftCardBuySystem = 37,
    CryptoCurrencySystem = 38,
    PrepaidCardSystem = 39,
    BarcodeScanner = 40,
    TellerCashDispenserSystem = 41,
    EWalletSystem = 42,
    AdvertisingStreamPlayer = 43,
    TableSystem = 44,
    CustomerControlSystem = 45
}
