import { Pipe, PipeTransform } from '@angular/core';
import { StockMediumType } from '../_enums/stockMediumType';
@Pipe({
    name: 'stockMediumType'
})
export class StockMediumTypePipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];

        return StockMediumType[value];
    }
}
