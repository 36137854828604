import { Pipe, PipeTransform } from '@angular/core';
import { VersionType } from '../_enums/versionType';
@Pipe({
    name: 'versionType'
})
export class VersionTypePipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];

        return VersionType[value];
    }
}
