export enum LifeCycleStep {
    None = 0,
    Assembled = 1,
    ComponentsTested = 2,
    ReadyForProduction = 3,
    InTestLabUse = 4,
    InProductionUse = 5,
    Suspended = 6,
    Decommissioned = 7,
}
