<div class="p-grid">
    <div class="p-col-12 p-md-3">
        <img width="50%" src="{{ imgSource }}" alt=""/>
    </div>
    <div class="p-col-12 p-md-9 ">
        <div class="p-col-12 ">
            <div class="p-grid">
                <div class="p-col-12 p-md-12">

                    <ng-container *ngIf="globalCache.singleWebClient()">

                        <div>{{ labels.webClientVersion }} - {{ labels.version }}:
                            {{ globalCache.currentARTWebClientVersion }}</div>

                        <div>{{ labels.artServerVersion }} - {{ labels.version }}:
                            {{ globalCache.currentARTServerVersion    }}</div><br/>

                        <div *ngIf="currentConnectedArt != ''">
                            {{ labels.artServerUrl }} - {{ currentConnectedArt }}</div><br/>

                    </ng-container>

                    <ng-container *ngIf="globalCache.groupWebClient()">

                        {{ labels.groupClientVersion }} <br/>

                        {{ labels.version }}: {{globalCache.currentARTWebClientGroupVersion}}

                        <div>{{ labels.groupServerVersion }} - {{ labels.version }}:
                            {{globalCache.currentARTGroupServerVersion}}</div><br/>

                        <div *ngIf="currentConnectedArt != ''">
                            {{ labels.artServerUrl }} - {{currentConnectedArt}}</div><br/>

                        <ng-container  *ngIf="isAllowedAdministration">

                            <button class="send-button"
                                    (click)="openGroupClientUpdateDialog()">{{ labels.updateGroupClient }}</button>

                        </ng-container>
                        <br/><br/>

                    </ng-container>

                    Copyright &copy; 2002 - 2024 CountR Cash Systems GmbH
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-3">
        {{ labels.emailAddress }}: <br/>
        {{ labels.website }}:
    </div>
    <div class="p-col-12 p-md-9">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-12 p-md-12">
                    <a class="external-link" href="mailto:customer.relations@countr.de">
                        <i class="pi pi-external-link"></i>
                        customer.relations@countr.de</a> <br/>
                    <a class="external-link" href="https://countr.de">
                        <i class="pi pi-external-link"></i>
                        www.countr.de</a>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog styleClass="terminal-logfiles-dialog-view" [closeOnEscape]="true"
          [responsive]="true" [resizable]="false" [draggable]="false" [closable]="false"
          [(visible)]="displayView"
          appendTo="body"
          *ngIf="groupClientUpdateView === true" [modal]="true">

    <p-header>
        {{ modalLabels.update }}
    </p-header>

    <div class="p-grid">
        <div class="p-col-12 p-md-12">
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-12">
                        {{ modalLabels.select }}
                    </div>
                    <div class="p-col-12">

                        <input type="file" id="groupUploadFile" name="groupUploadFile"
                               (change)="uploadGroupClientZip($event)">

                    </div>
                </div>
                <p-toolbar>
                    <div class="p-toolbar-group-left">

                    </div>
                    <div class="p-toolbar-group-right">

                        <button class="send-button" (click)="cancelUpdateGroupClient()">{{ modalLabels.cancel }}</button>
                        <button class="send-button" (click)="updateGroupClient()"> {{ modalLabels.startUpdate }}</button>

                    </div>
                </p-toolbar>
            </div>
        </div>
    </div>
</p-dialog>
