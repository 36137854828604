import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services/terminal.service';

import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';

import { LoggingService } from '../../../../../../auth/_services/log.service';
import { OperatorAccessLogListItem } from '../../../../../../auth/_models/operatoraccessloglistitem';
import * as moment from "moment";
import { DatePipe, UpperCasePipe } from "@angular/common";
import { TranslatePipe } from "@ngx-translate/core";
import { TerminalNamePipe } from "../../../../../../auth/_pipes/terminalName.pipe";
import { OperatorNamePipe } from "../../../../../../auth/_pipes/operatorName.pipe";
import { AccessPointPipe } from "../../../../../../auth/_pipes/accessPoint.pipe";
import { RewriteEnumEntriesForTranslationPipe } from "../../../../../../auth/_pipes/rewriteEnumEntriesForTranslation.pipe";
import { GeneralRewritePipe } from "../../../../../../auth/_pipes/generalRewrite.pipe";
import { AccessState } from "../../../../../../auth/_enums/accessState";
import { AccessType } from "../../../../../../auth/_enums/accessType";


@Component({
    selector: "terminal-single-details-operator-access-log",
    templateUrl: "./terminal-single-details-operator-access-log.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe, TranslatePipe, TerminalNamePipe, OperatorNamePipe, AccessPointPipe, UpperCasePipe, RewriteEnumEntriesForTranslationPipe, GeneralRewritePipe]
})

export class TerminalSingleDetailsOperatorAccessLogComponent implements OnInit, AfterViewInit, OnChanges {

    @Input('terminalID') terminalID: number;

    operatorAccessLog: any;
    paginatorPage: number = 1;
    paginatorRowsPerPage: number = 20;
    operatorAccessLogView: boolean = false;
    columns: any[];


    constructor(
        private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        private loggingService: LoggingService,
        public globalCacheVariable: GlobalCacheVariable,
        private generalEnumRewrite: GeneralRewritePipe,
        private datePipe: DatePipe,
        private translate: TranslatePipe,
        private terminalName: TerminalNamePipe,
        private operatorName: OperatorNamePipe,
        private accessPoint: AccessPointPipe,
        private upperCase: UpperCasePipe,
        private rewriteEnumEntriesForTranslation: RewriteEnumEntriesForTranslationPipe,
        private zone: NgZone,
    ) {
        this.operatorAccessLog = new Array<OperatorAccessLogListItem>();

        this.columns = [
            {
                field: 'OperatorID',
                header: this.translate.transform('LOGS.OPERATOR_ACCESS.TABLE.OPERATOR_ID'),
                class: 'tb-left'
            },
            {
                field: 'OperatorName',
                header: this.translate.transform('LOGS.OPERATOR_ACCESS.TABLE.OPERATOR_NAME'),
                class: 'tb-left'
            },
            {
                field: 'AccessPoint',
                header: this.translate.transform('LOGS.OPERATOR_ACCESS.TABLE.ACCESS_FROM'),
                class: 'tb-left'
            },
            {
                field: 'AccessDate',
                header: this.translate.transform('LOGS.OPERATOR_ACCESS.TABLE.ACCESS_ON'),
                class: 'tb-left'
            },
            { field: 'AccessState', header: this.translate.transform('BASE.STATE'), class: 'tb-left' },
            { field: 'AccessType', header: this.translate.transform('BASE.TYPE'), class: 'tb-left' },
            { field: 'AccessDetails', header: this.translate.transform('BASE.DETAILS'), class: 'tb-left' },
        ];
    }

    getOperatorAccessLogData() {
        let startDate = moment().subtract(100, "days");
        let endDate = moment();
        this.loggingService.getRecentOperatorAccessLogs(this.terminalID, 10)
            .subscribe(data => {

                this.zone.run(() => {
                    for (let i = 0; i < data.length; i++) {
                        data[i].OperatorName = this.operatorName.transform(data[i].OperatorID);
                        data[i].AccessDate = this.datePipe.transform(data[i].AccessDate, this.globalCacheVariable.globalFormatSettings.dateFormatLong);
                        data[i].AccessPoint = this.accessPoint.transform(data[i].AccessPoint, data[i].TerminalID, data[i].ClientEndpoint);
                        if (data[i].AccessState !== 'undefined') {
                            data[i].AccessState = this.translate.transform(
                                this.upperCase.transform(
                                    this.rewriteEnumEntriesForTranslation.transform(
                                        'ENUMS.ACCESS_STATE.' + this.generalEnumRewrite.enumRewrite(
                                            AccessState, data[i].AccessState), 'ENUMS.ACCESS_STATE.')
                                )
                            );
                        }

                        if (data[i].AccessType !== 'undefined') {
                            data[i].AccessType = this.translate.transform(
                                this.upperCase.transform(
                                    this.rewriteEnumEntriesForTranslation.transform(
                                        'ENUMS.ACCESS_TYPE.' + this.generalEnumRewrite.enumRewrite(
                                            AccessType, data[i].AccessType), 'ENUMS.ACCESS_TYPE.')
                                )
                            );
                        }
                    }
                    this.operatorAccessLog = data;
                    this.operatorAccessLogView = true;
                });

            });
    }

    ngOnInit(): void {
        //this.getOperatorAccessLogData();
    }

    ngOnChanges(changes: SimpleChanges) {
        //console.log(changes.terminalID);
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getOperatorAccessLogData();
        }
    }


    ngAfterViewInit(): void {
        // this.getTerminalIdentifierList();
    }
}
