<p-dialog styleClass="art-details-terminal-dialog" appendTo="body" [closeOnEscape]="true" [responsive]="true"
          [resizable]="false" [style]="{width: '100vw', 'min-height': '100vh'}"
          (onShow)="showDialogMaximized($event,terminalDetailDialog)" #terminalDetailDialog
          [draggable]="false" [closable]="false" *ngIf="viewReady" [(visible)]="viewReady" [modal]="true">

    <p-header>
        {{ artInstallationDetails?.ArtName             }} -
        {{ currentTerminalDetailsView.TerminalName     }} -
        {{ currentTerminalDetailsView.TerminalTypeName }}
        <i class="close-button fa fa-times" (click)="closeTerminalDetailsModal()"></i>
    </p-header>

    <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 205px)'}" *ngIf="viewReady">

        <div class="p-grid">
            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">

                        <p-card header="{{ currentAddress?.LocationName }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <ng-container *ngIf="artInstallationDetails.ArtWebClientEnabled == false">
                                            <b>{{ goToWebSiteLabel }}</b>
                                        </ng-container>
                                        <ng-container *ngIf="artInstallationDetails.ArtWebClientEnabled == true">
                                            <ng-container *ngIf="artInstallationDetails.ArtWebClientUri != ''">
                                                <b>
                                                    <a href="https://{{artInstallationDetails.ArtWebClientUri}}"
                                                       class="external-link" target="_blank">
                                                        <i class="pi pi-external-link"></i>
                                                        {{ goToWebSiteLabel }}
                                                    </a>
                                                </b>
                                            </ng-container>
                                            <ng-container *ngIf="artInstallationDetails.ArtWebClientUri == ''">
                                                <b>{{ goToWebSiteLabel }}</b>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ locationLabel.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ locationLabel.street  }}</div>
                                        <div class="p-col-12">{{ locationLabel.address }}</div>
                                        <div class="p-col-12">{{ locationLabel.zipCode }}</div>
                                        <div class="p-col-12">{{ locationLabel.city    }}</div>
                                        <div class="p-col-12">{{ locationLabel.county  }}</div>
                                        <div class="p-col-12">{{ locationLabel.state   }}</div>
                                        <div class="p-col-12">{{ locationLabel.country }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.Street       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.AddressLine2 }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.ZipCode      }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.City         }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.County       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.State        }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PostalAddress?.Country      }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ contactInfosLabel.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ contactInfosLabel.email }}</div>
                                        <div class="p-col-12">{{ contactInfosLabel.phone }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ currentAddress?.EmailAddress }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.PhoneNumber  }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ contactPersonLabel.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ contactPersonLabel.name  }}</div>
                                        <div class="p-col-12">{{ contactPersonLabel.email }}</div>
                                        <div class="p-col-12">{{ contactPersonLabel.phone }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ currentAddress?.ContactPerson?.Name        }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.ContactPerson.EmailAddress }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentAddress?.ContactPerson.PhoneNumber  }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ locationLabel.timeZone }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <div class="p-col-12">{{ currentAddress?.TimeZone }}&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                </div>
            </div>
            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">

                        <p-card header="{{ labels.terminalState.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">{{ labels.terminalState.connected }}</div>
                                    <div class="p-col-7">
                                        <div class="{{terminalConnected.class}}">{{terminalConnected.text}}</div>
                                    </div>
                                    <div class="p-col-5">{{ labels.terminalState.main }}</div>
                                    <div class="p-col-7">
                                        <div class="{{terminalState.class}}">{{terminalState.text}}</div>
                                    </div>
                                    <ng-container *ngIf="globalCacheVariable.groupWebClientWorld()">
                                        <div class="p-col-5">{{ LabelBlockUnblock.BlockUnblock }}</div>
                                        <div class="p-col-4">
                                            <div *ngIf="!blockState && currentTerminalDetailsView.TerminalState !== 7" class="terminal-state-background-success">Not blocked</div>
                                            <div *ngIf="blockState && currentTerminalDetailsView.TerminalState != 7" class="terminal-state-background-error">Block pending</div>
                                            <div *ngIf="blockState && currentTerminalDetailsView.TerminalState == 7" class="terminal-state-background-manufactured-blocked">Blocked</div>
                                            <div *ngIf="!blockState && currentTerminalDetailsView.TerminalState == 7" class="terminal-state-background-error">Unblock pending</div>
                                        </div>
                                        <div class="p-col-3">
                                                <button type="button" pButton icon="pi pi-bars" (click)="menu.toggle($event)"></button>
                                                <p-menu #menu [popup]="true" [model]="blockMenuItems" appendTo="body"></p-menu>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="globalCacheVariable.groupWebClient() && blockState && currentTerminalDetailsView.TerminalState == 7">
                                        <div class="p-col-5">{{ LabelBlockUnblock.BlockUnblock }}</div>
                                        <div class="p-col-4">
                                            <div *ngIf="blockState && currentTerminalDetailsView.TerminalState == 7" class="terminal-state-background-manufactured-blocked">Blocked</div>
                                        </div>
                                        <div class="p-col-3">
                                        </div>
                                    </ng-container>
                                    <div class="p-col-5">{{ labels.terminalState.componentState }}</div>
                                    <div class="p-col-7">
                                        <div style="font-size: 22px" class="{{componentState.class}}"
                                             title="{{componentState.text}}"></div>
                                    </div>
                                </div>
                            </ng-container>

                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ labels.terminalStatistics.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ labels.terminalStatistics.firstSeen     }}</div>
                                        <div class="p-col-12">{{ labels.terminalStatistics.lastSeen      }}</div>
                                        <div class="p-col-12">{{ labels.terminalStatistics.lifeCycleStep }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ currentTerminalDetailsView.FirstSeen        }}&nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.LastSeen         }}&nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.LifeCycleStepTxt }}&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                    <div class="p-col-12">

                        <p-card header="{{ labels.terminalConfigLabels.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.id             }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.type           }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.name           }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.timeZone       }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.macAddress     }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.serialNr       }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.systemCurrency }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.distributorNr  }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.customerNr     }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.propertyNr     }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.locationId     }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ currentTerminalDetailsView.TerminalId       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.TerminalTypeName }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.TerminalName     }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.TimeZone         }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.MacAddress       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.SerialNumber     }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.SystemCurrency   }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.DistributorNo    }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.CustomerNo       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.PropertyNo       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetailsView.LocationId       }} &nbsp;
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                </div>
            </div>
            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">

                        <p-card header="{{ labels.terminalVersionLabel }} ({{versionCount}}/{{versionCountMax}})">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-12">

                                        <p-table #dtv [columns]="columnsTerminalDetails"
                                                 [value]="terminalVersionsList" [scrollable]="true"
                                                 [style]="{width:'auto', height: '600px'}" scrollHeight="580px"
                                                 (onFilter)="onFilterVersions($event)"
                                                 [globalFilterFields]="['Name', 'Type']"
                                                 class="media-log-table content-table" *ngIf="terminalDetailTableView">
                                            <ng-template pTemplate="caption">
                                                <div style="text-align: right">
                                                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                                    <input type="text" pInputText size="40"
                                                           placeholder="{{ findButton }}"
                                                           (input)="dtv.filterGlobal($event.target.value, 'contains')"
                                                           style="width:auto">
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="colgroup">
                                                <colgroup>
                                                    <col style="width:150px">
                                                    <col style="width:150px">
                                                    <col style="width:150px">
                                                </colgroup>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-columsDetails>
                                                <tr>
                                                    <th class="{{col.class}}" *ngFor="let col of columsDetails"
                                                        [pSortableColumn]="col.field">
                                                        {{col.header}}
                                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-version>
                                                <tr>
                                                    <td class="tb-left" >{{ version.type    }}</td>
                                                    <td class="tb-left" >{{ version.name    }}</td>
                                                    <td class="tb-right">{{ version.version }}</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>

                                    </div>
                                </div>
                            </ng-container>
                        </p-card>

                    </div>
                </div>
            </div>
        </div>

    </p-scrollPanel>

    <p-footer>
        <p-toolbar>
            <div class="p-toolbar-group-left"></div>
        </p-toolbar>
    </p-footer>

</p-dialog>

<art-installation-terminal-block-unblock *ngIf="artDetailsTerminalBlockUnblockView === true"
                                   (closeTerminalBlockUnblock)       ='closeTerminalBlockUnblock()'
                                         [viewReadyBlockUnblock]     ="artDetailsTerminalBlockUnblockView"
                                         [artInstallationDetails]    ="artInstallationDetails"
                                         [currentTerminalDetails]    ="currentTerminalDetailsView"

></art-installation-terminal-block-unblock>
