import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services/terminal.service';
import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';
import { Terminal } from "../../../../../../auth/_models";
import { TerminalIdentifierEntry } from "../../../../../../auth/_models/terminalIdentifierEntry";
import { TerminalUpdateResultType } from "../../../../../../auth/_enums/terminalUpdateResultType";
import { MessageService } from "primeng/api";
import { Utils } from '../../../../../../auth/_helpers/utils';
import { DatePipe } from '@angular/common';

@Component({
    selector: "terminal-single-details-terminal-edit",
    templateUrl: "./terminal-single-details-terminal-edit.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe]
})

export class TerminalSingleDetailsTerminalEditComponent implements OnInit, AfterViewInit, OnChanges {

    @Input('terminalID') terminalID: number;
    terminalSingleDetailsEdit: boolean = false;
    terminalData: Terminal;
    terminalEditData: Terminal;
    terminalIdentifier: TerminalIdentifierEntry;
    terminalEditCopySafeDoorEmergencyPin: string;
    terminalEditCopyLocationName: string;
    terminalEditCopyTerminalName: string;
    editMode: boolean = false;

    constructor(
        private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        public globalCacheVariable: GlobalCacheVariable,
        private zone: NgZone,
        private messageService: MessageService,
        private datePipe: DatePipe
    ) {
        this.terminalEditCopySafeDoorEmergencyPin = '';
        this.terminalEditCopyLocationName = '';
        this.terminalEditCopyTerminalName = '';
    }

    generateSafeDoorPin() {
        let digits: number = 4;

        const ran = () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].sort((x, z) => {
            let ren = Math.random();
            if (ren == 0.5) return 0;
            return ren > 0.5 ? 1 : -1
        });
        let generatedNumber = Array(digits).fill(null).map(x => ran()[(Math.random() * 9).toFixed()]).join('');
        this.terminalEditCopySafeDoorEmergencyPin = generatedNumber;
        $('.generated-pin').text(generatedNumber);
        //return generatedNumber;
    }

    abortTerminalEdit() {
        this.editMode = false;
        $("p-dialog .terminal-single-dialog-view .button-line.action").hide();
        $("p-dialog .terminal-single-dialog-view .button-line.edit").show();
        this.terminalEditCopySafeDoorEmergencyPin = '';
        this.terminalEditCopyLocationName = this.terminalEditData.LocationName;
        this.terminalEditCopyTerminalName = this.terminalEditData.TerminalName;
    }

    onSubmit(form, isValid: boolean) {
        if (this.terminalEditCopySafeDoorEmergencyPin != '') {
            this.terminalEditData.SafeDoorEmergencyPin = this.terminalEditCopySafeDoorEmergencyPin;
        }
        else {
            this.terminalEditData.SafeDoorEmergencyPin = null;
        }
        this.terminalEditData.LocationName = this.terminalEditCopyLocationName;
        this.terminalEditData.TerminalName = this.terminalEditCopyTerminalName;
        this.terminalService.updateTerminal(this.terminalEditData)
            .subscribe(data => {
                this.zone.run(() => {

                    //console.log("data of terminal Update -> ", data);
                    $('.location-editable').removeClass('error');
                    $('.terminal-name-editable').removeClass('error');
                    //$('.mac-address-editable').removeClass('error');
                    $('.emergency-pin-editable').removeClass('error');
                    if (data === TerminalUpdateResultType.TerminalUpdated) {
                        this.messageService.add({ key: 'infoMessages', severity: 'success', summary: 'Terminal update', life: 5000, detail: 'Terminal  (' + this.terminalEditData.TerminalName + ', ' + this.terminalEditData.TerminalID + ') - Terminal update successful!' });
                        this.editMode = false;
                        $("p-dialog .terminal-single-dialog-view .button-line.action").hide();
                        $("p-dialog .terminal-single-dialog-view .button-line.edit").show();
                    }
                    else {
                        switch (data) {
                            case TerminalUpdateResultType.InvalidSessionKey:
                                $('.location-editable').addClass('error');
                                $('.terminal-name-editable').addClass('error');
                                //$('.mac-address-editable').addClass('error');
                                $('.emergency-pin-editable').addClass('error');
                                this.messageService.add({ key: 'infoMessages', severity: 'error', summary: 'Terminal update', life: 5000, detail: 'Terminal  (' + this.terminalEditData.TerminalName + ', ' + this.terminalEditData.TerminalID + ') - Invalid Sessionkey!' });
                                break;
                            case TerminalUpdateResultType.InsufficientRights:
                                $('.location-editable').addClass('error');
                                $('.terminal-name-editable').addClass('error');
                                //$('.mac-address-editable').addClass('error');
                                $('.emergency-pin-editable').addClass('error');

                                this.messageService.add({ key: 'infoMessages', severity: 'error', summary: 'Terminal update', life: 5000, detail: 'Terminal  (' + this.terminalEditData.TerminalName + ', ' + this.terminalEditData.TerminalID + ') - Insufficient rights!' });
                                break;
                            case TerminalUpdateResultType.InvalidTerminalID:
                                $('.location-editable').addClass('error');
                                $('.terminal-name-editable').addClass('error');
                                //$('.mac-address-editable').addClass('error');
                                $('.emergency-pin-editable').addClass('error');

                                this.messageService.add({ key: 'infoMessages', severity: 'error', summary: 'Terminal update', life: 5000, detail: 'Terminal  (' + this.terminalEditData.TerminalName + ', ' + this.terminalEditData.TerminalID + ') - Invalid Terminal ID!' });
                                break;
                            case TerminalUpdateResultType.TerminalNameRequired:
                                $('.terminal-name-editable').addClass('error');
                                this.messageService.add({ key: 'infoMessages', severity: 'error', summary: 'Terminal update', life: 5000, detail: 'Terminal  (' + this.terminalEditData.TerminalName + ', ' + this.terminalEditData.TerminalID + ') - Terminal name required!' });
                                break;
                            case TerminalUpdateResultType.TerminalNameAlreadyExists:
                                $('.terminal-name-editable').addClass('error');
                                this.messageService.add({ key: 'infoMessages', severity: 'error', summary: 'Terminal update', life: 5000, detail: 'Terminal  (' + this.terminalEditData.TerminalName + ', ' + this.terminalEditData.TerminalID + ') - Terminal name already in use!' });
                                break;
                        }
                    }
                });
            });
    }

    editTerminal() {
        this.terminalEditCopyLocationName = this.terminalEditData.LocationName;
        this.terminalEditCopyTerminalName = this.terminalEditData.TerminalName;
        this.terminalEditCopySafeDoorEmergencyPin = '';

        $('.emergency-pin-input').val("????");
        $('.generated-pin').text("");
        this.editMode = true;
        $("p-dialog .terminal-single-dialog-view .button-line.action").show();
        $("p-dialog .terminal-single-dialog-view .button-line.edit").hide();
    }

    getTerminalEditData() {
        this.terminalService.getSingleTerminalIdentifier(this.terminalID)
            .subscribe(dataIdentifier => {
                this.zone.run(() => {
                    this.terminalIdentifier = dataIdentifier;
                    this.terminalService.getSingleTerminal(this.terminalID)
                        .subscribe(data => {
                            this.zone.run(() => {
                                this.terminalData = data;
                                this.terminalData.FirstSeen = this.datePipe.transform(Utils.replaceDateString(this.terminalData.FirstSeen), this.globalCacheVariable.globalFormatSettings.dateFormatLong)
                                this.terminalData.LastSeen = this.datePipe.transform(Utils.replaceDateString(this.terminalData.LastSeen), this.globalCacheVariable.globalFormatSettings.dateFormatLong)
                                this.terminalEditData = Object.assign({}, this.terminalData);
                                //console.log("this.terminalEditData ", this.terminalEditData);
                                this.terminalSingleDetailsEdit = true;
                                this.editMode = false;
                                $("p-dialog .terminal-single-dialog-view .button-line.action").hide();
                                $("p-dialog .terminal-single-dialog-view .button-line.edit").show();
                            });
                        });
                });
            });
    }

    ngOnInit(): void {
        //this.getTerminalEditData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getTerminalEditData();
        }
    }

    ngAfterViewInit(): void {
    }
}
