export enum ArtInstallationMode {
    None = 0,
    InDevelopment = 1, // Ali, Björn, Max
    InQualityAssurance = 2, // Thomas, Matthias, Matthias II
    InTestLabUse = 3, // Ash
    InProductionUse = 4, // Tsogo, etc.
    Decommissioned = 5, // Alle 😉
    New = 6,
    Showroom = 7,
    Factory = 8,
}
