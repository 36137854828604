export class Address {
    AddressLine2: string;
    City: string;
    Country: string;
    County: string;
    State: string;
    Street: string;
    ZipCode: string;
}

export class AddressView {
    street: string;
    zipCode: string;
    city: string;
    state: string;
    country: string;
    full: string;
}
