import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../_services/user.service";
import { Observable, Subject, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { GlobalCacheVariable } from '../_globals/globals';
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router, private _userService: UserService, public globalCacheVariable: GlobalCacheVariable) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.globalCacheVariable.groupWebClient()) {
            let currentGroupUser = JSON.parse(localStorage.getItem('currentGroupUser'));
            // console.log("inside auth.guard -> canActivate -> ", currentGroupUser);
            if (currentGroupUser && currentGroupUser.Token !== "" && currentGroupUser.Token !== null && currentGroupUser.Token !== undefined) {
                //TODO: implement login process again for checking if still allowed to serve
                // console.log("logged in user -> ", currentGroupUser);
                return true;
            }
            else {
                // console.log("not logged in user -> ", currentGroupUser);
                this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        }
        else {
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            // console.log("inside auth.guard -> canActivate -> ", currentUser);
            if (currentUser && currentUser.SessionKey !== "" && currentUser.SessionKey !== null && currentUser.SessionKey !== undefined) {
                //TODO: implement login process again for checking if still allowed to serve
                // console.log("logged in user -> ", currentUser);
                return true;
            }
            else {
                //console.log("not logged in user -> ", currentUser);
                this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        }


        /*return this._userService.verify().pipe(map(
          data => {
            if (data !== null) {
              // logged in so return true
              return true;
            }
            // error when verify so redirect to login page with the return url
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
          },
          error => {
            // error when verify so redirect to login page with the return url
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
          }
          ));*/
    }
}
