<ng-container *ngIf="globalCacheVariable.singleWebClient()">
    <p-table #dt [value]="currentOperatorSession" [scrollable]="true" [globalFilterFields]="['UserID','UserName', 'ComputerName', 'Endpoint']" [style]="{width:'auto'}" class="operator-session-log-table content-table">
        <ng-template pTemplate="caption">
            <div style="text-align: right">
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50" placeholder="" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto; margin-right: 10px">
                <button class="send-button" (click)="refreshOperatorSession()">{{'USER.CURRENT_OPERATOR_SESSIONS.REFRESH' | translate}}</button>
            </div>
        </ng-template>
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col style="width:50px">
                <col style="width:150px">
                <col style="width:150px">
                <col style="width:100px">
                <col style="width:200px">
                <col style="width:200px">
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!--<th class="tb-left">{{'BASE.TERMINAL' | translate}}</th>-->
                <th class="{{col.class}}" *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-log>
            <tr>
                <td class="tb-left">{{log.UserID}}</td>
                <td class="tb-left">{{log.UserName}}</td>
                <td class="tb-right">{{log.LoginDate | date: globalCacheVariable.globalFormatSettings.dateFormatLong}}</td>
                <td class="tb-left">{{log.ClientType}}</td>
                <td class="tb-right">{{log.Endpoint}}</td>
                <td class="tb-left">{{log.ComputerName}}</td>

            </tr>
        </ng-template>

    </p-table>


</ng-container>
<ng-container *ngIf="globalCacheVariable.groupWebClient()">

    <p-table #dt [value]="currentOperatorSession" [scrollable]="true" [globalFilterFields]="['User.Username', 'Endpoint']" [style]="{width:'auto'}" class="operator-session-log-table content-table">
        <ng-template pTemplate="caption">
            <div style="text-align: right">
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50" placeholder="" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto; margin-right: 10px">
                <button class="send-button" (click)="refreshOperatorSession()">{{'USER.CURRENT_OPERATOR_SESSIONS.REFRESH' | translate}}</button>
            </div>
        </ng-template>
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col style="width:150px">
                <col style="width:150px">
                <col style="width:150px">
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!--<th class="tb-left">{{'BASE.TERMINAL' | translate}}</th>-->
                <th class="{{col.class}}" *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-log>
            <tr>
                <td class="tb-left">{{log.User.FirstName}} {{log.User.LastName}} ({{log.User.Username}})</td>
                <td class="tb-right">{{log.LoginTime | date: globalCacheVariable.globalFormatSettings.dateFormatLong}}</td>
                <td class="tb-right">{{log.Endpoint}}</td>
            </tr>
        </ng-template>

    </p-table>


</ng-container>
