import { Pipe, PipeTransform } from '@angular/core';
import { GlobalCacheVariable } from "../_globals/globals";
import { Operator } from "../_models/operator";

@Pipe({
    name: 'operatorName'
})
export class OperatorNamePipe implements PipeTransform {

    constructor(public globalCacheVariable: GlobalCacheVariable) {
    }

    transform(value: number): any {
        let currentOperatorName: string = 'Unknown Operator (ID:' + value + ')';
        if (this.globalCacheVariable.OperatorsMap.get(value)) {
            let currentOperator = this.globalCacheVariable.OperatorsMap.get(value);
            return currentOperator['OperatorName'];
        } else {
            return currentOperatorName;
        }
    }

    nameWithID(operator: Operator): string {
        return operator.OperatorName + ' (ID: ' + operator.OperatorID + ')';
    }
}
