import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone
} from '@angular/core';
import { GlobalCacheVariable } from "../../../auth/_globals/globals";
import { GroupServerService } from "../../../auth/_services/groupserver.service";
import { ResponseType } from "../../../auth/_enums/group/responseType";
import { UserRight } from "../../../auth/_enums/group/userRight";
import { GeneralService } from '../../../auth/_services/general.service';
import { ToastSeverity } from "../../../auth/_enums/messages/toastSeverity";
import { MessageType } from "../../../auth/_enums/messages/messageType";
import { environment } from "../../../../environments/environment";
import { IArtGroupServerVersion } from '../../../auth/_models/IArtGroupServerVersion';
import { GeneralRewritePipe } from '../../../auth/_pipes/generalRewrite.pipe';
import {
    IArtAboutLabels,
    IArtAboutModalLabels
} from '../../../auth/_models/IArtAboutLabels';

@Component({
    selector: "art-about",
    templateUrl: "./art-about.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [GeneralRewritePipe]
})
export class ArtAboutComponent implements OnInit {
    zipFiles: File = null;
    displayView: boolean = false;
    groupClientUpdateView: boolean = false;
    currentConnectedArt: string = '';
    imgSource: string = '../../../../assets/app/media/img/logos/countr-logo-small.png';
    labels: IArtAboutLabels;
    modalLabels: IArtAboutModalLabels;

    constructor(
        public globalCache: GlobalCacheVariable,
        private groupService: GroupServerService,
        private generalService: GeneralService,
        private zone: NgZone,
        private generalPipe: GeneralRewritePipe,
    ) {
        this.labels = {
            webClientVersion: this.generalPipe.translate('USER.MENU.ART_WEB_CLIENT_VERSION'),
            version: this.generalPipe.translate('BASE.VERSION'),
            artServerVersion: this.generalPipe.translate('USER.MENU.ART_SERVER_VERSION'),
            artServerUrl: this.generalPipe.translate('USER.MENU.ART_SERVER_URL'),
            groupClientVersion: this.generalPipe.translate('USER.MENU.ART_GROUP_WEB_CLIENT_VERSION'),
            groupServerVersion: this.generalPipe.translate('USER.MENU.ART_GROUP_SERVER_VERSION'),
            updateGroupClient: this.generalPipe.translate('USER.MENU.UPDATE_GROUP_CLIENT'),
            emailAddress: this.generalPipe.translate('STATISTICS.ART_OVERVIEW.MODALS.CONTACT_INFORMATION.EMAIL_ADDRESS'),
            website: this.generalPipe.translate('USER.MENU.WEBSITE'),
        };
        this.modalLabels = {
            update: this.generalPipe.translate('USER.MODAL.UPDATE'),
            select: this.generalPipe.translate('USER.MODAL.SELECT'),
            startUpdate: this.generalPipe.translate('USER.MODAL.START_UPDATE'),
            cancel: this.generalPipe.translate('BASE.BUTTONS.CANCEL'),
        };
    }

    ngOnInit(): void {

        if (this.globalCache.singleWebClient()) {

            this.generalService.getArtServerVersionNumber()
                .subscribe(data => {

                    this.zone.run(() => {
                        this.globalCache.currentARTServerVersion = data;
                    })

                });

            if (!environment.production) {
                this.currentConnectedArt = environment.baseUrl;
            }

        }

        if (this.globalCache.groupWebClient()) {

            this.groupService.getArtGroupServerVersion()
                .subscribe((artGroupServerVersion: IArtGroupServerVersion) => {

                    this.zone.run(() => {
                        this.globalCache.currentARTGroupServerVersion = artGroupServerVersion.VersionNumber;
                    })

                })

            if (!environment.production) {
                this.currentConnectedArt = environment.ARTGroupServerBaseIP;
            }

        }

    }

    openGroupClientUpdateDialog() {
        this.zipFiles = null;
        this.displayView = true;
        this.groupClientUpdateView = true;
    }

    updateGroupClient() {
        const fd = new FormData();
        fd.append('files', this.zipFiles, this.zipFiles.name);
        this.groupService.updateGroupClient(fd)
            .subscribe(data => {

                this.zone.run(() => {

                    if (data === ResponseType.Updated) {

                        GlobalCacheVariable.toast.next({
                            severity: ToastSeverity.Success,
                            text: this.generalPipe.translate('USER.MODAL.SUCCESS_UPDATED'),
                            title: this.generalPipe.translate('USER.MODAL.UPDATE_SUCCESS'),
                            life: 3000,
                            type: MessageType.Toast,
                        });

                        this.zipFiles = null;
                        this.displayView = false;
                        this.groupClientUpdateView = false;
                    }
                    else {

                        GlobalCacheVariable.toast.next({
                            severity: ToastSeverity.Error,
                            text: this.generalPipe.translate('USER.MODAL.SERVER_EXCEPTION'),
                            title: this.generalPipe.translate('USER.MODAL.UPDATE_EXCEPTION'),
                            life: 3000,
                            type: MessageType.Toast,
                        });

                    }

                });

            });

    }

    cancelUpdateGroupClient() {
        this.zipFiles = null;
        this.displayView = false;
        this.groupClientUpdateView = false;
    }

    uploadGroupClientZip(event) {
        this.zipFiles = <File>event.target.files[0];
    }

    get isAllowedAdministration() {
        return this.globalCache.checkUserRight(UserRight.Administration);
    }

}
