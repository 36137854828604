import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services/';

import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';

import { TerminalIdentifierEntry } from "../../../../../../auth/_models/terminalIdentifierEntry";


@Component({
    selector: "terminal-single-details-identifier",
    templateUrl: "./terminal-single-details-identifier.component.html",
    encapsulation: ViewEncapsulation.None,
})

export class TerminalSingleDetailsIdentifierComponent implements OnInit, AfterViewInit, OnChanges {

    @Input('terminalID') terminalID: number;

    terminalIdentifierListData: TerminalIdentifierEntry[];
    paginatorPage: number = 1;
    paginatorRowsPerPage: number = 20;
    terminalIdentifierLogView: boolean = false;

    constructor(
        private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        public globalCacheVariable: GlobalCacheVariable,
        private zone: NgZone,
    ) {

    }

    getTerminalIdentifier() {
        this.terminalService.getSingleTerminalIdentifier(this.terminalID)
            .subscribe(data => {
                let terminalIdentifierArray = [];
                this.zone.run(() => { // <== added
                    terminalIdentifierArray.push(data);
                    this.terminalIdentifierListData = terminalIdentifierArray;

                    this.terminalIdentifierLogView = true;
                    setTimeout(function() {
                        //$(".terminal-single-details-container .terminal-identifier-log-table .ui-table-scrollable-body").mCustomScrollbar("destroy");
                        /*$(".terminal-single-details-container .terminal-identifier-log-table .ui-table-scrollable-body").mCustomScrollbar({
                            "scrollInertia": 0,
                            scrollbarPosition: "outside",
                            axis:"y",
                        });*/

                    }, 0);
                });

            });
    }

    ngOnInit(): void {
        //this.getTerminalIdentifier();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getTerminalIdentifier();
        }
    }

    ngAfterViewInit(): void {
        // this.getTerminalIdentifierList();
    }
}
