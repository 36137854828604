export enum UserRight {
    None = 0,
    Administration = 1 << 0,
    ArtOverview = 1 << 1,
    UsersView = 1 << 2,
    ConfigurationView = 1 << 3,
    MaintenanceOverview = 1 << 4,
    DashboardView = 1 << 5,
    QuarterlyDashboardView = 1 << 6,
    TerminalLifecycleView = 1 << 7,
    ComputerRegistrationView = 1 << 8,
    ManufacturingOrderOverview = 1 << 9,
    LicenseKeyUsageOverview = 1 << 10,
    MapView = 1 << 11,
    StockOverview = 1 << 12,
    ComponentOverview = 1 << 13,
    FillLevelView = 1 << 14,
    ManufacturingOrders = 1 << 15,
    KioskRegistration = 1 << 16,
}
