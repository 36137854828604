import { Component, Input, NgZone, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { GlobalCacheVariable } from '../../../auth/_globals/globals';
import { TranslatePipe } from '@ngx-translate/core';
import { GroupServerService } from '../../../auth/_services/groupserver.service';
import { TerminalService } from '../../../auth/_services';
import { Router } from '@angular/router';
import { FeatureType } from '../../../auth/_enums/featureType';
import { GeneralRewritePipe } from '../../../auth/_pipes/generalRewrite.pipe';
import { FeatureStateMapper } from '../../../auth/_helpers/componentState';
import { DependencyPipe } from '../../../auth/_pipes/dependency.pipe';
import { FeatureMonitoringEntry, FeatureMonitoringView, DependencyView } from '../../../auth/_models/featureMonitoringEntry';
import { ComponentType } from '../../../auth/_enums/componentType';
import { FeatureState } from "../../../auth/_enums/featureState";

@Component({
    selector: "feature-overview",
    templateUrl: "./feature-overview.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [TranslatePipe, GeneralRewritePipe, DependencyPipe]
})
export class FeatureOverviewComponent implements OnInit, OnChanges {

    @Input('currentTerminalID') currentTerminalID: number;
    showedFeatures: FeatureMonitoringView[];
    columns: any[];
    displayTerminalFeature: boolean = false;
    currentChangeState: boolean = false;
    //AutoCycleConfig
    cycleInterval: any;
    cycleIntervalTime: number = 20000;

    constructor(
        private groupServerService: GroupServerService,
        private zone: NgZone,
        public globalCacheVariable: GlobalCacheVariable,
        public translate: TranslatePipe,
        public terminalService: TerminalService,
        private _router: Router,
        private generalRewrite: GeneralRewritePipe,
        private featureStateMapper: FeatureStateMapper,
        private dependencyPipe: DependencyPipe,

    ) { }

    ngOnInit(): void {
        clearInterval(this.cycleInterval);
        this.startInterval();
        this.getFeatureOverviewList();
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.currentTerminalID.currentValue !== changes.currentTerminalID.previousValue) {
            this.getFeatureOverviewList();
        }

    }

    showComponentMonitor(dependencyView: DependencyView) {
        this._router.navigate(
            ['component-overview', this.currentTerminalID, dependencyView.componentID, dependencyView.componentRank]
        ).then();
    }

    getFeatureOverviewList() {
        let currentUrl = this._router.url;
        if (
            currentUrl === '/terminal-identifiers'
            || currentUrl === '/terminal-icon'
            || currentUrl === '/terminal-list'
            || currentUrl === '/terminal-fill-level'
            || currentUrl === '/dashboard'
        ) {
            this.terminalService.getFeatureMonitorEntries(this.currentTerminalID)
                .subscribe((featureMonitorEntries: FeatureMonitoringEntry[]) => {

                    this.zone.run(() => {
                        this.changeCurrentViewedTerminal(featureMonitorEntries);
                    });

                });

        }
        else {
            clearInterval(this.cycleInterval);
        }
    }

    changeCurrentViewedTerminal(featureMonitorEntries: FeatureMonitoringEntry[]) {
        this.currentChangeState = false;

        if (featureMonitorEntries !== null && featureMonitorEntries !== undefined && featureMonitorEntries.length > 0) {
            this.currentChangeState = true;

            this.zone.run(() => {
                this.showedFeatures = [];

                for (const featureMonitorEntry of featureMonitorEntries) {
                    let entry: FeatureMonitoringView = {
                        type: this.generalRewrite.enumTranslation(FeatureType, featureMonitorEntry.Type, 'ENUMS.FEATURE_TYPE.'),
                        class: this.featureStateMapper.featureStateClass.get(featureMonitorEntry.State),
                        dependencies: []

                    };

                    for (const dependency of featureMonitorEntry.Dependencies) {
                        let title = this.generalRewrite.enumTranslation(ComponentType, dependency.ComponentID, 'ENUMS.COMPONENT_TYPE.');
                        let alt = '';
                        let img = this.dependencyPipe.transform(dependency.Type) + '_' + this.generalRewrite.enumRewrite(FeatureState, dependency.State);
                        let src = 'assets/app/media/img/dependencyIcons/' + img + '.png';

                        if (dependency.ComponentRank > 0) {
                            title += ' ' + dependency.ComponentRank
                        }

                        if (!dependency.Type) {
                            img = 'Unknown';
                            alt = 'Unknown Dependency # ' + dependency.Type;
                        }

                        let dependencyEntry: DependencyView = {
                            title: title,
                            alt: alt,
                            src: src,
                            componentID: dependency.ComponentID,
                            componentRank: dependency.ComponentRank
                        }
                        entry.dependencies.push(dependencyEntry)
                    }

                    this.showedFeatures.push(entry)
                }

                this.displayTerminalFeature = true;
            });

        }
        else {
            this.displayTerminalFeature = true;
        }

    }

    startInterval() {
        const self = this;

        if (this.globalCacheVariable.singleWebClient()) {
            this.cycleIntervalTime = this.globalCacheVariable.dashboard.dashboardUpdateInterval.cycleIntervalTime;
        }
        else {
            this.cycleIntervalTime = 20000;
        }

        self.cycleInterval = setInterval(function() {
            self.getFeatureOverviewList();
        }, this.cycleIntervalTime);
    }

}
