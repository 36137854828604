/**
Is PhysicalMediumType on terminal software side.
*/
export enum AcceptorMedium {
    None = 0,
    Bill = 1,
    Ticket = 2,
    MagneticCard = 3,
    SmartCard = 4,
    Coin = 5,
    CreditCard = 6,
    RfidCard = 7,
    Chip = 8,
    Coupon = 9,
    PrepaidCard = 10,
    Invoice = 11,
    Check = 12,
    GiftCard = 13,
    CashAdvanceReceipt = 14,
    IouReceipt = 15,
}
