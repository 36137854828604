import { Injectable } from "@angular/core";
import { ArtRightX } from '../_enums/artRightX'
import { Menu, MenuGroup } from '../_models/menu';
import { UserRight } from '../_enums/group/userRight';

@Injectable()
export class MenuService {
    artWebClientMainMenu: Menu[] = [];
    artGroupWebClientMainMenu: MenuGroup[] = [];
    mainMenuSubElements = new Map<string, any[]>();
    routerLinks = new Map<number, any>();
    routerLinksGroup = new Map<number, any>();

    constructor() { }

    setRouterLink(mainMenu, groupClient) {

        for (const menu of mainMenu) {

            if (menu.items.length > 0) {
                this.setRouterLink(menu.items, groupClient);
            }
            else {

                if (groupClient) {
                    this.routerLinksGroup.set(menu.id, { link: menu.link, title: menu.title });
                }
                else {
                    this.routerLinks.set(menu.id, { link: menu.link, title: menu.title });
                }

            }

        }

    }

    mainMenuWebClient() {

        if (this.artWebClientMainMenu.length > 0) {
            return this.artWebClientMainMenu;
        }
        else {
            let mainMenu: Menu[] = [
                {
                    id: ArtRightX.Monitoring,
                    link: '',
                    title: 'MENU.MONITORING.MAIN',
                    class: '',
                    artRightX: ArtRightX.Monitoring,
                    menuClass: 'monitoring',
                    icon: 'pi pi-desktop',
                    items: [
                        {
                            id: ArtRightX.Dashboard,
                            link: '/dashboard',
                            title: 'MENU.MONITORING.CHILD.DASHBOARD',
                            class: '',
                            artRightX: ArtRightX.Dashboard,
                            menuClass: '',
                            icon: '',
                            items: [],

                        },
                        {
                            id: ArtRightX.DashboardDowntime,
                            link: '/dashboard-downtime',
                            title: 'MENU.MONITORING.CHILD.DASHBOARD_DOWNTIME',
                            class: '',
                            artRightX: ArtRightX.DashboardDowntime,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.Terminals,
                            link: '',
                            title: 'MENU.MONITORING.CHILD.TERMINALS',
                            class: '',
                            artRightX: ArtRightX.Terminals,
                            menuClass: '',
                            icon: '',
                            items: [
                                {
                                    id: ArtRightX.TermIconView,
                                    link: '/terminal-icon',
                                    title: 'TERMINAL.BASE.VIEW_SWITCH.ICON',
                                    class: '',
                                    artRightX: ArtRightX.TermIconView,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                },
                                {
                                    id: ArtRightX.TermListView,
                                    link: '/terminal-list',
                                    title: 'TERMINAL.BASE.VIEW_SWITCH.LIST',
                                    class: '',
                                    artRightX: ArtRightX.TermListView,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                },
                                {
                                    id: ArtRightX.TermFillLevelView,
                                    link: '/terminal-fill-level',
                                    title: 'TERMINAL.BASE.VIEW_SWITCH.FILL_LEVEL',
                                    class: '',
                                    artRightX: ArtRightX.TermFillLevelView,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                }
                            ],
                        },
                        {
                            id: ArtRightX.ComponentOverview,
                            link: '/component-overview',
                            title: 'MENU.MONITORING.CHILD.COMPONENT_OVERVIEW',
                            class: '',
                            artRightX: ArtRightX.ComponentOverview,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.TerminalIdentifiers,
                            link: '/terminal-identifiers',
                            title: 'MENU.MONITORING.CHILD.TERMINAL_IDENTIFIER',
                            class: '',
                            artRightX: ArtRightX.TerminalIdentifiers,
                            menuClass: '',
                            icon: '',
                            items: [],
                        }
                    ],
                },
                {
                    id: ArtRightX.LogViews,
                    link: '',
                    title: 'MENU.LOGS.MAIN',
                    class: '',
                    artRightX: ArtRightX.LogViews,
                    menuClass: 'logs',
                    //icon      : 'fa fa-file-text'
                    icon: 'pi pi-file',
                    items: [
                        {
                            id: ArtRightX.LvTransactionLog,
                            link: '/transaction-log',
                            title: 'MENU.LOGS.CHILD.TRANSACTION',
                            class: '',
                            artRightX: ArtRightX.LvTransactionLog,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.LvErrorLog,
                            link: '/error-log',
                            title: 'MENU.LOGS.CHILD.ERROR',
                            class: '',
                            artRightX: ArtRightX.LvErrorLog,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.LvEventLog,
                            link: '/event-log',
                            title: 'MENU.LOGS.CHILD.EVENT',
                            class: '',
                            artRightX: ArtRightX.LvEventLog,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.LvMediaLog,
                            link: '/media-log',
                            title: 'MENU.LOGS.CHILD.MEDIA',
                            class: '',
                            artRightX: ArtRightX.LvMediaLog,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.LvMaintenanceLog,
                            link: '/maintenance-log',
                            title: 'MENU.LOGS.CHILD.MAINTENANCE',
                            class: '',
                            artRightX: ArtRightX.LvMaintenanceLog,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.LvOperatorAccessLog,
                            link: '/operator-access-log',
                            title: 'MENU.LOGS.CHILD.OPERATOR_ACCESS',
                            class: '',
                            artRightX: ArtRightX.LvOperatorAccessLog,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.LvConfigurationChangeLog,
                            link: '/configuration-change-log',
                            title: 'MENU.LOGS.CHILD.CONFIGURATION_CHANGE',
                            class: '',
                            artRightX: ArtRightX.LvConfigurationChangeLog,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.LvArtEventLog,
                            link: '/art-event-log',
                            title: 'MENU.LOGS.CHILD.ART_EVENT',
                            class: '',
                            artRightX: ArtRightX.LvArtEventLog,
                            menuClass: '',
                            icon: '',
                            items: [],
                        }
                    ],
                },
                {
                    id: ArtRightX.Statistics,
                    link: '',
                    title: 'MENU.STATISTICS.MAIN',
                    class: '',
                    artRightX: ArtRightX.Statistics,
                    menuClass: 'statistics',
                    icon: 'pi pi-chart-bar',
                    //icon      : 'fa fa-bar-chart',
                    items: [
                        {
                            id: ArtRightX.TransactionStatistics,
                            link: '',
                            title: 'MENU.STATISTICS.CHILD.TRANSACTION_STATISTICS',
                            class: '',
                            artRightX: ArtRightX.TerminalStatistics,
                            menuClass: '',
                            icon: '',
                            items: [
                                {
                                    id: ArtRightX.TransactionCounts,
                                    link: '/transaction-count',
                                    title: 'MENU.STATISTICS.CHILD.TRANSACTION_COUNT',
                                    class: '',
                                    artRightX: ArtRightX.TransactionCounts,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                },
                                {
                                    id: ArtRightX.TransactionStatistics,
                                    link: '/transaction-statistics',
                                    title: 'MENU.STATISTICS.CHILD.TRANSACTION_STATISTICS',
                                    class: '',
                                    artRightX: ArtRightX.TransactionStatistics,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                }
                            ],
                        },
                        {
                            id: ArtRightX.TerminalStatistics,
                            link: '',
                            title: 'MENU.STATISTICS.CHILD.TERMINAL_STATISTICS',
                            class: '',
                            artRightX: ArtRightX.TerminalStatistics,
                            menuClass: '',
                            icon: '',
                            items: [
                                {
                                    id: ArtRightX.TerminalAvailability,
                                    link: '/terminal-availability',
                                    title: 'MENU.STATISTICS.CHILD.TERMINAL_AVAILABILITY',
                                    class: '',
                                    artRightX: ArtRightX.TerminalAvailability,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                },
                                {
                                    id: ArtRightX.UtilizationReport,
                                    link: '/utilization-report',
                                    title: 'MENU.STATISTICS.CHILD.UTILIZATION_REPORT',
                                    class: '',
                                    artRightX: ArtRightX.UtilizationReport,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                }
                            ],
                        },
                        {
                            id: ArtRightX.MediaStatistics,
                            link: '',
                            title: 'MENU.STATISTICS.CHILD.MEDIA_STATISTICS',
                            class: '',
                            artRightX: ArtRightX.Statistics,
                            menuClass: '',
                            icon: '',
                            items: [
                                {
                                    id: ArtRightX.CashStatistics,
                                    link: '/media-cash-statistics',
                                    title: 'MENU.STATISTICS.CHILD.MEDIA_CASH',
                                    class: '',
                                    artRightX: ArtRightX.CashStatistics,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                },
                                {
                                    id: ArtRightX.CardStatistics,
                                    link: '/media-card-statistics',
                                    title: 'MENU.STATISTICS.CHILD.MEDIA_CARD',
                                    class: '',
                                    artRightX: ArtRightX.CardStatistics,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                },
                                {
                                    id: ArtRightX.CardTurnover,
                                    link: '/media-card-turnover',
                                    title: 'MENU.STATISTICS.CHILD.MEDIA_CARD_TURNOVER',
                                    class: '',
                                    artRightX: ArtRightX.CardTurnover,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                },
                                {
                                    id: ArtRightX.ChipTurnover,
                                    link: '/media-chip-turnover',
                                    title: 'MENU.STATISTICS.CHILD.MEDIA_CHIP_TURNOVER',
                                    class: '',
                                    artRightX: ArtRightX.ChipTurnover,
                                    menuClass: '',
                                    icon: '',
                                    items: [],
                                }
                            ],
                        }
                        ,
                        {
                            id: ArtRightX.OnlineSystemStatistics,
                            link: '/media-online-system-statistics',
                            title: 'MENU.STATISTICS.CHILD.MEDIA_ONLINE_SYSTEMS',
                            class: '',
                            artRightX: ArtRightX.OnlineSystemStatistics,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.PaymentStatistics,
                            link: '/payment-reports',
                            title: 'MENU.STATISTICS.CHILD.PAYMENT_REPORTS',
                            class: '',
                            artRightX: ArtRightX.PaymentStatistics,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.DonationReport,
                            link: '/donations',
                            title: 'MENU.STATISTICS.CHILD.DONATIONS',
                            class: '',
                            artRightX: ArtRightX.DonationReport,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.EndOfShiftReport,
                            link: '/end-of-shift',
                            title: 'MENU.STATISTICS.CHILD.END_OF_SHIFT',
                            class: '',
                            artRightX: ArtRightX.EndOfShiftReport,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                    ],

                },
                {
                    id: ArtRightX.Balancing,
                    link: '',
                    title: 'MENU.BALANCING.MAIN',
                    class: '',
                    artRightX: ArtRightX.Balancing,
                    menuClass: 'balancing',
                    icon: 'pi pi-wallet',
                    //icon      : 'fa fa-bar-chart',
                    items: [
                        {
                            id: ArtRightX.BalanceReport,
                            link: '/balancing-reports',
                            title: 'MENU.BALANCING.CHILD.BALANCING_REPORTS',
                            class: '',
                            artRightX: ArtRightX.BalanceReport,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                    ],

                },
                {
                    id: ArtRightX.Management,
                    link: '',
                    title: 'MENU.MANAGEMENT.MAIN',
                    class: '',
                    artRightX: ArtRightX.Management,
                    menuClass: 'management',
                    icon: 'pi pi-sliders-v',
                    items: [
                        {
                            id: ArtRightX.OperatorManagement,
                            link: '/operators',
                            title: 'MENU.MANAGEMENT.CHILD.OPERATORS',
                            class: '',
                            artRightX: ArtRightX.OperatorManagement,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.PlayerManagement,
                            link: '/player-information',
                            title: 'MENU.MANAGEMENT.CHILD.PLAYER_INFORMATION',
                            class: '',
                            artRightX: ArtRightX.PlayerManagement,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        {
                            id: ArtRightX.CustomerManagement,
                            link: '/payment-customer',
                            title: 'MENU.MANAGEMENT.CHILD.PAYMENT_CUSTOMER',
                            class: '',
                            artRightX: ArtRightX.CustomerManagement,
                            menuClass: '',
                            icon: '',
                            items: [],
                        },
                        /*
                        {
                            id        : ArtRightX.Jackpot Forms,
                            link      : '/jackpot-payouts',
                            title     : 'MENU.MANAGEMENT.CHILD.JACKPOT_PAYOUTS',
                            class     : '',
                            artRightX : ArtRightX.JackpotFormsManagement,
                            menuClass : '',
                            icon      : '',
                            items     : [],
                        },*/
                    ],

                },
            ]
            this.routerLinks = new Map();
            this.setRouterLink(mainMenu, false);
            this.artWebClientMainMenu = mainMenu;
            this.buildMenuSubmenusEntries(false);
            return mainMenu;
        }

    }

    mainMenuGroupWebClient() {

        if (this.artGroupWebClientMainMenu.length > 0) {
            return this.artGroupWebClientMainMenu;
        }
        else {
            let groupMainMenu: MenuGroup[];
            groupMainMenu = [
                {
                    title: 'MENU.STATISTICS.MAIN',
                    icon: 'pi-chart-bar',
                    link: '',
                    class: '',
                    menuClass: '',
                    items: [
                        {
                            id: UserRight.ArtOverview,
                            title: 'MENU.STATISTICS.CHILD.ART_OVERVIEW',
                            icon: '',
                            userRight: UserRight.ArtOverview,
                            link: '/art-group-server-overview',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.MaintenanceOverview,
                            title: 'MENU.STATISTICS.CHILD.MAINTENANCE_OVERVIEW',
                            icon: '',
                            userRight: UserRight.MaintenanceOverview,
                            link: '/maintenance-overview',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.DashboardView,
                            title: 'MENU.MONITORING.CHILD.DASHBOARD',
                            icon: '',
                            userRight: UserRight.DashboardView,
                            link: '/dashboard',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.QuarterlyDashboardView,
                            title: 'MENU.STATISTICS.CHILD.QUARTERLY_DASHBOARD',
                            icon: '',
                            userRight: UserRight.QuarterlyDashboardView,
                            link: '/quarterly-dashboard',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        /*{
                            id        : UserRight.FillLevelView,
                            title     : 'TERMINAL.BASE.VIEW_SWITCH.FILL_LEVEL',
                            icon      : '',
                            userRight : UserRight.FillLevelView,
                            link      : '/terminal-fill-level',
                            class     : '',
                            menuClass : '',
                            items     : []
                        },*/
                        {
                            id: UserRight.MapView,
                            title: 'MENU.STATISTICS.CHILD.MAP_VIEW',
                            icon: '',
                            userRight: UserRight.MapView,
                            link: '/map-view',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.StockOverview,
                            title: 'MENU.STATISTICS.CHILD.STOCK_OVERVIEW',
                            icon: '',
                            userRight: UserRight.StockOverview,
                            link: '/stock-overview',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.ComponentOverview,
                            title: 'MENU.STATISTICS.CHILD.COMPONENT_OVERVIEW',
                            icon: '',
                            userRight: UserRight.ComponentOverview,
                            link: '/component-overview-group',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                    ],
                },
                {
                    title: 'MENU.MANAGEMENT.MAIN',
                    icon: 'pi pi-sliders-v',
                    link: '',
                    class: '',
                    menuClass: 'monitoring',
                    items: [
                        {
                            id: UserRight.UsersView,
                            title: 'MENU.MANAGEMENT.CHILD.USER_MANAGEMENT',
                            icon: '',
                            userRight: UserRight.UsersView,
                            link: '/user-management',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.ManufacturingOrders,
                            title: 'MENU.MANAGEMENT.CHILD.MANUFACTURING_ORDERS',
                            icon: '',
                            userRight: UserRight.ManufacturingOrders,
                            link: '/manufacturing-orders',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.TerminalLifecycleView,
                            title: 'MENU.MANAGEMENT.CHILD.TERMINAL_LIFECYCLE',
                            icon: '',
                            userRight: UserRight.TerminalLifecycleView,
                            link: '/terminal-lifecycle',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.ManufacturingOrderOverview,
                            title: 'MENU.MANAGEMENT.CHILD.MANUFACTURING_ORDER_OVERVIEW',
                            icon: '',
                            userRight: UserRight.ManufacturingOrderOverview,
                            link: '/manufacturing-order-overview',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.ComputerRegistrationView,
                            title: 'MENU.MANAGEMENT.CHILD.COMPUTER_SERIAL',
                            icon: '',
                            userRight: UserRight.ComputerRegistrationView,
                            link: '/computer-serial-registration',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.LicenseKeyUsageOverview,
                            title: 'MENU.MANAGEMENT.CHILD.LICENSE_KEY_USAGE_OVERVIEW',
                            icon: '',
                            userRight: UserRight.LicenseKeyUsageOverview,
                            link: '/license-key-usage-overview',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                        {
                            id: UserRight.KioskRegistration,
                            title: 'MENU.MANAGEMENT.CHILD.KIOSK_REGISTRATION',
                            icon: '',
                            userRight: UserRight.KioskRegistration,
                            link: '/kiosk-registration',
                            class: '',
                            menuClass: '',
                            items: []
                        },
                    ],
                },
                {
                    title: 'MENU.SETTINGS.MAIN',
                    icon: 'pi pi-cog',
                    link: '',
                    class: '',
                    menuClass: 'monitoring',
                    items: [
                        {
                            id: UserRight.ConfigurationView,
                            title: 'MENU.SETTINGS.CHILD.CONFIGURATION_MANAGEMENT',
                            icon: '',
                            userRight: UserRight.ConfigurationView,
                            link: '/configuration-management',
                            class: '',
                            menuClass: '',
                            items: []
                        }
                    ],
                }

            ];
            this.routerLinks = new Map();
            this.setRouterLink(groupMainMenu, true);
            this.artGroupWebClientMainMenu = groupMainMenu;
            this.buildMenuSubmenusEntries(true);
            return groupMainMenu;
        }

    }

    buildMenuSubmenusEntries(groupClient: boolean) {
        let msMenu = new Map<string, any[]>();
        let mainMenu = groupClient ? this.artGroupWebClientMainMenu : this.artWebClientMainMenu;

        for (let currentMainMenuEntry of mainMenu) {
            msMenu.set(currentMainMenuEntry.title, this.childEntries(currentMainMenuEntry));

            for (let currentSubEntry of currentMainMenuEntry.items) {
                msMenu.set(currentSubEntry.title, this.childEntries(currentSubEntry));
            }

        }

        this.mainMenuSubElements = msMenu;
    }

    childEntries(currentMainMenuEntry) {
        let subEntries = [];

        if (currentMainMenuEntry.link != '') {
            subEntries.push(currentMainMenuEntry.link);
        }

        for (let currentEntry of currentMainMenuEntry.items) {

            if (currentEntry.link != '') {
                subEntries.push(currentEntry.link);
            }
            else {

                for (let item of currentEntry.items) {

                    if (item.link != '') {
                        subEntries.push(item.link);
                    }
                    else {

                        for (let subItem of item.items.length) {

                            if (subItem.link != '') {
                                subEntries.push(subItem.link);
                            }

                        }

                    }

                }

            }

        }

        return subEntries;
    }

    helpMenu() {
    }

    userMenu() {
        let userMenu: any = [];

        userMenu.push(
            {
                section: 'Profile',
                link: '/user/profile',
                title: 'USER.MENU.USER_PROFILE',
                class: '',
                artRightX: -1,
                items: [],
            },
            {
                section: 'Logout',
                link: '/logout',
                title: 'USER.MENU.LOGOUT',
                class: '',
                artRightX: -1,
                items: [],
            },
        );

        return userMenu;
    }

}
