import { ArtRightX } from '../app/auth/_enums/artRightX';

export const environment = {
    production: true,
    //Urls for RestApis
    baseUrl: document.location.protocol + '//' + document.location.hostname + ':' + document.location.port,
    apiPath: '/FrontendServices.asmx/',
    //Loglevel for Debug
    logState: false, //true, false
    logLevel: 0, //trace = 0, debug = 1, info = 2, warn = 3, error = 4, fatal = 5
    //EntryPoint
    artEntryPoint: ArtRightX.Dashboard,
    defaultEntryPoint: 'index',

    //Group Variables
    ARTGroupServerBaseIP: document.location.protocol + '//' + document.location.hostname + ':' + '44320' + '/api/',
    ARTGroupEntryPoint: '/art-group-server-overview',
};
