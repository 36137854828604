export enum MachineStatus {
    None = -1,
    Offline = 0,
    Online = 1,
    SoftwareRestart = 2,
    TerminalRestart = 3,
    Shutdown = 4,
    Block = 5,
    Unblock = 6,
    ManufacturerBlock = 7,
    ManufacturerUnblock = 8,
}
