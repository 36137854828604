export enum ModuleLicense {
    Advertising = 1,
    GrccPaging = 2,
    PropertyManagement = 3,
    Replication = 4,
    CardBurning = 5,
    ShopManagement = 6,
    CurrencyCounting = 7,
    TerminalLicensing = 8,
    MultiCurrency = 9,
    RemoteAccess = 10,
    DebitAccounts = 11,
    PreventiveMaintenance = 12,
    FaceRecognition = 13,
    //ArtClientLicensing    = 14,
    FactorySupport = 15,
    ArtConnect = 16,
}
