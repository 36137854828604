<form class="m-form terminal-edit" (ngSubmit)="onSubmit(f.value, f.valid)" #f="ngForm" novalidate *ngIf="terminalSingleDetailsEdit">
    <div class="form-group">
        <!--<label for="terminalType">{{'MANAGEMENT.OPERATORS.EXPANDED.OPERATOR_SETTINGS.NAME' | translate}}:</label>-->
        <label class="title">{{'MONITORING.MAP_OVERVIEW.BUTTONS.TERMINAL_TYPE' | translate}}:</label><br>
        <label class="value"> {{terminalEditData.TerminalType | terminalTypeByID | terminalTypeNaming}}</label>
    </div>
    <div class="form-group">
        <label class="title">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.TERMINAL_ID' | translate}}:</label><br>
        <label class="value"> {{terminalEditData.TerminalID}}</label>
    </div>
    <div class="form-group">
        <label class="title">{{'STATISTICS.ART_OVERVIEW.MODALS.TERMINAL_CONFIGURATION.PROPERTY_NUMBER' | translate}}:</label><br>
        <label class="value"> {{terminalEditData.PropertyNumber}}</label>
    </div>
    <div class="form-group">
        <label class="title">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.LOCATION' | translate}}:</label><br>
        <label  *ngIf="!editMode" class="value location-non-editable"> {{terminalEditData.LocationName}}</label>
        <input  *ngIf="editMode" type="text" pInputText  class="form-control location-editable" name="location" required [(ngModel)]="terminalEditCopyLocationName" id="location" value="{{terminalEditCopyLocationName}}">
    </div>
    <div class="form-group">
        <label class="title" for="terminalName">{{'MONITORING.DASHBOARD_DOWNTIME.TABLE.TERMINAL_NAME' | translate}}:</label><br>
        <label *ngIf="!editMode" class="value terminal-name-non-editable"> {{terminalEditData.TerminalName}}</label>
        <input  *ngIf="editMode" type="text" pInputText  class="form-control terminal-name-editable" name="terminalName" required [(ngModel)]="terminalEditCopyTerminalName" id="terminalName" value="{{terminalEditCopyTerminalName}}">
    </div>
    <div class="form-group">
        <label class="title" for="macAddress">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.MAC_ADDRESS' | translate}}:</label><br>
        <label *ngIf="!editMode" class="value mac-address-non-editable"> {{terminalEditData.MacAddress}}</label>
        <input  *ngIf="editMode" type="text" pInputText  class="form-control mac-address-editable" name="macAddress" required [(ngModel)]="terminalEditData.MacAddress" id="macAddress" value="{{terminalEditData.MacAddress}}" placeholder="XX-XX-XX-XX-XX-XX">
    </div>
    <div class="form-group">
        <label class="title" for="emergencyPin">{{'TERMINAL.TERMINAL_IDENTIFIER.EMERGENCY_PIN' | translate}}:</label><br>
        <label *ngIf="!editMode" class="value emergency-pin-non-editable"> **** </label>
        <input  *ngIf="editMode" oninput="this.value=this.value.replace(/[^0-9]/g,'');" type="password" pPassword
               class="form-control emergency-pin-editable emergency-pin-input" name="emergencyPin" required id="emergencyPin"
               [(ngModel)]="terminalEditCopySafeDoorEmergencyPin" value="{{terminalEditCopySafeDoorEmergencyPin}}">
        <label class="value emergency-pin-editable generated-pin">  </label>
        <button  *ngIf="editMode" pButton type="button" class="send-button emergency-pin-editable" (click)="generateSafeDoorPin()">{{'BASE.BUTTONS.GENERATE' | translate}}</button>
    </div>
    <div class="form-group">
        <label class="title">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.IP_ADDRESS' | translate}}:</label><br>
        <label class="value"> {{terminalIdentifier.IPAddress}}</label>
    </div>

    <div class="form-group">
        <label class="title">{{'TERMINAL.SINGLE_DETAILS.TABS.STATUS' | translate}}:</label><br>
        <label class="value"> {{terminalIdentifier.Status | terminalStatus | translate}}</label>
    </div>
    <div class="form-group">
        <label class="title">{{'TERMINAL.TERMINAL_ADDRESS.SPECIFIC_TIME_ZONE.TIME_ZONE' |translate}}:</label><br>
        <label class="value"> {{terminalIdentifier.TimeZone}}</label>
    </div>
    <div class="form-group">
        <label class="title">{{'TERMINAL.TERMINAL_IDENTIFIER.TABLE.SYSTEM_CURRENCY' | translate}}:</label><br>
        <label class="value"> {{terminalIdentifier.SystemCurrency}}</label>
    </div>

    <div class="button-line action">
        <button type="button" class="btn btn-success btn-abort" value="buttonabort" (click)="abortTerminalEdit()">{{'BASE.BUTTONS.CANCEL' | translate}}</button>
        <button type="submit" class="btn btn-success btn-save" value="buttonsave">{{'BASE.BUTTONS.SAVE' | translate}}</button>
    </div>
    <div class="button-line edit">
                <button type="button" class="btn btn-success btn-edit" value="buttonedit" (click)="editTerminal()">{{'BASE.BUTTONS.EDIT' | translate}}</button>
    </div>

</form>
