export enum MediumType {
    Rejected = -1,
    None = 0,
    Coin = 1,
    Bill = 2,
    Ticket = 3,
    MagneticCard = 4,
    SmartCard = 5,
    RfidCard = 6,
    Chip = 7,
    Atm = 8,
    AtmFee1 = 9,
    AtmFee2 = 10,
    AtmFee3 = 11,
    Deposit = 12,
    Rounding = 13,
    Sam = 14,
    Coupon = 15,
    Cec = 16,
    CecCasinoFee = 17,
    CecProviderFee = 18,
    Donation = 19,
    LoanValue = 20,
    LoanRepayValue = 21,
    CreditCard = 22,
    ClearedCredit = 23,
    Marker = 24,
    Tax = 25,
    Pos = 26,
    PosMerchantFee = 27, // special
    PosProviderFee = 28, // special
    EWalletValue = 29, // special (eWallet - CountR Payment Modules)
    BillPay = 30,
    BillPayFee = 31,
    Check = 32,
    CheckFee = 33,
    GiftCard = 34,
    GiftCardFee = 35,
    MobileTopUp = 36,
    MobileTopUpFee = 37,
    WireTransfer = 38,
    WireTransferFee = 39,
    PrepaidCard = 40,
    Fee = 41,
    CryptoCurrency = 42,
    CashAdvanceReceipt = 43,
    JackpotValue = 44,
    IouReceipt = 45
}
