import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalCacheVariable } from '../_globals/globals';
import { catchError, map } from 'rxjs/operators';
import { ApiDataConverter } from "../_helpers/api-data-converter";
import { ApiCallType } from "../_enums/apiCallType";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class StatisticService {

    constructor(
        private http: HttpClient,
        public cache: GlobalCacheVariable) {
    }

    private static extractBridge(res, apiType) {
        let response = ApiDataConverter.extractData(res, apiType);
        return response || {};
    }

    public handleError(errorMessage: any, apiType) {
        ApiDataConverter.errorHandling(errorMessage, apiType);
        return observableThrowError(errorMessage);
    }

    getUtilizationReportStatistics(terminalID, start, end) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalIDs: terminalIDsCache,
                start: start,
                end: end
            };
            return this.http.post(this.cache.ARTApi + 'GetUtilizationReportStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetUtilizationReportStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetUtilizationReportStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllMediaCardTurnoverStatistics(casinoDay) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                casinoDay: casinoDay,
            };
            return this.http.post(this.cache.ARTApi + 'GetMediaCardTurnoverStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetMediaCardTurnoverStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetMediaCardTurnoverStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }

    }

    getAllMediaChipTurnoverStatistics(dateFrom, dateTo, terminalID) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = [-1];
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateFrom,
                end: dateTo,
                terminalIDs: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetMediaChipTurnoverStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetMediaChipTurnoverStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetMediaChipTurnoverStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllMediaChipTurnoverStatisticsDetails(date, terminalID) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                countDate: date,
                terminalID: terminalID,
            };
            return this.http.post(this.cache.ARTApi + 'GetChipTurnoverDetails', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetChipTurnoverDetails);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetChipTurnoverDetails);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllMediaOnlineSystemStatistics(terminalID, dateStart, dateEnd) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = [0];
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                terminalIDs: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetMediaOnlineSystemStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetMediaOnlineSystemStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetMediaOnlineSystemStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllMediaOnlineSystemDetailsStatistics(terminalID, dateStart, dateEnd, CosName) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = 0;
            } else {
                terminalIDsCache = terminalID;
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                cosName: CosName,
                terminalID: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetMediaOnlineSystemDetailsStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetMediaOnlineSystemDetailsStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetMediaOnlineSystemDetailsStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllMediaCardStatistics(terminalID, dateStart, dateEnd) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                //terminalIDsCache = this.getAllTerminalId();
                terminalIDsCache = [-1];
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                terminalIDs: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetMediaCardStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetMediaCardStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetMediaCardStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllMediaTicketStatisticsOperator(terminalID, dateStart, dateEnd, operators) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                terminalIDs: terminalIDsCache,
                operator: operators,
            };
            return this.http.post(this.cache.ARTApi + 'GetMediaTicketStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetMediaTicketStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetMediaTicketStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllMediaCashStatistics(terminalID, dateStart, dateEnd, withOpTransactions) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = [-1];
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                terminalIDs: terminalIDsCache,
                withOpTransactions: withOpTransactions,
            };
            return this.http.post(this.cache.ARTApi + 'GetMediaCashStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetMediaCashStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetMediaCashStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllTransactionCounts(terminalID, dateStart, dateEnd) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                terminalIDs: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetTransactionCountStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTransactionCountStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetTransactionCountStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllTransactionStatistics(terminalID, dateStart, dateEnd) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
                //terminalIDsCache = [9067];
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                terminalIDs: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetTransactionReportStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTransactionReportStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetTransactionReportStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getTerminalAvailability(terminalID, dateStart, dateEnd, period, separateOperatorSeries, seperateGraphForTerminals) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
                //terminalIDsCache = [9067];
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                period: period,
                separateOperatorSeries: separateOperatorSeries,
                showGraphsForIndividualTerminals: seperateGraphForTerminals,
                terminalIDs: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetTerminalAvailability', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTerminalAvailability);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetTerminalAvailability);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getTerminalState(terminalID, dateStart, dateEnd) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
                //terminalIDsCache = [9067];
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                terminalIDs: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetTerminalState', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTerminalState);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetTerminalState);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getDonationReportStatistics(terminalID, dateStart, dateEnd) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                terminalIDs: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetDonationReportStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetDonationReportStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetDonationReportStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getDonationAccounts() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetDonationAccounts', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetDonationAccounts);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetDonationAccounts);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getEndOfShiftReportStatistics(terminalID, dateStart, dateEnd) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            } else {
                terminalIDsCache = [terminalID];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                terminalIDs: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetEndOfShiftReportStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetEndOfShiftReportStatistics);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetEndOfShiftReportStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getOpeningInventoryEntries(dateFrom, terminalID) {
        try {
            let body = {
                countDate: dateFrom,
                terminalID: terminalID,
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetOpeningInventoryEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetOpeningInventoryEntries);
                    }),
                    map(data => {
                        return StatisticService.extractBridge(data, ApiCallType.GetOpeningInventoryEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    //Utils
    getAllTerminalId() {
        let terminalArray = this.cache.Terminals;
        let terminalArrayRequest = [];
        for (let i = 0; i < terminalArray.length; i++) {
            terminalArrayRequest.push(terminalArray[i].TerminalID);
        }
        return terminalArrayRequest;
    }
}

