import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services';
import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';
import { Location } from "@angular/common";
import { Helpers } from "../../../../../../helpers";
import { TranslatePipe } from "@ngx-translate/core";
import { MachineStatus } from "../../../../../../auth/_enums/machineStatus";
import { TerminalType } from "../../../../../../auth/_enums/terminalType";
import { MessageService } from 'primeng/api';
import { ArtRight } from "../../../../../../auth/_enums/artRight";

@Component({
    selector: "terminal-identifier-list",
    templateUrl: "./terminal-identifier-list.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [TranslatePipe]
})

export class TerminalIdentifierListComponent implements OnInit, AfterViewInit {

    displayTerminalIdentifierList: boolean = false;
    @Input() filterSection: boolean;
    columns: any[];
    currentTerminalID: number = 0;
    currentTerminalName: string = '';
    contextMenuMachine: any;
    currentContextMenuID: number = 0;
    terminalSingleDetailView: boolean = false;
    includeConfigVersion: boolean = false;
    knownRestartTerminal: any = {
        terminalID: '',
        newState: -1,
        terminalName: ''
    };
    //FeatureOverview
    displayFeatureOverview: boolean = false;
    featureOverviewView: boolean = false;
    exportColumns: any[];

    constructor(private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        private location: Location,
        public globalCacheVariable: GlobalCacheVariable,
        private zone: NgZone,
        private translate: TranslatePipe,
        private messageService: MessageService,
    ) {
        this.columns = [
            {
                field: 'TerminalID',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.TERMINAL_ID'),
                class: 'tb-right'
            },
            {
                field: 'IPAddress',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.IP_ADDRESS'), class: 'tb-left'
            },
            {
                field: 'SerialNumber',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.SERIAL_NUMBER'), class: 'tb-left'
            },
            {
                field: 'MacAddress',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.MAC_ADDRESS'),
                class: 'tb-left'
            },
            {
                field: 'CpuArchitectureHardware',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.CPU_ARCH_HARDWARE'),
                class: 'tb-left'
            },
            {
                field: 'CpuArchitectureSoftware',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.CPU_ARCH_SOFTWARE'),
                class: 'tb-left'
            },
            {
                field: 'TerminalName',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.NAME'),
                class: 'tb-left'
            },
            {
                field: 'LocationName',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.LOCATION'),
                class: 'tb-left'
            },
            {
                field: 'TimeZone',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.TIME_ZONE'),
                class: 'tb-left'
            },
            {
                field: 'SystemCurrency',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.SYSTEM_CURRENCY'),
                class: 'tb-left'
            },
            {
                field: 'DistributorID',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.DISTRIBUTOR_NUMBER'),
                class: 'tb-right'
            },
            {
                field: 'CustomerID',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.CUSTOMER_NUMBER'),
                class: 'tb-right'
            },
            {
                field: 'PropertyNumber',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.PROPERTY_NUMBER'),
                class: 'tb-right'
            },
            {
                field: 'LocationID',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.LOCATION_ID'),
                class: 'tb-right'
            },
            {
                field: 'AtmTerminalID',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.BANK_TERMINAL_ID'),
                class: 'tb-right'
            },
            {
                field: 'OperatingSystem',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.OPERATING_SYSTEM'),
                class: 'tb-left'
            },
            {
                field: 'RuntimeEnvironment',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.RUNTIME_ENVIRONMENT'),
                class: 'tb-left'
            },
            {
                field: 'SoftwareVersion',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.SOFTWARE_VERSION'),
                class: 'tb-left'
            },
            {
                field: 'ConfigVersion',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.CONFIG_VERSION'),
                class: 'tb-left'
            },
            {
                field: 'OnlineSystem',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.ONLINE_SYSTEM'),
                class: 'tb-left'
            },
            {
                field: 'VpnState',
                header: this.translate.transform('TERMINAL.TERMINAL_IDENTIFIER.TABLE.VPN_STATE'),
                class: 'tb-left'
            }
        ];
    }

    ngOnInit(): void {
        let showConfigVersion = this.globalCacheVariable.artConfigurationEntries.get("ArtWebClientDashboard/ShowConfigVersionInSoftwareStats")
        if (showConfigVersion !== undefined) {
            if (showConfigVersion.Value === "" || showConfigVersion.Value === null || showConfigVersion.Value === undefined) {
                this.includeConfigVersion = false;
            } else {
                this.includeConfigVersion = showConfigVersion.Value === "True";
            }
        }
    }

    ngAfterViewInit(): void {
        this.getTerminalIdentifierList();
    }

    terminalRefresh() {
        this.getTerminalIdentifierList();
    }

    getTerminalIdentifierList() {
        Helpers.setLoading(true, this.translate.transform('BASE.LOADING.TITLE'));
        this.terminalService.getTerminalIdentifierList()
            .subscribe(data => {
                this.zone.run(() => { // <== added
                    this.globalCacheVariable.TerminalsIdentifier = data;
                    this.terminalService.filterTerminalIdentifierVisibility(this.globalCacheVariable.TerminalsIdentifier, "multiple");
                    this.displayTerminalIdentifierList = true;
                    Helpers.setLoading(false);
                });
            });
    }

    openTerminalSingleDetails(terminalID) {
        this.currentTerminalID = terminalID;
        for (let i = 0; i < this.globalCacheVariable.Terminals.length; i++) {
            if (this.globalCacheVariable.Terminals[i].TerminalID === terminalID) {
                this.currentTerminalName = this.globalCacheVariable.Terminals[i].TerminalName
            }
        }
        this.globalCacheVariable.modal.terminalSingleDetail.displayTerminalSingleDetail = true;
        this.terminalSingleDetailView = true;
    }

    closeTerminalSingleDetails() {
        this.globalCacheVariable.modal.terminalSingleDetail.displayTerminalSingleDetail = false;
        this.terminalSingleDetailView = false;
    }

    changeTerminalStateSoftRestart(newState, terminalID) {
        for (let i = 0; i < this.globalCacheVariable.Terminals.length; i++) {
            if (this.globalCacheVariable.Terminals[i].TerminalID === terminalID) {
                this.currentTerminalName = this.globalCacheVariable.Terminals[i].TerminalName
            }
        }
        this.knownRestartTerminal = { newState: newState, terminalID: terminalID, terminalName: this.currentTerminalName };
        this.messageService.clear();
        this.messageService.add({
            key: 's',
            sticky: true,
            severity: 'warn',
            summary: this.translate.transform('TERMINAL.CONFIRMATION_TOASTS.SOFTWARE_RESTART.SUMMARY_FIRST') + ' ' + this.currentTerminalName + ' (' + terminalID + ')?',
            detail: this.translate.transform('TERMINAL.CONFIRMATION_TOASTS.SOFTWARE_RESTART.DETAIL')
        });
    }

    onConfirmSoftwareRestart(state) {
        Helpers.setLoading(true, this.translate.transform('BASE.LOADING.TITLE'));
        this.terminalService.setTerminalStatus(this.knownRestartTerminal.terminalID, this.knownRestartTerminal.newState, state)
            .subscribe(data => {
                this.zone.run(() => { // <== added
                    Helpers.setLoading(false);
                    this.messageService.clear('s');
                });
            });
    }

    onRejectSoftwareRestart() {
        this.knownRestartTerminal = { newState: -1, terminalID: '', terminalName: '' };
        this.messageService.clear('s');
    }

    changeTerminalStateHardRestart(newState, terminalID) {
        this.knownRestartTerminal = { newState: newState, terminalID: terminalID };
        this.messageService.clear();
        this.messageService.add({
            key: 'h',
            sticky: true,
            severity: 'warn',
            summary: this.translate.transform('TERMINAL.CONFIRMATION_TOASTS.HARDWARE_RESTART.SUMMARY_FIRST') + ' ' + this.currentTerminalName + ' (' + terminalID + ')?',
            detail: this.translate.transform('TERMINAL.CONFIRMATION_TOASTS.HARDWARE_RESTART.DETAIL')
        });
    }

    onConfirmHardwareRestart(state) {
        Helpers.setLoading(true, this.translate.transform('BASE.LOADING.TITLE'));
        this.terminalService.setTerminalStatus(this.knownRestartTerminal.terminalID, this.knownRestartTerminal.newState, state)
            .subscribe(data => {
                this.zone.run(() => { // <== added
                    Helpers.setLoading(false);
                    this.messageService.clear('h');
                });
            });
    }

    onRejectHardwareRestart() {
        this.knownRestartTerminal = { newState: -1, terminalID: '', terminalName: '' };
        this.messageService.clear('h');
    }

    changeTerminalStateShutdown(newState, terminalID) {
        this.knownRestartTerminal = { newState: newState, terminalID: terminalID };
        this.messageService.clear();
        this.messageService.add({
            key: 'sh',
            sticky: true,
            severity: 'warn',
            summary: this.translate.transform('TERMINAL.CONFIRMATION_TOASTS.SHUTDOWN.SUMMARY_FIRST') + ' ' + this.currentTerminalName + ' (' + terminalID + ')?',
            detail: this.translate.transform('TERMINAL.CONFIRMATION_TOASTS.SHUTDOWN.DETAIL')
        });
    }

    onConfirmShutdown(state) {
        Helpers.setLoading(true, this.translate.transform('BASE.LOADING.TITLE'));
        this.terminalService.setTerminalStatus(this.knownRestartTerminal.terminalID, this.knownRestartTerminal.newState, state)
            .subscribe(data => {
                this.zone.run(() => { // <== added
                    Helpers.setLoading(false);
                    this.messageService.clear('sh');
                });
            });
    }

    onRejectShutdown() {
        this.knownRestartTerminal = { newState: -1, terminalID: '', terminalName: '' };
        this.messageService.clear('sh');
    }

    changeTerminalState(newState, terminalID) {
        this.terminalService.setTerminalStatus(terminalID, newState)
            .subscribe(data => {
                /*
                this.zone.run(() => { // <== added

                    console.log("dataTransactions -> ", dataTransactions);
                    this.lastTransactions = dataTransactions;
                });
                */
            });
    }

    showDialogMaximized(event, dialog) {
        dialog.maximize();
    }

    contextMenuClose(terminalID) {
    }

    showFeatureOverview(terminalID) {
        this.currentTerminalID = terminalID;
        for (let i = 0; i < this.globalCacheVariable.Terminals.length; i++) {
            if (this.globalCacheVariable.Terminals[i].TerminalID === terminalID) {
                this.currentTerminalName = this.globalCacheVariable.Terminals[i].TerminalName
            }
        }
        this.displayFeatureOverview = true;
        this.featureOverviewView = true;
    }

    contextMenuChange(event, terminalID) {
        this.currentContextMenuID = terminalID;

        //TODO translation
        this.contextMenuMachine = [
            {
                label: '',
                icon: 'pi pi-fw pi-times', command: (event) => {
                    this.contextMenuClose(terminalID);
                }
            }
        ];
        for (let t = 0; t < this.globalCacheVariable.Terminals.length; t++) {
            if (this.globalCacheVariable.Terminals[t].TerminalID === terminalID) {
                if (this.globalCacheVariable.Terminals[t].Status !== 0) {
                    if (this.globalCacheVariable.checkARTRight(ArtRight.TerminalOutOfOrder)) {
                        this.contextMenuMachine.push(
                            {
                                label: this.translate.transform('TERMINAL.CONTEXT_MENU.SET_ONLINE'),
                                command: (event) => {
                                    this.changeTerminalState(MachineStatus.Online, terminalID);
                                }

                            },
                            {
                                label: this.translate.transform('TERMINAL.CONTEXT_MENU.SET_OFFLINE'),
                                command: (event) => {
                                    this.changeTerminalState(MachineStatus.Offline, terminalID);
                                }

                            });
                    }
                    if (this.globalCacheVariable.checkARTRight(ArtRight.TerminalRestart)) {
                        this.contextMenuMachine.push(

                            {
                                label: this.translate.transform('TERMINAL.CONTEXT_MENU.SOFTWARE_RESTART'),
                                command: (event) => {
                                    this.changeTerminalStateSoftRestart(MachineStatus.SoftwareRestart, terminalID);
                                }

                            },
                            {
                                label: this.translate.transform('TERMINAL.CONTEXT_MENU.TERMINAL_RESTART'),
                                command: (event) => {
                                    this.changeTerminalStateHardRestart(MachineStatus.TerminalRestart, terminalID);
                                }

                            },
                            {
                                label: this.translate.transform('TERMINAL.CONTEXT_MENU.SHUT_DOWN'),
                                command: (event) => {
                                    //TODO
                                    this.changeTerminalStateShutdown(MachineStatus.Shutdown, terminalID);

                                }

                            });
                    }
                }
                break;
            }
        }

        for (let t = 0; t < this.globalCacheVariable.Terminals.length; t++) {
            if (this.globalCacheVariable.Terminals[t].TerminalID === terminalID) {
                if (this.globalCacheVariable.Terminals[t].TerminalType !== TerminalType.AdPlayer) {
                    this.contextMenuMachine.push({
                        label: this.translate.transform('TERMINAL.CONTEXT_MENU.SHOW_TRANSACTIONS'),
                        //icon: 'pi pi-fw pi-chevron-left',
                        routerLink: ['/transaction-log/' + terminalID],
                        //queryParams: {'terminal': terminalID}
                    });
                }
                break;
            }
        }

        this.contextMenuMachine.push(
            {
                label: this.translate.transform('TERMINAL.CONTEXT_MENU.SHOW_COMPONENT_OVERVIEW'),
                routerLink: ['/component-overview/' + terminalID],
            },
            {
                label: this.translate.transform('TERMINAL.CONTEXT_MENU.SHOW_FEATURE_OVERVIEW'),
                command: (event) => {
                    this.showFeatureOverview(terminalID);
                }
            },
            {
                label: this.translate.transform('TERMINAL.CONTEXT_MENU.SHOW_TERMINAL_DASHBOARD'),
                command: (event) => {
                    this.openTerminalSingleDetails(terminalID);
                }
            },
        );
    }
}
