export enum ErrorType {
    OpenWithoutLogin = 1,
    LoginFailedWrongPassword = 2,
    CoinAcceptorNotInEndPosition = 3,
    CoinDispenserNotInEndPosition = 4,
    AcceptorRemovedWhileDoorClosed = 5,
    DispenserRemovedWhileDoorClosed = 6,
    HopperUnderRunsMinimalValue = 7,
    DispenserUnderRunsMinimalValue = 8,
    AcceptorExceedsMaximumCapacity = 9,
    TimeSyncTooOften = 10,
    AlreadyAssignedTerminalId = 11,
    MaximumNumberOfTerminalsReached = 12,
    CommunicationProblem = 13,
    DoorOpened = 14,
    ServerTimeAndTerminalTimeDiffer = 15,
    InvalidPropertyNumber = 16,
    PinAlert = 17,
    TransactionApprovalRequired = 18,
    TransactionApproved = 19,
    RemoteAccessConfirmation = 20,
}
