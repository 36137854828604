import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ScriptLoaderService } from '../../../../../../_services/script-loader.service';
import { TerminalService } from '../../../../../../auth/_services/terminal.service';
import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';
import { LoggingService } from '../../../../../../auth/_services/log.service';
import { MaintenanceLogListItem } from '../../../../../../auth/_models/maintenanceloglistitem';
import * as moment from "moment";
import { TranslatePipe } from "@ngx-translate/core";
import { DatePipe, UpperCasePipe } from "@angular/common";
import { EventSourcePipe } from "../../../../../../auth/_pipes/eventSource.pipe";
import { TerminalNamePipe } from "../../../../../../auth/_pipes/terminalName.pipe";
import { ComponentTypePipe } from "../../../../../../auth/_pipes/componentType.pipe";
import { RewriteEnumEntriesForTranslationPipe } from "../../../../../../auth/_pipes/rewriteEnumEntriesForTranslation.pipe";
import { GeneralRewritePipe } from "../../../../../../auth/_pipes/generalRewrite.pipe";
import { MaintenanceType } from "../../../../../../auth/_enums/maintenanceType";
import { DeviceType } from "../../../../../../auth/_enums/deviceType";

@Component({
    selector: "terminal-single-details-maintenance-log",
    templateUrl: "./terminal-single-details-maintenance-log.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [UpperCasePipe, RewriteEnumEntriesForTranslationPipe, DatePipe, TranslatePipe, EventSourcePipe, TerminalNamePipe, ComponentTypePipe, GeneralRewritePipe]
})

export class TerminalSingleDetailsMaintenanceLogComponent implements OnInit, AfterViewInit, OnChanges {

    @Input('terminalID') terminalID: number;

    maintenanceLog: any;
    paginatorPage: number = 1;
    paginatorRowsPerPage: number = 20;
    maintenanceLogView: boolean = false;
    columns: any[];

    constructor(
        private _script: ScriptLoaderService,
        private terminalService: TerminalService,
        private loggingService: LoggingService,
        public globalCacheVariable: GlobalCacheVariable,
        private datePipe: DatePipe,
        private translate: TranslatePipe,
        private eventSource: EventSourcePipe,
        private terminalName: TerminalNamePipe,
        private componentTypePipe: ComponentTypePipe,
        private upperCase: UpperCasePipe,
        private rewriteEnumEntriesForTranslation: RewriteEnumEntriesForTranslationPipe,
        private generalRewrite: GeneralRewritePipe,
        private zone: NgZone,
    ) {
        this.maintenanceLog = new Array<MaintenanceLogListItem>();
        this.columns = [
            { field: 'TerminalID', header: this.translate.transform('BASE.TERMINAL') },
            { field: 'MaintenanceDate', header: this.translate.transform('BASE.DATE') },
            { field: 'OperatorID', header: this.translate.transform('BASE.OPERATOR') },
            { field: 'MaintenanceType', header: this.translate.transform('LOGS.MAINTENANCE.TABLE.ACTIVITY') },
            { field: 'ComponentType', header: this.translate.transform('LOGS.MAINTENANCE.TABLE.COMPONENT') },
            { field: 'ComponentRank', header: this.translate.transform('LOGS.MAINTENANCE.TABLE.RANK') },
            { field: 'DeviceType', header: this.translate.transform('BASE.NAME') },
            { field: 'MaintenanceDetails', header: this.translate.transform('LOGS.MAINTENANCE.TABLE.COMMENT') }
        ];
    }

    getMaintenanceLogData() {
        let startDate = moment().subtract(1000, "days");
        let endDate = moment();
        this.loggingService.getRecentMaintenanceLogs(this.terminalID, 10)
            .subscribe(data => {
                this.zone.run(() => {
                    for (let i = 0; i < data.length; i++) {
                        let maintenance = data[i];
                        maintenance.TerminalID = this.terminalName.transform(maintenance.TerminalID);
                        maintenance.MaintenanceDate = this.datePipe.transform(maintenance.MaintenanceDate, this.globalCacheVariable.globalFormatSettings.dateFormatLong);
                        maintenance.EventSource = this.eventSource.transform(maintenance.EventSource);
                        if (maintenance.MaintenanceDetails !== 'undefined') {
                            maintenance.MaintenanceDetailsShortener = this.generalRewrite.truncate(maintenance.MaintenanceDetails, 25);
                        }
                        if (maintenance.MaintenanceType !== 'undefined') {
                            maintenance.MaintenanceType = this.translate.transform(
                                this.upperCase.transform(
                                    this.rewriteEnumEntriesForTranslation.transform(
                                        'ENUMS.MAINTENANCE_TYPE.' + this.generalRewrite.enumRewrite(
                                            MaintenanceType, maintenance.MaintenanceType), 'ENUMS.MAINTENANCE_TYPE.')
                                )
                            );
                        }

                        if (maintenance.ComponentType !== 'undefined') {
                            maintenance.ComponentType = this.translate.transform(
                                this.upperCase.transform(
                                    this.rewriteEnumEntriesForTranslation.transform(
                                        'ENUMS.COMPONENT_TYPE.' + this.componentTypePipe.transform(
                                            maintenance.ComponentType), 'ENUMS.COMPONENT_TYPE.')
                                )
                            );
                        }

                        if (maintenance.DeviceType !== 'undefined') {
                            maintenance.DeviceType = this.generalRewrite.enumTranslation(DeviceType, maintenance.DeviceType, 'ENUMS.DEVICE_TYPE.');
                        }
                    }
                    this.maintenanceLog = data;
                    this.maintenanceLogView = true;

                });
            });
    }

    ngOnInit(): void {
        //this.getMaintenanceLogData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.terminalID.currentValue !== changes.terminalID.previousValue) {
            this.getMaintenanceLogData();
        }
    }

    ngAfterViewInit(): void {
        // this.getTerminalIdentifierList();
    }
}
