<!--<div class="terminal-filter">

    <form #f="ngForm" novalidate>
        <div>
            {{'TERMINAL.BASE.FILTER.TERMINAL_TYPE.MAIN' | translate}}:
            <select name="selectedTerminalType" id="selectedTerminalType" [(ngModel)]="globalCacheVariable.filter.terminalIdentifierFilter.terminalType" (change)="filterTerminals(f.value, f.valid)">
                <option value="all" selected="selected">{{'BASE.FILTER.ALL' | translate}}</option>
                <option *ngFor="let terminalType of terminalType" value="{{terminalType}}">
                    {{terminalType | terminalTypeNaming}}
                </option>
            </select>
        </div>
        <div>
            {{'TERMINAL.BASE.FILTER.PROPERTY.MAIN' | translate}}:
            <select name="selectedProperty" id="selectedProperty" [(ngModel)]="globalCacheVariable.filter.terminalIdentifierFilter.property" (change)="filterTerminals(f.value, f.valid)">
                <option value="all" selected="selected">{{'BASE.FILTER.ALL' | translate}}</option>
                <option *ngFor="let property of globalCacheVariable.Properties" value="{{property.PropertyID}}">
                    {{property.PropertyName}}
                </option>
            </select>
        </div>
        <div>
            {{'TERMINAL.BASE.FILTER.TERMINAL_STATE.MAIN' | translate}}:
            <select name="selectedConnectionState" id="selectedConnectionState" [(ngModel)]="globalCacheVariable.filter.terminalIdentifierFilter.terminalState" (change)="filterTerminals(f.value, f.valid)">
                <option value="all" selected="selected">{{'BASE.FILTER.ALL' | translate}}</option>
                <option value="Connected" selected="selected">{{'TERMINAL.BASE.FILTER.TERMINAL_STATE.CONNECTED' | translate}}</option>
                <option value="notConnected" selected="selected">{{'TERMINAL.BASE.FILTER.TERMINAL_STATE.NOT_CONNECTED' | translate}}</option>
                <option value="Failure" selected="selected">{{'TERMINAL.BASE.FILTER.TERMINAL_STATE.FAILURE' | translate}}</option>
            </select>
        </div>
        <div>
            <div class="ui-inputgroup">
                {{'BASE.SEARCH.FIND' | translate}}:
                <input type="text" pInputText name="selectedFreeText" id="selectedFreetext" required [(ngModel)]="globalCacheVariable.filter.terminalIdentifierFilter.freetext" (change)="filterTerminals(f.value, f.valid)">
                <button type="button" class="ui-button-warn" (click)="resetFreeText(f.value, f.valid)">{{'TERMINAL.BASE.FILTER.BUTTONS.CLEAR' | translate}}</button>
            </div>
        </div>
        <button class="send-button" (click)="refreshTerminal()">{{'BASE.BUTTONS.REFRESH' | translate}}</button>
        <div>
            <div>{{globalCacheVariable.filter.terminalIdentifierFilter.terminalFilteredCount}} {{'TERMINAL.BASE.FILTER.OF' | translate}} {{globalCacheVariable.filter.terminalIdentifierFilter.terminalCount}}</div>
        </div>


    </form>
</div>
-->

<p-toolbar >
    <div class="p-toolbar-group-left" style="margin-left:0px;">
        <span>{{'TERMINAL.BASE.FILTER.TERMINAL_TYPE.MAIN' | translate}}:</span>
        <p-dropdown [options]="terminalTypes" [(ngModel)]="globalCacheVariable.filter.terminalIdentifierFilter.terminalType"
                    (onChange)="filterTerminalView()"
                    appendTo="body"
                    filter="true">
            <ng-template let-item pTemplate="selectedItem">
                <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
            </ng-template>
            <ng-template let-item pTemplate="item">
                <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                    <div style="font-size:14px;margin-top:4px">{{item.label}}</div>
                </div>
            </ng-template>
        </p-dropdown>

        <span style="margin-left:10px;">{{'TERMINAL.BASE.FILTER.PROPERTY.MAIN' | translate}}:</span>
        <p-dropdown styleClass="dropdown-control" panelStyleClass="dashboard-switch-container" [options]="propertyArray"
                    (onChange)="filterTerminalView()"
                    [(ngModel)]="globalCacheVariable.filter.terminalIdentifierFilter.property"
                    appendTo="body"
                    filter="true">
            <ng-template let-item pTemplate="selectedItem">
                <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
            </ng-template>
            <ng-template let-property pTemplate="item">
                <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                    <div style="font-size:14px;margin-top:4px;margin-left: .5em">{{property.label}}</div>
                </div>
            </ng-template>
        </p-dropdown>

        <span style="margin-left:10px;">{{'TERMINAL.BASE.FILTER.CONNECTION.MAIN' | translate}}:</span>
        <p-dropdown [options]="terminalConnection" [(ngModel)]="globalCacheVariable.filter.terminalIdentifierFilter.terminalState"
                    (onChange)="filterTerminalView()"
                    appendTo="body"
                    filter="true">
            <ng-template let-item pTemplate="selectedItem">
                <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
            </ng-template>
            <ng-template let-item pTemplate="item">
                <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                    <div style="font-size:14px;margin-top:4px">{{item.label}}</div>
                </div>
            </ng-template>
        </p-dropdown>

        <span style="margin-left:10px;">{{'BASE.SEARCH.FIND' | translate}}:</span>
        <div class="p-inputgroup">
            <input id="selectedFreetext" type="text" [(ngModel)]="globalCacheVariable.filter.terminalIdentifierFilter.freetext" pInputText placeholder="" (change)="filterTerminalView()">
            <button  *ngIf="globalCacheVariable.filter.terminalIdentifierFilter.freetext !== ''" type="button" pButton pRipple label="" (click)="resetFreeText()"><fa-icon [icon]="faDeleteLeft"></fa-icon></button>
        </div>
        <div style="margin-left:10px">
            <span>({{globalCacheVariable.filter.terminalIdentifierFilter.terminalFilteredCount}}/{{globalCacheVariable.filter.terminalIdentifierFilter.terminalCount}})</span>
        </div>
        <button class="send-button" style="line-height: 24px; margin-left: 20px;"
                (click)="filterTerminalView()"  title="{{'BASE.BUTTONS.LOAD' | translate}}"><i class="pi pi-replay"></i></button>


    </div>
</p-toolbar>
