import { Pipe, PipeTransform } from '@angular/core';
import { MediumType } from '../_enums/mediumType';
@Pipe({
    name: 'mediumType'
})
export class MediumTypePipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];

        return MediumType[value];
    }
}
