import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalCacheVariable } from '../_globals/globals';
import { catchError, map } from 'rxjs/operators';
import { ApiDataConverter } from "../_helpers/api-data-converter";
import { ApiCallType } from "../_enums/apiCallType";
import { OperatorSettingsType } from '../_enums/operatorSettingsType';
import { ToastService } from "./toast.service";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class OperatorService {

    constructor(
        private http: HttpClient,
        public cache: GlobalCacheVariable,
        private toastService: ToastService
    ) { }

    private static extractBridge(res, apiType) {
        let response = ApiDataConverter.extractData(res, apiType);
        return response || {};
    }

    public handleError(errorMessage: any, apiType) {
        ApiDataConverter.errorHandling(errorMessage, apiType);
        return observableThrowError(errorMessage);
    }

    getUserSettings(type = 2) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                settingsType: type,
            };
            return this.http.post(this.cache.ARTApi + 'GetUserSettings', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetUserSettings);
                    }),
                    map(data => {
                        return OperatorService.extractBridge(data, ApiCallType.GetUserSettings);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    setUserSettings(type: OperatorSettingsType = OperatorSettingsType.WebClientSettings, settingsValue: string) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                settingsType: type,
                settingsValue: settingsValue,
            };
            return this.http.post(this.cache.ARTApi + 'SetUserSettings', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.SetUserSettings);
                    }),
                    map(data => {
                        return OperatorService.extractBridge(data, ApiCallType.SetUserSettings);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtConfigurations() {
        try {
            let body = {
                strSessionKey: this.cache.getSessionKey()
            };
            return this.http.post(this.cache.ARTApi + 'GetConfigurationEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetConfigurationEntries);
                    }),
                    map(data => {
                        return OperatorService.extractBridge(data, ApiCallType.GetConfigurationEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getPlayerInformation(from, to) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                from: from,
                to: to,
            };
            return this.http.post(this.cache.ARTApi + 'GetPlayers', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPlayers);
                    }),
                    map(data => {
                        return OperatorService.extractBridge(data, ApiCallType.GetPlayers);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    changeOperatorPassword(passwordData) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                oldPassword: passwordData.oldPassword,
                newPassword: passwordData.password,
            };
            return this.http.post(this.cache.ARTApi + 'UpdateUserPassword', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.UpdateUserPassword);
                    }),
                    map(data => {
                        return OperatorService.extractBridge(data, ApiCallType.UpdateUserPassword);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    addOperator(operator, assignedProperties = null) {
        try {
            let assignedPropertyNumbers = assignedProperties;

            if (operator.ArtRightsX.length > 0) {
                operator.ArtRightsX = this.removeExtensionData(operator.ArtRightsX);
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                op: operator,
                assignedPropertyNumbers: assignedPropertyNumbers,
            };
            return this.http.post(this.cache.ARTApi + 'AddOperator', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.AddOperator);
                    }),
                    map(data => {
                        return OperatorService.extractBridge(data, ApiCallType.AddOperator);
                    }));
        } catch (error) {
            this.toastService.error('test', 'test', 'toast', 10000, true);

            console.log("errorlog in try catch", error);
        }
    }

    updateOperator(operator, assignedProperties = null) {
        try {
            operator.ExtensionData = null;
            operator.AdditionalInfo.ExtensionData = null;
            operator.EntryCreated = "";
            operator.LastModified = "";
            operator.ArtPasswordChangeDate = "";
            operator.PasswordChangeDate = "";
            if (operator.ArtRightsX.length > 0) {
                operator.ArtRightsX = this.removeExtensionData(operator.ArtRightsX);
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                op: operator,
                assignedPropertyNumbers: assignedProperties,
            };
            return this.http.post(this.cache.ARTApi + 'UpdateOperator', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.UpdateOperator);
                    }),
                    map(data => {
                        return OperatorService.extractBridge(data, ApiCallType.UpdateOperator);
                    })
                );
        } catch (error) {
            console.log(error);
        }
    }

    removeExtensionData(elem) {
        for (let e = 0; e < elem.length; e++) {
            let currentRight = elem[e];
            currentRight.ExtensionData = null;
            if (currentRight.SubRights.length > 0) {
                this.removeExtensionData(currentRight.SubRights);
            }
        }
        return elem;
    }

    deleteOperator(operator) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                operatorIDToDelete: operator.OperatorID,
            };
            return this.http.post(this.cache.ARTApi + 'DeleteOperator', body, httpOptions)
                .pipe(map(data => {
                    return OperatorService.extractBridge(data, ApiCallType.DeleteOperator);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllOperators() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey()
            };
            return this.http.post(this.cache.ARTApi + 'GetOperators', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetOperators);
                    }),
                    map(data => {
                        return OperatorService.extractBridge(data, ApiCallType.GetOperators);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getUserSessions() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey()
            };

            return this.http.post(this.cache.ARTApi + 'GetUserSessions', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetUserSessions);
                    }),
                    map(data => {
                        return OperatorService.extractBridge(data, ApiCallType.GetUserSessions);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllOperatorsFull() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey()
            };
            return this.http.post(this.cache.ARTApi + 'GetOperatorsFull', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetOperatorsFull);
                    }),
                    map(data => {
                        return OperatorService.extractBridge(data, ApiCallType.GetOperatorsFull);
                    }));
        } catch (error) {
            console.log(error);
        }
    }
}

