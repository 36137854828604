import { Injectable } from '@angular/core';
import { TerminalType } from '../_enums/terminalType';
import { TerminalTypeDefinition } from '../_models/terminalTypeDefinition';

@Injectable()
export class TerminalTypeDefinitionService {
    private terminalTypesDefs = new Map<TerminalType, TerminalTypeDefinition>();
    private acs: TerminalTypeDefinition;
    private jps: TerminalTypeDefinition;
    private vt: TerminalTypeDefinition;
    private caion: TerminalTypeDefinition;
    private ccs: TerminalTypeDefinition;
    private ces: TerminalTypeDefinition;
    private rodi: TerminalTypeDefinition;
    private pos: TerminalTypeDefinition;
    private cachio: TerminalTypeDefinition;
    private caro: TerminalTypeDefinition;
    private cashioTitanium: TerminalTypeDefinition;
    private cashioGold: TerminalTypeDefinition;
    private cashioSilver: TerminalTypeDefinition;
    private cashioChrome: TerminalTypeDefinition;
    private cashioPlatinumGen2: TerminalTypeDefinition;
    private adPlayer: TerminalTypeDefinition;
    private twoPay: TerminalTypeDefinition;
    private cashDispenser: TerminalTypeDefinition;
    private cashioJps: TerminalTypeDefinition;
    private cashioTitaniumGen2: TerminalTypeDefinition;
    private tita: TerminalTypeDefinition;
    private cata: TerminalTypeDefinition;

    constructor() {
        this.acs = new TerminalTypeDefinition();
        this.acs.GeneralDataSync = false;
        this.acs.OperatorSync = false;
        this.acs.Stock = false;
        this.acs.AccountSync = false;
        this.acs.Replication = false;
        this.acs.ServiceCards = false;
        this.acs.MediaPolicy = false;
        this.acs.TransactionApproval = false;
        this.acs.ConfigurationProvider = false;
        this.acs.ElectronicLock = false;
        this.acs.Branding = false;
        this.acs.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.Acs, this.acs);

        this.jps = new TerminalTypeDefinition();
        this.jps.GeneralDataSync = false;
        this.jps.OperatorSync = false;
        this.jps.Stock = false;
        this.jps.AccountSync = false;
        this.jps.Replication = false;
        this.jps.ServiceCards = false;
        this.jps.MediaPolicy = false;
        this.jps.TransactionApproval = false;
        this.jps.ConfigurationProvider = false;
        this.jps.ElectronicLock = false;
        this.jps.Branding = false;
        this.jps.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.Jps, this.jps);

        this.vt = new TerminalTypeDefinition();
        this.vt.GeneralDataSync = false;
        this.vt.OperatorSync = false;
        this.vt.Stock = false;
        this.vt.AccountSync = false;
        this.vt.Replication = false;
        this.vt.ServiceCards = false;
        this.vt.MediaPolicy = false;
        this.vt.TransactionApproval = false;
        this.vt.ConfigurationProvider = false;
        this.vt.ElectronicLock = false;
        this.vt.Branding = false;
        this.vt.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.Vt, this.vt);

        this.caion = new TerminalTypeDefinition();
        this.caion.GeneralDataSync = true;
        this.caion.OperatorSync = true;
        this.caion.Stock = true;
        this.caion.AccountSync = true;
        this.caion.Replication = true;
        this.caion.ServiceCards = true;
        this.caion.MediaPolicy = true;
        this.caion.TransactionApproval = true;
        this.caion.ConfigurationProvider = false;
        this.caion.ElectronicLock = false;
        this.caion.Branding = false;
        this.caion.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.Caion, this.caion);

        this.ccs = new TerminalTypeDefinition();
        this.ccs.GeneralDataSync = false;
        this.ccs.ConfigurationProvider = false;
        this.ccs.SessionManager = false;
        this.ccs.OperatorSync = false;
        this.ccs.Stock = false;
        this.ccs.Replication = false;
        this.ccs.TransactionApproval = false;
        this.ccs.ServiceCards = false;
        this.ccs.ElectronicLock = false;
        this.ccs.AccountSync = false;
        this.ccs.MediaPolicy = false;
        this.ccs.Branding = false;
        this.terminalTypesDefs.set(TerminalType.Ccs, this.ccs);

        this.ces = new TerminalTypeDefinition();
        this.ces.GeneralDataSync = false;
        this.ces.OperatorSync = false;
        this.ces.Stock = false;
        this.ces.AccountSync = false;
        this.ces.Replication = false;
        this.ces.ServiceCards = false;
        this.ces.MediaPolicy = false;
        this.ces.TransactionApproval = false;
        this.ces.ConfigurationProvider = false;
        this.ces.ElectronicLock = false;
        this.ces.Branding = false;
        this.ces.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.Ces, this.ces);

        this.rodi = new TerminalTypeDefinition();
        this.rodi.GeneralDataSync = false;
        this.rodi.OperatorSync = false;
        this.rodi.Stock = false;
        this.rodi.AccountSync = false;
        this.rodi.Replication = false;
        this.rodi.ServiceCards = false;
        this.rodi.MediaPolicy = false;
        this.rodi.TransactionApproval = false;
        this.rodi.ConfigurationProvider = false;
        this.rodi.ElectronicLock = false;
        this.rodi.Branding = false;
        this.rodi.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.Rodi, this.rodi);

        this.pos = new TerminalTypeDefinition();
        this.pos.GeneralDataSync = false;
        this.pos.OperatorSync = false;
        this.pos.Stock = false;
        this.pos.AccountSync = false;
        this.pos.Replication = false;
        this.pos.ServiceCards = false;
        this.pos.MediaPolicy = false;
        this.pos.TransactionApproval = false;
        this.pos.ConfigurationProvider = false;
        this.pos.ElectronicLock = false;
        this.pos.Branding = false;
        this.pos.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.Pos, this.pos);

        this.cachio = new TerminalTypeDefinition();
        this.cachio.GeneralDataSync = true;
        this.cachio.ConfigurationProvider = true;
        this.cachio.OperatorSync = true;
        this.cachio.Stock = true;
        this.cachio.ElectronicLock = true;
        this.cachio.AccountSync = true;
        this.cachio.Replication = true;
        this.cachio.ServiceCards = true;
        this.cachio.MediaPolicy = true;
        this.cachio.TransactionApproval = true;
        this.cachio.Branding = true;
        this.cachio.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.Cashio, this.cachio);

        this.caro = new TerminalTypeDefinition();
        this.caro.GeneralDataSync = true;
        this.caro.OperatorSync = true;
        this.caro.Stock = true;
        this.caro.Replication = true;
        this.caro.ServiceCards = true;
        this.caro.MediaPolicy = true;
        this.caro.AccountSync = false;
        this.caro.ConfigurationProvider = false;
        this.caro.ElectronicLock = false;
        this.caro.Branding = false;
        this.caro.SessionManager = false;
        this.caro.TransactionApproval = false;
        this.terminalTypesDefs.set(TerminalType.Caro, this.caro);

        this.cashioTitanium = new TerminalTypeDefinition();
        this.cashioTitanium.GeneralDataSync = true;
        this.cashioTitanium.ConfigurationProvider = true;
        this.cashioTitanium.OperatorSync = true;
        this.cashioTitanium.Stock = true;
        this.cashioTitanium.ElectronicLock = true;
        this.cashioTitanium.AccountSync = true;
        this.cashioTitanium.Replication = true;
        this.cashioTitanium.ServiceCards = true;
        this.cashioTitanium.MediaPolicy = true;
        this.cashioTitanium.TransactionApproval = true;
        this.cashioTitanium.Branding = true;
        this.cashioTitanium.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.CashioTitanium, this.cashioTitanium);

        this.cashioGold = new TerminalTypeDefinition();
        this.cashioGold.GeneralDataSync = true;
        this.cashioGold.ConfigurationProvider = true;
        this.cashioGold.OperatorSync = true;
        this.cashioGold.Stock = true;
        this.cashioGold.ElectronicLock = true;
        this.cashioGold.AccountSync = true;
        this.cashioGold.Replication = true;
        this.cashioGold.ServiceCards = true;
        this.cashioGold.MediaPolicy = true;
        this.cashioGold.TransactionApproval = true;
        this.cashioGold.Branding = true;
        this.cashioGold.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.CashioGold, this.cashioGold);

        this.cashioSilver = new TerminalTypeDefinition();
        this.cashioSilver.GeneralDataSync = true;
        this.cashioSilver.ConfigurationProvider = true;
        this.cashioSilver.OperatorSync = true;
        this.cashioSilver.Stock = true;
        this.cashioSilver.ElectronicLock = true;
        this.cashioSilver.AccountSync = true;
        this.cashioSilver.Replication = true;
        this.cashioSilver.ServiceCards = true;
        this.cashioSilver.MediaPolicy = true;
        this.cashioSilver.TransactionApproval = true;
        this.cashioSilver.Branding = true;
        this.cashioSilver.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.CashioSilver, this.cashioSilver);

        this.cashioChrome = new TerminalTypeDefinition();
        this.cashioChrome.GeneralDataSync = true;
        this.cashioChrome.ConfigurationProvider = true;
        this.cashioChrome.OperatorSync = true;
        this.cashioChrome.Stock = true;
        this.cashioChrome.ElectronicLock = true;
        this.cashioChrome.AccountSync = true;
        this.cashioChrome.Replication = true;
        this.cashioChrome.ServiceCards = true;
        this.cashioChrome.MediaPolicy = true;
        this.cashioChrome.TransactionApproval = true;
        this.cashioChrome.Branding = true;
        this.cashioChrome.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.CashioChrome, this.cashioChrome);

        this.cashioPlatinumGen2 = new TerminalTypeDefinition();
        this.cashioPlatinumGen2.GeneralDataSync = true;
        this.cashioPlatinumGen2.ConfigurationProvider = true;
        this.cashioPlatinumGen2.OperatorSync = true;
        this.cashioPlatinumGen2.Stock = true;
        this.cashioPlatinumGen2.ElectronicLock = true;
        this.cashioPlatinumGen2.AccountSync = true;
        this.cashioPlatinumGen2.Replication = true;
        this.cashioPlatinumGen2.ServiceCards = true;
        this.cashioPlatinumGen2.MediaPolicy = true;
        this.cashioPlatinumGen2.TransactionApproval = true;
        this.cashioPlatinumGen2.Branding = true;
        this.cashioPlatinumGen2.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.CashioPlatinumGen2, this.cashioPlatinumGen2);

        this.adPlayer = new TerminalTypeDefinition();
        this.adPlayer.GeneralDataSync = true;
        this.adPlayer.OperatorSync = true;
        this.adPlayer.Replication = true;
        this.adPlayer.ServiceCards = true;
        this.adPlayer.ConfigurationProvider = false;
        this.adPlayer.Stock = false;
        this.adPlayer.ElectronicLock = false;
        this.adPlayer.AccountSync = false;
        this.adPlayer.MediaPolicy = false;
        this.adPlayer.TransactionApproval = false;
        this.adPlayer.Branding = false;
        this.adPlayer.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.AdPlayer, this.adPlayer);

        this.twoPay = new TerminalTypeDefinition();
        this.twoPay.GeneralDataSync = true;
        this.twoPay.ConfigurationProvider = true;
        this.twoPay.OperatorSync = true;
        this.twoPay.ElectronicLock = false;
        this.twoPay.AccountSync = true;
        this.twoPay.Replication = true;
        this.twoPay.ServiceCards = true;
        this.twoPay.MediaPolicy = true;
        this.twoPay.TransactionApproval = true;
        this.twoPay.Stock = false;
        this.twoPay.Branding = false;
        this.twoPay.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.TwoPay, this.twoPay);

        this.cashDispenser = new TerminalTypeDefinition();
        this.cashDispenser.GeneralDataSync = true;
        this.cashDispenser.ConfigurationProvider = true;
        this.cashDispenser.OperatorSync = false;
        this.cashDispenser.ElectronicLock = false;
        this.cashDispenser.AccountSync = false;
        this.cashDispenser.Replication = true;
        this.cashDispenser.ServiceCards = false;
        this.cashDispenser.MediaPolicy = false;
        this.cashDispenser.TransactionApproval = false;
        this.cashDispenser.Stock = false;
        this.cashDispenser.Branding = false;
        this.cashDispenser.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.CashDispenser, this.cashDispenser);

        this.cashioJps = new TerminalTypeDefinition();
        this.cashioJps.GeneralDataSync = true;
        this.cashioJps.ConfigurationProvider = true;
        this.cashioJps.OperatorSync = true;
        this.cashioJps.Stock = true;
        this.cashioJps.ElectronicLock = true;
        this.cashioJps.AccountSync = true;
        this.cashioJps.Replication = true;
        this.cashioJps.ServiceCards = true;
        this.cashioJps.MediaPolicy = true;
        this.cashioJps.TransactionApproval = true;
        this.cashioJps.Branding = false;
        this.cashioJps.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.CashioJps, this.cashioJps);

        this.cashioTitaniumGen2 = new TerminalTypeDefinition();
        this.cashioTitaniumGen2.GeneralDataSync = true;
        this.cashioTitaniumGen2.ConfigurationProvider = true;
        this.cashioTitaniumGen2.OperatorSync = true;
        this.cashioTitaniumGen2.Stock = true;
        this.cashioTitaniumGen2.ElectronicLock = true;
        this.cashioTitaniumGen2.AccountSync = true;
        this.cashioTitaniumGen2.Replication = true;
        this.cashioTitaniumGen2.ServiceCards = true;
        this.cashioTitaniumGen2.MediaPolicy = true;
        this.cashioTitaniumGen2.TransactionApproval = true;
        this.cashioTitaniumGen2.Branding = true;
        this.cashioTitaniumGen2.SessionManager = false;
        this.terminalTypesDefs.set(TerminalType.CashioTitaniumGen2, this.cashioTitaniumGen2);

        this.tita = new TerminalTypeDefinition();
        this.tita.GeneralDataSync = true;
        this.tita.ConfigurationProvider = true;
        this.tita.SessionManager = true;
        this.tita.OperatorSync = true;
        this.tita.Stock = true;
        this.tita.Replication = true;
        this.tita.TransactionApproval = true;
        this.tita.ServiceCards = false;
        this.tita.ElectronicLock = false;
        this.tita.AccountSync = false;
        this.tita.MediaPolicy = false;
        this.tita.Branding = false;
        this.terminalTypesDefs.set(TerminalType.Tita, this.tita);

        this.cata = new TerminalTypeDefinition();
        this.cata.GeneralDataSync = true;
        this.cata.ConfigurationProvider = true;
        this.cata.SessionManager = true;
        this.cata.OperatorSync = true;
        this.cata.Stock = true;
        this.cata.Replication = true;
        this.cata.TransactionApproval = true;
        this.cata.ServiceCards = false;
        this.cata.ElectronicLock = false;
        this.cata.AccountSync = false;
        this.cata.MediaPolicy = false;
        this.cata.Branding = false;
        this.terminalTypesDefs.set(TerminalType.Cata, this.cata);
    }

    getDefinition(type) {
        return this.terminalTypesDefs.get(type);
    }
}
