import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalCacheVariable } from './auth/_globals/globals';
import { Stock } from './auth/_helpers/stock';
import { TransactionTypes } from './auth/_helpers/transactionType';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from "./_services/script-loader.service";
import { TerminalService } from "./auth/_services";
import { LoggingService } from "./auth/_services/log.service";
import { OperatorService } from "./auth/_services/operator.service";
import { RewriteService } from "./auth/_services/rewrite.service";
import { DebugService } from "./auth/_services/debug.service";
import { StatisticService } from "./auth/_services/statistic.service";
import { DashboardService } from "./auth/_services/dashboard.service";
import { BalancingService } from "./auth/_services/balancing.service";
import { GroupServerService } from "./auth/_services/groupserver.service";
import { TooltipService } from "./auth/_services/tooltip.service";
import { PaymentKioskService } from "./auth/_services/payment-kiosk.service";

import { ThemeRoutingModule } from "./theme/theme-routing.module";
import { AuthModule } from "./auth/auth.module";

import { AccordionModule } from 'primeng/accordion';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from "primeng/toast";
import { CardModule } from 'primeng/card';
import { TerminalTypeNamingPipe } from "./auth/_pipes/terminalTypeNaming.pipe";
import { TerminalIconRewritePipe } from "./auth/_pipes/terminalIconRewrite.pipe";
import { SharingModule } from "./shared.module";
import { NgChartsModule } from 'ng2-charts';
import { TimeSpanPipe } from "./auth/_pipes/timeSpan.pipe";
import { FormatService } from "./auth/_services/format.service";
import { CheckRightsService } from "./auth/_services/checkRights.service";
import { JackpotService } from "./auth/_services/jackpot.service";
import { MenuService } from "./auth/_services/menu.service";
import { GeneralService } from './auth/_services/general.service';
import { ArtFilterService } from './auth/_services/artFilter.service';
import { GraphConfigurations } from "./auth/_helpers/graph-configurations";
import { TerminalTypeDefinitionService } from './auth/_services/terminalTypeDefinition.service';
import { ComponentStateMapper, FeatureStateMapper, TerminalStateClass } from './auth/_helpers/componentState';
import { SortService } from "./auth/_services/sort.service";
import { TerminalHelper } from './auth/_helpers/terminal-helper';
import { ExportService } from "./auth/_services/export.service";
import { ToastService } from "./auth/_services/toast.service";
import { FormatDate } from "./auth/_helpers/formatHelper/formatDate";
//import {UserProfileModule} from "./theme/pages/default/user/profile/user-profile.module";

@NgModule({
    declarations: [
        ThemeComponent,
        AppComponent,
    ],
    imports: [
        LayoutModule,
        BrowserModule,
        FormsModule,
        ToastModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        ThemeRoutingModule,
        AccordionModule,
        PanelModule,
        ButtonModule,
        RadioButtonModule,
        CardModule,
        TranslateModule.forRoot(),
        AuthModule,
        SharingModule,
        NgChartsModule,
        //UserProfileModule
        //DataTablesModule,

    ],
    providers: [
        ScriptLoaderService,
        TerminalService,
        LoggingService,
        GraphConfigurations,
        GlobalCacheVariable,
        OperatorService,
        JackpotService,
        DebugService,
        TerminalIconRewritePipe,
        TerminalTypeNamingPipe,
        RewriteService,
        StatisticService,
        DashboardService,
        BalancingService,
        Stock,
        FormatDate,
        TransactionTypes,
        GroupServerService,
        TooltipService,
        TimeSpanPipe,
        PaymentKioskService,
        CheckRightsService,
        FormatService,
        MenuService,
        GeneralService,
        ArtFilterService,
        TerminalTypeDefinitionService,
        SortService,
        ComponentStateMapper,
        ToastService,
        TerminalStateClass,
        FeatureStateMapper,
        TerminalHelper,
        ExportService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
