import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Terminal } from "../../../../../../auth/_models";
import { TerminalState } from "../../../../../../auth/_enums/terminalState";

@Component({
    selector: 'terminal-icon',
    templateUrl: './terminal-icon.component.html',
    styleUrls: ['./terminal-icon.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class TerminalIconComponent {

    @Input('terminal') terminal: Terminal;
    @Output()
    terminalDetail: EventEmitter<String> = new EventEmitter<String>(); //creating an output event
    //Enums declaration for HTML use
    TerminalState = TerminalState;

    toggleDetailOpen(event, terminal) {
        this.terminalDetail.emit(terminal);
    }
}
