import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'terminalStatus'
})
export class TerminalStatusPipe implements PipeTransform {

    transform(value: number): string {

        if (value === 0) {
            return "TERMINAL.BASE.TERMINAL_STATUS.DISCONNECTED";
        }
        else {
            return "TERMINAL.BASE.TERMINAL_STATUS.CONNECTED";
        }

    }
}
