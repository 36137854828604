import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalCacheVariable } from '../_globals/globals';
import { catchError, map } from 'rxjs/operators';
import { TransactionType } from "../_enums/transactionType";
import { ApiDataConverter } from "../_helpers/api-data-converter";
import { ApiCallType } from "../_enums/apiCallType";
//define TransactionTypeGroups

let OperatorTransactions = [
    TransactionType.JackpotPayout,
    TransactionType.FillBagFront,
    TransactionType.EmptyHopper,
    TransactionType.JackpotRear,
    TransactionType.FillBackRear,
    TransactionType.CassetteRemoved,
    TransactionType.CassetteInserted,
    TransactionType.BillValidatorEmptied,
    TransactionType.CoinsInserted,
    TransactionType.RejectBoxEmptied,
    TransactionType.OpenCreditCleared,
    TransactionType.TestHopper,
    TransactionType.MediaOutEmptied,
    TransactionType.MediaInEmptied,
    TransactionType.CashDifference,
    TransactionType.CashboxFilled,
    TransactionType.CashboxWithdrawn,
    TransactionType.CashboxReload,
    TransactionType.MediaRefill,
    TransactionType.DepositWithheld,
    TransactionType.DepositPaid,
    TransactionType.RoundingDifferenceCleared,
    TransactionType.SamToSuperSam,
    TransactionType.JackpotCancelCredit,
    TransactionType.JackpotShortPay,
    TransactionType.GMHopperRefill,
    TransactionType.ProgressiveJackpot,
    TransactionType.CaptureBinEmptied,
    TransactionType.StackerStockAdded,
    TransactionType.PrintedTicketsCleared,
    TransactionType.LostMoney,
    TransactionType.LostMoneyCleared,
    TransactionType.JackpotAdditional,
    TransactionType.CoinAcceptorEmptied,
    TransactionType.PosCleared,
    TransactionType.CheckCashingCleared,
    TransactionType.AtmCleared,
    TransactionType.LoansCleared,
    TransactionType.DonationsCleared,
    TransactionType.TaxWithheldCleared,
    TransactionType.DebitPayout,
    TransactionType.DebitReturn,
    TransactionType.CashierCashPayout,
    TransactionType.CashierCashReturn,
    TransactionType.StackerEmptied,
    TransactionType.EWalletDepositCleared,
    TransactionType.EWalletWithdrawalCleared,
    TransactionType.BillPayCleared,
    TransactionType.CheckCashingPaymentCleared,
    TransactionType.MobileTopUpCleared,
    TransactionType.GiftCardSaleCleared,
    TransactionType.WireTransferCleared,
    TransactionType.DebitCardSaleCleared,
    TransactionType.CryptoCurrencyBuyCleared,
    TransactionType.CryptoCurrencySaleCleared,
    TransactionType.FeeWithheldCleared,
    TransactionType.PrintedIouReceiptsCleared
];
let CustomerTransactions = [
    TransactionType.CoinRedemption,
    TransactionType.BillBreaker,
    TransactionType.TicketRedemption,
    TransactionType.MagneticCardRedemption,
    TransactionType.SmartCardRedemption,
    TransactionType.TicketPrinted,
    TransactionType.MagneticCardCharged,
    TransactionType.SmartCardCharged,
    TransactionType.CoinsDispensed,
    TransactionType.AtmWithdrawal,
    TransactionType.AtmCashAdvance,
    TransactionType.AtmBalanceInquiry,
    TransactionType.PosWithdrawal,
    TransactionType.ChipRedemption,
    TransactionType.ChipsPaidOut,
    TransactionType.CouponRedemption,
    TransactionType.CheckCashing,
    TransactionType.LoanRaised,
    TransactionType.LoanRepaidCash,
    TransactionType.LoanRepaidCard,
    TransactionType.MarkerRedemption,
    TransactionType.EWalletDeposit,
    TransactionType.EWalletWithdrawal,
    TransactionType.RejectedBill,
    TransactionType.RejectedCard,
    TransactionType.RejectedTicket,
    TransactionType.TicketRecreated,
    TransactionType.BonusCardRedemption,
    TransactionType.BonusCardCharged,
    TransactionType.BillPay,
    TransactionType.CheckCashingPayment,
    TransactionType.MobileTopUp,
    TransactionType.GiftCardSale,
    TransactionType.WireTransfer,
    TransactionType.DebitCardSale,
    TransactionType.GiftCardBuy,
    TransactionType.CryptoCurrencyBuy,
    TransactionType.PrepaidCardCharged,
    TransactionType.CryptoCurrencySale,
    TransactionType.PrepaidCardIssued,
    TransactionType.IouReceiptRedemption
];
let AtmTransactions = [
    TransactionType.AtmWithdrawal,
    TransactionType.AtmCashAdvance,
    TransactionType.AtmBalanceInquiry
];
let JackpotTransactions = [
    TransactionType.JackpotPayout,
    TransactionType.JackpotCancelCredit,
    TransactionType.JackpotShortPay,
    TransactionType.GMHopperRefill,
    TransactionType.ProgressiveJackpot,
    TransactionType.JackpotAdditional
];
let MagneticCardTransactions = [
    TransactionType.MagneticCardCharged,
    TransactionType.MagneticCardRedemption
];
let SmartCardTransactions = [
    TransactionType.SmartCardCharged,
    TransactionType.SmartCardRedemption
];
let TicketTransactions = [
    TransactionType.TicketPrinted,
    TransactionType.TicketRedemption
];
let LoanTransactions = [
    TransactionType.LoanRaised,
    TransactionType.LoanRepaidCash,
    TransactionType.LoanRepaidCard
    //TransactionType.LoansCleared
];
let EWalletTransactions = [
    TransactionType.EWalletDeposit,
    TransactionType.EWalletWithdrawal,
];
let PaymentTransactions = [
    TransactionType.BillPay,
    TransactionType.CheckCashingPayment,
    TransactionType.MobileTopUp,
    TransactionType.GiftCardSale,
    TransactionType.BillPayCleared,
    TransactionType.WireTransfer,
    TransactionType.GiftCardBuy,
    TransactionType.CryptoCurrencyBuy,
    TransactionType.PrepaidCardCharged,
    TransactionType.CryptoCurrencySale,
    TransactionType.PrepaidCardIssued
];

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LoggingService {

    constructor(
        private http: HttpClient,
        public cache: GlobalCacheVariable) {
    }

    private static extractBridge(res, apiType) {
        let response = ApiDataConverter.extractData(res, apiType);
        return response || {};
    }

    public handleError(errorMessage: any, apiType) {
        ApiDataConverter.errorHandling(errorMessage, apiType);
        return observableThrowError(errorMessage);
    }

    getTransactionSearchData(searchCategory, searchCriterion, searchParameter) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                searchCategory: searchCategory,
                searchCriterion: searchCriterion,
                searchParameter: searchParameter
            };
            return this.http.post(this.cache.ARTApi + 'SearchForTransactions', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.SearchForTransactions);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.SearchForTransactions);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    GetTransactionPicture(terminalID, transactionNumber, index, imageType, storageLocation) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                storageLocation: storageLocation,
                terminalID: terminalID,
                transNo: transactionNumber,
                imageType: imageType,
                index: index,
            };
            return this.http.post(this.cache.ARTApi + 'GetTransactionPicture', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTransactionPicture);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetTransactionPicture);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getTransactionLogsDetail2(transactionNumber, terminalID) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                transactionNumber: transactionNumber,
                terminalID: terminalID,
            };
            return this.http.post(this.cache.ARTApi + 'GetTransactionDetails2', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTransactionDetails2);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetTransactionDetails2);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getTransactionLogsDetail(transactionID) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                transactionID: transactionID,
            };
            return this.http.post(this.cache.ARTApi + 'GetTransactionDetails', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTransactionDetails);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetTransactionDetails);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getAllTransactionLogs(terminalID, transactionType, operator, dateStart, dateEnd, page = null, entriesPerPage = null, utcState = false) {
        try {
            let terminalIDsCache = null;
            let operatorRest = -1;
            let transactionTypeArray = [];
            if (transactionType === null || transactionType === -1 || transactionType === undefined) {
                transactionType = 'all';
            }
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            }
            else {
                terminalIDsCache = terminalID;
            }
            switch (transactionType) {
                case 'all':
                    transactionTypeArray = [];
                    break;
                case 'OperatorTransactions':
                    transactionTypeArray = OperatorTransactions;
                    break;
                case 'CustomerTransactions':
                    transactionTypeArray = CustomerTransactions;
                    break;
                case 'AtmTransactions':
                    transactionTypeArray = AtmTransactions;
                    break;
                case 'JackpotTransactions':
                    transactionTypeArray = JackpotTransactions;
                    break;
                case 'MagneticCardTransactions':
                    transactionTypeArray = MagneticCardTransactions;
                    break;
                case 'SmartCardTransactions':
                    transactionTypeArray = SmartCardTransactions;
                    break;
                case 'TicketTransactions':
                    transactionTypeArray = TicketTransactions;
                    break;
                case 'LoanTransactions':
                    transactionTypeArray = LoanTransactions;
                    break;
                case 'EWalletTransactions':
                    transactionTypeArray = EWalletTransactions;
                    break;
                case 'PaymentTransactions':
                    transactionTypeArray = PaymentTransactions;
                    break;
                default:
                    transactionTypeArray.push(Number(transactionType));
                    break;

            }
            if (operator === 'all' || operator === -1 || operator === undefined || operator === null) {
                operatorRest = -1;
            }
            else {
                operatorRest = parseInt(operator);
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                terminalIDs: terminalIDsCache,
                transactionTypes: transactionTypeArray,
                operatorID: operatorRest,
                page: page,
                itemsPerPage: entriesPerPage,
                useUtcDate: utcState
            };
            return this.http.post(this.cache.ARTApi + 'GetTransactions', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTransactions);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetTransactions);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getRecentTransactions(terminalID = null, count = 3) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                count: count
            };
            return this.http.post(this.cache.ARTApi + 'GetRecentTransactions', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetRecentTransactions);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetRecentTransactions);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    //ERROR LOGS
    getAllErrorLogs(terminalID = null, dateStart = null, dateEnd = null, page = null, entriesPerPage = null) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            }
            else {
                terminalIDsCache = terminalID;
            }
            let body = {
                sessionKey: this.cache.getSessionKey(), start: dateStart, end: dateEnd,
                terminalIDs: terminalIDsCache,
                page: page, itemsPerPage: entriesPerPage
            };
            return this.http.post(this.cache.ARTApi + 'GetErrorLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetErrorLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetErrorLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getRecentErrorLogs(terminalID = null, count = 3) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                count: count
            };
            return this.http.post(this.cache.ARTApi + 'GetRecentErrorLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetRecentErrorLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetRecentErrorLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    //ConfigurationChangeLog
    getAllConfigurationChangeLogs(terminalID = null, dateStart = null, dateEnd = null, page = null, entriesPerPage = null) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            }
            else {
                terminalIDsCache = terminalID;
            }
            let body = {
                sessionKey: this.cache.getSessionKey(), start: dateStart, end: dateEnd,
                terminalIDs: terminalIDsCache,
                page: page, itemsPerPage: entriesPerPage
            };
            return this.http.post(this.cache.ARTApi + 'GetConfigurationChangeLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetConfigurationChangeLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetConfigurationChangeLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getRecentConfigurationChangeLogs(terminalID = null, count = 3) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                count: count
            };
            return this.http.post(this.cache.ARTApi + 'GetRecentConfigurationChangeLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetRecentConfigurationChangeLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetRecentConfigurationChangeLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    //OperatorAccess
    getAllOperatorAccessLogs(operatorID = null, dateStart = null, dateEnd = null, page = null, entriesPerPage = null) {
        try {
            let operatorIDsCache = -1;

            if (operatorID === 'all' || operatorID === -1 || operatorID === 0) {
                //let terminalArrayResponse = this.getAllTerminalId();
                operatorIDsCache = -1;
            }
            else {
                operatorIDsCache = operatorID;
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                start: dateStart,
                end: dateEnd,
                operatorID: operatorIDsCache,
                page: page, itemsPerPage: entriesPerPage
            };
            return this.http.post(this.cache.ARTApi + 'GetOperatorAccessLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetOperatorAccessLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetOperatorAccessLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getRecentOperatorAccessLogs(terminalID = null, count = 3) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                count: count
            };
            return this.http.post(this.cache.ARTApi + 'GetRecentOperatorAccessLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetRecentOperatorAccessLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetRecentOperatorAccessLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    //MaintenanceLogs
    getAllMaintenanceLogs(terminalID = null, dateStart = null, dateEnd = null, page = null, entriesPerPage = null) {
        try {
            const artEventTypesData = 0;
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            }
            else {
                terminalIDsCache = terminalID;
            }
            let body = {
                sessionKey: this.cache.getSessionKey(), start: dateStart, end: dateEnd,
                terminalIDs: terminalIDsCache,
                page: page, itemsPerPage: entriesPerPage
            };
            return this.http.post(this.cache.ARTApi + 'GetMaintenanceLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetMaintenanceLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetMaintenanceLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getRecentMaintenanceLogs(terminalID = null, count = 3) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                count: count
            };
            return this.http.post(this.cache.ARTApi + 'GetRecentMaintenanceLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetRecentMaintenanceLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetRecentMaintenanceLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    //MediaLogs
    getAllMediaLogs(terminalID = null, dateStart = null, dateEnd = null, page = null, entriesPerPage = null) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            }
            else {
                terminalIDsCache = terminalID;
            }
            let body = {
                sessionKey: this.cache.getSessionKey(), start: dateStart, end: dateEnd,
                terminalIDs: terminalIDsCache,
                page: page, itemsPerPage: entriesPerPage
            };
            return this.http.post(this.cache.ARTApi + 'GetMediaLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetMediaLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetMediaLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getRecentMediaLogs(terminalID = null, count = 3) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                count: count
            };
            return this.http.post(this.cache.ARTApi + 'GetRecentMediaLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetRecentMediaLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetRecentMediaLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    //EventLogs
    getAllEventLogs(terminalID = null, eventSource = null, eventSeverity = null, dateStart = null, dateEnd = null, page = null, entriesPerPage = null) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            }
            else {
                terminalIDsCache = terminalID;
            }
            let body = {
                sessionKey: this.cache.getSessionKey(), start: dateStart, end: dateEnd,
                terminalIDs: terminalIDsCache,
                eventSource: eventSource,
                eventSeverity: eventSeverity,
                page: page, itemsPerPage: entriesPerPage
            };
            return this.http.post(this.cache.ARTApi + 'GetEventLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetEventLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetEventLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getRecentEventLogs(terminalID = null, count = 3) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                count: count
            };
            return this.http.post(this.cache.ARTApi + 'GetRecentEventLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetRecentEventLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetRecentEventLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    //ArtEventLogs
    getAllArtEventLogs(eventType: number = null, dateStart = null, dateEnd = null, page = null, entriesPerPage = null, eventSource: number = null) {
        try {
            //let artEventTypesData:number = eventType;
            //let artEventSourceData:number = eventSource;
            let body = {
                sessionKey: this.cache.getSessionKey(),
                artEventSource: eventSource,
                artEventTypes: eventType,
                start: dateStart,
                end: dateEnd,
                page: page, itemsPerPage: entriesPerPage
            };

            return this.http.post(this.cache.ARTApi + 'GetArtEventLogEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetArtEventLogEntries);
                    }),
                    map(data => {
                        return LoggingService.extractBridge(data, ApiCallType.GetArtEventLogEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    //Utils
    getAllTerminalId() {
        let terminalArray = this.cache.Terminals;
        let terminalArrayRequest = [];
        for (let i = 0; i < terminalArray.length; i++) {
            terminalArrayRequest.push(terminalArray[i].TerminalID);
        }
        return terminalArrayRequest;
    }

    private static handleError(error: any) {
        return observableThrowError(error);
    }



}

