import { Injectable } from "@angular/core";

import { User } from "../_models/index";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) {
    }

    verify() {
        let s = 'Verify Call active';
        // console.log("test Verify s" + s);
        //return this.http.get('/api/verify', this.jwt()).pipe(map((response: Response) => response.json()));
        return true;
    }

    forgotPassword(email: string) {
        return this.http.post('/api/forgot-password', JSON.stringify({ email }), this.jwt())//.map((response: Response) => response.json());
    }
    /*
      getAll() {
        return this.http.get('/api/users', this.jwt()).map((response: Response) => response.json());
      }
    
      getById(OperatorID: number) {
        return this.http.get('/api/users/' + OperatorID, this.jwt()).map((response: Response) => response.json());
      }
    */
    create(user: User) {
        return this.http.post('/api/users', user, this.jwt())//.map((response: Response) => response.json());
    }
    /*
      update(user: User) {
        return this.http.put('/api/users/' + user.OperatorID, user, this.jwt()).map((response: Response) => response.json());
      }
    
      delete(OperatorID: number) {
        return this.http.delete('/api/users/' + OperatorID, this.jwt()).map((response: Response) => response.json());
      }
    */
    // private helper methods

    private jwt() {
        // create authorization header with jwt token
        // console.log("inside JWT -> ");
        let productionMode = 'active';
        if (localStorage.getItem('currentUser')) {
        }
        else {
            localStorage.setItem('currentUser', '{}');
        }
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (productionMode === 'active') {
            if (currentUser && currentUser.SessionKey) {
                //let abc = 'inside JWT';
                //console.log("JWT" + abc);
                let testToken = '123456789';
                //let headers = new Headers({'Authorization': 'Bearer ' + testToken});
                const httpOptions = {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + currentUser.SessionKey })
                };
                //let headers : new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + testToken })
                //let headers = new Headers({'Authorization': 'Bearer ' + currentUser.token});

                return httpOptions;
            }
        }
        else {
            if (currentUser && currentUser.token) {
                //let abc = 'inside JWT';
                //console.log("JWT" + abc);
                const httpOptions = {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + currentUser.token })
                };
                //let headers = new Headers({'Authorization': 'Bearer ' + currentUser.token});
                return httpOptions;
            }
        }
    }
}
