import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'terminalStates'
})
export class TerminalStatesPipe implements PipeTransform {

    transform(value: number): string {
        switch (value) {
            case 0:
                return "ENUMS.TERMINAL_STATE.OUT_OF_SERVICE";
            case 1:
                return "ENUMS.TERMINAL_STATE.READY";
            case 2:
                return "ENUMS.TERMINAL_STATE.IN_TRANSACTION";
            case 3:
                return "ENUMS.TERMINAL_STATE.ERROR";
            case 4:
                return "ENUMS.TERMINAL_STATE.BLOCKED";
            case 5:
                return "ENUMS.TERMINAL_STATE.MANUFACTURED_BLOCKED"
            case 101:
                return "ENUMS.TERMINAL_STATE.SHUT_OFF"
            default:
                return "ENUMS.TERMINAL_STATE.NONE";

        }
    }
}
