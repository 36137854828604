<div class="terminal terminal-icon-{{terminal.TerminalID}}" [ngClass]="{'no-art-connection' : terminal.Status === 0}" *ngIf="terminal.Visible === true">
    <div class="status-lights" *ngIf="terminal.Status === 0">
        <div class="lights-inner">
            <div class="light">
                <div class="red-light inactive"></div>
            </div>
            <div class="light">
                <div class="green-light inactive"></div>
            </div>
            <div class="light">
                <div class="white-light inactive"></div>
            </div>
        </div>
    </div>
    <div class="status-lights" *ngIf="terminal.Status !== 0">
        <div class="lights-inner">
            <div class="light">
                <div class="red-light" [ngClass]="{'inactive' : terminal.UpperLamp === 1, 'constant' : terminal.UpperLamp === 2, 'flash' : terminal.UpperLamp >= 3}"></div>
            </div>
            <div class="light">
                <div class="green-light" [ngClass]="{'inactive' : terminal.MiddleLamp === 1, 'constant' : terminal.MiddleLamp === 2, 'flash' : terminal.MiddleLamp >= 3}"></div>
            </div>
            <div class="light">
                <div class="white-light" [ngClass]="{'inactive' : terminal.LowerLamp === 1, 'constant' : terminal.LowerLamp === 2, 'flash' : terminal.LowerLamp >= 3}"></div>
            </div>
        </div>
    </div>
    <div class="terminal-module" *ngIf="terminal.TerminalType != 17">
        <div class="terminal-jackpot {{terminal.TemplateParams.JosClass}}" title="{{terminal.TemplateParams.JosTitle | translate}}" >
            <span class="centerabsolute">JOS</span>
        </div>



        <div class="terminal-atm {{terminal.TemplateParams.AtmClass}}"
         title="{{terminal.TemplateParams.AtmTitle | translate}}" *ngIf="terminal.AtmState !== -1 && terminal.AtmState !== 0">
            <span class="centerabsolute">ATM</span>
        </div>

        <div class="terminal-atm no-information" title="{{'TERMINAL.TERMINAL_ICON.MODULE.ATM_NO_INFORMATION' | translate}}" *ngIf="terminal.AtmState === 0">
            <div class="no-info-icon">
                <img src="assets/app/media/img/icons/no-info-icon.png" alt="" />
            </div>
            <span class="centerabsolute">ATM</span>
        </div>


        <div class="terminal-cos {{terminal.TemplateParams.CosClass}}"
             title="{{terminal.TemplateParams.CosTitle | translate}}">
            <span class="centerabsolute">COS</span>
        </div>
    </div>
    <div class="terminal-inner" [ngClass]="{'out-of-service' : terminal.TerminalState === TerminalState.OutOfService, 'blocked' :  terminal.TerminalState === TerminalState.Blocked, 'error' :  terminal.TerminalState === TerminalState.Failure, 'manufactured-blocked' :  terminal.TerminalState === TerminalState.ManufacturedBlocked}" title="{{'TERMINAL.TERMINAL_ICON.GENERAL.OPEN_DETAIL' | translate}}">
        <div class="terminal-header">
            <div class="connection-icons">
                <div class="status-inner centerrelative {{terminal.TemplateParams.TerminalStatusClass}}"  title="{{terminal.TemplateParams.TerminalStatusTitle | translate}}">
                    <img class="centerabsolute" src="{{terminal.TemplateParams.TerminalStatusImage}}" alt="" />
                </div>
            </div>
            <div class="status-icons">
                <div class="status-inner centerrelative technician"  title="{{'TERMINAL.TERMINAL_ICON.STATUS.TERMINAL_TECHNICIAN' | translate}}" *ngIf="terminal.OperatorLoginState === 4">
                    <img class="centerabsolute" src="assets/app/media/img/icons/technician-icon.png" alt="" />
                </div>
                <div class="status-inner centerrelative operator active" title="{{'TERMINAL.TERMINAL_ICON.STATUS.TERMINAL_OPERATOR' | translate}}" *ngIf="terminal.OperatorLoginState === 2">
                    <img class="centerabsolute" src="assets/app/media/img/icons/operator-icon.png" alt=""/>
                </div>
            </div>
            <div class="transaction-icon">
                <div class="status-inner centerrelative transaction active"  title="{{'TERMINAL.TERMINAL_ICON.TRANSACTION.ACTIVE' | translate}}" *ngIf="terminal.TransactionState === 1">
                    <img class="centerabsolute" src="assets/app/media/img/icons/transaction-icon.png" alt="" />
                </div>
            </div>
        </div>
        <div #img class="terminal-image" (click)="toggleDetailOpen($event, terminal)">
            <img #img class="{{terminal.TerminalTypeClass}}-terminal" src="assets/app/media/img/products/{{terminal.TerminalTypeClass}}.png" alt=""/>
        </div>
        <div class="terminal-name" (click)="toggleDetailOpen($event, terminal)">{{terminal.TerminalName}}</div>
    </div>
</div>

<!--TEST ICONS A LOT-->
