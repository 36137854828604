import { Component, OnInit, ViewEncapsulation, AfterViewInit, NgZone } from '@angular/core';
import { Helpers } from '../../../helpers';
import { GlobalCacheVariable } from '../../../auth/_globals/globals';
import { Router } from "@angular/router";
import { ArtInstallationMode } from "../../../auth/_enums/group/artInstallationMode";
import { TranslatePipe } from "@ngx-translate/core";
import { UserRight } from "../../../auth/_enums/group/userRight";
import * as moment from "moment";
import { GroupServerService } from "../../../auth/_services/groupserver.service";
import { BasicSearchResultType } from "../../../auth/_enums/group/basicSearchResultType";
import { MessageService } from "primeng/api";
import { fromEvent, Subscription } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { MessageSocketService } from "../../../auth/_services/message-socket.service";
import { WebsocketService } from "../../../auth/_services/websocket.service";;

declare let mLayout: any;

@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [TranslatePipe],
})
export class HeaderNavComponent implements OnInit, AfterViewInit {

    currentOperatorSessionView: boolean = false;
    displayCurrentOperatorSessionView: boolean = false;

    artAboutView: boolean = false;
    displayArtAboutView: boolean = false;

    logfilesView: boolean = false;
    displayLogfilesView: boolean = false;

    //Notification
    notificationInterval;
    notificationIntervalTime: number = 10000;

    //SearchSystem
    searchCategories: any = [];
    searchResults: any;
    artDetailsTerminalView: boolean = false;
    artDetailsPropertyView: boolean = false;
    artInstallationDetailsView: boolean = false;

    currentTerminalDetailsID: any;
    currentPropertyDetailsID: any;
    currentARTDetailsID: any;
    currentTerminalDetails: any;
    ARTInstallation: any;
    currentPropertyDetails: any;
    currentARTDetailName: string;
    private subscription: Subscription;


    constructor(
        public globalCacheVariable: GlobalCacheVariable,
        private _router: Router,
        private translate: TranslatePipe,
        private zone: NgZone,
        private groupServerService: GroupServerService,
        private messageService: MessageService,
        private messageSocketService: MessageSocketService,
        private websocketService: WebsocketService,
    ) {
        this.searchResults = {
            arts: [],
            terminals: [],
            properties: [],
            operators: [],
            distributors: [],
            customers: []
        }

        this.searchCategories = [
            {
                label: this.translate.transform('STATISTICS.ART_OVERVIEW.ART_INSTALLATION_MODE.IN_DEVELOPMENT'),
                value: ArtInstallationMode.InDevelopment,
                disabled: false,
                class: "--orange-color"
            },
            {
                label: this.translate.transform('STATISTICS.ART_OVERVIEW.ART_INSTALLATION_MODE.IN_QUALITY_ASSURANCE'),
                value: ArtInstallationMode.InQualityAssurance,
                disabled: false,
                class: "--orange-color"
            },
            {
                label: this.translate.transform('STATISTICS.ART_OVERVIEW.ART_INSTALLATION_MODE.IN_TEST_LAB_USE'),
                value: ArtInstallationMode.InTestLabUse,
                disabled: false,
                class: "--orange-color"
            },
            {
                label: this.translate.transform('STATISTICS.ART_OVERVIEW.ART_INSTALLATION_MODE.IN_PRODUCTION_USE'),
                value: ArtInstallationMode.InProductionUse,
                disabled: false,
                class: "--green-color"
            },
            {
                label: this.translate.transform('STATISTICS.ART_OVERVIEW.ART_INSTALLATION_MODE.DECOMMISSIONED'),
                value: ArtInstallationMode.Decommissioned,
                disabled: false,
                class: "--red-color"
            },
        ];
    }

    logout() {
        this.endWebsocket();
    }

    public endWebsocket() {
        /*
        console.log("this.messageSocketService ", this.messageSocketService);
        this.websocketService.unsubscribe();
        this.websocketService.complete();
        this.websocketService.subject.complete();
        this.websocketService.subject.unsubscribe();
        //unsubscribe
        this.messageSocketService.messages.unsubscribe();
        this._router.navigate(['/logout']);

         */
    }

    openTerminalDetails(searchEntry) {
        console.log("searchEntry T ", searchEntry);
        this.getArtDetailsData(searchEntry, 'Terminal');
    }

    openPropertyDetails(property) {
        this.getArtDetailsData(property, 'Property');
    }

    openArtDetails(art) {
        this.getArtDetailsData(art, 'Art');
        /*
        this.currentARTDetailsID = artID.artID;
        this.currentARTDetailName = artID.artName;
        this.artInstallationDetailsView = true;
        this.zone.run(() => {
            this.displayArtDetails = true;
        });

         */
    }

    closeSearch() {
        const searchDropdowns = Array.from(
            document.getElementsByClassName('m-dropdown__body') as HTMLCollectionOf<HTMLElement>,
        );
        searchDropdowns.forEach(searchDropdown => {
            searchDropdown.style.display = 'none';
        });
    }

    clearSearch() {
        this.searchResults = {
            arts: [],
            terminals: [],
            properties: [],
            operators: [],
            distributors: [],
            customers: []
        }
        const searchDropdowns = Array.from(
            document.getElementsByClassName('m-dropdown__body') as HTMLCollectionOf<HTMLElement>,
        );
        searchDropdowns.forEach(searchDropdown => {
            searchDropdown.style.display = 'none';
        });

        const searchStrings = Array.from(
            document.getElementsByClassName('m-list-search__form-input') as HTMLCollectionOf<HTMLElement>,
        );
        searchStrings[0].offsetParent[0].value = '';

        this.globalCacheVariable.Search.selectedKeyword = '';
    }

    closeARTDetails() {
        //console.log("close aART details")
        this.artInstallationDetailsView = false;
    }

    closePropertyDetails() {

    }

    closeTerminalDetails() {

    }

    getArtDetailsData(searchEntry, state) {
        console.log("searchEntry ADD ", searchEntry);
        this.groupServerService.getArtInstallationDetails(searchEntry.ArtId).subscribe(data => {
            //console.log("data ADD ", data);


            switch (state) {
                case 'Terminal':
                    if (data.Terminals.length > 0) {
                        for (let t = 0; t < data.Terminals.length; t++) {
                            if (searchEntry.Id == data.Terminals[t].TerminalId) {
                                this.ARTInstallation = data;
                                this.currentARTDetailsID = data.ArtId;
                                this.currentARTDetailName = data.ArtName;
                                this.currentTerminalDetails = data.Terminals[t];
                                this.currentTerminalDetails.TerminalFull = data.Terminals[t];
                                this.currentTerminalDetailsID = data.Terminals[t].TerminalId;
                                this.zone.run(() => {
                                    this.artDetailsTerminalView = true;
                                });
                                break;
                            }
                        }
                    }
                    break;

                case 'Property':
                    if (data.Properties.length > 0) {
                        for (let p = 0; p < data.Properties.length; p++) {
                            if (searchEntry.Id == data.Properties[p].PropertyNo) {
                                this.ARTInstallation = data;
                                this.currentARTDetailsID = data.ArtId;
                                this.currentARTDetailName = data.ArtName;
                                this.currentPropertyDetails = data.Properties[p];
                                this.currentPropertyDetails.FullPropertyInformation = data.Properties[p];
                                this.currentPropertyDetailsID = data.Properties[p].PropertyNo;
                                this.zone.run(() => {
                                    this.artDetailsPropertyView = true;
                                });
                                break;
                            }
                        }
                    }
                    break;

                case 'Art':
                    this.ARTInstallation = data;
                    this.currentARTDetailsID = data.ArtId;
                    this.currentARTDetailName = data.ArtName;
                    this.zone.run(() => {
                        this.artInstallationDetailsView = true;
                    });
                    break;

            }
            /*
            this.arts = [];
            this.artIDs = [];
            this.currentShownArts = 0;
            for(let i = 0; i < data.length; i++){
                let currentTerminals = [];

                let visible = false;
                if(this.selectedArtInstallationMode === Number(data[i].ArtMode)){
                    visible = true;
                    this.currentShownArts++;
                }
                let name = data[i].ArtName;
                if(name === ''){
                    name = data[i].ArtId;
                }
                this.arts.push({
                    artID:data[i].ArtId,
                    artName: name,
                    artMode: Number(data[i].ArtMode),
                    userCount:Math.floor(Math.random() * 101),
                    terminalCount: data[i].TerminalsCount,
                    lastSync: moment(data[i].Timestamp).format(this.globalCacheVariable.DefaultTimeFormatMoment),
                    address: {
                        street: data[i].AddressExt.PostalAddress.Street,
                        zipCode: data[i].AddressExt.PostalAddress.ZipCode,
                        city: data[i].AddressExt.PostalAddress.City,
                        state: data[i].AddressExt.PostalAddress.State,
                        country: data[i].AddressExt.PostalAddress.Country
                    },
                    artTransactions: {},
                    artFullInformation: data[i],
                    visible: visible,
                });
                this.artIDs.push(data[i].ArtId);

            }

            for (let a = 0; a < this.arts.length; a++) {
                this.ARTS.set(this.arts[a].artID, this.arts[a]);
            }

            this.globalCacheVariable.artNames = this.ARTS;
            //console.log("this.globalCacheVariable.artNames ", this.globalCacheVariable.artNames);
            this.allArts = this.arts.length;
            this.artFilterService.arts = this.arts;
            this.viewReady = true;
            this.groupServerService.getMonthlyTransactionTotals( moment().subtract(30, 'days').toDate(), moment().toDate(), this.artIDs).subscribe(data => {
                for(let t = 0; t < data.length; t++){
                    for(let a = 0; a < this.arts.length; a++){
                        if(data[t].ArtId === this.arts[a].artID){
                            this.arts[a].artTransactions = data[t];
                        }
                    }
                }
            });
            */
        });
    }



    searchLoad() {

        const searchStrings = Array.from(
            document.getElementsByClassName('m-list-search__form-input') as HTMLCollectionOf<HTMLElement>,
        );
        var searchStringValue = searchStrings[0].offsetParent[0].value;

        this.globalCacheVariable.Search.selectedKeyword = searchStringValue;
        if (this.globalCacheVariable.Search.selectedKeyword != '') {
            if (this.globalCacheVariable.Search.selectedKeyword.length >= 4) {
                this.groupServerService.BasicSearch(this.globalCacheVariable.Search.selectedKeyword)
                    .subscribe(data => {
                        this.zone.run(() => {
                            this.searchResults = {
                                arts: [],
                                terminals: [],
                                properties: [],
                                operators: [],
                                distributors: [],
                                customers: []
                            }
                            for (let d = 0; d < data.length; d++) {
                                switch (data[d].Type) {
                                    case BasicSearchResultType.None:
                                        break;
                                    case BasicSearchResultType.ArtInstallation:
                                        this.searchResults.arts.push(data[d]);
                                        break;
                                    case BasicSearchResultType.Property:
                                        this.searchResults.properties.push(data[d]);
                                        break;
                                    case BasicSearchResultType.Terminal:
                                        this.searchResults.terminals.push(data[d]);
                                        break;
                                    case BasicSearchResultType.Operator:
                                        this.searchResults.operators.push(data[d]);
                                        break;
                                    case BasicSearchResultType.Distributor:
                                        break;
                                    case BasicSearchResultType.Customer:
                                        break;
                                }
                            }
                            //console.log("searchResults ", this.searchResults);
                            const searchDropdowns = Array.from(
                                document.getElementsByClassName('m-dropdown__body') as HTMLCollectionOf<HTMLElement>,
                            );
                            searchDropdowns.forEach(searchDropdown => {
                                searchDropdown.style.display = 'block';
                            });

                            //var searchDropdown = document.getElementsByClassName("m-dropdown__body");
                            //searchDropdown.style.setProperty('display', 'block');
                            //$("searchForm").visible = true;
                        })
                    });
            } else {
                this.messageService.add({
                    key: 'searchMessage',
                    severity: 'error',
                    summary: 'Minimum 4 chars',
                    life: 3500,
                    detail: 'Please use minimum 4 characters for search'
                });
            }
        }
        /*
       this._router.navigate(['/group-search', this.globalCacheVariable.Search.selectedSearchId,
           this.globalCacheVariable.Search.selectedKeyword
       ]);
         */

    }

    get isAllowedUserManagement() {
        return this.globalCacheVariable.checkUserRight(UserRight.UsersView);
    }

    openCurrentOperator() {
        this.currentOperatorSessionView = true;
        this.displayCurrentOperatorSessionView = true;
    }

    openArtAbout() {

        this.artAboutView = true;
        this.displayArtAboutView = true;
    }

    closeArtAboutDialog() {
        this.artAboutView = false;
        this.displayArtAboutView = false;
    }

    openLogfiles(log, index) {
        let currentLogFilesLink = this.globalCacheVariable.ARTServerBaseIP + '/RequestedLogFiles/' + log.taskID + '/' + log.fileName;
        window.open(currentLogFilesLink, "_blank");
        this.globalCacheVariable.notifications.Logs.splice(index, 1);
    }

    closeLogfiles(log) {

        //5f1a5b5c-ac30-443c-aa9b-b91739996245
        //CASHIO_Silver_1414_Logs_2022-07-22-00-00-00_2022-07-23-00-00-00_5f1a5b5c-ac30-443c-aa9b-b91739996245
        //this.logfilesView = false;
        //this.displayLogfilesView = false;
    }

    closeEventMessage(event, index) {
        this.globalCacheVariable.notifications.Events.splice(index, 1);
    }

    closeEventMessages() {
        this.globalCacheVariable.notifications.Events = [];
    }

    toggleAsideNavbar() {
        if (this.globalCacheVariable.sideNavbarMinimizedState === false) {
            $("body").addClass('m-brand--minimize m-aside-left--minimize');
            $("#m_aside_left_offcanvas_toggle").addClass('m-brand__toggler--active');
            this.globalCacheVariable.sideNavbarMinimizedState = true;
        } else {
            $("body").removeClass('m-brand--minimize m-aside-left--minimize');
            $("#m_aside_left_offcanvas_toggle").removeClass('m-brand__toggler--active');
            this.globalCacheVariable.sideNavbarMinimizedState = false;
        }
        /*
              $("#m_aside_left_minimize_toggle").mToggle({
                  target: "body",
                  targetState: "m-brand--minimize m-aside-left--minimize",
                  togglerState: "m-brand__toggler--active"
              }).on("toggle", function () {
                  e.pauseDropdownHover(800), t.pauseDropdownHover(800)
              }), $("#m_aside_left_hide_toggle").mToggle({
                  target: "body",
                  targetState: "m-aside-left--hide",
                  togglerState: "m-brand__toggler--active"
              }).on("toggle", function () {
                  e.pauseDropdownHover(800), t.pauseDropdownHover(800)
              })*/
    }

    ngOnInit() {
        /*
        this.globalCacheVariable.notifications.Events.push({
            terminalName: "Testname",
            terminalID: 9060,
            terminalEvent: 10,
            timestamp: 1685947648
        });

         */

        var self = this;
        if (this.globalCacheVariable.singleWebClient()) {
            self.notificationInterval = setInterval(function() {
                let currentTime = moment().toISOString(true);
                if (self.globalCacheVariable.notification) {
                    let notifications = self.globalCacheVariable.notifications;
                    let notificationCount = 0;
                    let alertCount = 0;
                    let eventCount = 0;
                    let logCount = 0;

                    if (notifications.Alerts.length > 0) {
                        for (let a = 0; a < notifications.Alerts.length; a++) {

                        }
                        alertCount = notifications.Alerts.length;
                    } else {

                    }

                    if (notifications.Events.length > 0) {
                        for (let a = 0; a < notifications.Events.length; a++) {
                            notifications.Events[a].timestampShown = moment(notifications.Events[a].timestamp).fromNow();
                        }
                        eventCount = notifications.Events.length;
                    } else {

                    }

                    if (notifications.Logs.length > 0) {
                        for (let a = 0; a < notifications.Logs.length; a++) {
                            notifications.Logs[a].timestampShown = moment(notifications.Logs[a].timestamp).fromNow();
                        }
                        logCount = notifications.Logs.length;
                    } else {

                    }
                    notificationCount = alertCount + eventCount + logCount;
                    self.globalCacheVariable.notifications.Count = notificationCount;
                    //console.log("self.globalCacheVariable.notifications.Count ", self.globalCacheVariable.notifications.Count);
                    //console.log("self.globalCacheVariable.notifications.Logs ", self.globalCacheVariable.notifications.Logs);
                } else {
                    self.globalCacheVariable.notifications.Count = 0;
                }


            }, this.notificationIntervalTime);
        }
    }

    ngAfterViewInit() {


        //mLayout.initHeader();
        /*
        //Animationscode for the notificationbell
        d = function () {
            $("#m_aside_header_topbar_mobile_toggle").click(function () {
                $("body").toggleClass("m-topbar--on")
            }), setInterval(function () {
                $("#m_topbar_notification_icon .m-nav__link-icon").addClass("m-animate-shake"), $("#m_topbar_notification_icon .m-nav__link-badge").addClass("m-animate-blink")
            }, 3e3), setInterval(function () {
                $("#m_topbar_notification_icon .m-nav__link-icon").removeClass("m-animate-shake"), $("#m_topbar_notification_icon .m-nav__link-badge").removeClass("m-animate-blink")
            }, 6e3)
        },
         */
    }

}
